import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { JobExamQuestionSelectField_query } from './__generated__/JobExamQuestionSelectField_query.graphql';

type JobExamQuestion = {
  id: string;
  title: string;
};

type Props = {
  query: JobExamQuestionSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: JobExamQuestion;
};

type State = {
  input: string;
  search: string;
};

class JobExamQuestionSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar questão da prova',
    name: 'jobExam.question',
  };

  componentDidUpdate(prevProps) {
    if (
      this.props?.values?.jobExam?.id != null &&
      this.props?.values?.jobExam?.id !== prevProps?.values?.jobExam?.id
    ) {
      const refetchVariables = (fragmentVariables) => ({
        ...fragmentVariables,
        jobExamId: this.props.values.jobExam.id,
      });

      this.props.relay.refetch(refetchVariables, null, () => {}, {
        force: true,
      });
    }
  }

  getItemFromNode = ({ id, title }) => {
    return {
      id,
      name: title,
    };
  };

  getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      search,
    };
  };

  toIdName = (question) => ({
    id: question.id,
    title: question.title,
  });

  getConnection = () => {
    const { query } = this.props;
    const { jobExam } = query;

    if (!jobExam) {
      return { count: 0, edges: [] };
    }

    return {
      count: jobExam.questionsWeight.length,
      edges: jobExam.questionsWeight.map(({ question }) => ({
        node: this.toIdName(question),
      })),
    };
  };

  render() {
    const { query, relay, ...props } = this.props;
    const { jobExam } = query;

    const emptyMessage = jobExam
      ? 'Prova não possui questões'
      : 'Escolha uma prova';

    return (
      <ResourceSelectField
        relay={relay}
        connection={this.getConnection()}
        getItemFromNode={this.getItemFromNode}
        emptyMessage={emptyMessage}
        {...props}
      />
    );
  }
}

const JobExamQuestionSelectFieldRefetchContainer = createRefetchContainer(
  JobExamQuestionSelectField,
  {
    query: graphql`
      fragment JobExamQuestionSelectField_query on Query
      @argumentDefinitions(jobExamId: { type: ID }) {
        jobExam: node(id: $jobExamId) {
          id
          ... on JobExam {
            questionsWeight {
              question {
                id
                title
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobExamQuestionSelectFieldRefetchQuery($jobExamId: ID) {
      ...JobExamQuestionSelectField_query @arguments(jobExamId: $jobExamId)
    }
  `,
);

export default JobExamQuestionSelectFieldRefetchContainer;
