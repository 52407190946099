import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/manager';

const managerRoutes: RouteType[] = [
  {
    name: 'manager.invitations',
    path: `${PATH}/employees/invitations`,
    labelHeader: ({ t }) => t('Manage My Team'),
    component: loadable(
      () => import('../../../components/manager/UserManagerInvitationDetails'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.CORE.USER.MANAGE_TEAM],
    requiredFeatures: [MODULES.CORE, MODULES.TEAM_MANAGEMENT],
    routes: [
      {
        name: 'manager.invitations.list',
        path: `${PATH}/employees/invitations`,
        labelHeader: ({ t }) => t('Manage My Team'),
        component: loadable(
          () => import('../../../components/manager/UserManagerInvitation'),
        ),
        exact: false,
        requiredRoles: [ADMIN_ROLE, ROLES.CORE.USER.MANAGE_TEAM],
        requiredFeatures: [MODULES.CORE, MODULES.TEAM_MANAGEMENT],
        modalRoutes: [
          {
            name: 'manager.invitations.invite',
            path: `${PATH}/employees/invitations/invite`,
            labelHeader: ({ t }) => t('Invite employee to your team'),
            modalTitle: ({ t }) => t('Invite employee to your team'),
            component: loadable(
              () =>
                import(
                  '../../../components/manager/ManagerInviteUser/ManagerInviteUser'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.CORE.USER.MANAGE_TEAM],
            requiredFeatures: [MODULES.CORE, MODULES.TEAM_MANAGEMENT],
          },
          {
            name: 'manager.invitations.details.id',
            path: `${PATH}/employees/invitations/details/:id`,
            labelHeader: ({ t }) => t('Transfer employee'),
            modalTitle: ({ t }) => t('Transfer employee'),
            component: loadable(
              () =>
                import(
                  '../../../components/manager/ManagerInviteUser/SelectManagerToTransfer'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.CORE.USER.MANAGE_TEAM],
            requiredFeatures: [MODULES.CORE, MODULES.TEAM_MANAGEMENT],
          },
        ],
      },
    ],
  },
];

export default managerRoutes;
