/**
 * @generated SignedSource<<17d03d01b82811a8a74a70b2ccbea498>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Brand_company$data = {
  readonly id: string;
  readonly logo: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly nameFriendly: string | null | undefined;
  readonly " $fragmentType": "Brand_company";
};
export type Brand_company$key = {
  readonly " $data"?: Brand_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"Brand_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Brand_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameFriendly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "logo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "fitIn",
              "value": true
            }
          ],
          "kind": "ScalarField",
          "name": "url",
          "storageKey": "url(fitIn:true)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "5b9bbbe8e99ad2fffa5359d3b1fd5a4a";

export default node;
