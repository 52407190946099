/**
 * @generated SignedSource<<7651f9ff4a4125b02e42bb0ad945a1b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCrisp_user$data = {
  readonly company: {
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "useCrisp_user";
};
export type useCrisp_user$key = {
  readonly " $data"?: useCrisp_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCrisp_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useCrisp_user"
};

(node as any).hash = "cb0daac6a91ce7879a0105eece7cfd18";

export default node;
