import Tooltip from '@material-ui/core/Tooltip';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { space } from 'styled-system';

import Avatar from './Avatar';

import type { UserAvatar_user } from './__generated__/UserAvatar_user.graphql';

const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${space}
`;

type Props = {
  user: UserAvatar_user;
  size?: number;
  withName?: boolean;
  withTooltip?: boolean;
};

const UserAvatar = ({
  user,
  size = 35,
  withName,
  withTooltip = false,
  ...props
}: Props) => {
  const url = user?.profileImage?.url;
  const userName = user?.name;

  const renderContent = () => {
    return (
      <Wrapper {...props}>
        <Avatar name={userName} imageUrl={url} size={size} />
        {withName && (
          <NameWrapper>
            <Name>{userName}</Name>
          </NameWrapper>
        )}
      </Wrapper>
    );
  };

  if (withTooltip) {
    return (
      <Tooltip title={userName} placement='top'>
        {renderContent()}
      </Tooltip>
    );
  }

  return renderContent();
};

export default createFragmentContainer(UserAvatar, {
  user: graphql`
    fragment UserAvatar_user on User
    @argumentDefinitions(
      width: { type: "Int", defaultValue: 50 }
      height: { type: "Int", defaultValue: 50 }
    ) {
      name
      profileImage {
        url(width: $width, height: $height)
      }
    }
  `,
});
