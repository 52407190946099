/**
 * @generated SignedSource<<72296a30f37f2e30f0966cdc993b3521>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackUtils_user$data = {
  readonly id: string;
  readonly isManager: boolean;
  readonly " $fragmentType": "FeedbackUtils_user";
};
export type FeedbackUtils_user$key = {
  readonly " $data"?: FeedbackUtils_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackUtils_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "FeedbackUtils_user"
};

(node as any).hash = "bae82782709212bff0571c796e390cbb";

export default node;
