import { commitMutation, graphql } from 'react-relay';

import { Environment } from '../../../relay/index';

import type {
  UserNotificationMarkAllAsOpenedInput,
  UserNotificationMarkAllAsOpenedResponse,
} from './__generated__/UserNotificationMarkAllAsOpenedMutation.graphql';

const mutation = graphql`
  mutation UserNotificationMarkAllAsOpenedMutation(
    $input: UserNotificationMarkAllAsOpenedInput!
  ) {
    UserNotificationMarkAllAsOpened(input: $input) {
      error
      me {
        unreadNotificationsCount
        notifications(first: 10)
          @connection(key: "Notification_notifications", filters: []) {
          edges {
            node {
              id
              openedAtWeb
            }
          }
        }
      }
    }
  }
`;

function commit(
  input: $Diff<UserNotificationMarkAllAsOpenedInput, { source: string }>,
  onCompleted?: (response: UserNotificationMarkAllAsOpenedResponse) => void,
  onError?: (error: Error) => void,
) {
  const variables = {
    input: {
      ...input,
      source: 'WEB',
    },
  };

  return commitMutation(Environment, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };
