import FontIcon from '@material-ui/core/Icon';

type Props = {
  children: React.ReactNode;
  color?: string;
  style?: string;
  onClick?: string;
  title?: string;
};
const Icon = ({ children, color, style, onClick, title, ...props }: Props) => (
  <FontIcon
    className='material-icons'
    style={style}
    onClick={onClick}
    title={title}
    {...props}
  >
    {children}
  </FontIcon>
);

export default Icon;
