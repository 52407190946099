import {
  findRouteTreePath as findRouteTreePathRouter,
  getRouteFromPath as getRouteFromPathRouter,
  getRouteTo,
  hasMatchedLegacy as hasMatchedLegacyRouter,
  safeGoBackRouter,
} from '@feedback/router';

import type { LoadableComponent } from '@loadable/component';

import type { modulesNamesFragment } from '../__generated__/modulesNamesFragment.graphql';

import { getRoutes } from '../routes';

import { legacyRoutes } from './legacyRoutes';

export * from '@feedback/router';
export const routeTo = getRouteTo(getRoutes());
export const getRouteFromPath = getRouteFromPathRouter(getRoutes());
export const hasMatchedLegacy = hasMatchedLegacyRouter(legacyRoutes);
export const findRouteTreePath = findRouteTreePathRouter(getRoutes());
export const safeGoBack = safeGoBackRouter(routeTo);

type LabelParams = {
  t: (value: string) => string;
  company: modulesNamesFragment;
};
export type RouteType = {
  type?: string;
  name: string;
  path: string;
  component: LoadableComponent<any> | React.ComponentType<any>;
  exact?: boolean;
  routes?: RouteType[];
  modalRoutes?: RouteType[]; // routes for modal
  labelHeader?: string | (({ t, company }: LabelParams) => string);
  label?: string | (({ t, company }: LabelParams) => string); // label for Tabs
  modalTitle?: string | (({ t, company }: LabelParams) => string);
  requiredRoles?: string[];
  requiredFeatures?: string[];
  requiredProjectsTypes?: string[];
  from?: string;
  to?: string;
  buttons?: ({ t }: { t: any }) => [
    {
      label: string;
      link: string;
    },
  ];
};

type LocationState = { nextPathName: string; search: string };

export const getNextPathName = (location: LocationState) => {
  if (location.pathname) {
    return `${location.pathname}${location.search ? location.search : ''}`;
  }

  return '';
};
