import loadable from '@loadable/component';

import { MODULES, ROLES, ADMIN_ROLE } from '@feedback/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/complaints';

// MODULE: OMBUDSMAN

const complaintsRoutes: RouteType[] = [
  /* USER VIEW */
  {
    name: 'complaints.new',
    path: `${PATH}/new`,
    labelHeader: 'Abrir Caso',
    component: loadable(
      () => import('../../../components/ombudsman/internal/ComplaintNew'),
    ),
    exact: true,
    requiredRoles: [
      ROLES.OMBUDSMAN.COMPLAINT.ALL,
      ROLES.OMBUDSMAN.USER.ALL,
      ROLES.OMBUDSMAN.CREATE_CASE,
    ],
    requiredFeatures: [MODULES.OMBUDSMAN],
  },
  {
    name: 'complaints.user.list',
    path: `${PATH}/user/list`,
    labelHeader: 'Lista de Casos',
    component: loadable(
      () => import('../../../components/ombudsman/internal/ComplaintUserList'),
    ),
    exact: true,
    requiredRoles: [
      ROLES.OMBUDSMAN.COMPLAINT.ALL,
      ROLES.OMBUDSMAN.USER.ALL,
      ROLES.OMBUDSMAN.SEE_CASE,
    ],
    requiredFeatures: [MODULES.OMBUDSMAN],
  },
  {
    name: 'complaints.user.details',
    path: `${PATH}/user/:id/details`,
    labelHeader: 'Detalhes - Caso',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/internal/details/ComplaintDetails'
        ),
    ),
    exact: false,
    requiredRoles: [
      ROLES.OMBUDSMAN.COMPLAINT.ALL,
      ROLES.OMBUDSMAN.USER.ALL,
      ROLES.OMBUDSMAN.SEE_CASE,
    ],
    requiredFeatures: [MODULES.OMBUDSMAN],
    routes: [
      {
        name: 'complaints.user.details.case',
        labelHeader: 'Caso - Detalhes',
        label: 'Caso',
        path: `${PATH}/user/:id/details/case`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internal/details/ComplaintDetailsCase'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.COMPLAINT.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.user.details.files',
        labelHeader: 'Caso - Detalhes',
        label: 'Anexos',
        path: `${PATH}/user/:id/details/files`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internal/details/ComplaintDetailsFileList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.COMPLAINT.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.user.details.return',
        labelHeader: 'Caso - Detalhes',
        label: 'Chat/Retorno',
        path: `${PATH}/user/:id/details/return`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internal/details/return/ComplaintDetailsReturn'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.COMPLAINT.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
    ],
  },

  /* ANALYTICS VIEW */
  {
    name: 'complaints.view',
    path: `${PATH}/view`,
    label: ({ t }) => t('{{ombudsman}}'),
    labelHeader: ({ t }) => t('{{ombudsman}}'),
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/internalAnalytics/ComplaintInternalAnalytics'
        ),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.OMBUDSMAN],
    routes: [
      {
        name: 'complaints.view.dashboard',
        path: `${PATH}/view/dashboard`,
        label: ({ t }) => t('Dashboard'),
        labelHeader: ({ t }) => t('Dashboard'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/dashboard/ComplaintDashboard'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.DASHBOARD.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.view.list',
        path: `${PATH}/view/list`,
        label: ({ t }) => t('Cases list'),
        labelHeader: ({ t }) => t('Cases list'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/ComplaintList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.view.pivot',
        path: `${PATH}/view/pivot`,
        label: ({ t }) => t('Pivot Table'),
        labelHeader: ({ t }) => t('Pivot Table'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/ComplaintPivotTable'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OMBUDSMAN.DASHBOARD.ALL,
          ROLES.OMBUDSMAN.ANALYTICS.ALL,
          ROLES.OMBUDSMAN.SETTINGS.ALL,
        ],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
    ],
  },
  {
    name: 'complaints.details',
    path: `${PATH}/:id/details`,
    labelHeader: 'Detalhes - Caso',
    component: loadable(
      () =>
        import('../../../components/ombudsman/internalAnalytics/ComplaintView'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.OMBUDSMAN],
    routes: [
      {
        name: 'complaints.details.case',
        labelHeader: 'Caso - Detalhes',
        label: 'Caso',
        path: `${PATH}/:id/details/case`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/details/case/ComplaintViewCase'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.details.files',
        labelHeader: 'Caso - Detalhes',
        label: 'Anexos',
        path: `${PATH}/:id/details/files`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/details/files/ComplaintFileList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.details.events',
        labelHeader: 'Caso - Detalhes',
        label: 'Apuração',
        path: `${PATH}/:id/details/events`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/details/events/ComplaintEventList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.details.actions',
        labelHeader: 'Caso - Detalhes',
        label: 'Ações',
        path: `${PATH}/:id/details/actions`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/details/actions/ComplaintActionFlow'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.details.return',
        labelHeader: 'Caso - Detalhes',
        label: 'Retorno',
        path: `${PATH}/:id/details/return`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/details/return/ComplaintReturn'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
    ],
  },
  {
    name: 'complaints.settings',
    path: `${PATH}/settings`,
    labelHeader: 'Ajustes',
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN],
    routes: [
      {
        name: 'complaints.settings.subjects',
        path: `${PATH}/settings/subjects`,
        labelHeader: 'Configuração de Temas e Ações',
        label: 'Temas',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/subject/ComplaintSubjectList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.settings.actions',
        path: `${PATH}/settings/actions`,
        labelHeader: 'Configuração de Temas e Ações',
        label: 'Ações',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/actions/ComplaintActionsList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.settings.customize',
        path: `${PATH}/settings/customize`,
        labelHeader: 'Customização',
        label: 'Customização',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/customize/ComplaintCustomization'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.settings.form',
        path: `${PATH}/settings/form`,
        labelHeader: 'Formulário',
        label: 'Formulário',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/form/ComplaintForm'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.settings.tags',
        path: `${PATH}/settings/tags`,
        labelHeader: 'Tags',
        label: 'Tags',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/tagModule/TagModuleComplaint'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
      {
        name: 'complaints.settings.notifications',
        path: `${PATH}/settings/notifications`,
        labelHeader: ({ t }) => t('Notifications'),
        label: ({ t }) => t('Notifications'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/notifications/ComplaintSettingsNotifications'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
    ],
  },
  {
    name: 'complaints.settings.actions.new',
    path: `${PATH}/settings/actions/new`,
    labelHeader: 'Criar ação',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/internalAnalytics/settings/actions/ComplaintActionsAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN],
  },
  {
    name: 'complaints.settings.actions.edit',
    path: `${PATH}/settings/actions/edit/:id`,
    labelHeader: 'Editar ação',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/internalAnalytics/settings/actions/ComplaintActionsEdit'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN],
  },
  {
    name: 'complaints.settings.subjects.new',
    path: `${PATH}/settings/subjects/new`,
    labelHeader: 'Criar Tema',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/internalAnalytics/settings/subject/add/ComplaintSubjectAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN],
  },

  {
    name: 'complaints.settings.subjects.edit',
    path: `${PATH}/item-settings/:id`,
    labelHeader: 'Editar Tema',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/internalAnalytics/settings/subject/detail/ComplaintSubjectEdit'
        ),
    ),
    exact: false,
    requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN],
    routes: [
      {
        name: 'complaints.settings.subjects.edit.detail',
        path: `${PATH}/item-settings/:id/detail`,
        labelHeader: 'Editar Tema',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/internalAnalytics/settings/subject/detail/ComplaintSubjectDetail'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN],
      },
    ],
  },
];

export default complaintsRoutes;
