import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';
import { useTranslation } from 'react-i18next';

import type { UserSelectField_query } from './__generated__/UserSelectField_query.graphql';

type Props = {
  query: UserSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  fullWidth?: boolean;
  value?: User;
  hasAddress?: boolean | null;
  hintText?: string;
  name?: string;
};
type User = {
  id: string;
  name: string;
  profileImage: { url: string } | null;
};
const UserSelectField = (props: Props) => {
  const { t } = useTranslation();

  const getItemFromNode = ({ id, name, profileImage }: User) => {
    if (!id && !name) {
      return;
    }

    return {
      id,
      name,
      profileImage: profileImage,
    };
  };

  const { filters = {} } = props;

  const getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      filters: {
        ...filters,
        search,
      },
    };
  };

  const getRenderVariables = (value) => {
    const { search } = value;

    return {
      filters: {
        ...filters,
        search,
      },
    };
  };

  const {
    hintText = t('Search Contributor'),
    name = 'user',
    query,
    relay,
    environment,
    ...rest
  } = props;

  return (
    <ResourceSelectField
      getItemFromNode={getItemFromNode}
      getFilterFragmentVariables={getFilterFragmentVariables}
      getRenderVariables={getRenderVariables}
      relay={relay}
      connection={query.usersSelected}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

export default createRefetchContainer(
  UserSelectField,
  {
    query: graphql`
      fragment UserSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 300 }
        search: { type: String, defaultValue: "" }
        searchWithViewer: { type: Boolean, defaultValue: true }
        hasHeadCount: { type: Boolean }
        groupIdsNotIn: { type: "[ID]" }
        userIdNotIn: { type: "[ID]" }
        filters: { type: UserFilter }
      ) {
        usersSelected: users(
          first: $first
          search: $search
          searchWithViewer: $searchWithViewer
          hasHeadCount: $hasHeadCount
          groupIdsNotIn: $groupIdsNotIn
          userIdNotIn: $userIdNotIn
          filters: $filters
        )
          @connection(
            key: "UserSelectField_usersSelected"
            filters: ["filters"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              email
              active
              profileImage {
                url
              }
              correlations {
                employeeID
              }
              manager {
                id
                name
                email
                active
                profileImage {
                  url
                }
                correlations {
                  employeeID
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query UserSelectFieldQuery(
      $first: Int
      $search: String
      $searchWithViewer: Boolean
      $hasHeadCount: Boolean
      $groupIdsNotIn: [ID]
      $userIdNotIn: [ID]
      $filters: UserFilter
    ) {
      ...UserSelectField_query
        @arguments(
          first: $first
          search: $search
          searchWithViewer: $searchWithViewer
          hasHeadCount: $hasHeadCount
          groupIdsNotIn: $groupIdsNotIn
          userIdNotIn: $userIdNotIn
          filters: $filters
        )
    }
  `,
);
