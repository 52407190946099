/**
 * @generated SignedSource<<8f190e532a5753b00e4bc500b3231335>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserAvatarWithScore_user$data = {
  readonly jobTitle: string | null | undefined;
  readonly meetingRatingsAverage: number | null | undefined;
  readonly name: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
  readonly " $fragmentType": "UserAvatarWithScore_user";
};
export type UserAvatarWithScore_user$key = {
  readonly " $data"?: UserAvatarWithScore_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatarWithScore_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserAvatarWithScore_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAvatar_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingRatingsAverage",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "099e6ae8352e2d600919f867970effca";

export default node;
