import _IconButton from '@material-ui/core/IconButton';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ProfilePicBadge from '../common/ProfilePicBadge';

import { routeTo } from '../../router/utils';

import type { User_me } from './__generated__/User_me.graphql';

const IconButton = styled(_IconButton)`
  color: ${(props) => props.theme.richHeaderTextColor};
`;

type Props = {
  me: User_me;
};
const User = ({ me }: Props) => {
  const history = useHistory();

  const onClick = () => {
    history.push(routeTo('me.account.edit'));
  };

  if (!me) {
    return null;
  }

  const { profileImage } = me;
  const profileImageUrl = profileImage ? profileImage.url : null;

  return (
    <IconButton onClick={onClick}>
      <ProfilePicBadge size={28} profileImage={profileImageUrl} />
    </IconButton>
  );
};

export default createFragmentContainer(User, {
  me: graphql`
    fragment User_me on User {
      profileImage {
        url
      }
    }
  `,
});
