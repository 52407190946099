import { combineReducers } from 'redux';

import login from '../redux/modules/login';
import signUp from '../redux/modules/signUp';

import app from './modules/app';

export default combineReducers({
  app,
  login,
  signUp,
});
