import { useTheme } from '@feedback/hooks';

type Props = {
  width: number;
  height: number;
};
const Poll = ({ width = 30, height = 30 }: Props) => {
  const theme = useTheme();

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 60 71'
    >
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#607389'
          d='M54.604 0H12.54c-.59 0-1.07.482-1.07 1.076 0 .595.48 1.077 1.07 1.077h42.064c1.796 0 3.256 1.47 3.256 3.276v51.346c0 .595.479 1.077 1.07 1.077.59 0 1.07-.482 1.07-1.077V5.43C60 2.436 57.58 0 54.604 0zM58.921 60.549a1.08 1.08 0 0 0-1.079 1.082v3.91a3.294 3.294 0 0 1-3.285 3.295H5.443a3.294 3.294 0 0 1-3.285-3.295V5.46a3.294 3.294 0 0 1 3.285-3.294h2.039c.596 0 1.08-.485 1.08-1.083A1.08 1.08 0 0 0 7.481 0H5.443C2.443 0 0 2.449 0 5.46V65.54C0 68.551 2.442 71 5.443 71h49.114C57.558 71 60 68.551 60 65.541v-3.91a1.08 1.08 0 0 0-1.079-1.082z'
        />
        <path
          fill='#607389'
          fillRule='nonzero'
          d='M15.44 7.012c-4.62 0-8.381 3.736-8.381 8.327 0 4.592 3.76 8.328 8.382 8.328s8.383-3.736 8.383-8.328c0-4.591-3.76-8.327-8.383-8.327zm0 14.46c-3.403 0-6.173-2.75-6.173-6.132 0-3.382 2.77-6.133 6.174-6.133 3.404 0 6.173 2.75 6.173 6.133 0 3.381-2.77 6.132-6.173 6.132z'
        />
        <path
          fill='#607389'
          d='M28.428 10.519h23.438c.593 0 1.075-.393 1.075-.877s-.482-.877-1.075-.877H28.428c-.594 0-1.075.393-1.075.877s.481.877 1.075.877zM28.444 15.778h17.229c.603 0 1.092-.392 1.092-.877 0-.484-.489-.876-1.092-.876H28.444c-.602 0-1.091.392-1.091.876 0 .485.489.877 1.091.877zM28.444 21.037h20.76c.602 0 1.09-.393 1.09-.877s-.488-.876-1.09-.876h-20.76c-.603 0-1.091.392-1.091.876s.488.877 1.09.877zM28.428 31.556h23.438c.593 0 1.075-.393 1.075-.877s-.482-.877-1.075-.877H28.428c-.594 0-1.075.393-1.075.877s.481.877 1.075.877zM28.444 35.938h17.229c.603 0 1.092-.392 1.092-.876s-.489-.877-1.092-.877H28.444c-.602 0-1.091.393-1.091.877s.489.876 1.091.876zM28.444 41.198h20.76c.602 0 1.09-.393 1.09-.877s-.488-.877-1.09-.877h-20.76c-.603 0-1.091.393-1.091.877s.488.877 1.09.877z'
        />
        <path
          fill='#607389'
          fillRule='nonzero'
          d='M15.44 47.333c-4.62 0-8.381 3.736-8.381 8.327 0 4.592 3.76 8.328 8.382 8.328s8.383-3.736 8.383-8.328c0-4.591-3.76-8.327-8.383-8.327zm0 14.46c-3.403 0-6.173-2.75-6.173-6.133 0-3.381 2.77-6.132 6.174-6.132 3.404 0 6.173 2.75 6.173 6.132 0 3.382-2.77 6.133-6.173 6.133z'
        />
        <path
          fill='#607389'
          d='M28.428 51.716h23.438c.593 0 1.075-.393 1.075-.876 0-.484-.482-.877-1.075-.877H28.428c-.594 0-1.075.393-1.075.877 0 .483.481.876 1.075.876zM28.444 56.099h17.229c.603 0 1.092-.393 1.092-.877s-.489-.876-1.092-.876H28.444c-.602 0-1.091.392-1.091.876s.489.877 1.091.877zM28.444 61.358h20.76c.602 0 1.09-.393 1.09-.877s-.488-.876-1.09-.876h-20.76c-.603 0-1.091.392-1.091.876s.488.877 1.09.877z'
        />
        <path
          fill={theme.primaryLighterColor}
          d='M13.61 34.052a1.044 1.044 0 0 0-1.492 0 1.082 1.082 0 0 0 0 1.513l2.6 2.64a1.045 1.045 0 0 0 1.493 0l5.44-5.522.016-.016L25.279 29a1.082 1.082 0 0 0 0-1.514 1.044 1.044 0 0 0-1.491 0l-2.759 2.801a7.914 7.914 0 0 0-5.967-2.709c-4.413 0-8.003 3.645-8.003 8.125s3.59 8.124 8.003 8.124 8.004-3.644 8.004-8.124c0-.591-.473-1.07-1.055-1.07-.583 0-1.055.479-1.055 1.07 0 3.3-2.644 5.983-5.894 5.983s-5.894-2.684-5.894-5.983c0-3.3 2.644-5.983 5.894-5.983a5.83 5.83 0 0 1 4.47 2.086l-4.067 4.13-1.856-1.884z'
        />
      </g>
    </svg>
  );
};

export default Poll;
