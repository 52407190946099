import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { NineBoxSelectField_query } from './__generated__/NineBoxSelectField_query.graphql';

type Props = {
  query: NineBoxSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  fullWidth?: boolean;
  value?: NineBox;
};

type NineBox = {
  id: string;
  name: string;
};
const NineBoxSelectField = ({
  hintText = 'Buscar 9box',
  name = 'nineBox',
  query,
  relay,
  ...rest
}: Props) => {
  const getItemFromNode = ({ id, name }) => {
    return {
      id,
      name: name,
    };
  };

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.nineBoxes}
      getItemFromNode={getItemFromNode}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

const NineBoxSelectFieldRefetchContainer = createRefetchContainer(
  NineBoxSelectField,
  {
    query: graphql`
      fragment NineBoxSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 50 }
        after: { type: String }
        filters: { type: NineBoxFilter }
      ) {
        nineBoxes(first: $first, after: $after, filters: $filters)
          @connection(
            key: "NineBoxSelectField_nineBoxes"
            filters: ["filters"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query NineBoxSelectFieldQuery(
      $first: Int
      $after: String
      $filters: NineBoxFilter
    ) {
      ...NineBoxSelectField_query
        @arguments(first: $first, after: $after, filters: $filters)
    }
  `,
);

export default NineBoxSelectFieldRefetchContainer;
