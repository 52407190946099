import type { GraphQLTaggedNode } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';
import type { ReactNode } from 'react';
import React, { Suspense } from 'react';
import type { CacheConfig, FetchPolicy, RenderPolicy } from 'relay-runtime';

import { Loading } from '@feedback/ui';

import { withRelayBoundary } from './withRelayBoundary';

type Config = {
  queriesParams?: (props: any) => Record<string, unknown>;
  query: GraphQLTaggedNode;
  queryOptions?: {
    fetchKey?: string | number;
    fetchPolicy?: FetchPolicy;
    networkCacheConfig?: CacheConfig;
    UNSTABLE_renderPolicy?: RenderPolicy;
  };
  loadingView?: ReactNode | null;
  getFragmentProps?: (
    fragmentProps: Record<string, unknown> | any,
  ) => Record<string, unknown> | any;
};
export const withLazyLoadQuery = (
  FragmentComponent: React.ComponentType<any>,
  config: Config,
) => {
  const {
    query,
    queryOptions = {
      fetchPolicy: 'network-only',
    },
    queriesParams,
    loadingView = <Loading fullScreen={true} />,
    getFragmentProps,
  } = config;

  const QueryRendererWrapper = (props) => {
    const variables = queriesParams ? queriesParams(props) : {};

    const response = useLazyLoadQuery(query, variables, {
      fetchKey: props.fetchKey,
      ...queryOptions,
    });

    const fragmentProps = getFragmentProps
      ? getFragmentProps(response)
      : { query: response };

    return (
      <Suspense fallback={loadingView}>
        <FragmentComponent {...props} {...fragmentProps} />
      </Suspense>
    );
  };

  QueryRendererWrapper.displayName = `withLazyLoadQuery(${
    FragmentComponent.displayName || FragmentComponent.name
  })`;

  return withRelayBoundary(QueryRendererWrapper, {
    loadingView,
  });
};
