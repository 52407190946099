/**
 * @generated SignedSource<<33103e6e33529e21879747a82c20700f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RediredictIfAuthenticatedMiddlewareQuery$variables = Record<PropertyKey, never>;
export type RediredictIfAuthenticatedMiddlewareQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RediredictIfAuthenticatedMiddleware_query">;
};
export type RediredictIfAuthenticatedMiddlewareQuery = {
  response: RediredictIfAuthenticatedMiddlewareQuery$data;
  variables: RediredictIfAuthenticatedMiddlewareQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RediredictIfAuthenticatedMiddlewareQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RediredictIfAuthenticatedMiddleware_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RediredictIfAuthenticatedMiddlewareQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "features",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectType",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c913e8852be7f933bd244a0792bcbb3",
    "id": null,
    "metadata": {},
    "name": "RediredictIfAuthenticatedMiddlewareQuery",
    "operationKind": "query",
    "text": "query RediredictIfAuthenticatedMiddlewareQuery {\n  ...RediredictIfAuthenticatedMiddleware_query\n}\n\nfragment RediredictIfAuthenticatedMiddleware_query on Query {\n  me {\n    id\n    ...usePermissionFragment\n  }\n}\n\nfragment usePermissionFragment on User {\n  isAdmin\n  allRoles\n  company {\n    features\n    projectType\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "80ab09cf4068c0324a15641f84e89d1e";

export default node;
