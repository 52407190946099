/**
 * @generated SignedSource<<514f778805e73edf71fc5f379acaa148>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobTitleSelectFieldQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  search?: string | null | undefined;
};
export type JobTitleSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobTitleSelectField_query">;
};
export type JobTitleSelectFieldQuery = {
  response: JobTitleSelectFieldQuery$data;
  variables: JobTitleSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobTitleSelectFieldQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "JobTitleSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "JobTitleSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "JobTitleConnection",
        "kind": "LinkedField",
        "name": "jobTitles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "JobTitleSelectField_jobTitles",
        "kind": "LinkedHandle",
        "name": "jobTitles"
      }
    ]
  },
  "params": {
    "cacheID": "a84f311cddb69f9c68799dcf3eade429",
    "id": null,
    "metadata": {},
    "name": "JobTitleSelectFieldQuery",
    "operationKind": "query",
    "text": "query JobTitleSelectFieldQuery(\n  $count: Int\n  $search: String\n  $after: String\n) {\n  ...JobTitleSelectField_query_26u0lg\n}\n\nfragment JobTitleSelectField_query_26u0lg on Query {\n  jobTitles(first: $count, search: $search, after: $after) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b45a13f6010a7a5564f8259106364954";

export default node;
