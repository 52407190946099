import loadable from '@loadable/component';

import { MODULES } from '@feedback/roles';

import type { RouteType } from '../../utils';

import userInCompanyMiddleware from '../../middlewares/UserInCompanyMiddleware';

import { homeRedirect } from './homeRedirect';


const externalRoutes: RouteType[] = [
  {
    name: 'notFound.external',
    path: '/notFound',
    component: loadable(() => import('../../components/NotFoundExternal')),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
  },
  {
    name: 'feedbacks.external',
    path: `/:nick`,
    labelHeader: ({ t }) => t('External {{Feedback}}'),
    component: userInCompanyMiddleware(
      loadable(
        () =>
          import('../../../components/externalFeedback/form/ExternalFeedback'),
      ),
    ),
    exact: true,
    requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
  },
  homeRedirect,
];

export default externalRoutes;
