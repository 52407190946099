/**
 * @generated SignedSource<<5653a1048e129d05e1c53e5fed5d3811>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobRequestStatusEnum = "AVAILABLE" | "COMPLETED" | "DISAPPROVED" | "NEW" | "%future added value";
export type JobRequestFilter = {
  costRevenueCenter?: string | null | undefined;
  costRevenueCenterParent?: string | null | undefined;
  createdAt_gte?: string | null | undefined;
  createdAt_lte?: string | null | undefined;
  jobPosting?: string | null | undefined;
  positionApplicationStatus?: string | null | undefined;
  responsibles?: ReadonlyArray<string | null | undefined> | null | undefined;
  search?: string | null | undefined;
  status_in?: ReadonlyArray<JobRequestStatusEnum | null | undefined> | null | undefined;
  tags?: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type JobRequestSelectFieldRefetchQuery$variables = {
  after?: string | null | undefined;
  filters?: JobRequestFilter | null | undefined;
  first?: number | null | undefined;
};
export type JobRequestSelectFieldRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobRequestSelectField_query">;
};
export type JobRequestSelectFieldRefetchQuery = {
  response: JobRequestSelectFieldRefetchQuery$data;
  variables: JobRequestSelectFieldRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filters"
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobRequestSelectFieldRefetchQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters"
          },
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "JobRequestSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "JobRequestSelectFieldRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "JobRequestConnection",
        "kind": "LinkedField",
        "name": "jobRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobRequestEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobRequest",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requestId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPosting",
                    "kind": "LinkedField",
                    "name": "jobPosting",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CostRevenueCenter",
                    "kind": "LinkedField",
                    "name": "costRevenueCenter",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "JobRequestSelectField_jobRequests",
        "kind": "LinkedHandle",
        "name": "jobRequests"
      }
    ]
  },
  "params": {
    "cacheID": "4aeff45874d8c72caa6a8392d6362388",
    "id": null,
    "metadata": {},
    "name": "JobRequestSelectFieldRefetchQuery",
    "operationKind": "query",
    "text": "query JobRequestSelectFieldRefetchQuery(\n  $first: Int\n  $after: String\n  $filters: JobRequestFilter\n) {\n  ...JobRequestSelectField_query_1XoDko\n}\n\nfragment JobRequestSelectField_query_1XoDko on Query {\n  jobRequests(first: $first, after: $after, filter: $filters) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        requestId\n        jobPosting {\n          title\n          id\n        }\n        costRevenueCenter {\n          name\n          id\n        }\n        status\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2648c67bfe582a6fc50b200bff580a26";

export default node;
