const config = {
  GRAPHQL_URL: process.env.GRAPHQL_URL,
  GRAPHQL_SUBSCRIPTION_URL: process.env.GRAPHQL_SUBSCRIPTION_URL,
  TEMPLATE_URL: process.env.TEMPLATE_URL,
  REDIRECT_SERVER_URI: process.env.REDIRECT_SERVER_URI,
  FIREBASE_MESSAGING_SEND_ID: process.env.FIREBASE_MESSAGING_SEND_ID,
  FIREBASE_VAPID: process.env.FIREBASE_VAPID,
  COMMIT_SHA: process.env.COMMIT_SHA,
  CRISP_CHAT_ID: process.env.CRISP_CHAT_ID,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  LOGID_URL: process.env.LOGID_URL,
  OPENPIX_LINK_URL: process.env.OPENPIX_LINK_URL,
  OPENPIX_PLUGIN_URL: process.env.OPENPIX_PLUGIN_URL,
  NODE_ENV: process.env.NODE_ENV,
};

export default config;
