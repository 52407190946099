// @flow

import styled from 'styled-components';

const SVG = styled.svg`
  min-width: ${(props) => props.width}px;
`;

type Props = {
  size: number;
  color?: string;
  viewBox?: string;
};

const CheckboxUnchecked = ({ size, color, viewBox }: Props) => {
  return (
    <SVG viewBox={viewBox} width={size} height={size}>
      <circle
        fill={color}
        cx='50'
        cy='50'
        r='50'
        stroke='#c7c7c7'
        strokeWidth='3'
      />
    </SVG>
  );
};

CheckboxUnchecked.defaultProps = {
  color: 'none',
  viewBox: '0 0 100 100',
  size: 20,
};

export default CheckboxUnchecked;
