import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/planning';

const requiredFeatures = [
  {
    $or: [MODULES.PLANNING, MODULES.HIRING],
  },
];

const jobRole: RouteType[] = [
  {
    name: 'jobRoles',
    path: `${PATH}/jobRoles`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Planejamento de Cargos',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.JOB_ROLE.ALL,
    ],
    requiredFeatures: [MODULES.CORE],
    routes: [
      {
        name: 'jobRoles.list',
        path: `${PATH}/jobRoles/list`,
        label: 'Lista',
        labelHeader: 'Cargos',
        component: loadable(
          () => import('../../../components/jobRole/JobRoleList'),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.JOB_ROLE.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
        modalRoutes: [
          {
            name: 'jobRoles.list.add',
            path: `${PATH}/jobRoles/list/add`,
            labelHeader: 'Cargos - Adicionar',
            component: loadable(
              () => import('../../../components/jobRole/form/JobRoleAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.JOB_ROLE.ALL,
            ],
            requiredFeatures: [MODULES.CORE],
          },
        ],
      },
      {
        name: 'salaryRanges',
        path: `${PATH}/jobRoles/salaryRangeList`,
        label: 'Faixas Salariais',
        labelHeader: 'Faixas Salariais',
        component: loadable(
          () => import('../../../components/salaryRange/SalaryRangeList'),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.SALARY_RANGE.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
      {
        name: 'jobRoles.export',
        path: `${PATH}/jobRoles/export`,
        labelHeader: 'Cargos',
        label: 'Exportar',
        component: loadable(
          () => import('../../../components/planning/export/JobRoleExport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.EXPORT.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
      },
    ],
  },
  {
    name: 'jobRole.view',
    path: `${PATH}/jobRole/view/:id/tabs`,
    labelHeader: 'Cargo - Detalhes',
    component: loadable(() => import('../../../components/jobRole/JobRole')),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.JOB_ROLE.ALL,
    ],
    requiredFeatures: [MODULES.CORE],
    routes: [
      // Used for tabbed routes
      {
        name: 'jobRole.details',
        path: `${PATH}/jobRole/view/:id/tabs/details`,
        labelHeader: 'Cargo - detalhes',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.JOB_ROLE.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
        exact: false,
        label: 'Detalhes',
        component: loadable(
          () => import('../../../components/jobRole/JobRoleDetails'),
        ),
        modalRoutes: [
          {
            name: 'jobRole.edit',
            path: `${PATH}/jobRole/view/:id/tabs/details/edit`,
            labelHeader: 'Cargo - detalhes',
            component: loadable(
              () => import('../../../components/jobRole/form/JobRoleEdit'),
            ),
            modalTitle: 'Editar cargo',
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.JOB_ROLE.ALL,
            ],
            requiredFeatures: [MODULES.CORE],
          },
        ],
      },
      {
        name: 'jobRole.salaryrange',
        path: `${PATH}/jobRole/view/:id/tabs/salaryrange`,
        labelHeader: 'Cargo - detalhes',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.JOB_ROLE.ALL,
        ],
        requiredFeatures,
        exact: false,
        label: 'Faixas Salariais',
        component: loadable(
          () => import('../../../components/jobRole/JobRoleSalaryRanges'),
        ),
        modalRoutes: [
          {
            name: 'jobRole.salaryrange.add',
            path: `${PATH}/jobRole/view/:id/tabs/salaryrange/add`,
            labelHeader: 'Cargo - detalhes',
            component: loadable(
              () => import('../../../components/jobRole/JobRoleSalaryRangeAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.JOB_ROLE.ALL,
            ],
            requiredFeatures,
            modalTitle: 'Atribuição de Faixa Salarial e Sindicato',
          },
        ],
      },
      {
        name: 'jobRole.exams',
        path: `${PATH}/jobRole/view/:id/tabs/exams`,
        labelHeader: 'Cargo - detalhes',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.JOB_ROLE.ALL,
        ],
        requiredFeatures,
        exact: false,
        label: 'Provas',
        component: loadable(
          () => import('../../../components/jobRole/JobRoleExams'),
        ),
        modalRoutes: [
          {
            name: 'jobRole.exams.detail',
            path: `${PATH}/jobRole/view/:id/tabs/exams/detail/:examId`,
            component: loadable(
              () => import('../../../components/jobRole/JobRoleSalaryRangeAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.JOB_ROLE.ALL,
            ],
            requiredFeatures,
            modalTitle: 'Detalhe da Prova',
          },
        ],
      },
      {
        name: 'jobRole.headcount',
        path: `${PATH}/jobRole/view/:id/tabs/headcounts`,
        labelHeader: 'Cargo - detalhes',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.JOB_ROLE.ALL,
        ],
        requiredFeatures,
        exact: false,
        label: 'HeadCounts',
        component: loadable(
          () => import('../../../components/jobRole/JobRoleHeadCounts'),
        ),
      },
      {
        name: 'jobRole.view.candidates',
        labelHeader: 'Cargo - detalhes',
        label: 'Candidatos',
        path: `${PATH}/jobRole/view/:id/tabs/candidates`,
        component: loadable(
          () => import('../../../components/jobRole/JobRoleCandidateList'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'salaryRanges.new',
    path: `${PATH}/jobRole/salaryRanges/add`,
    labelHeader: 'Faixas Salariais - Adicionar',
    component: loadable(
      () => import('../../../components/salaryRange/SalaryRangeAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.SALARY_RANGE.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
  },
  {
    name: 'salaryRange',
    path: `${PATH}/jobRole/salaryRange/:id`,
    labelHeader: 'Faixa Salarial - Detalhe',
    component: loadable(
      () => import('../../../components/salaryRange/SalaryRange'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.SALARY_RANGE.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'salaryRanges.detail',
        path: `${PATH}/jobRole/salaryRange/:id/detail`,
        labelHeader: 'Faixa Salarial - Detalhe',
        component: loadable(
          () => import('../../../components/salaryRange/SalaryRangeDetail'),
        ),
        exact: true,
        label: 'Detalhes',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.SALARY_RANGE.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
      {
        name: 'salaryRanges.edit',
        path: `${PATH}/jobRole/salaryRange/:id/edit`,
        labelHeader: 'Faixa Salarial - Editar',
        component: loadable(
          () => import('../../../components/salaryRange/SalaryRangeEdit'),
        ),
        exact: true,
        label: 'Editar',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.SALARY_RANGE.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
];

export default jobRole;
