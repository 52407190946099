import { commitMutation, graphql } from 'react-relay';

import Environment from '../relay/Environment';

import type {
  PreSignedFileUploadMutationInput,
  PreSignedFileUploadMutationResponse,
} from './__generated__/PreSignedFileUploadMutation.graphql';

const mutation = graphql`
  mutation PreSignedFileUploadMutation(
    $input: PreSignedFileUploadMutationInput!
  ) {
    PreSignedFileUpload(input: $input) {
      payload {
        url
        fields
      }
      error
    }
  }
`;

function commit(
  input: PreSignedFileUploadMutationInput,
  onCompleted: (response: PreSignedFileUploadMutationResponse) => void,
  onError: (error: Error) => void,
) {
  const variables = {
    input,
  };

  return commitMutation(Environment, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };
