import { ResourceSelectField } from '@feedback/select';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import type { SalaryRangeSelectField_query } from './__generated__/SalaryRangeSelectField_query.graphql';

type Props = {
  query: SalaryRangeSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: SalaryRange;
};

type SalaryRange = {
  id: string;
  name: string;
};
const SalaryRangeSelectField = (props: Props) => {
  const getItemFromNode = ({ id, name }) => {
    return {
      id,
      name,
    };
  };

  const {
    hintText = 'Buscar Faixa Salarial',
    name = 'salaryRange',
    query,
    relay,
    ...rest
  } = props;

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.salaryRanges}
      getItemFromNode={getItemFromNode}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

const SalaryRangeSelectFieldRefetchContainer = createRefetchContainer(
  SalaryRangeSelectField,
  {
    query: graphql`
      fragment SalaryRangeSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 50 }
        search: { type: String }
      ) {
        salaryRanges(first: $count, search: $search)
          @connection(
            key: "SalaryRangeSelectField_salaryRanges"
            filters: ["search"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query SalaryRangeSelectFieldQuery($count: Int, $search: String) {
      ...SalaryRangeSelectField_query @arguments(count: $count, search: $search)
    }
  `,
);

export default SalaryRangeSelectFieldRefetchContainer;
