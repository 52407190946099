import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/users';

const usersRoutes: RouteType[] = [
  {
    name: 'users.view',
    path: `${PATH}/view/:id`,
    labelHeader: ({ t }) => t('Users'),
    component: loadable(() => import('../../../components/user/view/UserView')),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
    routes: [
      {
        name: 'user.view.profile',
        // DO NOT FIX without retesting all user tabs, this break
        // path: `${PATH}/view/:id/profile`,
        path: `${PATH}/view/:id//profile`,
        requiredRoles: [],
        requiredFeatures: [],
        exact: false,
        labelHeader: ({ t }) => t('Profile'),
        label: ({ t }) => t('Profile'),
        component: loadable(
          () => import('../../../components/career/CareerProfile'),
        ),
      },
      {
        name: 'users.view.overview',
        labelHeader: ({ t }) => t('General'),
        path: `${PATH}/view/:id/overview`,
        component: loadable(
          () => import('../../../components/user/view/tabs/UserOverview'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [
          MODULES.FEEDBACK,
          MODULES.GOALS,
          MODULES.PERFORMANCE_REVIEW,
        ],
        label: ({ t }) => t('General'),
      },
      {
        name: 'users.view.feedback',
        label: ({ t }) => t('{{Feedback}}'),
        labelHeader: ({ t }) => t('{{Feedback}}'),
        path: `${PATH}/view/:id/feedback`,
        component: loadable(() => import('../../../components/common/TabView')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.FEEDBACK.ALL,
          ROLES.FEEDBACK.USER.ALL,
          ROLES.FEEDBACK.ADMIN.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
          ROLES.FEEDBACK.TEAM_RECEIVED,
          ROLES.FEEDBACK.TEAM_SENT,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
        routes: [
          {
            name: 'users.view.feedback.evolutionReceived',
            labelHeader: ({ t }) => t('Evolution Received'),
            label: ({ t }) => t('Evolution Received'),
            path: `${PATH}/view/:id/feedback/evolutionReceived`,
            component: loadable(
              () =>
                import(
                  '../../../components/user/view/tabs/UserEvolutionReceived'
                ),
            ),
            exact: true,
            requiredRoles: [
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.USER.ALL,
              ROLES.FEEDBACK.FEEDBACK_RECEIVED,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
          {
            name: 'users.view.feedback.evolutionSent',
            labelHeader: ({ t }) => t('Evolution Sent'),
            label: ({ t }) => t('Evolution Sent'),
            path: `${PATH}/view/:id/feedback/evolutionSent`,
            component: loadable(
              () =>
                import('../../../components/user/view/tabs/UserEvolutionSent'),
            ),
            exact: true,
            requiredRoles: [
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.USER.ALL,
              ROLES.FEEDBACK.FEEDBACK_SENT,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
          {
            name: 'users.view.feedback.received',
            label: ({ t }) => t('Received {{Feedbacks}}'),
            labelHeader: ({ t }) => t('Received {{Feedbacks}}'),
            path: `${PATH}/view/:id/feedback/received`,
            component: loadable(
              () =>
                import(
                  '../../../components/user/view/tabs/UserFeedbackReceivedList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.USER.ALL,
              ROLES.FEEDBACK.FEEDBACK_RECEIVED,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
          {
            name: 'users.view.feedback.sent',
            label: ({ t }) => t('Sent {{Feedbacks}}'),
            labelHeader: ({ t }) => t('Sent {{Feedbacks}}'),
            path: `${PATH}/view/:id/feedback/sent`,
            component: loadable(
              () =>
                import(
                  '../../../components/user/view/tabs/UserFeedbackSentList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.USER.ALL,
              ROLES.FEEDBACK.FEEDBACK_SENT,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
        ],
      },
      {
        name: 'users.view.feedbackExternal',
        label: ({ t }) => t('External {{Feedbacks}}'),
        labelHeader: ({ t }) => t('External {{Feedbacks}}'),
        path: `${PATH}/view/:id/feedbackExternal`,
        component: loadable(() => import('../../../components/common/TabView')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_EXTERNAL.ALL,
          ROLES.FEEDBACK_EXTERNAL.ADMIN.ALL,
          ROLES.FEEDBACK_EXTERNAL.TEAM,
        ],
        requiredFeatures: [MODULES.FEEDBACK, MODULES.FEEDBACK_EXTERNAL],
        routes: [
          {
            name: 'users.view.feedbackExternal.evolutionReceived',
            path: `${PATH}/view/:id/feedbackExternal/evolutionReceived`,
            label: ({ t }) => t('External Evolution'),
            labelHeader: ({ t }) => t('External Evolution'),
            component: loadable(
              () =>
                import(
                  '../../../components/user/view/tabs/UserExternalFeedbackEvolution'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_EXTERNAL.ALL,
              ROLES.FEEDBACK.ANALYTICS.ALL,
              ROLES.FEEDBACK_EXTERNAL.TEAM,
            ],
            requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
          },
          {
            name: 'users.view.feedbackExternal.received',
            path: `${PATH}/view/:id/feedbackExternal/received`,
            label: ({ t }) => t('External {{Feedbacks}}'),
            labelHeader: ({ t }) => t('External {{Feedbacks}}'),
            component: loadable(
              () =>
                import(
                  '../../../components/user/view/tabs/UserExternalFeedbackList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_EXTERNAL.ALL,
              ROLES.FEEDBACK_EXTERNAL.USER.ALL,
              ROLES.FEEDBACK_EXTERNAL.TEAM,
            ],
            requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
          },
        ],
      },
      {
        name: 'users.view.goalGroup',
        labelHeader: ({ t }) => t('Goals'),
        path: `${PATH}/view/:id/myGoals`,
        component: loadable(
          () => import('../../../components/goalGroup/list/GoalsGroupUserList'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.GOALS],
        label: ({ t }) => t('Goals'),
      },
      {
        name: 'users.view.performanceReview',
        labelHeader: ({ t }) => t('Performance Review'),
        path: `${PATH}/view/:id/performanceReview/list`,
        component: loadable(
          () =>
            import(
              '../../../components/performance/manager/ManagerPerformanceList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        label: ({ t }) => t('Performance Review'),
      },
      {
        name: 'users.view.oneOnOne',
        label: ({ t }) => t('{{FeedbackOneToOne}}'),
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        path: `${PATH}/view/:id/oneToOne`,
        component: loadable(() => import('../../../components/common/TabView')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
        routes: [
          {
            name: 'users.view.oneOnOne.registered',
            label: ({ t }) => t('Registered'),
            labelHeader: ({ t }) => t('Registered'),
            path: `${PATH}/view/:id/oneToOne/registered`,
            component: loadable(
              () =>
                import(
                  '../../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneUserList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL,
              ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
            ],
            requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
          },
          {
            name: 'users.view.oneOnOne.schedules',
            label: ({ t }) => t('Schedules'),
            labelHeader: ({ t }) => t('Schedules'),
            path: `${PATH}/view/:id/oneToOne/schedules`,
            component: loadable(
              () =>
                import(
                  '../../../components/oneOnOne/oneOnOneSchedule/OneOnOneScheduleUserList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL,
              ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
            ],
            requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
          },
          {
            name: 'users.view.oneOnOne.private',
            label: ({ t }) => t('Private'),
            labelHeader: ({ t }) => t('Private'),
            path: `${PATH}/view/:id/oneToOne/private`,
            component: loadable(
              () =>
                import(
                  '../../../components/oneOnOne/userFeedbackOneToOne/private/OneOnOnePrivateUserManagerList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL,
              ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
            ],
            requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
          },
        ],
      },
      {
        name: 'users.view.pdi',
        label: ({ t }) => t('PDI'),
        labelHeader: ({ t }) => t('PDI'),
        path: `${PATH}/view/:id/pdi/list`,
        component: loadable(
          () => import('../../../components/pdi/PdiUserList'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PDI],
      },
      {
        name: 'users.view.new.goals',
        labelHeader: ({ t }) => t('GOALS'),
        path: `${PATH}/view/:user/:type/goals/list/:id`,
        component: loadable(
          () => import('../../../components/goalGroup/list/GoalGroupGoalList'),
        ),
        requiredRoles: [],
        requiredFeatures: [MODULES.GOALS],
      },
    ],
  },
  {
    name: 'users.create',
    path: `${PATH}/create`,
    labelHeader: ({ t }) => t('Users'),
    component: loadable(
      () => import('../../../components/user/create/UserCreate'),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'users.list',
    path: `${PATH}/all/list`,
    labelHeader: ({ t }) => t('Users'),
    requiredRoles: [
      ADMIN_ROLE,
      // ROLES.FEEDBACK.ADMIN.ALL,
      // ROLES.GOALS.ADMIN.ALL,
      // ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
    ],
    requiredFeatures: [
      MODULES.FEEDBACK,
      MODULES.FEEDBACK_EXTERNAL,
      MODULES.GOALS,
      MODULES.PERFORMANCE_REVIEW,
      MODULES.PDI,
      MODULES.FEEDBACK_ONE_TO_ONE,
    ],
    exact: false,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    label: ({ t }) => t('Users'),
    routes: [
      {
        name: 'users.list',
        path: `${PATH}/all/list`,
        labelHeader: ({ t }) => t('Users'),
        requiredRoles: [
          ADMIN_ROLE,
          // ROLES.FEEDBACK.ADMIN.ALL,
          // ROLES.GOALS.ADMIN.ALL,
          // ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
        ],
        requiredFeatures: [
          MODULES.FEEDBACK,
          MODULES.FEEDBACK_EXTERNAL,
          MODULES.GOALS,
          MODULES.PERFORMANCE_REVIEW,
          MODULES.PDI,
          MODULES.FEEDBACK_ONE_TO_ONE,
        ],
        exact: true,
        component: loadable(
          () => import('../../../components/user/list/Users'),
        ),
        label: ({ t }) => t('Users'),
      },
    ],
  },
];

export const userFeedbackTabs = ['users.view.feedback'];
export const userGoalsTabs = ['users.view.goalGroup', 'users.view.new.goals'];
export const userPerformanceReviewTabs = ['users.view.performanceReview'];

export default usersRoutes;
