import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ADMIN_ROLE, ROLES } from '@feedback/roles';

import { routeTo } from '../../../../router/utils';

export const charges = ({ t, hasRole }) => {
  const canSeeReport = hasRole([
    ADMIN_ROLE,
    ROLES.OPEN_PIX.ADMIN.ALL,
    ROLES.OPEN_PIX.CHARGES,
    ROLES.OPEN_PIX.REPORT,
  ]);

  const isUser =
    hasRole([ROLES.OPEN_PIX.USER.ALL]) || hasRole([ROLES.OPEN_PIX.CHARGES]);

  const isSeller = hasRole([ROLES.OPEN_PIX.SELLER]);

  const chargeRoute = {
    label: t('Charges'),
    icon: <AttachMoneyIcon />,
  };

  if (canSeeReport) {
    return [
      {
        ...chargeRoute,
        link: routeTo('openpix.dashboard.report'),
      },
    ];
  }

  if (isSeller) {
    return [
      {
        ...chargeRoute,
        link: routeTo('openpix.dashboard.mycharges'),
      },
    ];
  }

  if (isUser) {
    return [
      {
        ...chargeRoute,
        link: routeTo('openpix.dashboard.charge.list'),
      },
    ];
  }

  return [
    {
      ...chargeRoute,
      icon: <AttachMoneyIcon />,
      link: routeTo('openpix.dashboard'),
    },
  ];
};
