import { commitMutation, graphql } from 'react-relay';

import { Environment } from '../relay/index';

import type {
  UserLogoutInput,
  UserLogoutMutationResponse,
} from './__generated__/UserLogoutMutation.graphql';

const mutation = graphql`
  mutation UserLogoutMutation($input: UserLogoutInput!) {
    UserLogout(input: $input) {
      error
    }
  }
`;

const commit = (
  input: UserLogoutInput,
  onCompleted: (response: UserLogoutMutationResponse) => void,
  onError: (error: Error) => void,
) => {
  commitMutation(Environment, {
    mutation,
    variables: {
      input,
    },
    onCompleted,
    onError,
  });
};

export default { commit };
