/**
 * <module>::<model>::<anything>
 * Hiring::Candidate::
 */

export const SEPARATOR = '::';

export const ADJUST = {
  MODULE: 'ADJUST',

  FEATURE: {
    MYTEAM: 'MYTEAM',
    EXPORT: 'EXPORT',
  },

  ROLES: {
    MYTEAM: {
      INCOMPLETE_TRANSFERS: 'INCOMPLETE_TRANSFERS',
      PENDING_INVITES: 'PENDING_INVITES',
    },
    EXPORT: {
      USER: 'USER',
      JOB_ROLE: 'JOB_ROLE',
      HEADCOUNT: 'HEADCOUNT',
      COST_REVENUE_CENTER: 'COST_REVENUE_CENTER',
      CANDIDATE: 'CANDIDATE',
      JOB_REQUEST: 'JOB_REQUEST',
      HIRING_REFERRAL: 'HIRING_REFERRAL',
      GROUP_INTERVIEW_ROOM_CANDIDATES: 'GROUP_INTERVIEW_ROOM_CANDIDATES',
      USER_FEEDBACK: 'USER_FEEDBACK',
      USER_FEEDBACK_EXTERNAL: 'USER_FEEDBACK_EXTERNAL',
    },
  },
} as const;

export const FEEDBACK_ONE_TO_ONE = {
  MODULE: 'FEEDBACK_ONE_TO_ONE',

  FEATURE: {
    ADMIN: 'ADMIN',
    USER: 'USER',
  },
  ROLES: {
    MANAGER: {
      EDIT: 'EDIT',
      EXCLUDE: 'EXCLUDE',
    },
  },
} as const;

export const FEEDBACK_RANKING = {
  MODULE: 'FEEDBACK_RANKING',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
} as const;

export const FEEDBACK_EXTERNAL = {
  MODULE: 'FEEDBACK_EXTERNAL',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },

  ROLES: {
    TEAM: 'TEAM',
  },
} as const;

export const FEEDBACK = {
  MODULE: 'FEEDBACK',

  FEATURE: {
    FEEDBACK: 'FEEDBACK',
    ANALYTICS: 'ANALYTICS',
    USER: 'USER',
    ADMIN: 'ADMIN',
  },

  ROLES: {
    SELF_REVIEW: 'SELF_REVIEW',
    REQUEST_FEEDBACK: 'REQUEST_FEEDBACK',
    GIVE_FEEDBACK: 'GIVE_FEEDBACK',
    FEEDBACK_RECEIVED: 'FEEDBACK_RECEIVED',
    FEEDBACK_SENT: 'FEEDBACK_SENT',
    TEAM_RECEIVED: 'TEAM_RECEIVED',
    TEAM_SENT: 'TEAM_SENT',
    FEEDBACK_RECEIVED_REPORT: 'FEEDBACK_RECEIVED_REPORT',
    FEEDBACK_SENT_REPORT: 'FEEDBACK_SENT_REPORT',
  },
} as const;

export const FEEDBACK_GROUP = {
  MODULE: 'FEEDBACK_GROUP',

  FEATURE: {
    FEEDBACK: 'FEEDBACK',
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
} as const;

export const HIRING = {
  MODULE: 'HIRING',

  FEATURE: {
    CANDIDATE: 'CANDIDATE',
    INTERVIEW: 'INTERVIEW',
    JOB_POSTING: 'JOB_POSTING',
    JOB_REQUEST: 'JOB_REQUEST',
    LANDING_PAGE: 'LANDING_PAGE',
    JOB_EXAM: 'JOB_EXAM',
    REFERRAL: 'REFERRAL',
    MY_REFERRAL: 'MY_REFERRAL',
    GROUP_INTERVIEW: 'GROUP_INTERVIEW',
    IMPORT: 'IMPORT',
    ADJUST: 'ADJUST',
  },

  ROLES: {
    JOB_REQUEST: {
      ALL: 'ALL',
      CREATE: 'CREATE',
      APPROVE: 'APPROVE',
      APPROVE_ONLY_AREA: 'APPROVE_ONLY_AREA',
      LIST_ALL: 'LIST_ALL',
    },
  },
} as const;

export const OMBUDSMAN = {
  MODULE: 'OMBUDSMAN',

  FEATURE: {
    COMPLAINT: 'COMPLAINT',
    DASHBOARD: 'DASHBOARD',
    ANALYTICS: 'ANALYTICS',
    SETTINGS: 'SETTINGS',
    USER: 'USER',
  },

  ROLES: {
    SEE_CASE: 'SEE_CASE',
    CREATE_CASE: 'CREATE_CASE',
  },
} as const;

export const OMBUDSMAN_EXTERNAL = {
  MODULE: 'OMBUDSMAN_EXTERNAL',

  FEATURE: {
    COMPLAINT_EXTERNAL: 'COMPLAINT_EXTERNAL',
    DASHBOARD: 'DASHBOARD',
    ANALYTICS: 'ANALYTICS',
    SETTINGS: 'SETTINGS',
  },
} as const;

export const PDI = {
  MODULE: 'PDI',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },

  ROLES: {
    CREATE: 'CREATE',
  },
} as const;

export const PLANNING = {
  MODULE: 'PLANNING',

  FEATURE: {
    COST_REVENUE_CENTER: 'COST_REVENUE_CENTER',
    HEADCOUNT: 'HEADCOUNT',
    JOB_ROLE: 'JOB_ROLE',
    SALARY_RANGE: 'SALARY_RANGE',
    BUSINESS_DIVISION: 'BUSINESS_DIVISION',
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
  },
} as const;

export const RESEARCH = {
  MODULE: 'RESEARCH',

  FEATURE: {
    POLL: 'POLL',
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
} as const;

export const GOALS = {
  MODULE: 'GOALS',

  FEATURE: {
    GOALS: 'GOALS',
    ADMIN: 'ADMIN',
    USER: 'USER',
  },

  ROLES: {
    USER: {
      VIEW: 'VIEW',
    },
    AREA: {
      VIEW: 'VIEW',
    },
    GOAL_GROUP: {
      CREATE: 'CREATE',
      VIEW: 'VIEW',
    },
  },
} as const;

export const PERMISSIONS = {
  MODULE: 'PERMISSIONS',

  FEATURE: {
    ROLE_GROUP: 'ROLE_GROUP',
    USER: 'USER',
  },

  ROLES: {
    USERS: {
      LIST: 'LIST',
      TRANSFER: 'TRANSFER',
      IMPORT: 'IMPORT',
    },
  },
} as const;

export const PERFORMANCE_REVIEW = {
  MODULE: 'PERFORMANCE_REVIEW',

  FEATURE: {
    USER: 'USER',
    MANAGER: 'MANAGER',
    ADMIN: 'ADMIN',
  },
} as const;

export const SOCIAL = {
  MODULE: 'SOCIAL',

  FEATURE: {
    NEWSFEED: 'NEWSFEED',
    SEE_ALL_POSTS: 'SEE_ALL_POSTS',
    ANALYTICS: 'ANALYTICS',
    SETTINGS: 'SETTINGS',
  },

  ROLES: {
    POST: {
      PUBLIC: 'PUBLIC',
      GROUPS: 'GROUPS',
    },
  },
} as const;

export const HOME = {
  MODULE: 'HOME',

  FEATURE: {
    EDIT_BANNER: 'EDIT_BANNER',
  },
} as const;

export const SUPPORT = {
  MODULE: 'SUPPORT',
} as const;

export const NINE_BOX = {
  MODULE: 'NINE_BOX',
  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
} as const;

export const TEMP = {
  MODULE: 'TEMP',
} as const;

export const BETA = {
  MODULE: 'BETA',
} as const;

export const CORE = {
  MODULE: 'CORE',

  ROLES: {
    USER: {
      INVITE: 'INVITE',
      MANAGE_TEAM: 'MANAGE_TEAM',
    },
    PREFERENCES: {
      CHANGE_MANAGER: 'CHANGE_MANAGER',
      CHANGE_NAME: 'CHANGE_NAME',
      CHANGE_PASSWORD: 'CHANGE_PASSWORD',
      CHANGE_JOB_TITLE: 'CHANGE_JOB_TITLE',
      CHANGE_NICKNAME: 'CHANGE_NICKNAME',
      CHANGE_TAXID: 'CHANGE_TAXID',
      CHANGE_EMAIL: 'CHANGE_EMAIL',
    },
    APPLICATION: 'APPLICATION',
    LOGIN: 'LOGIN',
  },
} as const;

export const GROUP = {
  MODULE: 'GROUP',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
  ROLES: {
    CREATE: 'CREATE',
  },
} as const;

export const LOGID = {
  MODULE: 'LOGID',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
} as const;

export const TEAM_MANAGEMENT = {
  MODULE: 'TEAM_MANAGEMENT',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },
} as const;

export const OPEN_PIX = {
  MODULE: 'OPEN_PIX',

  FEATURE: {
    USER: 'USER',
    ADMIN: 'ADMIN',
  },

  ROLES: {
    CUSTOMERS: 'CUSTOMERS',
    CHARGES: 'CHARGES',
    QRCODES: 'QRCODES',
    TRANSACTIONS: 'TRANSACTIONS',
    REFUND: 'REFUND',
    PAYMENTS: 'PAYMENTS',
    REPORT: 'REPORT',
    SELLER: 'SELLER',
  },
} as const;

export const API = {
  MODULE: 'API',

  FEATURE: {
    ADMIN: 'ADMIN',
  },
};

export const OPENPIX_CASHOUT = {
  MODULE: 'OPENPIX_CASHOUT',

  FEATURE: {
    ADMIN: 'ADMIN',
  },
};

export const GIFTBACK = {
  MODULE: 'GIFTBACK',

  FEATURE: {
    ADMIN: 'ADMIN',
  },
};

export const MODULES = {
  [OPEN_PIX.MODULE]: OPEN_PIX.MODULE,
  [TEMP.MODULE]: TEMP.MODULE,
  [BETA.MODULE]: BETA.MODULE,
  [API.MODULE]: API.MODULE,
  [OPENPIX_CASHOUT.MODULE]: OPENPIX_CASHOUT.MODULE,
  [GIFTBACK.MODULE]: GIFTBACK.MODULE,
  [ADJUST.MODULE]: ADJUST.MODULE,
  [PERMISSIONS.MODULE]: PERMISSIONS.MODULE,
  [CORE.MODULE]: CORE.MODULE,
  [HOME.MODULE]: HOME.MODULE,
  [SUPPORT.MODULE]: SUPPORT.MODULE,
  [FEEDBACK.MODULE]: FEEDBACK.MODULE,
  [FEEDBACK_GROUP.MODULE]: FEEDBACK_GROUP.MODULE,
  [FEEDBACK_ONE_TO_ONE.MODULE]: FEEDBACK_ONE_TO_ONE.MODULE,
  [HIRING.MODULE]: HIRING.MODULE,
  [OMBUDSMAN.MODULE]: OMBUDSMAN.MODULE,
  [OMBUDSMAN_EXTERNAL.MODULE]: OMBUDSMAN_EXTERNAL.MODULE,
  [PLANNING.MODULE]: PLANNING.MODULE,
  [RESEARCH.MODULE]: RESEARCH.MODULE,
  [GOALS.MODULE]: GOALS.MODULE,
  [FEEDBACK_RANKING.MODULE]: FEEDBACK_RANKING.MODULE,
  [FEEDBACK_EXTERNAL.MODULE]: FEEDBACK_EXTERNAL.MODULE,
  [PERFORMANCE_REVIEW.MODULE]: PERFORMANCE_REVIEW.MODULE,
  [SOCIAL.MODULE]: SOCIAL.MODULE,
  [PDI.MODULE]: PDI.MODULE,
  [NINE_BOX.MODULE]: NINE_BOX.MODULE,
  [GROUP.MODULE]: GROUP.MODULE,
  [LOGID.MODULE]: LOGID.MODULE,
  [TEAM_MANAGEMENT.MODULE]: TEAM_MANAGEMENT.MODULE,
} as const;

// kill this
export const FEATURES = {
  ADMIN: 'ADMIN',
} as const;

// and also kill this, only ALL makes sense now
export const ACTIONS = {
  ALL: 'ALL',
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const;
const { ALL } = ACTIONS;

const moduleAll = (module: string) => ({
  ALL: `${module}${SEPARATOR}${ALL}`,
});

// Generate a generic role
export const gRole = <I extends string>(
  module: string,
  item: I,
): { [key in I]: string } => ({
  [item]: `${module}${SEPARATOR}${item}`,
});

export const genRole = <I extends string>(
  module: string,
  feature: string,
  item: I,
): { [key in I]: string } => ({
  [item]: `${module}${SEPARATOR}${feature}${SEPARATOR}${item}`,
});

const generateAll = (module: string, feature: string) => ({
  ALL: `${module}${SEPARATOR}${feature}${SEPARATOR}${ALL}`,
});

const featureAll = <F extends string>(
  module: string,
  feature: F,
): { [key in F]: { ALL: string } } => ({
  [feature]: {
    ...generateAll(module, feature),
  },
});

export const rolesForFeature = (
  module: string,
  features: Record<string, unknown>,
) =>
  Object.keys(features).reduce(
    (roles, feature) => ({
      ...roles,
      ...featureAll(module, feature),
    }),
    {},
  );

export const singleRoles = (
  module: string | null,
  roles: Record<string, unknown>,
  currentRole: string | null = null,
) =>
  Object.keys(roles).reduce((all, role) => {
    let roleObject = {};

    if (typeof roles[role] === 'string') {
      const newRole = currentRole ? `${currentRole}${SEPARATOR}${role}` : role;

      roleObject = {
        [role]: module ? `${module}${SEPARATOR}${newRole}` : `${newRole}`,
      };
    } else {
      const newRole = currentRole ? `${currentRole}${SEPARATOR}${role}` : role;

      roleObject = {
        [role]: singleRoles(module, roles[role], newRole),
      };
    }

    return {
      ...all,
      ...roleObject,
    };
  }, {});

type Module = {
  MODULE: string;
  FEATURE?: Record<string, unknown>;
  ROLES?: Record<string, unknown>;
};
const rolesForModule = (module: Module) => {
  const { MODULE, FEATURE = {}, ROLES = {} } = module;

  return {
    [MODULE]: {
      ...moduleAll(MODULE),
      ...rolesForFeature(MODULE, FEATURE),
      ...singleRoles(MODULE, ROLES),
    },
  };
};

export const ROLES = {
  ADMIN: {
    ALL: {
      ALL: 'ADMIN',
    },
  },
  ...rolesForModule(ADJUST),
  ...rolesForModule(CORE),
  ...rolesForModule(FEEDBACK_ONE_TO_ONE),
  ...rolesForModule(FEEDBACK),
  ...rolesForModule(FEEDBACK_GROUP),
  ...rolesForModule(FEEDBACK_EXTERNAL),
  ...rolesForModule(HIRING),
  ...rolesForModule(OMBUDSMAN),
  ...rolesForModule(OMBUDSMAN_EXTERNAL),
  ...rolesForModule(PLANNING),
  ...rolesForModule(RESEARCH),
  ...rolesForModule(GOALS),
  ...rolesForModule(PERMISSIONS),
  ...rolesForModule(FEEDBACK_RANKING),
  ...rolesForModule(PERFORMANCE_REVIEW),
  ...rolesForModule(SOCIAL),
  ...rolesForModule(HOME),
  ...rolesForModule(SUPPORT),
  ...rolesForModule(NINE_BOX),
  ...rolesForModule(GROUP),
  ...rolesForModule(PDI),
  ...rolesForModule(LOGID),
  ...rolesForModule(TEAM_MANAGEMENT),
  ...rolesForModule(OPEN_PIX),
  ...rolesForModule(OPENPIX_CASHOUT),
  ...rolesForModule(API),
};

// tests to update
// jest packages/main/src/modules/company/defaults/__tests__/CompanyDefaultRoleGroup.spec.ts
// jest packages/main/src/modules/company/mutations/console/__tests__/CompanyDefaults.spec.ts
// ROLES ENABLED ON DEFAULT COMPANY ROLE GROUP BASED ON MODULES
export const MODULE_DEFAULT_ROLES = {
  [MODULES.FEEDBACK_ONE_TO_ONE]: [ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
  [MODULES.FEEDBACK]: [ROLES.FEEDBACK.FEEDBACK.ALL, ROLES.FEEDBACK.USER.ALL],
  [MODULES.FEEDBACK_GROUP]: [
    ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
    ROLES.FEEDBACK_GROUP.USER.ALL,
  ],
  [MODULES.HIRING]: [ROLES.HIRING.MY_REFERRAL.ALL],
  [MODULES.OMBUDSMAN]: [
    ROLES.OMBUDSMAN.COMPLAINT.ALL,
    ROLES.OMBUDSMAN.USER.ALL,
  ],
  [MODULES.OMBUDSMAN_EXTERNAL]: [
    ROLES.OMBUDSMAN_EXTERNAL.COMPLAINT_EXTERNAL.ALL,
  ],
  [MODULES.PLANNING]: [],
  [MODULES.RESEARCH]: [ROLES.RESEARCH.USER.ALL],
  [MODULES.GOALS]: [ROLES.GOALS.GOALS.ALL],
  [MODULES.PERMISSIONS]: [],
  [MODULES.FEEDBACK_RANKING]: [ROLES.FEEDBACK_RANKING.USER.ALL],
  [MODULES.SOCIAL]: [ROLES.SOCIAL.NEWSFEED.ALL],
  [MODULES.TEMP]: [],
  [MODULES.FEEDBACK_EXTERNAL]: [ROLES.FEEDBACK_EXTERNAL.USER.ALL],
  [MODULES.PERFORMANCE_REVIEW]: [
    ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ROLES.PERFORMANCE_REVIEW.MANAGER.ALL,
  ],
  [MODULES.HOME]: [],
  [MODULES.SUPPORT]: [ROLES.SUPPORT.ALL],
  [MODULES.NINE_BOX]: [ROLES.NINE_BOX.USER.ALL],
  [MODULES.PDI]: [ROLES.PDI.USER.ALL, ROLES.PDI.CREATE],
  [MODULES.CORE]: [
    ROLES.CORE.USER.MANAGE_TEAM,
    ROLES.CORE.USER.INVITE,
    ROLES.CORE.PREFERENCES.CHANGE_NAME,
    ROLES.CORE.PREFERENCES.CHANGE_MANAGER,
    ROLES.CORE.PREFERENCES.CHANGE_JOB_TITLE,
    ROLES.CORE.PREFERENCES.CHANGE_PASSWORD,
    ROLES.CORE.PREFERENCES.CHANGE_NICKNAME,
    ROLES.CORE.PREFERENCES.CHANGE_TAXID,
    ROLES.CORE.PREFERENCES.CHANGE_EMAIL,
  ],
  [MODULES.GROUP]: [ROLES.GROUP.USER.ALL, ROLES.GROUP.CREATE],
  [MODULES.LOGID]: [ROLES.LOGID.USER.ALL],
  [MODULES.TEAM_MANAGEMENT]: [ROLES.TEAM_MANAGEMENT.USER.ALL],
  [MODULES.OPEN_PIX]: [ROLES.OPEN_PIX.USER.ALL],
  [MODULES.OPENPIX_CASHOUT]: [],
  [MODULES.API]: [],
} as const;

export const ADMIN_ROLE = ROLES.ADMIN.ALL.ALL;

export const DEFAULT_MODULES = [
  MODULES.ADJUST,
  MODULES.PERMISSIONS,
  MODULES.CORE,
  MODULES.HOME,
  MODULES.TEAM_MANAGEMENT,
  MODULES.SUPPORT,
];

// HR Modules, used to detect if only LogID is running
export const HR_MODULES = [
  MODULES.FEEDBACK,
  MODULES.FEEDBACK_GROUP,
  MODULES.FEEDBACK_ONE_TO_ONE,
  MODULES.HIRING,
  MODULES.OMBUDSMAN,
  MODULES.OMBUDSMAN_EXTERNAL,
  MODULES.PLANNING,
  MODULES.RESEARCH,
  MODULES.GOALS,
  MODULES.FEEDBACK_RANKING,
  MODULES.FEEDBACK_EXTERNAL,
  MODULES.PERFORMANCE_REVIEW,
  MODULES.SOCIAL,
  MODULES.GROUP,
];

export const PRE_REGISTER_MODULES = [
  MODULES.FEEDBACK,
  MODULES.FEEDBACK_GROUP,
  MODULES.FEEDBACK_ONE_TO_ONE,
  MODULES.HIRING,
  MODULES.OMBUDSMAN,
  MODULES.OMBUDSMAN_EXTERNAL,
  MODULES.PLANNING,
  MODULES.RESEARCH,
  MODULES.GOALS,
  MODULES.FEEDBACK_RANKING,
  MODULES.FEEDBACK_EXTERNAL,
  MODULES.PERFORMANCE_REVIEW,
  MODULES.SOCIAL,
  MODULES.HOME,
  MODULES.GROUP,
  MODULES.SUPPORT,
];

export const LOGID_MODULES = [MODULES.LOGID, MODULES.SUPPORT];

export const OPENPIX_MODULES = [
  MODULES.OPEN_PIX,
  MODULES.ADJUST,
  MODULES.PERMISSIONS,
  MODULES.SUPPORT,
];

export const COMPANY_PROJECT_TYPES_ENUM = {
  FEEDBACK_HOUSE: 'FEEDBACK_HOUSE',
  LOGID: 'LOGID',
  OPEN_PIX: 'OPEN_PIX',
};

export const COMPANY_PROJECT_TYPES_VALUES = {
  [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE]: {
    name: 'Feedback House',
    value: COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE,
  },
  [COMPANY_PROJECT_TYPES_ENUM.LOGID]: {
    name: 'Log.iD',
    value: COMPANY_PROJECT_TYPES_ENUM.LOGID,
  },
  [COMPANY_PROJECT_TYPES_ENUM.OPEN_PIX]: {
    name: 'OpenPix',
    value: COMPANY_PROJECT_TYPES_ENUM.OPEN_PIX,
  },
};
