// @flow

type Props = {
  color: string;
  size: string | number;
  standalone: boolean;
};

const Settings = ({ size, color, standalone, ...props }: Props) => {
  const gProps = standalone
    ? {}
    : {
        ...props,
        width: size,
        height: size,
      };

  const content = (
    <g fill='none' fillRule='evenodd' {...gProps}>
      <path d='M-2-2h24v24H-2z' />
      <path
        fill='#FFF'
        fillRule='nonzero'
        d='M17.43 10.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98L12.49.42A.488.488 0 0 0 12 0H8c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98L.46 12.63c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM10 13.5c-1.93 0-3.5-1.57-3.5-3.5S8.07 6.5 10 6.5s3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z'
      />
    </g>
  );

  return standalone ? (
    <svg width={size} height={size} viewBox='0 0 20 20' {...props}>
      {content}
    </svg>
  ) : (
    content
  );
};

Settings.defaultProps = {
  color: '#49d7fd',
  size: '20px',
  standalone: true,
};

export default Settings;
