/**
 * @generated SignedSource<<ddb7b5e0bc3e5302f5a79c49269f34f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackUtils_me$data = {
  readonly id: string;
  readonly " $fragmentType": "FeedbackUtils_me";
};
export type FeedbackUtils_me$key = {
  readonly " $data"?: FeedbackUtils_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackUtils_me">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "FeedbackUtils_me"
};

(node as any).hash = "73491262b6cab68daefe04a2323741b5";

export default node;
