import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils/index';


const PATH = '/home/hiring';

const groupInterviewRoutes: RouteType[] = [
  {
    name: 'groupInterview',
    path: `${PATH}/groupInterviews`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Entrevistas em grupo',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'groupInterview.list',
        path: `${PATH}/groupInterviews/list`,
        labelHeader: 'Entrevistas em grupo',
        component: loadable(
          () => import('../../../components/groupInterview/GroupInterviewList'),
        ),
        exact: false,
        label: 'Dinâmicas',
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
        requiredFeatures: [MODULES.HIRING],
        modalRoutes: [
          {
            name: 'groupInterview.add',
            labelHeader: 'Entrevistas em grupo',
            path: `${PATH}/groupInterviews/list/add`,
            component: loadable(
              () =>
                import('../../../components/groupInterview/GroupInterviewAdd'),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
            requiredFeatures: [MODULES.HIRING],
          },
        ],
      },
      {
        name: 'groupInterviewRoom.list',
        path: `${PATH}/groupInterviews/rooms`,
        labelHeader: 'Entrevistas em grupo - Salas',
        component: loadable(
          () =>
            import(
              '../../../components/groupInterviewRoom/GroupInterviewRoomList'
            ),
        ),
        exact: false,
        label: 'Todas as salas',
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
        requiredFeatures: [MODULES.HIRING],
        modalRoutes: [
          {
            name: 'groupInterviewRoom.add',
            labelHeader: 'Entrevistas em grupo - Salas',
            path: `${PATH}/groupInterviews/rooms/add`,
            component: loadable(
              () =>
                import(
                  '../../../components/groupInterviewRoom/GroupInterviewRoomAdd'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
            requiredFeatures: [MODULES.HIRING],
          },
        ],
      },
    ],
  },
  {
    name: 'groupInterview.view',
    path: `${PATH}/groupInterview/:id`,
    labelHeader: 'Entrevistas em grupo',
    component: loadable(
      () => import('../../../components/groupInterview/detail/GroupInterview'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'groupInterview.candidates',
        labelHeader: 'Entrevistas em grupo - Candidatos Convidados',
        label: 'Candidatos convidados',
        path: `${PATH}/groupInterview/:id/candidates`,
        component: loadable(
          () =>
            import(
              '../../../components/groupInterview/inviteCandidate/GroupInterviewCandidateList'
            ),
        ),
        exact: false,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
        requiredFeatures: [MODULES.HIRING],
        modalRoutes: [
          {
            name: 'groupInterview.candidates.invite',
            labelHeader: 'Entrevistas em grupo - Candidatos Convidados',
            modalTitle: 'Convidar candidatos',
            path: `${PATH}/groupInterview/:id/candidates/invite`,
            component: loadable(
              () =>
                import(
                  '../../../components/groupInterview/inviteCandidate/GroupInterviewInviteCandidates'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
            requiredFeatures: [MODULES.HIRING],
          },
        ],
      },
      {
        name: 'groupInterview.rooms',
        path: `${PATH}/groupInterview/:id/rooms`,
        label: 'Salas',
        labelHeader: 'Entrevistas em grupo - Salas',
        component: loadable(
          () =>
            import(
              '../../../components/groupInterview/GroupInterviewRoomsList'
            ),
        ),
        exact: false,
        requiredFeatures: [MODULES.HIRING],
        modalRoutes: [
          {
            name: 'groupInterview.rooms.add',
            labelHeader: 'Entrevistas em Grupo - Salas',
            path: `${PATH}/groupInterview/:id/rooms/add`,
            component: loadable(
              () =>
                import(
                  '../../../components/groupInterviewRoom/GroupInterviewRoomAdd'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
            requiredFeatures: [MODULES.HIRING],
          },
        ],
      },
      {
        name: 'groupInterview.edit',
        path: `${PATH}/groupInterview/:id/edit`,
        label: 'Ajustes',
        labelHeader: 'Entrevistas em grupo - Ajustes',
        component: loadable(
          () => import('../../../components/groupInterview/GroupInterviewEdit'),
        ),
        exact: true,
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'groupInterviewRoom.view',
    path: `${PATH}/groupInterviewRoom/:id`,
    labelHeader: 'Entrevista em grupo',
    component: loadable(
      () => import('../../../components/groupInterviewRoom/GroupInterviewRoom'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'groupInterviewRoom.candidates',
        labelHeader: 'Sala Dinâmica em grupo - Candidatos Convidados',
        label: 'Candidatos',
        path: `${PATH}/groupInterviewRoom/:id/candidates`,
        component: loadable(
          () =>
            import(
              '../../../components/groupInterviewRoom/candidates/GroupInterviewRoomCandidateList'
            ),
        ),
        exact: false,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'groupInterviewRoom.edit',
        path: `${PATH}/groupInterviewRoom/:id/edit`,
        label: 'Ajustes',
        labelHeader: 'Sala de Dinâmica em grupo - Ajustes',
        component: loadable(
          () =>
            import(
              '../../../components/groupInterviewRoom/GroupInterviewRoomEdit'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.GROUP_INTERVIEW.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
];

export default groupInterviewRoutes;
