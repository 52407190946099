import { createTheme } from '@material-ui/core/styles';
import { graphql } from 'react-relay';

import { ThemeMuiTheme } from '@feedback/ui';

// eslint-disable-next-line
graphql`
  fragment ThemeFragment on Theme {
    id
    name
    primaryDarkerColor
    primaryLighterColor
    button {
      backgroundColor
      textColor
    }
    richHeaderColor1
    richHeaderColor2
    richHeaderTextColor
    darkGray
    highlightColor
    mediumGray
    lightGray
    graphLighterColor
  }
`;

// eslint-disable-next-line
graphql`
  fragment ThemeCompanyFragment on Company {
    preferences {
      theme {
        ...ThemeFragment @relay(mask: false)
      }
    }
  }
`;

export const theme = (companyTheme) => {
  return {
    ...createTheme(ThemeMuiTheme(companyTheme)),
  };
};
