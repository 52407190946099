import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

import { routeTo } from '../../../router/utils';

export const team = ({ t }) => [
  {
    label: t('Team'),
    icon: <PeopleAltOutlinedIcon />,
    link: routeTo('manager.teams.list'),
  },
];
