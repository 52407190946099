import { IoFlagOutline } from 'react-icons/io5';

import { GOALS_PARAMS } from '../../../router/routes/goalsRoutes';
import { routeTo } from '../../../router/utils';
import type { SideBarMenuItemProps } from '../menuItems';

export const goals = ({ query, t }: SideBarMenuItemProps) => [
  {
    label: t('Goals'),
    icon: <IoFlagOutline />,
    link: routeTo('goals.groups.user.overview.list', {
      type: GOALS_PARAMS.USER,
      user: query.me.id,
    }),
  },
];

export const goalsAdmin = ({ t }: SideBarMenuItemProps) => [
  {
    label: t('Goals'),
    link: routeTo('goals.group.admin.overview.list', {
      type: GOALS_PARAMS.COMPANY,
    }),
    icon: <IoFlagOutline />,
  },
];
