const LineChart = ({ width, color }) => {
  return (
    <svg viewBox='0 0 20 17' width={width} height={width}>
      <g fill={color} fillRule='nonzero'>
        <path d='M1.355 13.37V16.6c0 .197.16.36.362.36h2.505c.197 0 .361-.159.361-.36v-5.685l-1.82 1.814a2.21 2.21 0 0 1-1.408.64zM5.832 10.875V16.6c0 .197.16.36.361.36h2.505c.197 0 .362-.159.362-.36v-3.37a2.186 2.186 0 0 1-1.508-.64l-1.72-1.716zM10.308 12.874V16.6c0 .197.16.36.361.36h2.506c.197 0 .36-.159.36-.36V9.724l-2.87 2.863c-.11.11-.23.205-.357.287zM17.77 5.496l-2.986 2.978v8.127c0 .197.16.36.362.36h2.505c.197 0 .361-.159.361-.36V5.721c-.082-.07-.143-.13-.189-.172l-.053-.053z' />
        <path d='M19.82.22c-.12-.127-.3-.193-.53-.193h-.066c-1.167.054-2.329.111-3.495.164-.156.009-.37.017-.538.185a.61.61 0 0 0-.127.188c-.173.373.07.615.184.73l.292.294c.201.205.407.41.612.61L9.105 9.233 5.938 6.074a1.008 1.008 0 0 0-1.425 0L.296 10.277a1.002 1.002 0 0 0 0 1.421l.189.189a1.008 1.008 0 0 0 1.425 0L5.224 8.58l3.166 3.158a1.015 1.015 0 0 0 1.43 0l7.95-7.93.9.892c.106.107.254.254.48.254a.596.596 0 0 0 .287-.077.782.782 0 0 0 .173-.127c.176-.177.21-.402.218-.578.032-.754.07-1.507.106-2.265l.054-1.135C20 .535 19.947.351 19.819.22z' />
      </g>
    </svg>
  );
};

LineChart.defaultProps = {
  width: 24,
  color: '#757575',
};

export default LineChart;
