import type { ButtonProps } from '@material-ui/core/Button';
import _Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { space, layout, flexbox, border } from 'styled-system';

import _Icon from '../icons/Icon';

const Icon = styled(_Icon)`
  font-size: 12px !important;
`;

type StyledButtonProps = {
  bgColor?: string;
};

const Button = styled(_Button)<StyledButtonProps>`
  margin-left: 8px;
  text-transform: none;
  padding: 8px 16px;
  line-height: 1;

  ${space}
  ${layout}
  ${flexbox}
  ${border}
`;

export type ActionButtonProps = {
  icon?: string;
  label?: string;
  link?: string;
  external?: boolean;
} & ButtonProps &
  StyledButtonProps;

const ActionButton = ({
  icon,
  size = 'medium',
  variant = 'contained',
  color = 'primary',
  children,
  label,
  link,
  external = false,
  ...props
}: ActionButtonProps) => {
  const innerElement = (
    <>
      {!!icon && <Icon>{icon}</Icon>}
      {label ? label : children}
    </>
  );

  if (link) {
    if (external) {
      return (
        <Button
          target='_blank'
          href={link}
          color={color}
          variant={variant}
          size={size}
          disableElevation
          {...props}
        >
          {innerElement}
        </Button>
      );
    }

    return (
      <Button
        component={Link}
        to={link}
        color={color}
        variant={variant}
        size={size}
        disableElevation
        {...props}
      >
        {innerElement}
      </Button>
    );
  }

  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      disableElevation
      {...props}
    >
      {innerElement}
    </Button>
  );
};

export default ActionButton;
