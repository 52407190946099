import loadable from '@loadable/component';

import { MODULES } from '@feedback/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/teams';

export const teamRoutes: RouteType[] = [
  {
    name: 'team.overview',
    path: `${PATH}`,
    labelHeader: ({ t }) => t('My Team'),
    requiredRoles: [],
    requiredFeatures: [MODULES.TEAM_MANAGEMENT],
    exact: false,
    component: loadable(() => import('../../../components/team/MyTeamDetails')),
    label: ({ t }) => t('My Team'),
    routes: [
      {
        name: 'manager.teams.list',
        labelHeader: ({ t }) => t('Members'),
        path: `${PATH}/list`,
        requiredRoles: [],
        requiredFeatures: [MODULES.TEAM_MANAGEMENT],
        exact: true,
        component: loadable(
          () => import('../../../components/team/members/TeamMembersOverview'),
        ),
        label: ({ t }) => t('Members'),
      },
      {
        name: 'manager.teams.comparative',
        labelHeader: ({ t }) => t('Comparative'),
        path: `${PATH}/comparative`,
        requiredRoles: [],
        requiredFeatures: [MODULES.NINE_BOX, MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/manager/Comparative/ManagerComparativeView'
            ),
        ),
        label: ({ t }) => t('Comparative'),
      },
      // TODO: Deprecated
      // {
      //   name: 'team.orgchart',
      //   path: `${PATH}/orgchart`,
      //   labelHeader: 'Meu Organograma',
      //   label: 'Organograma',
      //   exact: true,
      //   component: loadable(
      //     () => import('../../../components/team/MyOrgChart'),
      //   ),
      //   requiredRoles: [
      //     ADMIN_ROLE,
      //     ROLES.FEEDBACK.ADMIN.ALL,
      //     ROLES.FEEDBACK.ANALYTICS.ALL,
      //     ROLES.FEEDBACK.FEEDBACK.ALL,
      //   ],
      //   requiredFeatures: [MODULES.FEEDBACK],
      // },
      {
        name: 'team.myCostRevenueCenters',
        path: `${PATH}/myAreas`,
        labelHeader: ({ t }) => t('My Areas'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/MyCostRevenueCenterList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.CORE],
        label: ({ t }) => t('My Areas'),
      },
      {
        name: 'team.myBusinessDivision.list',
        path: `${PATH}/myBusinessDivision`,
        labelHeader: ({ t }) => t('My Business Divisions'),
        component: loadable(
          () =>
            import(
              '../../../components/businessDivision/MyBusinessDivisionList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.CORE],
        label: ({ t }) => t('My Business Divisions'),
      },
      {
        name: 'manager.teams.nineBox.details',
        labelHeader: ({ t }) => t('{{NineBox}}'),
        path: `${PATH}/nineBox/:id/`,
        requiredRoles: [],
        requiredFeatures: [MODULES.NINE_BOX],
        exact: false,
        component: loadable(
          () => import('../../../components/nineBox/team/NineBoxTabView'),
        ),
        routes: [
          {
            name: 'manager.teams.nineBox.details.box',
            labelHeader: ({ t }) => t('{{NineBox}}'),
            path: `${PATH}/nineBox/:id/box`,
            requiredRoles: [],
            requiredFeatures: [MODULES.NINE_BOX],
            exact: true,
            label: 'Box',
            component: loadable(
              () =>
                import('../../../components/nineBox/team/NineBoxTeamDetails'),
            ),
          },
          {
            name: 'manager.teams.nineBox.details.report',
            labelHeader: ({ t }) => t('Report'),
            path: `${PATH}/nineBox/:id/report`,
            requiredRoles: [],
            requiredFeatures: [MODULES.NINE_BOX],
            exact: true,
            label: ({ t }) => t('Report'),
            component: loadable(
              () =>
                import(
                  '../../../components/nineBox/team/report/NineBoxTeamReport'
                ),
            ),
          },
        ],
      },
    ],
  },
];
