import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { BusinessDivisionSelectField_query } from './__generated__/BusinessDivisionSelectField_query.graphql';

type Props = {
  query: BusinessDivisionSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  fullWidth?: boolean;
  value?: BusinessDivision;
};

type BusinessDivision = {
  id: string;
  name: string;
};
const BusinessDivisionSelectField = ({
  hintText = 'Unidade de Negócio',
  name = 'businessDivision',
  query,
  relay,
  ...rest
}: Props) => {
  const getItemFromNode = ({ id, name }) => {
    return {
      id,
      name,
    };
  };

  const getFilterFragmentVariables = (state) => {
    const { search, filters: resetFilter } = state;

    return {
      filters: resetFilter
        ? {}
        : {
            search,
          },
    };
  };

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.businessDivisions}
      getItemFromNode={getItemFromNode}
      hintText={hintText}
      name={name}
      getFilterFragmentVariables={getFilterFragmentVariables}
      {...rest}
    />
  );
};

const BusinessDivisionSelectFieldRefetchContainer = createRefetchContainer(
  BusinessDivisionSelectField,
  {
    query: graphql`
      fragment BusinessDivisionSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 50 }
        filters: { type: BusinessDivisionFilter }
        after: { type: String }
      ) {
        businessDivisions(first: $count, filters: $filters, after: $after)
          @connection(
            key: "BusinessDivisionSelectField_businessDivisions"
            filters: []
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query BusinessDivisionSelectFieldQuery(
      $count: Int
      $filters: BusinessDivisionFilter
      $after: String
    ) {
      ...BusinessDivisionSelectField_query
        @arguments(count: $count, filters: $filters, after: $after)
    }
  `,
);

export default BusinessDivisionSelectFieldRefetchContainer;
