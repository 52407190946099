import { GrOracle } from 'react-icons/gr';

import BancoDoBrasil from './banks/BancoDoBrasil';
import Bradesco from './banks/Bradesco';
import CheckboxChecked from './CheckboxChecked';
import CheckboxIndeterminate from './CheckboxIndeterminate';
import CheckboxUnchecked from './CheckboxUnchecked';
import Checklist from './Checklist';
import FeedbackGive from './FeedbackGive';
import FeedbackLogo from './FeedbackLogo';
import FeedbackLogoIcon from './FeedbackLogoIcon';
import FeedbackRequest from './FeedbackRequest';
import Feedbacks from './Feedbacks';
import Filter from './Filter';
import Flag from './Flag';
import GiveFeedback from './GiveFeedback';
import GroupGiveFeedbackIcon from './GroupGiveFeedbackIcon';
import Hiring from './Hiring';
import HomeTip from './HomeTip';
import Itau from './banks/Itau';
import LineChart from './LineChart';
import Logo from './Logo';
import MiniTrophy from './MiniTrophy';
import MyTeam from './MyTeam';
import NewGroups from './NewGroups';
import NewOmbudsman from './NewOmbudsman';
import NineBox from './NineBox';
import Ombudsman from './Ombudsman';
import Poll from './Poll';
import Permissions from './Permissions';
import Recurrent from './Recurrent';
import Settings from './Settings';
import Star from './Star';
import Teams from './Teams';
import Trash from './Trash';
import Success from './Success';
import Warning from './Warning';

export default {
  BancoDoBrasil,
  Bradesco,
  FeedbackLogoIcon,
  FeedbackGive,
  FeedbackRequest,
  GiveFeedback,
  Itau,
  MyTeam,
  Ombudsman,
  Feedbacks,
  Teams,
  NewGroups,
  NewOmbudsman,
  Permissions,
  Hiring,
  FeedbackLogo,
  Flag,
  CheckboxChecked,
  CheckboxUnchecked,
  CheckboxIndeterminate,
  Star,
  MiniTrophy,
  Poll,
  LineChart,
  Checklist,
  Logo,
  NineBox,
  GroupGiveFeedbackIcon,
  Filter,
  Settings,
  HomeTip,
  Recurrent,
  Trash,
  Success,
  Warning,
  GrOracle,
};
