import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { graphql } from 'react-relay';
import URLSearchParams from 'url-search-params';

import { setCompany } from '../../redux/modules/login';
import { getDomainName, TOKEN_KEY } from '../../security/auth';

import type { useDomainName_company$data } from './__generated__/useDomainName_company.graphql';

// eslint-disable-next-line
graphql`
  fragment useDomainName_company on Company {
    id
    _id
    name
    nameFriendly
    logo {
      url(fitIn: true)
    }
  }
`;

export const useDomainName = (company: useDomainName_company$data) => {
  const dispatch = useDispatch();

  const getMagicLinkToken = () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('t')) {
      return null;
    }

    localStorage.setItem(TOKEN_KEY, urlParams.get('t') ?? '');
  };

  useEffect(() => {
    getMagicLinkToken();

    if (company) {
      dispatch(
        setCompany({
          id: company.id,
          domainName: getDomainName(),
          nameFriendly: company.nameFriendly,
          name: company.name,
          logo: company.logo,
        }),
      );
    }
  }, [company]);

  return !!company;
};
