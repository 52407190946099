import { Component } from 'react';
import type {
  DropResult } from 'react-beautiful-dnd';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';
import styled from 'styled-components';

const DroppableList = styled.div`
  background: ${(props) => (props.isDraggingOver ? 'lightblue' : 'white')};
  padding: 1px;
`;

type Props<T> = {
  list: T[];
  getId: (item: T) => string;
  createItem: (list: T, item: T, index: number) => void;
  updateList: (list: T[]) => void;
};
class DragAndDrop<T> extends Component<Props<T>> {
  reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result: T[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result: DropResult) => {
    const { updateList, list } = this.props;

    if (!result.destination) {
      return;
    }

    const newList = this.reorder(
      list,
      result.source.index,
      result.destination.index,
    );

    updateList(newList);
  };

  render() {
    const {
      list,
      createItem,
      className,
      getId = (item) => item.id,
    } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId='cardCreate'>
          {(provided, snapshot) => (
            <DroppableList
              isDraggingOver={snapshot.isDraggingOver}
              className={className}
            >
              <div ref={provided.innerRef}>
                {
                  // list.map((item, index) => (
                  list.map((item, index) => {
                    const id = getId(item);

                    return (
                      <Draggable
                        key={`draggable-${index}:${id}`}
                        draggableId={id}
                        index={index}
                      >
                        {(provided) => (
                          <div>
                            <div
                              ref={provided.innerRef}
                              style={{
                                ...provided.draggableStyle,
                                padding: 1,
                                margin: '0 0 1px 0',
                              }}
                              {...provided.droppableProps}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div key={`item-${index}:${id}`}>
                                {createItem(list, item, index)}
                              </div>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                }
              </div>
            </DroppableList>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DragAndDrop;
