import loadable from '@loadable/component';

import type { RouteType } from '../../utils';

// Template
import LoginApp from '../../../components/auth/login/LoginApp';

import routeHandlers from '../routeHandlers';

const PATH = '/signUp';

const authenticationRoutes: RouteType = {
  path: `${PATH}`,
  name: 'signUpRoutes',
  exact: false,
  component: LoginApp,
  routes: [
    {
      name: 'signUp.magicToken',
      path: `${PATH}/magicToken/:token`,
      component: loadable(
        () => import('../../../components/auth/signUp/SignUpWithMagicToken'),
      ),
      exact: true,
    },
    {
      name: 'signUp.emailConfirmation',
      path: `${PATH}/emailConfirmation`,
      component: loadable(
        () => import('../../../components/auth/signUp/EmailConfirmation'),
      ),
      exact: true,
    },
    {
      name: 'signUp.finish',
      path: `${PATH}/finish`,
      component: loadable(
        () => import('../../../components/auth/signUp/FinishSignUp'),
      ),
      exact: true,
    },
    ...routeHandlers(PATH),
  ],
};

export default authenticationRoutes;
