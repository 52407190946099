import {
  useLazyLoadQuery,
  useRefetchableFragment,
  useRelayEnvironment,
 graphql } from 'react-relay';
import { useTranslation } from 'react-i18next';

import { ResourceSelectField } from '@feedback/select';
import type { FetchKeyProp } from '@feedback/relay';
import { refetchWithSuspense } from '@feedback/relay';

import { withRelayBoundary } from '../../relay';

import CostRevenueCenterSelectFieldRefetchQuery from './__generated__/CostRevenueCenterSelectFieldRefetchQuery.graphql';
import type { CostRevenueCenterSelectField_query$key } from './__generated__/CostRevenueCenterSelectField_query.graphql';
import type { CostRevenueCenterSelectFieldQuery } from './__generated__/CostRevenueCenterSelectFieldQuery.graphql';

type Props = {
  multiple?: boolean;
  onChange: (node: any) => void;
  fullWidth?: boolean;
  value?: CostRevenueCenter;
  resetInputOnSelection?: boolean;
  filters?: Record<string, unknown>;
} & FetchKeyProp;

type CostRevenueCenter = {
  id: string;
  name: string;
};
const CostRevenueCenterSelectField = (props: Props) => {
  const { t } = useTranslation();

  const {
    hintText = t('Search Area / Location'),
    name = 'costRevenueCenter',
    filters = {
      active: true,
    },
    resetInputOnSelection = false,
    ...rest
  } = props;

  const environment = useRelayEnvironment();
  const getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      filters: {
        search,
        ...filters,
      },
    };
  };

  const response = useLazyLoadQuery<CostRevenueCenterSelectFieldQuery>(
    graphql`
      query CostRevenueCenterSelectFieldQuery(
        $first: Int
        $after: String
        $filters: CostRevenueCenterFilter
      ) {
        ...CostRevenueCenterSelectField_query
          @arguments(first: $first, after: $after, filters: $filters)
      }
    `,
    {},
    {
      // fetchPolicy: 'store-or-network',
      fetchPolicy: 'network-only',
      fetchKey: props.fetchKey,
    },
  );

  // eslint-disable-next-line
  const [data, refetchFn] = useRefetchableFragment<
    CostRevenueCenterSelectFieldQuery,
    CostRevenueCenterSelectField_query$key
  >(
    graphql`
      fragment CostRevenueCenterSelectField_query on Query
      @refetchable(queryName: "CostRevenueCenterSelectFieldRefetchQuery")
      @argumentDefinitions(
        first: { type: Int, defaultValue: 30 }
        after: { type: String }
        filters: {
          type: CostRevenueCenterFilter
          defaultValue: { active: true }
        }
      ) {
        selectCostRevenueCenters: costRevenueCenters(
          first: $first
          after: $after
          filters: $filters
        )
          @connection(
            key: "CostRevenueCenterSelectField_selectCostRevenueCenters"
            filters: ["filters"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              active
            }
          }
        }
      }
    `,
    response,
  );

  const refetch = refetchWithSuspense(
    environment,
    CostRevenueCenterSelectFieldRefetchQuery,
    refetchFn,
  );

  const relay = {
    refetch,
  };

  const { selectCostRevenueCenters } = data;

  const getItemFromNode = ({ id, name }) => {
    return {
      id,
      name,
    };
  };

  const getRenderVariables = (state) => {
    const { search } = state;

    return {
      filters: {
        search,
        ...filters,
      },
    };
  };

  return (
    <ResourceSelectField
      getFilterFragmentVariables={getFilterFragmentVariables}
      getRenderVariables={getRenderVariables}
      relay={relay}
      getItemFromNode={getItemFromNode}
      connection={selectCostRevenueCenters}
      resetInputOnSelection={resetInputOnSelection}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

export default withRelayBoundary(CostRevenueCenterSelectField);
