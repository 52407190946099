import type { IconProps } from '@material-ui/core/Icon';
import _Icon from '@material-ui/core/Icon';

const Icon = ({ children, ...props }: IconProps) => (
  <_Icon color='inherit' {...props}>
    {children}
  </_Icon>
);

export default Icon;
