import loadable from '@loadable/component';

import {
  ADMIN_ROLE,
  COMPANY_PROJECT_TYPES_ENUM,
  MODULES,
  ROLES,
} from '@feedback/roles';

import type { RouteType } from '../../utils';
import { routeTo } from '../../utils';


const PATH = '/home/hiring';

const referralRoutes: RouteType[] = [
  {
    name: 'myHiringReferral',
    path: `${PATH}/myHiringReferral`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Indicated Candidates'),
    buttons: ({ t }) => [
      {
        label: t('Indicate Candidate'),
        link: routeTo('candidate.referral'),
      },
    ],
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.REFERRAL.ALL,
      ROLES.HIRING.MY_REFERRAL.ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
    requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
    routes: [
      {
        name: 'myHiringReferral',
        path: `${PATH}/myHiringReferral`,
        component: loadable(
          () =>
            import(
              '../../../components/hiringReferral/components/MyHiringReferralList'
            ),
        ),
        exact: false,
        labelHeader: ({ t }) => t('Indicated Candidates'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.REFERRAL.ALL,
          ROLES.HIRING.MY_REFERRAL.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
    ],
  },
];

export default referralRoutes;
