import styled from 'styled-components';

import { ProgressBar } from '@feedback/ui';
import { useTranslation } from 'react-i18next';

const Card = styled.div`
  border-radius: 2px;
  display: flex;
  width: fill-available;
  width: -moz-fill-available;
  width: -webkit-fill-available;
  max-width: 265px;
  height: 160px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 #dfdfdf, 0 1px 5px 0 #dfdfdf;
  flex-direction: column;
  margin-right: 20px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;

const CardHeader = styled.div`
  height: 80px;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgba(96, 115, 137, 0.2);
`;

const HeaderText = styled.p`
  text-align: center;
  font-weight: 600;
  color: #607389;
  font-size: 12px;
  margin-bottom: 1px;
`;

const Text = styled(HeaderText)``;

const CardBody = styled.div`
  margin-top: 15px;
  flex-direction: column;
  align-items: center;
  display: flex;
  max-height: 80px;
  width: inherit;
`;

const PercentageText = styled.span`
  margin-left: 10px;
  color: #607389;
  font-weight: 600;
  font-size: 14px;
`;

const StatusWrapper = styled.div`
  margin-left: 10px;
  align-items: center;
  display: flex;
`;

type Props = {
  total: number;
  stat: number;
  label: string;
  text: string;
  onClick?: () => void;
};
const StatusCard = ({ total, stat, label, text, onClick }: Props) => {
  const { t } = useTranslation();
  const percent = total > 0 ? ((stat / total) * 100).toFixed(0) : 0;

  return (
    <Card onClick={onClick}>
      <CardHeader>
        <HeaderText>{label.toUpperCase()}</HeaderText>
        <HeaderText>
          {' '}
          {stat} {t('of')} {total} {t('Collaborators')}{' '}
        </HeaderText>
      </CardHeader>
      <CardBody>
        <StatusWrapper>
          <ProgressBar percent={percent} />
          <PercentageText>{percent}%</PercentageText>
        </StatusWrapper>
        <Text>{text}</Text>
      </CardBody>
    </Card>
  );
};

export default StatusCard;
