const initialState = {
  domainName: null,
};

const types = {
  SET_COMPANY: 'APP:SET_COMPANY',
};

export const setDomainName = (domainName) => ({
  type: types.SET_DOMAIN_NAME,
  domainName,
});

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_COMPANY: {
      const { domainName } = action;

      return {
        ...state,
        domainName,
      };
    }

    default:
      return state;
  }
}
