/**
 * @generated SignedSource<<a75894a1526a79fefa54d3a95a1c405b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProvidersQuery$variables = Record<PropertyKey, never>;
export type ProvidersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Providers_query">;
};
export type ProvidersQuery = {
  response: ProvidersQuery$data;
  variables: ProvidersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvidersQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Providers_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProvidersQuery",
    "selections": [
      {
        "alias": "company",
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyDomainName",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyPreferencesFeedback",
                "kind": "LinkedField",
                "name": "feedback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedbackModulePreferences",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pluralName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyPreferencesFeedbackOneToOne",
                "kind": "LinkedField",
                "name": "feedbackOneToOne",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedbackOneToOneModulePreferences",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyComplaintPreferences",
                "kind": "LinkedField",
                "name": "complaint",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ComplaintModulePreferences",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyComplaintExternalPreferences",
                "kind": "LinkedField",
                "name": "complaintExternal",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ComplaintExternalModulePreferences",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyPreferencesNineBox",
                "kind": "LinkedField",
                "name": "nineBox",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NineBoxModulePreferences",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Theme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryDarkerColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryLighterColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Style",
                    "kind": "LinkedField",
                    "name": "button",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "backgroundColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "textColor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "richHeaderColor1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "richHeaderColor2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "richHeaderTextColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "darkGray",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "highlightColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediumGray",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lightGray",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "graphLighterColor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7bf789cc88d44f38a43c317a14f52b78",
    "id": null,
    "metadata": {},
    "name": "ProvidersQuery",
    "operationKind": "query",
    "text": "query ProvidersQuery {\n  ...Providers_query\n}\n\nfragment Providers_query on Query {\n  company: companyDomainName {\n    lang\n    preferences {\n      feedback {\n        module {\n          name\n          pluralName\n        }\n      }\n      feedbackOneToOne {\n        module {\n          name\n        }\n      }\n      complaint {\n        module {\n          name\n        }\n      }\n      complaintExternal {\n        title\n        module {\n          name\n        }\n      }\n      nineBox {\n        module {\n          name\n        }\n      }\n      theme {\n        id\n        name\n        primaryDarkerColor\n        primaryLighterColor\n        button {\n          backgroundColor\n          textColor\n        }\n        richHeaderColor1\n        richHeaderColor2\n        richHeaderTextColor\n        darkGray\n        highlightColor\n        mediumGray\n        lightGray\n        graphLighterColor\n      }\n    }\n    id\n  }\n  me {\n    lang\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "65ec9d6c161718061a0be404fe2e95eb";

export default node;
