/**
 * @generated SignedSource<<aa315fb71d4c5a4eb76ca7252b0ed7cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserNotificationsSourceEnum = "APP" | "WEB" | "%future added value";
export type UserNotificationMarkAsReadInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  source: UserNotificationsSourceEnum;
};
export type UserNotificationMarkAsReadMutation$variables = {
  input: UserNotificationMarkAsReadInput;
};
export type UserNotificationMarkAsReadMutation$data = {
  readonly UserNotificationMarkAsRead: {
    readonly error: string | null | undefined;
    readonly me: {
      readonly unreadNotificationsCount: number;
    } | null | undefined;
    readonly userNotification: {
      readonly body: string | null | undefined;
      readonly creator: {
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
      } | null | undefined;
      readonly id: string;
      readonly openedAtWeb: string | null | undefined;
      readonly params: ReadonlyArray<{
        readonly key: string | null | undefined;
        readonly value: string | null | undefined;
      } | null | undefined>;
      readonly sentAt: string | null | undefined;
      readonly urlWeb: string | null | undefined;
      readonly wasRead: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type UserNotificationMarkAsReadMutation = {
  response: UserNotificationMarkAsReadMutation$data;
  variables: UserNotificationMarkAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlWeb",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Parameter",
  "kind": "LinkedField",
  "name": "params",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openedAtWeb",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wasRead",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadNotificationsCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationMarkAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationMarkAsReadPayload",
        "kind": "LinkedField",
        "name": "UserNotificationMarkAsRead",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotification",
            "kind": "LinkedField",
            "name": "userNotification",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserAvatar_user"
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNotificationMarkAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationMarkAsReadPayload",
        "kind": "LinkedField",
        "name": "UserNotificationMarkAsRead",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotification",
            "kind": "LinkedField",
            "name": "userNotification",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "profileImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "height",
                            "value": 50
                          },
                          {
                            "kind": "Literal",
                            "name": "width",
                            "value": 50
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": "url(height:50,width:50)"
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd2af8836c8b1c5982a144742643bf1e",
    "id": null,
    "metadata": {},
    "name": "UserNotificationMarkAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotificationMarkAsReadMutation(\n  $input: UserNotificationMarkAsReadInput!\n) {\n  UserNotificationMarkAsRead(input: $input) {\n    error\n    userNotification {\n      id\n      body\n      creator {\n        ...UserAvatar_user\n        id\n      }\n      urlWeb\n      params {\n        key\n        value\n      }\n      sentAt\n      openedAtWeb\n      wasRead\n    }\n    me {\n      unreadNotificationsCount\n      id\n    }\n  }\n}\n\nfragment UserAvatar_user on User {\n  name\n  profileImage {\n    url(width: 50, height: 50)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaf1dabbebdcd0dcfe8c7198d4a5b991";

export default node;
