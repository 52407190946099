import PropTypes from 'prop-types';

const LogoIcon = ({ width, color }) => {
  const styles = {
    fill: color || '#642265',
  };

  return (
    <svg viewBox='0 0 174.7 167.04' width={width}>
      <g>
        <g>
          <path
            style={styles}
            d='M110.87,0h-47A63.75,63.75,0,0,0,0,63.66v99.9A3.5,3.5,0,0,0,3.5,167H110.87a63.74,63.74,0,0,0,63.83-63.65V63.66A63.74,63.74,0,0,0,110.87,0Zm27.54,80.45v2.64c0,16.31-.16,32.64.08,49a12.77,12.77,0,0,1-13,12.94c-26.47-.07-53,0-79.43,0-8.36,0-13.45-5.06-13.47-13.45q0-24.08,0-48.16V80.62L30.86,82c-2.38,2.05-5.38,2-7.43,0a5.21,5.21,0,0,1,.11-7.42c4.62-4.73,9.32-9.39,14-14.07Q56.26,41.83,75,23.17a27.11,27.11,0,0,1,3.58-3.05c4.53-3.18,9.14-3.21,13.71-.1A26,26,0,0,1,96,23.17q25.55,25.48,51.05,51A5.52,5.52,0,0,1,148.79,80a5.21,5.21,0,0,1-8,2.5C140.08,82,139.49,81.42,138.41,80.45Zm-42.47,54H124.8c2.91,0,3.29-.37,3.29-3.27q0-28.19,0-56.4a11.2,11.2,0,0,0-3.43-8.27q-18.15-18.12-36.25-36.3c-2.5-2.5-3.32-2.5-5.8,0-11.95,12-23.84,24-35.85,35.86a12.65,12.65,0,0,0-3.94,9.47q0,27.91,0,55.82c0,2.54.58,3.1,3.11,3.1H72.89c.62,0,1.26-.07,2.1-.11V131.9c0-9.76,0-19.52.06-29.28,0-4.19,2.93-6.94,7.11-7.07,2-.08,3.92,0,5.89,0,5.16,0,7.88,2.73,7.88,7.88q0,14.25,0,28.49C95.94,132.68,95.94,133.45,95.94,134.47Z'
          />
        </g>
      </g>
    </svg>
  );
};

LogoIcon.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default LogoIcon;
