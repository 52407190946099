// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const NewGroup = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <g fill='none' fillRule='evenodd'>
        <path d='M2.4-7h24v24h-24z' />
        <path
          fill={color}
          fillRule='nonzero'
          d='M6.4 8.598c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2-.9-1.999-2-1.999zm-4-3.999c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2S3.5 4.6 2.4 4.6zm0 7.998c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2-.9-1.999-2-1.999zm12-7.998c1.1 0 2-.9 2-2S15.5.6 14.4.6s-2 .9-2 2 .9 1.999 2 1.999zm-4 7.998c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2-.9-1.999-2-1.999zm4-3.999c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2-.9-1.999-2-1.999zm-4-3.999c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2-.9-1.999-2-1.999zM6.4.6c-1.1 0-2 .9-2 2s.9 1.999 2 1.999 2-.9 2-2S7.5.6 6.4.6z'
        />
      </g>
    </svg>
  );
};

NewGroup.defaultProps = {
  color: '#607389',
  viewBox: '0 0 17 17',
  height: 24,
};

export default NewGroup;
