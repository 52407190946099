import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Flex } from 'rebass';

const Transition = (props) => {
  return <Grow {...props} />;
};

export type Props = {
  isOpen: boolean;
  title: string | React.ReactNode | null;
  bodyStyle?: Record<string, unknown>;
  style?: Record<string, unknown>;
  contentStyle?: Record<string, unknown>;
  children: React.ReactNode;
  isScrollable?: boolean | null;
  handleClose: () => void;
  onClick?: () => void;
  actions?: React.ReactNode[];
  className?: string;
  dialogStyle?: Record<string, unknown>;
  showClose?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
};
const Modal = (props: Props) => {
  const {
    title = null,
    bodyStyle = {},
    contentStyle = {},
    isOpen,
    children,
    isScrollable,
    handleClose,
    className,
    dialogStyle = {
      transitionDuration: 600,
    },
    actions,
    onClick = () => {},
    showClose = false,
    fullWidth = false,
    maxWidth = 'sm',
  } = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleClose}
      onClick={onClick}
      className={className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...dialogStyle}
    >
      <Flex justifyContent='space-between'>
        {title && <DialogTitle>{title}</DialogTitle>}
        {showClose && (
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        )}
      </Flex>
      <DialogContent
        className='Modal__DialogContent'
        style={
          !isScrollable
            ? { ...contentStyle, ...bodyStyle, overflow: 'visible' }
            : bodyStyle
        }
      >
        {children}
      </DialogContent>
      {!!actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
