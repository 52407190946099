import { useEffect } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { GroupInterviewRoomSelectField_query } from './__generated__/GroupInterviewRoomSelectField_query.graphql';

type Props = {
  query: GroupInterviewRoomSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: GroupInterviewRoom;
  groupInterviewId: string;
};

type GroupInterviewRoom = {
  id: string;
  name: string;
};
const GroupInterviewRoomSelectField = (props: Props) => {
  const {
    hintText = 'Buscar Sala',
    name = 'groupInterviewRoom',
    relay,
    query,
    groupInterviewId,
    ...rest
  } = props;

  const { interview } = query;

  const getItemFromNode = (node) => ({
    id: node.id,
    name: node.name,
  });

  useEffect(() => {
    const refetchVariables = (fragmentVariables) => ({
      ...fragmentVariables,
      groupInterviewId,
    });

    relay.refetch(refetchVariables, null, () => {}, {
      force: true,
    });
  }, [groupInterviewId]);

  const getConnection = () => {
    if (!interview || !query.interview.groupInterviewRooms) {
      return { count: 0, edges: [] };
    }

    return query.interview.groupInterviewRooms;
  };

  const getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      search,
      groupInterviewId,
    };
  };

  return (
    <ResourceSelectField
      relay={relay}
      connection={getConnection()}
      getItemFromNode={getItemFromNode}
      getFilterFragmentVariables={getFilterFragmentVariables}
      name={name}
      hintText={hintText}
      emptyMessage={
        interview ? 'Dinâmica não possui salas' : 'Escolha uma dinâmica'
      }
      {...rest}
    />
  );
};

export default createRefetchContainer(
  GroupInterviewRoomSelectField,
  {
    query: graphql`
      fragment GroupInterviewRoomSelectField_query on Query
      @argumentDefinitions(
        groupInterviewId: { type: ID }
        first: { type: Int, defaultValue: 300 }
        search: { type: String, defaultValue: "" }
        after: { type: String }
      ) {
        interview: node(id: $groupInterviewId) {
          id
          ... on GroupInterview {
            hasActiveGroupInterviewRooms
            groupInterviewRooms(first: $first, search: $search, after: $after)
              @connection(
                key: "GroupInterviewRoomSelectField_groupInterviewRooms"
                filters: []
              ) {
              count

              pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query GroupInterviewRoomSelectFieldQuery(
      $groupInterviewId: ID
      $first: Int
      $search: String
      $after: String
    ) {
      ...GroupInterviewRoomSelectField_query
        @arguments(
          groupInterviewId: $groupInterviewId
          first: $first
          search: $search
          after: $after
        )
    }
  `,
);
