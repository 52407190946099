import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarCircle = styled(Avatar)`
  width: ${(props) => props.size}px !important;
  height: ${(props) => props.size}px !important;
  min-width: ${(props) => props.size}px !important;
  min-height: ${(props) => props.size}px !important;
  font-size: ${(props) => props.size / 2}px !important;
  background-color: ${(props) => props.theme.button.backgroundColor} !important;
  color: ${(props) => props.theme.button.textColor} !important;
`;

type Props = {
  name: string;
  size: number;
  imageUrl: string | null;
};

export default ({ name, size = 50, imageUrl, ...props }: Props) => {
  const initials = name
    ? name
        .split(' ')
        .slice(0, 2)
        .map((namePart) => namePart.charAt(0))
        .join('')
    : 'AN';

  return (
    <Container {...props}>
      {imageUrl ? (
        <AvatarCircle size={size} src={imageUrl} />
      ) : (
        <AvatarCircle size={size}> {initials} </AvatarCircle>
      )}
    </Container>
  );
};
