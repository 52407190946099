// @flow

type Props = {
  size: number;
  color?: string;
  viewBox?: string;
};

const CheckboxIndeterminate = ({ size, color, viewBox }: Props) => {
  return (
    <svg viewBox={viewBox} width={size} height={size}>
      <path d='M0 0h24v24H0z' fill='none' />
      <path
        fill={color}
        d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z'
      />
    </svg>
  );
};

CheckboxIndeterminate.defaultProps = {
  color: '#767676',
  viewBox: '0 0 24 24',
  size: 24,
};

export default CheckboxIndeterminate;
