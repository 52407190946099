/**
 * @generated SignedSource<<a32069747af04c5e6efaa3edfb1dac2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInCompanyMiddleware_query$data = {
  readonly company: {
    readonly _id: string;
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly id: string;
    readonly logo: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly name: string | null | undefined;
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly node: {
    readonly __typename: "Group";
    readonly id: string;
    readonly visibleExternally: boolean;
  } | {
    readonly __typename: "User";
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentType": "UserInCompanyMiddleware_query";
};
export type UserInCompanyMiddleware_query$key = {
  readonly " $data"?: UserInCompanyMiddleware_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInCompanyMiddleware_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "nick"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInCompanyMiddleware_query",
  "selections": [
    {
      "alias": "node",
      "args": [
        {
          "kind": "Variable",
          "name": "nick",
          "variableName": "nick"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodeNick",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "User",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "visibleExternally",
              "storageKey": null
            }
          ],
          "type": "Group",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "company",
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companyDomainName",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameFriendly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "fitIn",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "url",
              "storageKey": "url(fitIn:true)"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "features",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7039a1090580d4487bfaf243bf40684f";

export default node;
