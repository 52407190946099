import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring';

const adjustRoutes: RouteType[] = [
  {
    name: 'hiring.adjusts',
    path: `${PATH}/adjusts`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Ajustes de Contratação',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.ADJUST.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'hiring.requiredDocs',
        path: `${PATH}/adjusts/requiredDocs`,
        label: 'Documentos Padrão',
        labelHeader: 'Contratação',
        component: loadable(
          () => import('../../../components/hiring/requiredDocs/RequiredDocs'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.ADJUST.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'hiring.positionApplicationStatus',
        path: `${PATH}/adjusts/positionApplicationStatus`,
        label: 'Etapas de Seleção',
        labelHeader: 'Contratação',
        component: loadable(
          () =>
            import(
              '../../../components/hiring/jobRequestKanban/PositionApplicationStatusList'
            ),
        ),
        exact: false,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.ADJUST.ALL],
        requiredFeatures: [MODULES.HIRING],
        modalRoutes: [
          {
            name: 'hiring.positionApplicationStatus.add',
            path: `${PATH}/adjusts/positionApplicationStatus/add`,
            labelHeader: 'Etapas de Seleção - Adicionar Status',
            modalTitle: 'Adicionar Status',
            component: loadable(
              () =>
                import(
                  '../../../components/hiring/jobRequestKanban/PositionApplicationStatusAdd'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.HIRING.ALL,
              ROLES.HIRING.ADJUST.ALL,
            ],
            requiredFeatures: [MODULES.HIRING],
          },
          {
            name: 'hiring.positionApplicationStatus.edit',
            path: `${PATH}/adjusts/positionApplicationStatus/edit/:id`,
            labelHeader: 'Etapas de Seleção - Editar Status',
            modalTitle: 'Editar Status',
            component: loadable(
              () =>
                import(
                  '../../../components/hiring/jobRequestKanban/PositionApplicationStatusEdit'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.HIRING.ALL,
              ROLES.HIRING.ADJUST.ALL,
            ],
            requiredFeatures: [MODULES.HIRING],
          },
        ],
      },
      {
        name: 'hiring.adjusts.email',
        path: `${PATH}/adjusts/email`,
        label: 'Ajustes E-mail',
        labelHeader: 'Contratação',
        component: loadable(
          () => import('../../../components/hiring/EmailAdjusts'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.ADJUST.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'hiring.adjusts.jobRequest',
        path: `${PATH}/adjusts/jobRequest`,
        label: 'Requisição',
        labelHeader: 'Contratação',
        component: loadable(
          () => import('../../../components/hiring/JobRequestAdjusts'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.ADJUST.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'hiring.adjusts.requiredLoginFields',
        path: `${PATH}/adjusts/requiredLoginFields`,
        label: 'Ajustes Login',
        labelHeader: 'Contratação',
        component: loadable(
          () =>
            import(
              '../../../components/hiring/requiredLoginFields/RequiredLoginFields'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.ADJUST.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
];

export default adjustRoutes;
