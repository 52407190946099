import { ResourceSelectField } from '@feedback/select';
import Tooltip from '@material-ui/core/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import {
  filterFeedbackTopicsOnlyForManagers,
  TOPIC_MODULES,
} from '../../feedback/FeedbackUtils';

import type { FeedbackTopicSelectField_query } from './__generated__/FeedbackTopicSelectField_query.graphql';
import type { FeedbackTopicSelectField_user } from './__generated__/FeedbackTopicSelectField_user.graphql';

type Props = {
  query: FeedbackTopicSelectField_query;
  user: FeedbackTopicSelectField_user;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  fullWidth?: boolean;
  value?: FeedbackTopick;
};

type FeedbackTopick = {
  id: string;
  name: string;
};
const FeedbackTopicSelectField = (props: Props) => {
  const [description, setDescription] = useState<string>('');

  const { t } = useTranslation();

  const getItemFromNode = (node) => ({
    id: node.id,
    name: node.title,
    description: node.description || '',
  });

  const { filters = {} } = props;

  const getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      filters: {
        ...filters,
        search,
      },
    };
  };

  const getRenderVariables = (value) => {
    const { search } = value;

    return {
      filters: {
        ...filters,
        search,
      },
    };
  };

  const {
    query,
    relay,
    environment,
    hintText = t('Search Feedback Topic'),
    name = 'feedbackTopic',
    user,
    resetInputOnSelection = false,
    ...rest
  } = props;

  const filterModule = filters.modules
    ? filters.modules[0]
    : TOPIC_MODULES.INTERNAL;

  const feedbackTopics = filterFeedbackTopicsOnlyForManagers(
    query.feedbackTopics,
    user,
    filterModule,
  );

  return (
    <Tooltip title={description} placement={'right'}>
        <div>
          <ResourceSelectField
            relay={relay}
            connection={feedbackTopics}
            getItemFromNode={getItemFromNode}
            onSelectValue={({ description }) => setDescription(description)}
            hintText={hintText}
            name={name}
            getFilterFragmentVariables={getFilterFragmentVariables}
            getRenderVariables={getRenderVariables}
            resetInputOnSelection={resetInputOnSelection}
            {...rest}
          />
        </div>
      </Tooltip>
  );
};

export default createRefetchContainer(
  FeedbackTopicSelectField,
  {
    query: graphql`
      fragment FeedbackTopicSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 50 }
        after: { type: String }
        isDisabled: { type: Boolean, defaultValue: false }
        filters: { type: FeedbackTopicFilter }
      ) {
        feedbackTopics(
          first: $count
          after: $after
          isDisabled: $isDisabled
          filters: $filters
        )
          @connection(
            key: "FeedbackTopicSelectField_feedbackTopics"
            filters: ["filters"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              title
              description
              onlyForManagers
              modules
            }
          }
        }
      }
    `,
    user: graphql`
      fragment FeedbackTopicSelectField_user on User {
        isManager
      }
    `,
  },
  graphql`
    query FeedbackTopicSelectFieldQuery(
      $count: Int
      $after: String
      $isDisabled: Boolean
      $filters: FeedbackTopicFilter
    ) {
      ...FeedbackTopicSelectField_query
        @arguments(
          count: $count
          after: $after
          isDisabled: $isDisabled
          filters: $filters
        )
    }
  `,
);
