import { getMutationCallbacks } from './getMutationCallbacks';

export const getBatchInput = (
  ids: string[] | null,
  filters: Record<string, unknown> | null | undefined,
) => {
  const isSelectAll = (ids || []).length === 0;

  return {
    userIds: !isSelectAll ? ids : undefined,
    filters: isSelectAll ? filters : undefined,
  };
};

// TODO - use a hook instead
export type BatchActionParams = {
  resource: {
    [key: string]: string;
  };
  userIds: string[];
  filters?: Record<string, unknown>;
  action: 'add' | 'remove';
  showSnackbar: any;
  enqueueSnackbar: (message: string) => void;
  mutationName: string;
  mutation: {
    commit: (input: {}, onSuccess: () => void, onError: () => void) => void;
  };
};
export const batchActionMutation = (
  {
    resource,
    userIds,
    action,
    filters,
    showSnackbar,
    enqueueSnackbar,
    mutationName,
    mutation,
  }: BatchActionParams,
  filterUsers: Record<string, unknown>,
  callbacks?: {},
) => {
  const input = {
    ...resource,
    action,
    ...getBatchInput(userIds, filters),
  };

  const getShowSnackbar = ({ message }) => {
    if (enqueueSnackbar) {
      enqueueSnackbar(message);

      return
    }

    showSnackbar({ message });
  }


  const { onCompleted, onError } = getMutationCallbacks({
    name: mutationName,
    showSnackbar: getShowSnackbar,
    success:
      action === 'add'
        ? 'Usuário(s) adicionado com sucesso'
        : 'Usuário(s) removidos com sucesso',
    error:
      action === 'add'
        ? 'Erro ao adicionar usuário(s)'
        : 'Erro ao remover usuário(s)',
    ...callbacks,
  });

  mutation.commit(input, filterUsers, onCompleted, onError);
};
