import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

type Theme = {
  name: string;
  primaryDarkerColor: string;
  primaryLighterColor: string;
  button: {
    backgroundColor: string | null;
    textColor: string | null;
  };
  darkGray: string;
  primaryFont: string;
  highlightColor: string;
  mediumGray: string;
  lightGray: string;
  graphLighterColor: string;
};

export const useTheme = () => {
  const theme = useContext(ThemeContext) as Theme;

  return theme;
};
