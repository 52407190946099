/**
 * @generated SignedSource<<68bfceba39f2a07ac4bab1fc4d4f559c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type CompanyProjectType = "FEEDBACK_HOUSE" | "LOGID" | "OPEN_PIX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePermissionFragment$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly company: {
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly projectType: CompanyProjectType | null | undefined;
  } | null | undefined;
  readonly isAdmin: boolean | null | undefined;
  readonly " $fragmentType": "usePermissionFragment";
};
export type usePermissionFragment$key = {
  readonly " $data"?: usePermissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "usePermissionFragment"
};

(node as any).hash = "b6619d0b2d05d59564470db698be5499";

export default node;
