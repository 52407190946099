import PropTypes from 'prop-types';

const Filter = ({ height, color }) => {
  const styles = {
    fill: color || '#757575',
  };

  return (
    <svg viewBox='0 0 40 40' height={height}>
      <g>
        <path
          style={styles}
          d='M26.8,11.1h1.3c0.4,0,0.9-0.4,0.9-0.9c0-0.4-0.4-0.9-0.9-0.9h-1.3c-0.4,0-0.9,0.4-0.9,0.9    C26,10.7,26.3,11.1,26.8,11.1L26.8,11.1z'
        />
        <path
          style={styles}
          d='M39.4,3.4c-0.3-0.6-0.9-0.9-1.5-0.9H2C1.4,2.6,0.9,3,0.6,3.4C0.3,4,0.3,4.8,0.7,5.2l15,19v12.4    c0,0.6,0.3,1.2,0.9,1.5c0.3,0.1,0.4,0.1,0.9,0.1c0.3,0,0.7-0.1,1-0.3l4.7-3.2c0.7-0.4,1-1.3,1-2.1v-8.4l15-19    C39.7,4.8,39.7,4,39.4,3.4L39.4,3.4z M22.8,23.3c-0.1,0.1-0.1,0.3-0.1,0.4v8.7c0,0.3-0.1,0.6-0.3,0.7l-4.9,3.4V23.8    c0-0.3-0.1-0.6-0.3-0.7L7.6,11h15.5c0.4,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H6.2L2,4.2h35.8L22.8,23.3z'
        />
      </g>
    </svg>
  );
};

Filter.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
};

export default Filter;
