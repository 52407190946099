import loadable from '@loadable/component';

import {
  ADMIN_ROLE,
  COMPANY_PROJECT_TYPES_ENUM,
  MODULES,
  ROLES,
} from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring';

const jobPostingRoutes: RouteType[] = [
  {
    name: 'jobPosting',
    path: `${PATH}/jobPostings`,
    labelHeader: ({ t }) => t('Vacancy Announcements'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.JOB_POSTING.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'jobPosting',
        path: `${PATH}/jobPostings`,
        labelHeader: ({ t }) => t('Vacancy Announcements'),
        component: loadable(
          () => import('../../../components/jobPosting/JobPostingList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_POSTING.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'jobPosting.view',
    path: `${PATH}/jobPosting/:id`,
    labelHeader: ({ t }) => t('Vacancy Announcements'),
    component: loadable(
      () => import('../../../components/jobPosting/JobPosting'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.JOB_POSTING.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'jobPosting.dashboard',
        path: `${PATH}/jobPosting/:id/dashboard`,
        labelHeader: ({ t }) => t('Vacancy Announcements - Dashboard'),
        label: 'Dashboard',
        component: loadable(
          () => import('../../../components/jobPosting/JobPostingDashboard'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'jobPosting.applications',
        path: `${PATH}/jobPosting/:id/applications`,
        labelHeader: ({ t }) =>
          t('Vacancy Announcements - External Candidates'),
        label: ({ t }) => t('External Candidates'),
        component: loadable(
          () => import('../../../components/jobPosting/JobPostingApplications'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_POSTING.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'jobPosting.userApplications',
        path: `${PATH}/jobPosting/:id/usersApplications`,
        labelHeader: ({ t }) =>
          t('Vacancy Announcements - Internal Candidates'),
        label: ({ t }) => t('Internal Candidates'),
        component: loadable(
          () =>
            import('../../../components/jobPosting/JobPostingUserApplications'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.TEMP],
      },
      {
        name: 'jobPosting.detail',
        path: `${PATH}/jobPosting/:id/edit`,
        labelHeader: ({ t }) => t('Vacancy Announcements - Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () => import('../../../components/jobPosting/JobPostingEdit'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_POSTING.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'jobPosting.add',
    path: `${PATH}/jobPostings/add`,
    labelHeader: ({ t }) => t('Vacancy Announcements - Add'),
    component: loadable(
      () => import('../../../components/jobPosting/form/JobPostingAdd'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.JOB_POSTING.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'jobPosting.internal',
    path: `${PATH}/vacancies`,
    labelHeader: ({ t }) => t('Internal Jobs Posts'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [MODULES.TEMP],
    requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
    routes: [
      {
        name: 'jobPosting.internal',
        path: `${PATH}/vacancies`,
        labelHeader: ({ t }) => t('Internal Jobs Posts'),
        component: loadable(
          () => import('../../../components/jobPosting/JobPostingInternalList'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.TEMP],
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
    ],
  },
  {
    name: 'myVacancies',
    path: `${PATH}/myVacancies`,
    labelHeader: ({ t }) => t('My Internal Job Processes'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [MODULES.TEMP],
    requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
    routes: [
      {
        name: 'myVacancies',
        path: `${PATH}/myVacancies`,
        labelHeader: ({ t }) => t('My Internal Job Processes'),
        component: loadable(
          () => import('../../../components/jobPosting/MyVacancies'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.TEMP],
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
    ],
  },
];

export default jobPostingRoutes;
