// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const Star = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill={color}
          fillRule='evenodd'
          d='M16 24l-9.405 4.944 1.797-10.472-7.609-7.416 10.515-1.528L16 0l4.702 9.528 10.515 1.528-7.609 7.416 1.797 10.472z'
        />
      </g>
    </svg>
  );
};

Star.defaultProps = {
  color: '#309dd1',
  viewBox: '0 0 32 29',
  height: 32,
};

export default Star;
