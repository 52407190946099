import AutoComplete from '../autoComplete/AutoComplete';
// import styled from 'styled-components';

type OptionsType = {
  id: string;
  name?: string;
  primaryText?: string;
};

export type Props = {
  options: OptionsType[];
  onInputChange: (value: Record<string, unknown>) => void;
  inputValue: string;
  value: Record<string, unknown>[] | object;
  hintText?: string;
  onChange: (value: Record<string, unknown> | object[]) => void;
  fullWidth?: boolean;
  initialValue: string;
  getStringFromItem: (item: Record<string, unknown>) => string;
  multiple?: boolean;
};
const SelectField = (props: Props) => {
  const defaultGetStringFromItem = (item) => {
    if (item) {
      if (typeof item.name === 'string') {
        return item.name;
      }

      if (typeof item.label === 'string') {
        return item.label;
      }

      if (typeof item.primaryText === 'string') {
        return item.primaryText;
      }
    }

    return '';
  };

  const {
    options,
    onInputChange,
    value,
    hintText,
    onChange,
    fullWidth = true,
    inputValue,
    initialValue,
    getStringFromItem = defaultGetStringFromItem,
    multiple,
    ...rest
  } = props;

  const handleInputChange = (name, value) => {
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleOnChange = (name, value) => {
    if (multiple) {
      onChange([value]);
    } else {
      onChange(value);
    }
  };

  return (
    <AutoComplete
      multiple={false}
      items={
        options
          ? options.map((opt) => ({
              label: opt.name || opt.primaryText,
              value: opt.id,
              ...opt,
            }))
          : []
      }
      label={hintText}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      inputValue={inputValue}
      getStringFromItem={getStringFromItem}
      fullWidth={fullWidth}
      selectedItems={value}
      initialValue={initialValue}
      {...rest}
    />
  );
};

export default SelectField;
