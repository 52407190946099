import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring';

const jobExamRoutes: RouteType[] = [
  {
    name: 'jobExam',
    path: `${PATH}/jobExam`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Gestão de provas',
    requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'jobExam.list',
        path: `${PATH}/jobExam/jobExamList`,
        component: loadable(
          () => import('../../../components/jobExam/JobExamList'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Contratação',
        label: 'Provas',
      },
      {
        name: 'jobExam.jobExamQuestion',
        path: `${PATH}/jobExam/jobExamQuestionList`,
        component: loadable(
          () =>
            import('../../../components/jobExam/question/JobExamQuestionList'),
        ),
        exact: true,
        requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Contratação',
        label: 'Banco de questões',
      },
      {
        name: 'jobExam.adjusts',
        path: `${PATH}/jobExam/jobExamAdjusts`,
        component: loadable(
          () => import('../../../components/jobExam/JobExamAdjusts'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Contratação',
        label: 'Ajustes',
      },
    ],
  },
  {
    name: 'hiring.jobExam',
    path: `${PATH}/jobExamDetail/:id`,
    labelHeader: 'Prova - Detalhes',
    component: loadable(
      () => import('../../../components/jobExam/detail/JobExam'),
    ),
    exact: false,
    requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'hiring.jobExam.detail',
        path: `${PATH}/jobExamDetail/:id/detail`,
        requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        exact: false,
        label: 'Prova',
        labelHeader: 'Prova - Detalhes',
        component: loadable(
          () => import('../../../components/jobExam/JobExamDetail'),
        ),
      },
      {
        name: 'hiring.jobExam.candidates',
        path: `${PATH}/jobExamDetail/:id/jobExamCandidates`,
        component: loadable(
          () => import('../../../components/jobExam/JobExamCandidateList'),
        ),
        exact: true,
        requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Prova - Detalhes',
        label: 'Candidatos',
      },
      {
        name: 'hiring.jobExam.edit',
        path: `${PATH}/jobExamDetail/:id/edit`,
        labelHeader: 'Prova - Detalhes',
        requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        label: 'Ajustes',
        component: loadable(
          () => import('../../../components/jobExam/JobExamEdit'),
        ),
      },
    ],
  },
  {
    name: 'hiring.jobExam.add',
    path: `${PATH}/jobExams/add`,
    labelHeader: 'Prova - Adicionar',
    component: loadable(() => import('../../../components/jobExam/JobExamAdd')),
    exact: false,
    requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'hiring.jobExamQuestionList',
    path: `${PATH}/jobExamQuestionList`,
    labelHeader: 'Questões',
    component: loadable(
      () => import('../../../components/jobExam/question/JobExamQuestionList'),
    ),
    exact: false,
    requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'jobExam.question.add',
    path: `${PATH}/jobExamQuestionAdd`,
    labelHeader: 'Adicionar nova Questão',
    component: loadable(
      () => import('../../../components/jobExam/components/JobExamQuestionAdd'),
    ),
    exact: false,
    requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'hiring.jobExamQuestion',
    path: `${PATH}/jobExamQuestion/:id`,
    labelHeader: 'Questão - Detalhes',
    component: loadable(
      () => import('../../../components/jobExam/question/JobExamQuestion'),
    ),
    exact: false,
    requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'hiring.jobExamQuestion.detail',
        path: `${PATH}/jobExamQuestion/:id/detail`,
        requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        exact: false,
        label: 'Questão',
        labelHeader: 'Questão - Detalhes',
        component: loadable(
          () =>
            import(
              '../../../components/jobExam/question/JobExamQuestionDetail'
            ),
        ),
        modalRoutes: [
          {
            name: 'hiring.jobExamQuestions.add',
            labelHeader: 'Questão - Detalhes',
            path: `${PATH}/jobExamQuestion/:id/detail/add`,
            component: loadable(
              () =>
                import(
                  '../../../components/jobExam/question/JobExamAddQuestions'
                ),
            ),
            exact: true,
            requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
            requiredFeatures: [MODULES.HIRING],
          },
        ],
      },
      {
        name: 'hiring.jobExamQuestion.edit',
        path: `${PATH}/jobExamQuestion/:id/edit`,
        requiredRoles: [ROLES.HIRING.JOB_EXAM.ALL],
        requiredFeatures: [MODULES.HIRING],
        exact: false,
        label: 'Ajustes',
        labelHeader: 'Questão - Detalhes',
        component: loadable(
          () =>
            import('../../../components/jobExam/question/JobExamQuestionEdit'),
        ),
      },
    ],
  },
];

export default jobExamRoutes;
