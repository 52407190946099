/**
 * @generated SignedSource<<408766cec7dedd528cc475b327cc215a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobExamAnswerSelectFieldRefetchQuery$variables = {
  jobExamQuestionId?: string | null | undefined;
};
export type JobExamAnswerSelectFieldRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobExamAnswerSelectField_query">;
};
export type JobExamAnswerSelectFieldRefetchQuery = {
  response: JobExamAnswerSelectFieldRefetchQuery$data;
  variables: JobExamAnswerSelectFieldRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobExamQuestionId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobExamAnswerSelectFieldRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "jobExamQuestionId",
            "variableName": "jobExamQuestionId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "JobExamAnswerSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobExamAnswerSelectFieldRefetchQuery",
    "selections": [
      {
        "alias": "jobExamQuestion",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "jobExamQuestionId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobExamQuestionOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "JobExamQuestion",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42e78a813f95a050e16433718d507b22",
    "id": null,
    "metadata": {},
    "name": "JobExamAnswerSelectFieldRefetchQuery",
    "operationKind": "query",
    "text": "query JobExamAnswerSelectFieldRefetchQuery(\n  $jobExamQuestionId: ID\n) {\n  ...JobExamAnswerSelectField_query_90OVi\n}\n\nfragment JobExamAnswerSelectField_query_90OVi on Query {\n  jobExamQuestion: node(id: $jobExamQuestionId) {\n    __typename\n    id\n    ... on JobExamQuestion {\n      id\n      options {\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe2b93020d4d29a312ca478255b3d429";

export default node;
