type Props = {
  color: string;
  size: number;
};

const GroupGiveFeedbackIcon = ({ color, size }: Props) => (
  <svg viewBox='0 0 40 25' width={size} height={size}>
    <path
      d='M26.4 7.8V2.5c0-.3-.2-.5-.4-.7-.2-.1-.5-.1-.8 0C20 5.5 16.9 6 14.9 6H9c-2.3 0-4.2 1.9-4.2 4.2v1.6C4.8 14.1 6.7 16 9 16h2.8l3.1 7.4c.1.3.4.5.7.5.1 0 .2 0 .3-.1.4-.2.6-.6.4-1L13.4 16h1.5c7.2 0 10.1 3.8 10.2 4 .1.2.4.3.6.3h.2c.3-.1.5-.4.5-.7v-5.3c1.5-.3 2.6-1.6 2.6-3.2 0-1.7-1.1-3-2.6-3.3zm-20.1 4v-1.6c0-1.5 1.2-2.7 2.7-2.7h2.6v7H9c-1.5 0-2.7-1.2-2.7-2.7zm18.6 6c-1.6-1.3-4.9-3.2-10.1-3.2H13v-7h1.8C17 7.5 20 7 24.9 3.9v13.9zm1.5-5.1V9.4c.6.3 1.1.9 1.1 1.7 0 .6-.4 1.3-1.1 1.6zm7.1-2.4h-2.9c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h2.9c.4 0 .7-.3.7-.7.1-.4-.2-.7-.7-.7zm-3.9-1.6c.2.3.4.5.7.5h.3L33.4 8c.4-.2.6-.6.4-1-.2-.4-.6-.6-1-.4L30 7.7c-.3.2-.5.6-.4 1zm3.8 5.3l-2.8-1.1c-.4-.2-.8 0-1 .4-.2.4 0 .8.4 1l2.8 1.1h.3c.3 0 .6-.2.7-.5.2-.3 0-.8-.4-.9z'
      fill={color}
    />
  </svg>
);

GroupGiveFeedbackIcon.defaultProps = {
  color: 'white',
  size: 30,
};

export default GroupGiveFeedbackIcon;
