/**
 * @generated SignedSource<<78524f42f917a1efc8aafe59c11ccf75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThemeCompanyFragment$data = {
  readonly preferences: {
    readonly theme: {
      readonly button: {
        readonly backgroundColor: string | null | undefined;
        readonly textColor: string | null | undefined;
      };
      readonly darkGray: string;
      readonly graphLighterColor: string;
      readonly highlightColor: string;
      readonly id: string;
      readonly lightGray: string;
      readonly mediumGray: string;
      readonly name: string | null | undefined;
      readonly primaryDarkerColor: string;
      readonly primaryLighterColor: string;
      readonly richHeaderColor1: string;
      readonly richHeaderColor2: string;
      readonly richHeaderTextColor: string;
    };
  };
  readonly " $fragmentType": "ThemeCompanyFragment";
};
export type ThemeCompanyFragment$key = {
  readonly " $data"?: ThemeCompanyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeCompanyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThemeCompanyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Theme",
          "kind": "LinkedField",
          "name": "theme",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryDarkerColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryLighterColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Style",
              "kind": "LinkedField",
              "name": "button",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "backgroundColor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "textColor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richHeaderColor1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richHeaderColor2",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richHeaderTextColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "darkGray",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "highlightColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediumGray",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lightGray",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "graphLighterColor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "ab7a3306b28a7a2c5ffe067688b18622";

export default node;
