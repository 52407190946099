import List from '@material-ui/core/List';
import { forwardRef, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import { hasMatched } from '@feedback/router';

import type { ItemType } from './Sidebar';
import Item from './Item';

import { getItemsPath } from './utils';
import useSidebar from './useSidebar';

const getIsActive = ({ link, location, items }) => {
  if (link) {
    return link === location.pathname;
  }

  return getItemsPath(items || [])
    .map((path) => hasMatched(location, { path, exact: false }))
    .reduce((acc, cur) => acc || cur, false);
};

const renderItem = (item, index, isMobile: boolean) => (
  <Item link={item.link} key={index} label={item.label} isMobile={isMobile} />
);

const Group = (props: ItemType) => {
  const { link, label, icon, items, isMobile } = props;

  const location = useLocation();
  const { sidebarClose } = useSidebar();

  const [isGroupOpen, setGroupIsOpen] = useState<boolean>(false);

  const active = getIsActive({
    link,
    location,
    items,
  });

  const toggleGroup = () => setGroupIsOpen(!isGroupOpen);

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <NavLink to={link} ref={ref} {...itemProps} />
      )),
    [link],
  );

  if (link) {
    // use NavLink
    const onLinkClick = () => {
      if (isMobile) {
        sidebarClose();
      }
    };

    return (
      <ListItem
        button
        component={renderLink}
        onClick={onLinkClick}
        selected={active}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={label} />
      </ListItem>
    );
  }

  return (
    <>
      <ListItem button onClick={toggleGroup} selected={active}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={label} disableGutters={true} />
      </ListItem>
      {items && !!items.length && (
        <Collapse in={isGroupOpen}>
          <List component='div' disablePadding>
            {items.map((item, index) => renderItem(item, index, isMobile))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default Group;
