import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


export const PATH = '/home/research';

const pollRoutes: RouteType[] = [
  {
    name: 'poll.admin',
    path: `${PATH}/polls/admin`,
    component: loadable(() => import('../../../components/poll/PollDetails')),
    exact: false,
    labelHeader: 'Enquetes Admin',
    requiredRoles: [ADMIN_ROLE, ROLES.RESEARCH.ALL, ROLES.RESEARCH.ADMIN.ALL],
    requiredFeatures: [MODULES.RESEARCH],
    routes: [
      {
        name: 'poll.admin.list',
        path: `${PATH}/polls/admin/list`,
        labelHeader: 'Enquetes Admin',
        component: loadable(
          () => import('../../../components/poll/list/PollList'),
        ),
        exact: false,
        label: 'Lista',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
    ],
  },
  {
    name: 'poll.admin.add',
    labelHeader: 'Enquetes',
    path: `${PATH}/polls/add`,
    component: loadable(() => import('../../../components/poll/add/PollAdd')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.RESEARCH.ALL, ROLES.RESEARCH.ADMIN.ALL],
    requiredFeatures: [MODULES.RESEARCH],
  },
  {
    name: 'poll.admin.view',
    path: `${PATH}/poll/admin/:id`,
    labelHeader: 'Enquete',
    component: loadable(() => import('../../../components/poll/Poll')),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.RESEARCH.ALL, ROLES.RESEARCH.ADMIN.ALL],
    requiredFeatures: [MODULES.RESEARCH],
    routes: [
      {
        name: 'poll.admin.dashboard',
        labelHeader: 'Enquete - Dashboard',
        label: 'Dashboard',
        path: `${PATH}/poll/admin/:id/dashboard`,
        component: loadable(
          () =>
            import(
              '../../../components/poll/dashboard/report/PollDashboardReport'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
      {
        name: 'poll.admin.dashboard.details',
        labelHeader: 'Enquete - Dashboard',
        label: ({ t }) => t('Report'),
        path: `${PATH}/poll/admin/:id/report`,
        component: loadable(
          () =>
            import(
              '../../../components/poll/dashboard/details/PollDashboardDetails'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH, MODULES.TEMP],
      },
      // routes: [
      // {
      //   name: 'poll.admin.dashboard.details',
      //   path: `${PATH}/poll/admin/:id/dashboard/details`,
      //   label: 'Detalhes',
      //   labelHeader: 'Dashboard Detalhes',
      //   component: loadable(() =>
      //     import('../../../components/poll/dashboard/details/PollDashboardDetails'),
      //   ),
      //   exact: true,
      //   requiredRoles: [ADMIN_ROLE, ROLES.RESEARCH.ALL, ROLES.RESEARCH.ADMIN.ALL],
      //   requiredFeatures: [MODULES.RESEARCH],
      // },
      //     {
      //       name: 'poll.admin.dashboard.report',
      //       path: `${PATH}/poll/admin/:id/dashboard/report`,
      //       label: 'Relatórios',
      //       labelHeader: 'Dashboard Relatórios',
      //       component: loadable(() =>
      //         import('../../../components/poll/dashboard/report/PollDashboardReport'),
      //       ),
      //       exact: true,
      //       requiredRoles: [ADMIN_ROLE, ROLES.RESEARCH.ALL, ROLES.RESEARCH.ADMIN.ALL],
      //       requiredFeatures: [MODULES.RESEARCH],
      //     },
      //   ],
      // },
      {
        name: 'poll.admin.detail',
        labelHeader: 'Enquete - Questões',
        label: 'Questões',
        path: `${PATH}/poll/admin/:id/detail`,
        component: loadable(
          () => import('../../../components/poll/detail/PollDetail'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
      {
        name: 'poll.admin.users',
        labelHeader: 'Enquete - Usuários',
        label: 'Usuários',
        path: `${PATH}/poll/admin/:id/invitedUsers`,
        component: loadable(
          () => import('../../../components/poll/users/PollUserList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
      {
        name: 'poll.admin.answers',
        labelHeader: 'Enquete - Respostas',
        label: 'Respostas',
        path: `${PATH}/poll/admin/:id/answers`,
        component: loadable(
          () => import('../../../components/poll/answer/PollAnswerList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
      {
        name: 'poll.admin.engagement',
        labelHeader: 'Enquete - Engajamento',
        label: 'Engajamento',
        path: `${PATH}/poll/admin/:id/engagement`,
        component: loadable(
          () => import('../../../components/poll/engagement/PollEngagement'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
      {
        name: 'poll.admin.edit',
        labelHeader: 'Enquete - Ajustes',
        label: 'Ajustes',
        path: `${PATH}/poll/admin/:id/edit`,
        component: loadable(
          () => import('../../../components/poll/edit/PollEdit'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
      },
    ],
  },
  {
    name: 'poll.answer',
    path: `${PATH}/pollAnswer/:id`,
    component: loadable(
      () => import('../../../components/poll/answer/PollAnswerStep'),
    ),
    exact: true,
    labelHeader: 'Enquete',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.RESEARCH.ALL,
      ROLES.RESEARCH.USER.ALL,
      ROLES.RESEARCH.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.RESEARCH],
  },
  {
    name: 'poll.answered',
    path: `${PATH}/poll/:id/answer/:user`,
    component: loadable(() => import('../../../components/poll/PollAnswered')),
    exact: true,
    labelHeader: 'Enquete',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.RESEARCH.ALL,
      ROLES.RESEARCH.USER.ALL,
      ROLES.RESEARCH.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.RESEARCH],
  },
  {
    name: 'poll.user',
    path: `${PATH}/polls/user`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Poll'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.RESEARCH.ALL,
      ROLES.RESEARCH.USER.ALL,
      ROLES.RESEARCH.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.RESEARCH],
    routes: [
      {
        name: 'poll.user',
        path: `${PATH}/polls/user`,
        component: loadable(
          () => import('../../../components/common/content/TabbedContent'),
        ),
        exact: false,
        labelHeader: ({ t }) => t('Poll'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.RESEARCH.ALL,
          ROLES.RESEARCH.USER.ALL,
          ROLES.RESEARCH.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.RESEARCH],
        routes: [
          {
            name: 'poll.user.list',
            path: `${PATH}/polls/user/list`,
            labelHeader: ({ t }) => t('Polls'),
            component: loadable(
              () => import('../../../components/poll/invited/PollInvitedList'),
            ),
            exact: false,
            label: 'Convites para enquete',
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.RESEARCH.ALL,
              ROLES.RESEARCH.USER.ALL,
              ROLES.RESEARCH.ADMIN.ALL,
            ],
            requiredFeatures: [MODULES.RESEARCH],
            modalRoutes: [
              {
                name: 'poll.user.list.dialog',
                path: `${PATH}/polls/user/list/dialog/:id`,
                labelHeader: ({ t }) => t('Polls'),
                component: loadable(
                  () => import('../../../components/poll/answer/PollDialog'),
                ),
                exact: true,
                requiredRoles: [
                  ADMIN_ROLE,
                  ROLES.RESEARCH.ALL,
                  ROLES.RESEARCH.USER.ALL,
                  ROLES.RESEARCH.ADMIN.ALL,
                ],
                requiredFeatures: [MODULES.RESEARCH],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default pollRoutes;
