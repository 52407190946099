import invariant from 'invariant';
import type { showSnackbarFn } from '@feedback/ui';

export const getError = (data: any) => {
  if (data.error) {
    return data.error;
  }

  if (data.errors) {
    return data.errors.join('\n');
  }

  return null;
};

export type MutationCallbackArgs<R> = {
  name: string;
  showSnackbar?: showSnackbarFn;
  success?: string | undefined;
  error?: string;
  afterCompleted?: (response: R) => void;
  afterError?: () => void;
};
// TODO - add default error message
export const getMutationCallbacks = <R extends { [key: string]: any }>({
  name,
  showSnackbar,
  success,
  error,
  afterCompleted,
  afterError,
}: MutationCallbackArgs<R>) => {
  return {
    onCompleted: (response: R) => {
      const data = response[name];

      invariant(data, `mutation name parameter could be wrong: ${name}`);

      if (!data || data.error || (data.errors && data.errors.length > 0)) {
        showSnackbar &&
          showSnackbar({ message: getError(data) || error });
        afterError && afterError();

        return;
      }

      const successMessage = data.success || success;

      successMessage &&
        showSnackbar &&
        showSnackbar({ message: successMessage });
      afterCompleted && afterCompleted(response);
    },
    onError: () => {
      error && showSnackbar && showSnackbar({ message: error });
      afterError && afterError();
    },
  };
};
