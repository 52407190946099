/**
 * @generated SignedSource<<7c4d6cdb9b69922299527f1c39a803ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserNotificationsSourceEnum = "APP" | "WEB" | "%future added value";
export type UserNotificationMarkAllAsOpenedInput = {
  clientMutationId?: string | null | undefined;
  source: UserNotificationsSourceEnum;
};
export type UserNotificationMarkAllAsOpenedMutation$variables = {
  input: UserNotificationMarkAllAsOpenedInput;
};
export type UserNotificationMarkAllAsOpenedMutation$data = {
  readonly UserNotificationMarkAllAsOpened: {
    readonly error: string | null | undefined;
    readonly me: {
      readonly notifications: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly openedAtWeb: string | null | undefined;
          } | null | undefined;
        } | null | undefined>;
      };
      readonly unreadNotificationsCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type UserNotificationMarkAllAsOpenedMutation = {
  response: UserNotificationMarkAllAsOpenedMutation$data;
  variables: UserNotificationMarkAllAsOpenedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadNotificationsCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNotificationEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNotification",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "openedAtWeb",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationMarkAllAsOpenedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationMarkAllAsOpenedPayload",
        "kind": "LinkedField",
        "name": "UserNotificationMarkAllAsOpened",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "notifications",
                "args": null,
                "concreteType": "UserNotificationConnection",
                "kind": "LinkedField",
                "name": "__Notification_notifications_connection",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNotificationMarkAllAsOpenedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationMarkAllAsOpenedPayload",
        "kind": "LinkedField",
        "name": "UserNotificationMarkAllAsOpened",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "UserNotificationConnection",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "notifications(first:10)"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Notification_notifications",
                "kind": "LinkedHandle",
                "name": "notifications"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a396dea9f11ef356618fe448fc74ef24",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "UserNotificationMarkAllAsOpened",
            "me",
            "notifications"
          ]
        }
      ]
    },
    "name": "UserNotificationMarkAllAsOpenedMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotificationMarkAllAsOpenedMutation(\n  $input: UserNotificationMarkAllAsOpenedInput!\n) {\n  UserNotificationMarkAllAsOpened(input: $input) {\n    error\n    me {\n      unreadNotificationsCount\n      notifications(first: 10) {\n        edges {\n          node {\n            id\n            openedAtWeb\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a50ff45b719b788223982efea037ee1a";

export default node;
