import dot from 'dot-object';
import type { ParsedQuery } from 'query-string';
import queryString from 'query-string';

import { stringToValue } from './stringToValue';

export const parseQuery = (location: Location): ParsedQuery =>
  location ? queryString.parse(location.search.substr(1)) : {};

const getQuery = <T extends {}>(): T => {
  const query = parseQuery(location);

  const filter = Object.entries(query)
    .map(([key, value]) => ({ [key]: stringToValue(value as string, key) }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  return (dot.object(filter) as unknown) as T;
};

export default getQuery;
