/**
 * @generated SignedSource<<1df3ccedf037b151a071061e6cb11512>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComplaintSubjectModule = "EXTERNAL" | "INTERNAL" | "%future added value";
export type ComplaintSubjectSelectFieldQuery$variables = {
  active?: boolean | null | undefined;
  after?: string | null | undefined;
  complaintModule?: ComplaintSubjectModule | null | undefined;
  first?: number | null | undefined;
};
export type ComplaintSubjectSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ComplaintSubjectSelectField_query">;
};
export type ComplaintSubjectSelectFieldQuery = {
  response: ComplaintSubjectSelectFieldQuery$data;
  variables: ComplaintSubjectSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "complaintModule"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = [
  {
    "kind": "Variable",
    "name": "active",
    "variableName": "active"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "complaintModule",
    "variableName": "complaintModule"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ComplaintSubjectSelectFieldQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "ComplaintSubjectSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ComplaintSubjectSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ComplaintSubjectConnection",
        "kind": "LinkedField",
        "name": "complaintSubjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ComplaintSubjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComplaintSubject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ComplaintSubjectSelectField_complaintSubjects",
        "kind": "LinkedHandle",
        "name": "complaintSubjects"
      }
    ]
  },
  "params": {
    "cacheID": "cc53dcf4c763801220d87b4ac438f42a",
    "id": null,
    "metadata": {},
    "name": "ComplaintSubjectSelectFieldQuery",
    "operationKind": "query",
    "text": "query ComplaintSubjectSelectFieldQuery(\n  $first: Int\n  $after: String\n  $active: Boolean\n  $complaintModule: ComplaintSubjectModule\n) {\n  ...ComplaintSubjectSelectField_query_1IXrYM\n}\n\nfragment ComplaintSubjectSelectField_query_1IXrYM on Query {\n  complaintSubjects(first: $first, after: $after, active: $active, complaintModule: $complaintModule) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da72106a2d0eea3149929997c728a265";

export default node;
