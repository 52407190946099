import { fetchQuery } from 'react-relay';
import type { GraphQLTaggedNode, RefetchFnDynamic } from 'react-relay';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import type { Options } from 'react-relay/relay-hooks/useRefetchableFragmentNode';

// this will avoid suspense
export const refetchWithSuspense =
  (
    environment: RelayModernEnvironment,
    node: GraphQLTaggedNode,
    refetch: RefetchFnDynamic<any, any, Options>,
  ) =>
  (variables, test, callback) => {
    const normalizedVariables =
      typeof variables === 'function' ? variables() : variables;

    fetchQuery(environment, node, normalizedVariables).subscribe({
      complete: () => {
        // *After* the query has been fetched, we call
        // refetch again to re-render with the updated data.
        // At this point the data for the query should
        // be cached, so we use the 'store-only'
        // fetchPolicy to avoid suspending.
        refetch(normalizedVariables, { fetchPolicy: 'store-only' });
        callback && callback();
      },
      error: () => {
        // console.log('on error');
      },
    });
  };
