import { graphql } from 'react-relay';

import type { modulesNamesFragment } from './__generated__/modulesNamesFragment.graphql';

// eslint-disable-next-line
graphql`
  fragment modulesNamesFragment on Company {
    preferences {
      feedback {
        module {
          name
          pluralName
        }
      }
      feedbackOneToOne {
        module {
          name
        }
      }
      complaint {
        module {
          name
        }
      }
      complaintExternal {
        title
        module {
          name
        }
      }
      nineBox {
        module {
          name
        }
      }
    }
  }
`;

export const getFeedbackModuleName = (company: modulesNamesFragment) =>
  company?.preferences?.feedback?.module?.name || 'Feedback';

export const getFeedbackModulePluralName = (company: modulesNamesFragment) =>
  company?.preferences?.feedback?.module?.pluralName || 'Feedbacks';

export const getFeedbackOneToOneModuleName = (company: modulesNamesFragment) =>
  company?.preferences?.feedbackOneToOne?.module?.name || '1a1';

export const getOmbudsmanModuleName = (company: modulesNamesFragment) =>
  company?.preferences?.complaint?.module?.name || 'Ombudsman';

export const getOmbudsmanExternalModuleName = (company: modulesNamesFragment) =>
  company?.preferences?.complaintExternal?.module?.name || 'Ombudsman';

export const getNineBoxModuleName = (company: modulesNamesFragment) => {
  return company?.preferences?.nineBox?.module?.name || '9box';
};
