// @flow
type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const NineBox = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill={color}
          fillRule='nonzero'
          d='M1.333 5.75C.642 5.75.083 6.308.083 7s.559 1.25 1.25 1.25c.692 0 1.25-.558 1.25-1.25s-.558-1.25-1.25-1.25zm0-5C.642.75.083 1.308.083 2s.559 1.25 1.25 1.25c.692 0 1.25-.558 1.25-1.25S2.025.75 1.333.75zm0 10c-.691 0-1.25.567-1.25 1.25s.567 1.25 1.25 1.25A1.26 1.26 0 0 0 2.583 12c0-.683-.558-1.25-1.25-1.25zm5-5c-.691 0-1.25.558-1.25 1.25s.559 1.25 1.25 1.25c.692 0 1.25-.558 1.25-1.25s-.558-1.25-1.25-1.25zm0-5c-.691 0-1.25.558-1.25 1.25s.559 1.25 1.25 1.25c.692 0 1.25-.558 1.25-1.25S7.025.75 6.333.75zm0 10c-.691 0-1.25.567-1.25 1.25s.567 1.25 1.25 1.25A1.26 1.26 0 0 0 7.583 12c0-.683-.558-1.25-1.25-1.25zm5-5c-.691 0-1.25.558-1.25 1.25s.559 1.25 1.25 1.25c.692 0 1.25-.558 1.25-1.25s-.558-1.25-1.25-1.25zm0-5c-.691 0-1.25.558-1.25 1.25s.559 1.25 1.25 1.25c.692 0 1.25-.558 1.25-1.25S12.025.75 11.333.75zm0 10c-.691 0-1.25.567-1.25 1.25s.567 1.25 1.25 1.25a1.26 1.26 0 0 0 1.25-1.25c0-.683-.558-1.25-1.25-1.25z'
        />
        <path d='M-2-3h20v20H-2z' />
      </g>
    </svg>
  );
};

NineBox.defaultProps = {
  color: '#607389',
  viewBox: '0 0 13 15',
  height: 24,
};

export default NineBox;
