import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 40px;
  padding: 40px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  background: ${(props) => props.theme.primaryDarkerColor};
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.p`
  color: #ffffff;
  font-size: 32px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Image = styled.img`
  max-height: 175px;
  margin-bottom: -40px;
`;

const CardHeader = ({ title, image }) => (
  <Container>
    <Title>{title}</Title>
    {image && (
      <ImageContainer>
        <Image src={image} />
      </ImageContainer>
    )}
  </Container>
);

CardHeader.propTypes = {
  title: PropTypes.string,
};

export default CardHeader;
