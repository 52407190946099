import type {
  TextFieldProps as TextFieldMaterialProps,
} from '@material-ui/core/TextField';
import TextFieldMaterial from '@material-ui/core/TextField';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { space, maxWidth } from 'styled-system';

type MaskOptions = {
  showMaskOnHover: boolean;
  showMaskOnFocus: boolean;
  greedy: boolean;
};

export type TextFieldProps = {
  mask?: string;
  helperText?: string;
  max?: string;
  min?: string;
  maskOptions?: MaskOptions;
} & TextFieldMaterialProps;

const TextFieldMaterialStyled = styled(TextFieldMaterial)`
  ${space}
  ${maxWidth}
`;

const defaultMaskOptions = {
  showMaskOnHover: false,
};

const TextField = (props: TextFieldProps) => {
  const inputRef = useRef();
  const inputMaskRef = useRef(null);

  const { mask, maskOptions } = props;

  useEffect(() => {
    const loadInputMask = async () => {
      inputMaskRef.current = await import('inputmask');

      if (inputMaskRef.current) {
        const inputmask = new inputMaskRef.current.default(mask, {
          placeholder: '',
          ...defaultMaskOptions,
          ...maskOptions,
        });

        inputmask.mask(inputRef.current);
      }
    };

    if (mask && !inputMaskRef.current) {
      loadInputMask();
    }

    return () => {
      if (inputRef.current && inputMaskRef.current) {
        inputMaskRef.current.remove(inputRef.current);
      }
    };
  }, [mask]);

  const { helperText, max, min, inputProps, ...otherProps } = props;

  const textInputProps = {
    min,
    max,
    ...(props.inputProps || {}),
  };

  return (
    <TextFieldMaterialStyled
      inputRef={inputRef}
      // so we always have some text, and there are heights increases when the user is filling the form
      helperText={helperText || ''}
      inputProps={textInputProps}
      variant='outlined'
      margin='normal'
      {...otherProps}
    />
  );
};

export default TextField;
