/**
 * @generated SignedSource<<06ee1e25e429cb92bdae6247ce47f7ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobExamQuestionSelectField_query$data = {
  readonly jobExam: {
    readonly id: string;
    readonly questionsWeight?: ReadonlyArray<{
      readonly question: {
        readonly id: string;
        readonly title: string;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "JobExamQuestionSelectField_query";
};
export type JobExamQuestionSelectField_query$key = {
  readonly " $data"?: JobExamQuestionSelectField_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobExamQuestionSelectField_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jobExamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobExamQuestionSelectField_query",
  "selections": [
    {
      "alias": "jobExam",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "jobExamId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionWeight",
              "kind": "LinkedField",
              "name": "questionsWeight",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JobExamQuestion",
                  "kind": "LinkedField",
                  "name": "question",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "JobExam",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "af1845a5a2a1a5a37fc95932a7e47fa5";

export default node;
