/**
 * @generated SignedSource<<ef1e873912fff3f1f0fd4d68cfe56727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageInput_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
  readonly " $fragmentType": "ImageInput_user";
};
export type ImageInput_user$key = {
  readonly " $data"?: ImageInput_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageInput_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageInput_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAvatar_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "135089ff46fdd323ccf70473ed5ff38f";

export default node;
