import styled from 'styled-components';

import Icon from './Icon';

const TagItem = styled.div`
  margin: 4px;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: #bababa;
  display: flex;
  justify-content: center;
  min-width: 50px;
  color: rgb(96, 96, 96);
`;

const TagText = styled.span`
  padding: 7px;
`;

const RemoveTagIconContainer = styled.a`
  margin-top: -7px;
`;

const Tag = ({ children, onClick, style, allowRemove }) => {
  return (
    <TagItem>
      <TagText>{children}</TagText>
      {allowRemove && (
        <RemoveTagIconContainer onClick={onClick}>
          <Icon
            children='close'
            style={{
              textAlign: 'center',
              color: '#FFF',
              fontSize: 14,
              borderRadius: 15,
              backgroundColor: '#4890e5',
              overflow: 'hidden',
              ...style,
            }}
          />
        </RemoveTagIconContainer>
      )}
    </TagItem>
  );
};

export default Tag;
