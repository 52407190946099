import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { routeTo } from '../utils';

export const useShouldUserAcceptAgreement = (me) => {
  const history = useHistory();

  const { active } =
    me && me.company ? me.company.preferences.core.agreementTerm : false;

  let shouldUserAcceptAgreement = false;

  if (active) {
    shouldUserAcceptAgreement = me ? !me.hasAcceptAgreementTerm : false;
  }

  useEffect(() => {
    if (shouldUserAcceptAgreement) {
      history.push(routeTo('required.agreement'));
    }
  }, [shouldUserAcceptAgreement]);

  return shouldUserAcceptAgreement;
};
