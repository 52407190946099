import _CardActions from '@material-ui/core/CardActions';
import styled from 'styled-components';

import { hexToRgbaString } from '../helpers';

const CardActions = styled(_CardActions)`
  justify-content: flex-end;
  padding: 2px 10px;
  border-top: 1px solid
    ${(props) => hexToRgbaString(props.theme.primaryDarkerColor, 0.12)};
`;

export default CardActions;
