import type { ComponentType } from 'react';
import EventSeatOutlinedIcon from '@material-ui/icons/EventSeatOutlined';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SettingsInputHdmiOutlined from '@material-ui/icons/SettingsInputHdmiOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { IoKeyOutline } from 'react-icons/io5';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { graphql, readInlineData } from 'react-relay';
import { COMPANY_PROJECT_TYPES_ENUM } from '@feedback/roles';

import { routeTo } from '../../router/utils';

import { usePermission } from '../../security/usePermission';

import type { menuItems_query } from './__generated__/menuItems_query.graphql';
import {
  externalOmbudsmanAdmin,
  feedback,
  feedbackAdmin,
  feedbackOneToOne,
  feedbackOneToOneAdmin,
  goals,
  goalsAdmin,
  group,
  groupAdmin,
  hiring,
  ombudsman,
  ombudsmanAdmin,
  performance,
  performanceAdmin,
  planningAdmin,
  ranking,
  team,
  userAdmin,
  vacancies,
  pdi,
  pdiAdmin,
} from './items';
import { filterItems } from './filterItems';
import { charges } from './items/openpix/charges';
import { transactions } from './items/openpix/transactions';

const shouldRenderHomeSideBar = (me: menuItems_query['me']) =>
  me.company.projectType === COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE;

const menuItemsFragment = graphql`
  fragment menuItems_query on Query @inline {
    me {
      id
      ...usePermissionFragment
      ...permissionsUserFragment @relay(mask: false)
      isAdmin
      allRoles
      pendingFeedbackRequestCount
      pendingFeedbackRequestSentCount
      pendingUserManagerInvitationsCount
      pendingUserManagerTransfersCount
      feedbacksReceivedCount
      hasGoalGroup
      isResponsibleByCostRevenueCenters
      isResponsibleForSomeCase
      isInNineBox
      hasNineBoxReportAvailable
      hasDataExports
      businessDivisions(first: 1) {
        edges {
          node {
            id
          }
        }
      }
      myTeam(first: 3) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            name
            nick
          }
        }
      }
      company {
        features
        nameFriendly
        projectType
        preferences {
          complaint {
            module {
              name
            }
            hideMenu
          }
          feedback {
            module {
              name
              pluralName
            }
            allowFeedbackRequest
            allowSelfReview
          }
          complaintExternal {
            module {
              name
            }
          }
        }
      }
    }
  }
`;

export type SideBarMenuItemProps = {
  query: menuItems_query;
  t: any;
};

type Props = {
  menuItemsFragmentRef: menuItems_query;
  t: any;
};

export type MenuItem = {
  items: MenuItem[];
  link?: string;
  label: string;
  separator?: boolean;
  icon: ComponentType;
};

export default ({ menuItemsFragmentRef, t }: Props) => {
  const query = readInlineData(menuItemsFragment, menuItemsFragmentRef);
  const { hasRole } = usePermission(query.me);

  const items: MenuItem[] = [
    {
      label: t('Home'),
      icon: <HomeOutlinedIcon />,
      link: routeTo('home.feedback'),
      isDisplayed: [(me) => shouldRenderHomeSideBar(me)],
    },
    ...feedback({ query, t }),
    // ...career(),
    ...ranking({ query, t }),
    ...goals({ query, t }),
    ...performance({ query, t }),
    ...pdi({ query, t }),
    ...feedbackOneToOne({ query, t }),
    {
      label: t('Reservations'),
      icon: <EventSeatOutlinedIcon />,
      link: routeTo('logid.user.list.qrCodeReservation', {
        id: query.me.id,
      }),
    },
    ...team({ t }),
    ...group({ query, t }),
    ...vacancies({ query, t }),
    ...ombudsman({ query, t }),
    ...hiring({ query, t }),
    {
      label: t('Poll'),
      icon: <ForumOutlinedIcon />,
      link: routeTo('poll.user.list'),
    },
    {
      label: t('{{NineBox}}'),
      icon: <AppsOutlinedIcon />,
      link: query.me.hasNineBoxReportAvailable
        ? routeTo('nineBox.user.report', { id: query.me.id })
        : routeTo('nineBox.user.requests'),
      isDisplayed: [(me) => me.hasNineBoxReportAvailable || me.isInNineBox],
    },
    {
      label: t('Customers'),
      icon: <PersonOutlinedIcon />,
      link: routeTo('crm.user.customer.list'),
    },
    ...charges({ t, hasRole }),
    {
      label: t('Subscriptions'),
      icon: <CardMembershipIcon />,
      link: routeTo('openpix.paymentSubscription.list'),
    },
    ...transactions({ t, hasRole }),
    {
      label: t('Giftbacks'),
      icon: <AddShoppingCartIcon />,
      link: routeTo('openpix.giftbacks.overview'),
      isDisplayed: [
        () => !!query.me.company.preferences.openpix.giftback.active,
      ],
    },
    // giftback disabled
    {
      label: t('Giftbacks'),
      icon: <AddShoppingCartIcon />,
      link: routeTo('openpix.giftbacks.tutorial'),
      isDisplayed: [
        () => !query.me.company.preferences.openpix.giftback.active,
      ],
    },
    {
      label: t('QRCodes'),
      icon: <CropFreeIcon />,
      link: routeTo('openpix.pixqrcodes'),
    },
    {
      label: t('Payments'),
      icon: <PaymentIcon />,
      link: routeTo('openpix.payments'),
    },
    {
      label: t('Administrator'),
      separator: true,
      items: [
        {
          label: t('Communication'),
          icon: <LanguageIcon />,
          link: routeTo('social.analytics.dashboard'),
        },
        {
          label: t('{{NineBox}}'),
          icon: <AppsOutlinedIcon />,
          link: routeTo('nineBox.home.list'),
        },
        {
          label: t('Reservations'),
          icon: <EventSeatOutlinedIcon />,
          link: routeTo('logid.reports'),
        },
        ...feedbackAdmin({ query, t }),
        ...goalsAdmin({ query, t }),
        ...performanceAdmin({ query, t }),
        ...pdiAdmin({ query, t }),
        ...feedbackOneToOneAdmin({ query, t }),
        ...groupAdmin({ query, t }),
        ...ombudsmanAdmin({ query, t }),
        ...externalOmbudsmanAdmin({ query, t }),
        ...planningAdmin({ query, t }),
        {
          label: t('Poll'),
          icon: <ForumOutlinedIcon />,
          link: routeTo('poll.admin.list'),
        },
        ...userAdmin({ query, t }),
        {
          label: t('Permissions'),
          icon: <IoKeyOutline />,
          link: routeTo('permissions.users.list'),
        },
        {
          label: t('API/Plugins'),
          icon: <SettingsInputHdmiOutlined />,
          link: routeTo('applications.list'),
        },
        {
          label: t('Settings'),
          icon: <SettingsOutlinedIcon />,
          link: routeTo('settings'),
        },
      ],
    },
  ];

  const filtered = filterItems(items, query);

  return filtered;
};
