import { graphql, readInlineData } from 'react-relay';
import * as Yup from 'yup';

import { MODULES } from '@feedback/roles';

import { DIRECTION } from '../user/order';

import type { FeedbackUtils_company } from './__generated__/FeedbackUtils_company.graphql';
import type { FeedbackUtils_user } from './__generated__/FeedbackUtils_user.graphql';
import type { FeedbackUtils_me } from './__generated__/FeedbackUtils_me.graphql';


type FeedbackPreference = FeedbackUtils_company['preferences']['feedback'];
type Topic = FeedbackUtils_company['utilsTopics']['edges']['0']['node'];

export const fragmentFeedbackUtils_me = graphql`
  fragment FeedbackUtils_me on User @inline {
    id
  }
`;

export const fragmentFeedbackUtils_user = graphql`
  fragment FeedbackUtils_user on User @inline {
    id
    isManager
  }
`;

export const fragmentFeedbackUtils_company = graphql`
  fragment FeedbackUtils_company on Company @inline {
    utilsTopics: feedbackTopics(first: 100, isDisabled: false) {
      edges {
        node {
          id
          title
          onlyForManagers
          modules
          isDisabled
        }
      }
    }
    preferences {
      feedback {
        alwaysAnonymous
        formType
        formItems {
          type
          params {
            key
            value
          }
          optional
        }
        scoreScale {
          max
          min
          initialScoreValue
        }
      }
      feedbackExternal {
        alwaysAnonymous
        formType
        formItems {
          type
          params {
            key
            value
          }
          optional
        }
        scoreScale {
          min
          max
          initialScoreValue
        }
      }
      feedbackExternalGroup {
        alwaysAnonymous
        formType
        formItems {
          type
          params {
            key
            value
          }
          optional
        }
        scoreScale {
          min
          max
          initialScoreValue
        }
      }
      feedbackGroup {
        scoreScale {
          max
          min
          initialScoreValue
        }
        formItems {
          type
          params {
            key
            value
          }
        }
      }
    }
  }
`;

export const IDENTIFICATION = {
  IDENTIFIED: 'IDENTIFIED',
  NON_IDENTIFIED: 'NON_IDENTIFIED',
};

export const SORT = {
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST',
  MAX_SCORE: 'MAX_SCORE',
  MIN_SCORE: 'MIN_SCORE',
};

export const SORT_ITEMS = {
  [SORT.NEWEST]: {
    value: SORT.NEWEST,
    label: 'Mais recentes',
  },
  [SORT.OLDEST]: {
    value: SORT.OLDEST,
    label: 'Mais antigos',
  },
  [SORT.MAX_SCORE]: {
    value: SORT.MAX_SCORE,
    label: 'Maiores notas',
  },
  [SORT.MIN_SCORE]: {
    value: SORT.MIN_SCORE,
    label: 'Menores notas',
  },
};

export const SORT_FILTER = {
  CREATED_AT: 'CREATED_AT',
  SCORE: 'SCORE',
};

export const SORT_ITEMS_FILTER = {
  [SORT.NEWEST]: {
    value: { sort: SORT_FILTER.CREATED_AT, direction: DIRECTION.DESC },
    label: 'Mais recentes',
  },
  [SORT.OLDEST]: {
    value: { sort: SORT_FILTER.CREATED_AT, direction: DIRECTION.ASC },
    label: 'Mais antigos',
  },
  [SORT.MAX_SCORE]: {
    value: { sort: SORT_FILTER.SCORE, direction: DIRECTION.DESC },
    label: 'Maiores notas',
  },
  [SORT.MIN_SCORE]: {
    value: { sort: SORT_FILTER.SCORE, direction: DIRECTION.ASC },
    label: 'Menores notas',
  },
};

export const PREFERENCE_KIND = {
  FEEDBACK: 'FEEDBACK',
  EXTERNAL_FEEDBACK: 'EXTERNAL_FEEDBACK',
  FEEDBACK_GROUP: 'FEEDBACK_GROUP',
  EXTERNAL_FEEDBACK_GROUP: 'EXTERNAL_FEEDBACK_GROUP',
};
export const FORM_CONTEXT = {
  FEEDBACK: 'feedback',
  EXTERNAL_FEEDBACK: 'feedbackExternal',
  FEEDBACK_GROUP: 'feedbackGroup',
  EXTERNAL_FEEDBACK_GROUP: 'feedbackExternalGroup',
};

export const FEEDBACK_FIELD_ITEM = {
  TEXT: 'TEXT',
  TOPIC: 'TOPIC',
  REACTION: 'REACTION',
  SCORE: 'SCORE',
  IDENTIFIED: 'IDENTIFIED',
  RATINGS: 'RATINGS',
  PROFILE: 'PROFILE',
};

export const FEEDBACK_ITEMS = [
  FEEDBACK_FIELD_ITEM.TEXT,
  FEEDBACK_FIELD_ITEM.TOPIC,
  FEEDBACK_FIELD_ITEM.REACTION,
  FEEDBACK_FIELD_ITEM.SCORE,
  FEEDBACK_FIELD_ITEM.IDENTIFIED,
  FEEDBACK_FIELD_ITEM.RATINGS,
];

export const EXTERNAL_FEEDBACK_ITEMS = [
  FEEDBACK_FIELD_ITEM.TEXT,
  FEEDBACK_FIELD_ITEM.TOPIC,
  FEEDBACK_FIELD_ITEM.REACTION,
  FEEDBACK_FIELD_ITEM.SCORE,
  FEEDBACK_FIELD_ITEM.IDENTIFIED,
  FEEDBACK_FIELD_ITEM.RATINGS,
];

export const ATTRS = {
  LABEL: 'LABEL',
  PLACEHOLDER: 'PLACEHOLDER',
};

export const FEEDBACK_FORM_TYPE = {
  NO_TOPIC: 'NO_TOPIC',
  MULTIPLE_TOPICS: 'MULTIPLE_TOPICS',
  SINGLE_TOPIC: 'SINGLE_TOPIC',
};

export const FORM_TYPE_TO_FIELDS = {
  [FEEDBACK_FORM_TYPE.NO_TOPIC]: [
    FEEDBACK_FIELD_ITEM.TEXT,
    FEEDBACK_FIELD_ITEM.REACTION,
    FEEDBACK_FIELD_ITEM.SCORE,
    FEEDBACK_FIELD_ITEM.IDENTIFIED,
  ],
  [FEEDBACK_FORM_TYPE.SINGLE_TOPIC]: [
    FEEDBACK_FIELD_ITEM.TEXT,
    FEEDBACK_FIELD_ITEM.TOPIC,
    FEEDBACK_FIELD_ITEM.REACTION,
    FEEDBACK_FIELD_ITEM.SCORE,
    FEEDBACK_FIELD_ITEM.IDENTIFIED,
  ],
  [FEEDBACK_FORM_TYPE.MULTIPLE_TOPICS]: [
    FEEDBACK_FIELD_ITEM.TEXT,
    FEEDBACK_FIELD_ITEM.REACTION,
    FEEDBACK_FIELD_ITEM.RATINGS,
    FEEDBACK_FIELD_ITEM.IDENTIFIED,
  ],
};

export const ITEM_FIELD_NAME = {
  [FEEDBACK_FIELD_ITEM.TEXT]: 'text',
  [FEEDBACK_FIELD_ITEM.TOPIC]: 'feedbackTopic',
  [FEEDBACK_FIELD_ITEM.REACTION]: 'reactionId',
  [FEEDBACK_FIELD_ITEM.SCORE]: 'score',
  [FEEDBACK_FIELD_ITEM.IDENTIFIED]: 'isIdentified',
  [FEEDBACK_FIELD_ITEM.RATINGS]: 'ratings',
};

export const STATUS = {
  PENDING: 'PENDING',
  SENT: 'SENT',
  DECLINED: 'DECLINED',
};

export type Rating = {
  topic: string;
  score?: number;
};

export type FeedbackValues = {
  text?: string;
  feedbackTopic?: string;
  reactionId?: string;
  score?: number;
  isAnonymous: boolean;
  ratings: { [topic: string]: number };
};

export const FIELD_VALIDATION = {
  [FEEDBACK_FIELD_ITEM.TEXT]: Yup.string().required('É necessário um texto.'),
  [FEEDBACK_FIELD_ITEM.TOPIC]: Yup.string().required(
    'É necessário informar um tópico.',
  ),
  [FEEDBACK_FIELD_ITEM.REACTION]: Yup.string().required(
    'É necessário informar uma reação.',
  ),
  [FEEDBACK_FIELD_ITEM.SCORE]: Yup.number().required(
    'É necessário enviar uma nota',
  ),
  [FEEDBACK_FIELD_ITEM.IDENTIFIED]: Yup.mixed(),
  [FEEDBACK_FIELD_ITEM.RATINGS]: Yup.object(), // this is a key value, topic and score
};

export const TOPIC_MODULES = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  ONE_TO_ONE: 'ONE_TO_ONE',
  USER_OBJECTIVE: 'USER_OBJECTIVE',
};

export const getTopicModuleByFormType = (type: string) => {
  if (type === FORM_CONTEXT.FEEDBACK || type === FORM_CONTEXT.FEEDBACK_GROUP) {
    return TOPIC_MODULES.INTERNAL;
  }

  if (
    type === FORM_CONTEXT.EXTERNAL_FEEDBACK ||
    type === FORM_CONTEXT.EXTERNAL_FEEDBACK_GROUP
  ) {
    return TOPIC_MODULES.EXTERNAL;
  }

  return TOPIC_MODULES.INTERNAL;
};

export const filterValidTopics = (
  node,
  form: string,
  isManager: boolean | null,
) => {
  if (node.isDisabled) {
    return false;
  }

  if (node.modules && !node.modules.includes(form)) {
    return false;
  }

  if (node.onlyForManagers && isManager !== null) {
    if (!isManager) {
      return false;
    }
  }

  return true;
};

export const getValidTopics = (
  topics,
  form: string,
  isManager: boolean | null,
) => {
  return topics.filter(({ node }) => filterValidTopics(node, form, isManager));
};

const getIsManager = (user: FeedbackUtils_user | null) => {
  if (user) {
    return user.isManager;
  }

  return null;
};

export const getDefaultScore = (
  company: FeedbackUtils_company,
  type: string,
) => {
  const { preferences } = company;
  const { scoreScale, formType } = preferences[type];
  const { min, initialScoreValue } = scoreScale;

  if (initialScoreValue != null) {
    if (formType === FEEDBACK_FORM_TYPE.SINGLE_TOPIC) {
      return Math.max(initialScoreValue, min);
    }

    return initialScoreValue;
  }

  return initialScoreValue;
};

export const getScoreTopic = (
  company: FeedbackUtils_company,
  topic: TopicNode,
  defaultScore: number,
) => {
  return defaultScore;
};

export const getDefaultRatings = (
  company: FeedbackUtils_company,
  type: string,
  user: FeedbackUtils_user,
  validTopics: Topic[],
) => {
  const defaultScore = getDefaultScore(company, type);

  const utilsTopics = validTopics;

  return utilsTopics.reduce((acc, { node }) => {
    return {
      ...acc,
      [node.id]: getScoreTopic(company, node, defaultScore),
    };
  }, {});
};

export const DEFAULT_VALUE = {
  [FEEDBACK_FIELD_ITEM.IDENTIFIED]: (
    company: FeedbackUtils_company,
    type: string,
  ) => {
    return company.preferences[type].alwaysAnonymous
      ? IDENTIFICATION.NON_IDENTIFIED
      : IDENTIFICATION.IDENTIFIED;
  },
  [FEEDBACK_FIELD_ITEM.RATINGS]: (
    company: FeedbackUtils_company,
    type,
    user,
    validTopics: Topic[],
  ) => {
    const ratings = getDefaultRatings(company, type, user, validTopics);

    return ratings;
  },
  [FEEDBACK_FIELD_ITEM.SCORE]: (
    company: FeedbackUtils_company,
    type: string,
  ) => {
    return getDefaultScore(company, type);
  },
};

export const mapPropsToValues = (
  company: FeedbackUtils_company,
  type: string,
  user: FeedbackUtils_user,
  validTopics: Topic[],
) => {
  const fields = company.preferences[type].formItems.map((item) => item.type);

  return fields.reduce((acc, field, index) => {
    let defaultValue = DEFAULT_VALUE[field];

    if (defaultValue === undefined) {
      return acc;
    }

    if (typeof defaultValue === 'function') {
      defaultValue = defaultValue(company, type, user, validTopics);
    }

    const complement = field === FEEDBACK_FIELD_ITEM.TEXT ? `_${index}` : '';

    return { ...acc, [`${ITEM_FIELD_NAME[field]}${complement}`]: defaultValue };
  }, {});
};

export const getValidationSchema = (
  items: FormItem[],
  validateTopic = true,
) => {
  const schema = items.reduce((acc, field, index) => {
    const complement =
      field.type === FEEDBACK_FIELD_ITEM.TEXT ? `_${index}` : '';

    if (field.type === FEEDBACK_FIELD_ITEM.TOPIC && !validateTopic) {
      return acc;
    }

    // TODO - improve this to handle all types of fields
    const validationField =
      field.optional === true ? Yup.string() : FIELD_VALIDATION[field.type];

    return {
      ...acc,
      [`${ITEM_FIELD_NAME[field.type]}${complement}`]: validationField,
    };
  }, {});

  return schema;
};

export const getIsAnonymous = (
  feedbackPreferences: FeedbackPreference,
  isIdentified: boolean,
  isSelfReview?: boolean,
) => {
  const hasIdentifiedFormItem = feedbackPreferences.formItems.find(
    (item) => item.type === FEEDBACK_FIELD_ITEM.IDENTIFIED,
  );

  if (isSelfReview) {
    return false;
  }

  if (hasIdentifiedFormItem) {
    return !isIdentified;
  }

  return feedbackPreferences.alwaysAnonymous;
};

const isSelfReview = (
  me: FeedbackUtils_me | null,
  user: FeedbackUtils_user | null,
) => {
  if (!me) {
    return false;
  }

  if (!user) {
    return false;
  }

  return me.id === user.id;
};

type ValidFormItems = {
  feedbackPreferences: FeedbackPreference;
  me: FeedbackUtils_me;
  user: FeedbackUtils_user;
  isFeedbackRequest: boolean;
  hasTopic: boolean;
};
export const getValidFormItems = ({
  feedbackPreferences,
  me,
  user,
  isFeedbackRequest,
  hasTopic,
}: ValidFormItems) => {
  const { formItems, formType } = feedbackPreferences;

  const validFormItems = formItems.filter((item) => {
    // on self review user is always identified
    if (
      isSelfReview(me, user) &&
      item.type === FEEDBACK_FIELD_ITEM.IDENTIFIED
    ) {
      return false;
    }

    const isMultipleTopic = formType === FEEDBACK_FORM_TYPE.MULTIPLE_TOPICS;

    const hideSingleTopic =
      isFeedbackRequest && (isMultipleTopic || (hasTopic && !isMultipleTopic));

    const hideSingleScore = isFeedbackRequest && isMultipleTopic;
    const hideRatings = isFeedbackRequest && !isMultipleTopic;

    switch (item.type) {
      case FEEDBACK_FIELD_ITEM.TOPIC: {
        return !hideSingleTopic;
      }

      case FEEDBACK_FIELD_ITEM.RATINGS: {
        return !hideRatings;
      }

      case FEEDBACK_FIELD_ITEM.SCORE: {
        return !hideSingleScore;
      }
    }

    return true;
  });

  return validFormItems;
};

type FeedbackUtils = {
  companyRef: FeedbackUtils_company;
  type: string;
  userRef?: FeedbackUtils_user;
  meRef?: FeedbackUtils_me;
  isFeedbackRequest?: boolean;
  hasTopic?: boolean;
};
export const getFeedbackUtils = ({
  companyRef,
  type,
  userRef,
  meRef,
  isFeedbackRequest = false,
  hasTopic = false,
}: FeedbackUtils) => {
  const company = readInlineData(fragmentFeedbackUtils_company, companyRef);

  let user = null;
  let me = null;

  if (userRef) {
    user = readInlineData(fragmentFeedbackUtils_user, userRef);
  }

  if (meRef) {
    me = readInlineData(fragmentFeedbackUtils_me, meRef);
  }

  const feedbackPreferences = company.preferences[type];

  const formItems = getValidFormItems({
    feedbackPreferences,
    me,
    user,
    isFeedbackRequest,
    hasTopic,
  });

  const form = getTopicModuleByFormType(type);
  const isManager = getIsManager(user);
  const validTopics = getValidTopics(
    company.utilsTopics.edges,
    form,
    isManager,
  );

  return {
    mapPropsToValues: () => mapPropsToValues(company, type, user, validTopics),
    getValidationSchema: (validateTopic?: boolean) =>
      getValidationSchema(formItems, validateTopic),
    getIsAnonymous: (isIdentified: boolean, isSelfReview: boolean) =>
      getIsAnonymous(feedbackPreferences, isIdentified, isSelfReview),
  };
};

type TopicNode = FeedbackUtils_company['utilsTopics']['edges']['0']['node'];

type User = {
  isManager: boolean;
};
export const filterFeedbackTopicsOnlyForManagers = (
  feedbackTopics: any,
  user: User,
  filterModule = TOPIC_MODULES.INTERNAL,
) => {
  return {
    ...feedbackTopics,
    edges: feedbackTopics.edges.filter((edge) => {
      if (!edge || !edge.node) {
        return false;
      }

      const { node } = edge;

      if (!node.modules || !node.modules.includes(filterModule)) {
        return false;
      }

      if (node.isDisabled) {
        return false;
      }

      if (!user) {
        return true;
      }

      if (user.isManager) {
        return true;
      }

      return !node.onlyForManagers;
    }),
  };
};

type FormParam = {
  key: string;
  value: string;
};

type FormItem = {
  type: string;
  params: FormParam[];
  optional?: boolean;
};

export const handleFeatureFlag = (module: string) => {
  switch (module) {
    case TOPIC_MODULES.INTERNAL:
      return [MODULES.FEEDBACK];

    case TOPIC_MODULES.EXTERNAL:
      return [MODULES.FEEDBACK_EXTERNAL];

    case TOPIC_MODULES.ONE_TO_ONE:
      return [MODULES.FEEDBACK_ONE_TO_ONE];
  }
};
