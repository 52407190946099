/**
 * @generated SignedSource<<e8d1a1519f7cc7f658a0dc23b1f6cd02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostRevenueCenterFilter = {
  active?: boolean | null | undefined;
  businessDivision?: string | null | undefined;
  exclude?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeGoalGroup?: string | null | undefined;
  goalGroup?: string | null | undefined;
  hasGoalGroup?: boolean | null | undefined;
  include?: ReadonlyArray<string | null | undefined> | null | undefined;
  indirectParent?: string | null | undefined;
  isMeResponsible?: boolean | null | undefined;
  nextToLocation?: CoordinatesInputType | null | undefined;
  parent?: string | null | undefined;
  possibleDuplicated?: boolean | null | undefined;
  search?: string | null | undefined;
  tags?: string | null | undefined;
  withAddress?: boolean | null | undefined;
};
export type CoordinatesInputType = {
  label?: string | null | undefined;
  lat: number;
  lng: number;
};
export type CostRevenueCenterSelectFieldQuery$variables = {
  after?: string | null | undefined;
  filters?: CostRevenueCenterFilter | null | undefined;
  first?: number | null | undefined;
};
export type CostRevenueCenterSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CostRevenueCenterSelectField_query">;
};
export type CostRevenueCenterSelectFieldQuery = {
  response: CostRevenueCenterSelectFieldQuery$data;
  variables: CostRevenueCenterSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CostRevenueCenterSelectFieldQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "CostRevenueCenterSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CostRevenueCenterSelectFieldQuery",
    "selections": [
      {
        "alias": "selectCostRevenueCenters",
        "args": (v3/*: any*/),
        "concreteType": "CostRevenueCenterConnection",
        "kind": "LinkedField",
        "name": "costRevenueCenters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CostRevenueCenterEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CostRevenueCenter",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "selectCostRevenueCenters",
        "args": (v3/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "CostRevenueCenterSelectField_selectCostRevenueCenters",
        "kind": "LinkedHandle",
        "name": "costRevenueCenters"
      }
    ]
  },
  "params": {
    "cacheID": "96a24b7a91a4ecef4f724abeb9d69cef",
    "id": null,
    "metadata": {},
    "name": "CostRevenueCenterSelectFieldQuery",
    "operationKind": "query",
    "text": "query CostRevenueCenterSelectFieldQuery(\n  $first: Int\n  $after: String\n  $filters: CostRevenueCenterFilter\n) {\n  ...CostRevenueCenterSelectField_query_1XoDko\n}\n\nfragment CostRevenueCenterSelectField_query_1XoDko on Query {\n  selectCostRevenueCenters: costRevenueCenters(first: $first, after: $after, filters: $filters) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        active\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd6e11f43558ad9fb48c230691d8ab99";

export default node;
