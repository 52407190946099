import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { GroupSelectField_query } from './__generated__/GroupSelectField_query.graphql';

type Props = {
  query: GroupSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: Group;
};

type Group = {
  id: string;
  name: string;
};

type State = {
  input: string;
  search: string;
};

class GroupSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Grupo',
    name: 'group',
  };

  render() {
    const {
      query,
      relay, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    return (
      <ResourceSelectField relay={relay} connection={query.groups} {...props} />
    );
  }
}

const GroupSelectFieldFragmentContainer = createRefetchContainer(
  GroupSelectField,
  {
    query: graphql`
      fragment GroupSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 30 }
        after: { type: String }
      ) {
        groups(first: $count, after: $after)
          @connection(key: "GroupSelectField_groups", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query GroupSelectFieldQuery($count: Int, $after: String) {
      ...GroupSelectField_query @arguments(count: $count, after: $after)
    }
  `,
);

export default GroupSelectFieldFragmentContainer;
