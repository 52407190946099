/**
 * @generated SignedSource<<b216747ab2ff7233ac308f2f29907832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginFormQuery$variables = Record<PropertyKey, never>;
export type LoginFormQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoginForm_query">;
};
export type LoginFormQuery = {
  response: LoginFormQuery$data;
  variables: LoginFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginFormQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoginForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginFormQuery",
    "selections": [
      {
        "alias": "company",
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyDomainName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyCorePreferences",
                "kind": "LinkedField",
                "name": "core",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyCoreLoginPreferences",
                    "kind": "LinkedField",
                    "name": "login",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableEmail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableTaxID",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableEnrollment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableSaml",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableSaml2",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthConfig",
                    "kind": "LinkedField",
                    "name": "authConfigs",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "buttonLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyIntegrationPreferences",
                    "kind": "LinkedField",
                    "name": "integration",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompanyIntegrationMicrosoftPreferences",
                        "kind": "LinkedField",
                        "name": "microsoft",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tenant",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasHRModule",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectType",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1d93eff68bf073caa43d129894f6eb4",
    "id": null,
    "metadata": {},
    "name": "LoginFormQuery",
    "operationKind": "query",
    "text": "query LoginFormQuery {\n  ...LoginForm_query\n}\n\nfragment LoginForm_query on Query {\n  company: companyDomainName {\n    ...LoginTextField_company\n    ...LoginSamlField_company\n    hasHRModule\n    projectType\n    preferences {\n      core {\n        authConfigs {\n          type\n          buttonLabel\n          active\n          id\n        }\n        login {\n          enableSaml\n          enableSaml2\n          enableEmail\n          enableTaxID\n          enableEnrollment\n        }\n        integration {\n          microsoft {\n            tenant\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment LoginSamlField_company on Company {\n  preferences {\n    core {\n      authConfigs {\n        id\n        _id\n        type\n        buttonLabel\n        active\n      }\n      login {\n        enableSaml\n        enableSaml2\n      }\n      integration {\n        microsoft {\n          tenant\n        }\n      }\n    }\n  }\n}\n\nfragment LoginTextField_company on Company {\n  preferences {\n    core {\n      login {\n        enableEmail\n        enableTaxID\n        enableEnrollment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd265ceeeb3a7957d2031819b1174b6f";

export default node;
