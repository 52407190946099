import styled from 'styled-components';

import type { CompanyType } from '../../../../redux/modules/login';

const Brand = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  object-fit: contain;
  margin: 20px;
  width: 100%;
  max-width 200px;
  max-height: 150px;
`;

const CompanyName = styled.h1`
  font-weight: bold;
  text-align: center;
  color: #607389;
`;

type CompanyLogoProps = {
  company: CompanyType;
};

const CompanyLogo = ({ company }: CompanyLogoProps) => {
  if (!company?.logo?.url) {
    return <CompanyName>{company.nameFriendly}</CompanyName>;
  }

  return (
    <Brand>
      <Logo src={company.logo.url} />
    </Brand>
  );
};

export default CompanyLogo;
