import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { routeTo } from '../utils';

export const useShouldChangePassword = (me) => {
  const history = useHistory();
  const shouldChangePassword = me ? me.shouldChangePassword : false;

  useEffect(() => {
    if (shouldChangePassword) {
      history.push(routeTo('required.shouldChangePassword'));
    }
  }, [shouldChangePassword]);

  return shouldChangePassword;
};
