import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { PollSelectField_query } from './__generated__/PollSelectField_query.graphql';

type Props = {
  query: PollSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: Poll;
};

type Poll = {
  id: string;
  name: string;
};

type State = {
  input: string;
  search: string;
};

class PollSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Enquete',
    name: 'poll',
  };

  render() {
    const {
      query,
      relay, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    return (
      <ResourceSelectField relay={relay} connection={query.polls} {...props} />
    );
  }
}

const PollSelectFieldFragmentContainer = createRefetchContainer(
  PollSelectField,
  {
    query: graphql`
      fragment PollSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 30 }
        after: { type: String }
        filters: {
          type: "PollFilter"
          defaultValue: { isActive: true, search: "" }
        }
      ) {
        polls(first: $count, after: $after, filters: $filters)
          @connection(key: "PollSelectField_polls", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query PollSelectFieldQuery(
      $count: Int
      $after: String
      $filters: PollFilter
    ) {
      ...PollSelectField_query
        @arguments(count: $count, after: $after, filters: $filters)
    }
  `,
);

export default PollSelectFieldFragmentContainer;
