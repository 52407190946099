import loadable from '@loadable/component';

import { MODULES, ROLES, ADMIN_ROLE } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/company/complaints-external';

const externalComplaintsRoutes: RouteType[] = [
  {
    name: 'complaints.external.view',
    path: `${PATH}/view`,
    label: ({ t }) => t('{{ombudsmanExternal}}'),
    labelHeader: ({ t }) => t('{{ombudsmanExternal}}'),
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/externalAnalytics/ComplaintExternalAnalytics'
        ),
    ),
    exact: false,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
    routes: [
      {
        name: 'complaints.external.view.dashboard',
        path: `${PATH}/view/dashboard`,
        label: ({ t }) => t('Dashboard'),
        labelHeader: ({ t }) => t('Dashboard'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/dashboard/ComplaintExternalDashboard'
            ),
        ),
        exact: true,
        requiredRoles: [
          ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL,
          ROLES.OMBUDSMAN_EXTERNAL.DASHBOARD.ALL,
        ],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.view.list',
        path: `${PATH}/view/list`,
        label: ({ t }) => t('Cases list'),
        labelHeader: ({ t }) => t('Cases list'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/ComplaintExternalList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.view.pivot',
        path: `${PATH}/view/pivot`,
        label: ({ t }) => t('Pivot Table'),
        labelHeader: ({ t }) => t('Pivot Table'),
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/ComplaintExternalPivotTable'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OMBUDSMAN_EXTERNAL.DASHBOARD.ALL,
          ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL,
          ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL,
        ],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
    ],
  },
  {
    name: 'complaints.external.list.details',
    path: `${PATH}/list/:id`,
    labelHeader: 'Detalhes - Caso',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/externalAnalytics/ComplaintExternalView'
        ),
    ),
    exact: false,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
    routes: [
      {
        name: 'complaints.external.list.details.case',
        labelHeader: 'Caso - Detalhes',
        label: 'Caso',
        path: `${PATH}/list/:id/case`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/details/case/ComplaintExternalViewCase'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.list.details.files',
        labelHeader: 'Caso - Detalhes',
        label: 'Anexos',
        path: `${PATH}/list/:id/files`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/details/files/ComplaintExternalFileList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.list.details.events',
        labelHeader: 'Caso - Detalhes',
        label: 'Apuração',
        path: `${PATH}/list/:id/events`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/details/events/ComplaintExternalEventList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.list.details.actions',
        labelHeader: 'Caso - Detalhes',
        label: 'Ações',
        path: `${PATH}/list/:id/actions`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/details/actions/ComplaintExternalActionFlow'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.list.details.return',
        labelHeader: 'Caso - Detalhes',
        label: 'Retorno',
        path: `${PATH}/list/:id/return`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/details/return/ComplaintExternalReturn'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.ANALYTICS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
    ],
  },
  {
    name: 'complaints.external.settings',
    path: `${PATH}/settings`,
    labelHeader: 'Ajustes',
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
    routes: [
      {
        name: 'complaints.external.settings.subjects',
        labelHeader: 'Ajustes - Temas',
        label: 'Temas',
        path: `${PATH}/settings/subjects`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/settings/subjects/ComplaintExternalSubjectList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.settings.actions',
        labelHeader: 'Ajustes - Ações',
        label: 'Ações',
        path: `${PATH}/settings/actions`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/settings/actions/ComplaintExternalActionsList'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.settings.customize',
        labelHeader: 'Ajustes - Customização',
        label: 'Customização',
        path: `${PATH}/settings/customize`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/settings/customize/ComplaintExternalCustomization'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.settings.form',
        labelHeader: 'Ajustes - Formulário',
        label: 'Formulário',
        path: `${PATH}/settings/form`,
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/settings/form/ComplaintExternalForm'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
      {
        name: 'complaints.external.settings.tags',
        path: `${PATH}/settings/tags`,
        labelHeader: 'Tags',
        label: 'Tags',
        component: loadable(
          () =>
            import(
              '../../../components/ombudsman/externalAnalytics/settings/tagModule/TagModuleExternalComplaint'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
        requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
      },
    ],
  },
  {
    name: 'complaints.external.settings.subjects.new',
    path: `${PATH}/settings/subjects/new`,
    labelHeader: 'Criar Tema',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/externalAnalytics/settings/subjects/ComplaintExternalSubjectAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
  },

  {
    name: 'complaints.external.settings.subjects.edit',
    path: `${PATH}/subjects-settings/:id`,
    labelHeader: 'Editar Tema',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/externalAnalytics/settings/subjects/ComplaintExternalSubjectEdit'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
  },

  {
    name: 'complaints.external.settings.actions.new',
    path: `${PATH}/settings/actions/new`,
    labelHeader: 'Criar Ação',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/externalAnalytics/settings/actions/ComplaintExternalActionsAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
  },
  {
    name: 'complaints.external.settings.actions.edit',
    path: `${PATH}/settings/actions/edit/:id`,
    labelHeader: 'Editar Ação',
    component: loadable(
      () =>
        import(
          '../../../components/ombudsman/externalAnalytics/settings/actions/ComplaintExternalActionsEdit'
        ),
    ),
    exact: true,
    requiredRoles: [ROLES.OMBUDSMAN_EXTERNAL.SETTINGS.ALL],
    requiredFeatures: [MODULES.OMBUDSMAN_EXTERNAL],
  },
];

export default externalComplaintsRoutes;
