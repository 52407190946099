/**
 * @generated SignedSource<<c99a12b2b7cbb5bc46e95cdfda153752>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackTopicFilter = {
  AND?: ReadonlyArray<FeedbackTopicFilter | null | undefined> | null | undefined;
  OR?: ReadonlyArray<FeedbackTopicFilter | null | undefined> | null | undefined;
  hasParent?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  modules?: ReadonlyArray<string | null | undefined> | null | undefined;
  onlyForManagers?: boolean | null | undefined;
  parent?: string | null | undefined;
  search?: string | null | undefined;
};
export type FeedbackTopicSelectFieldQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  filters?: FeedbackTopicFilter | null | undefined;
  isDisabled?: boolean | null | undefined;
};
export type FeedbackTopicSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackTopicSelectField_query">;
};
export type FeedbackTopicSelectFieldQuery = {
  response: FeedbackTopicSelectFieldQuery$data;
  variables: FeedbackTopicSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDisabled"
},
v4 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v5 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v6 = {
  "kind": "Variable",
  "name": "isDisabled",
  "variableName": "isDisabled"
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackTopicSelectFieldQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "FeedbackTopicSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FeedbackTopicSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "CompanyFeedbackTopicConnection",
        "kind": "LinkedField",
        "name": "feedbackTopics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyFeedbackTopicEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyFeedbackTopic",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onlyForManagers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modules",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "FeedbackTopicSelectField_feedbackTopics",
        "kind": "LinkedHandle",
        "name": "feedbackTopics"
      }
    ]
  },
  "params": {
    "cacheID": "65ef3ff0c71ec9129d045768ee14d241",
    "id": null,
    "metadata": {},
    "name": "FeedbackTopicSelectFieldQuery",
    "operationKind": "query",
    "text": "query FeedbackTopicSelectFieldQuery(\n  $count: Int\n  $after: String\n  $isDisabled: Boolean\n  $filters: FeedbackTopicFilter\n) {\n  ...FeedbackTopicSelectField_query_4kqX2Y\n}\n\nfragment FeedbackTopicSelectField_query_4kqX2Y on Query {\n  feedbackTopics(first: $count, after: $after, isDisabled: $isDisabled, filters: $filters) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        title\n        description\n        onlyForManagers\n        modules\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ca33d1f496e6d6893254496e9a7c1b2";

export default node;
