// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const Profile = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <path
        fill={color}
        fillRule='nonzero'
        d='M8 3V0L4 4l4 4V5c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46A7.93 7.93 0 0 0 16 11c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L1.24 6.74A7.93 7.93 0 0 0 0 11c0 4.42 3.58 8 8 8v3l4-4-4-4v3z'
      />
    </svg>
  );
};

Profile.defaultProps = {
  color: '#607389',
  viewBox: '0 0 16 22',
  height: 24,
};

export default Profile;
