import _Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import type { DownshiftProps, StateChangeOptions } from 'downshift';
import Downshift from 'downshift';
import MuiDownshift from 'mui-downshift';
import { useState } from 'react';
import styled from 'styled-components';

import type { RelayRefetchProp } from 'react-relay';

import type { Connection } from '../types';

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Chip = styled(_Chip)`
  margin-right: 4px;
  margin-bottom: 4px;
`;

const Input = styled.input`
  display: inline-block;
  flex-grow: 1;
  min-width: 105;
  width: auto;
`;

const MaterialDownshift = styled(MuiDownshift)``;
// margin-top: 10px;
// margin-bottom: 5px;

const CustomInput = ({ selectedItems, removeItem, ...props }) => {
  return (
    <InputContainer>
      {selectedItems &&
        selectedItems.map((item) => (
          <Chip
            label={item.text}
            key={item.value}
            onDelete={() => removeItem(item)}
          />
        ))}
      <Input {...props} />
    </InputContainer>
  );
};

const EmptyWrapper = styled.span`
  font-style: italic;
  font-size: 13px;
  color: #607389;
`;

type Item = {
  label: string;
  value: string;
};

// TODO - improve this based on DownshiftProps
type Props = {
  items: Item[];
  onInputChange: (name: string, value: string) => void;
  getStringFromItem?: (item: Item) => string;
  // getListItem: ({ getListItem: () => void, item: Item }) => any,
  emptyMessage?: string;
  getSuggestionLabel?: (item: Item) => {
    primary: string | any;
    secondary?: string | any;
  };
  multiple?: boolean;
  selectedItems: Item[];
  name: string;
  initialValue?: Item;
  label: string;
  fullWidth?: boolean;
  getFragmentVariables: () => Record<string, any>;
  connection: Connection<any>;
  relay: RelayRefetchProp;
  disabled?: boolean;
  windowHeight: number;
  withInfiniteScroll: boolean;
  getFilterFragmentVariables: () => void;
  resetInputOnSelection?: boolean;
} & DownshiftProps<Item>;
const AutoComplete = (props: Props) => {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    onChange = () => null,
    getStringFromItem = (item: Item) => (item ? item.label : ''),
    selectedItems = [],
    onInputChange = () => null,
    onSelect = () => null,
    emptyMessage = 'Nenhum item encontrado',
    getSuggestionLabel = (item: Item) => item && { primary: item.label || '' },
    name,
    multiple,
    disabled,
    onStateChange,
    getInfiniteLoaderProps,
    inputValue,
    fullWidth,
    initialValue,
    items,
    label,
    resetInputOnSelection,
    errorProps,
  } = props;

  const getMenuHeight = () => {
    const { windowHeight, items } = props;
    const HEIGHTS = {
      350: 150,
      450: 200,
      600: 250,
      800: 300,
    };

    const maxHeight = Object.entries(HEIGHTS).reduce((acc, [key, value]) => {
      return windowHeight > key ? value : acc;
    }, 150);

    // TODO - check how to handle non 48 stardand row height
    // https://github.com/techniq/mui-downshift/blob/master/src/Menu.js#L136
    const maxHeightItems = items.length * 48;

    return Math.min(maxHeight, maxHeightItems);
  };

  const handleChange = (value: Record<string, any>) => {
    if (!onChange) {
      return;
    }

    if (multiple) {
      if (!value) {
        return onChange(name, []);
      }

      if (selectedItems.includes(value)) {
        return onChange(
          name,
          selectedItems.map((i) => i !== value),
        );
      }

      return onChange(name, [...selectedItems, value]);
    }

    if (!value) {
      return onChange(name, {});
    }

    return onChange(name, value);
  };

  const itemToString = (item) => getStringFromItem(item);

  const suggestionLabel = (suggestion) => getSuggestionLabel(suggestion);

  const getListItem = ({ getItemProps, item }) => {
    return item ? (
      <ListItem button {...getItemProps({ disabled })} style={{ zIndex: 9999 }}>
        <ListItemText {...suggestionLabel(item)} />
      </ListItem>
    ) : (
      <ListItem button disabled>
        <ListItemText primary={<EmptyWrapper>{emptyMessage}</EmptyWrapper>} />
      </ListItem>
    );
  };

  const handleInputValueChange = (inputValue: string) => {
    return onInputChange(name, inputValue);
  };

  const handleStateChange = (changes: StateChangeOptions) => {
    const { inputValue, type } = changes;

    if (type === Downshift.stateChangeTypes.changeInput) {
      // __autocomplete_change_input__
      handleInputValueChange(inputValue);
    }

    if (type === Downshift.stateChangeTypes.clickItem) {
      // __autocomplete_click_item__
      const { selectedItem } = changes;

      handleInputValueChange(selectedItem.name);
    }

    // TODO - if you pass `selectedItem` it will be a controlled prop
    // if (type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem) {
    //   // __autocomplete_controlled_prop_updated_selected_item__
    //   handleInputValueChange(inputValue);
    // }

    onStateChange && onStateChange(changes);
  };

  const handleSelect = (item) => {
    if (item) {
      return onSelect(item);
    }
  };

  const handleRemove = (item) => {
    return onChange
      ? onChange(
          name,
          selectedItems.filter((i) => i !== item),
        )
      : null;
  };

  const getIsLoading = () => {
    if (props.isLoading == null) {
      return isLoading;
    }

    return props.isLoading;
  };

  // TODO - remove this in a later refactor
  const getRestProps = () => {
    const {
      name,
      label,
      items,
      initialValue,
      multiple,
      selectedItems,
      inputValue,
      getInfiniteLoaderProps,
      // to not pass to downshift
      onChange,
      isOpen,
      disabled,
      onStateChange, // get more control over downshift
      resetInputOnSelection,
      environment, // this environment breaks downshift
      ...rest
    } = props;

    return rest;
  };

  const rest = getRestProps();

  const commonProps = {
    items,
    onChange: handleChange,
    onStateChange: handleStateChange,
    loading: getIsLoading(),
    itemToString,
    getListItem,
    getInfiniteLoaderProps: getInfiniteLoaderProps
      ? getInfiniteLoaderProps
      : undefined,
    showEmpty: true,
    fullWidth,
    variant: 'outlined',
    margin: 'normal',
    ...rest,
  };

  if (multiple) {
    return (
      <MaterialDownshift
        inputValue={inputValue}
        getInputProps={() => ({
          autoComplete: 'off',
          label,
          inputComponent: CustomInput,
          ...errorProps,
          inputProps: {
            selectedItems,
            removeItem: handleRemove,
          },
          disabled,
        })}
        breakingChanges={{
          resetInputOnSelection: resetInputOnSelection
            ? resetInputOnSelection
            : multiple,
        }}
        {...commonProps}
      />
    );
  }

  return (
    <MaterialDownshift
      inputValue={inputValue}
      initialValue={initialValue}
      onSelect={handleSelect}
      menuHeight={getMenuHeight()}
      getInputProps={() => {
        const inputTestId = props.name
          ? `downshift-input-${props.name}`
          : `downshift-input`;

        return {
          autoComplete: 'off',
          label,
          name,
          disabled,
          ...errorProps,
          inputProps: {
            'data-testid': inputTestId,
          },
        };
      }}
      breakingChanges={{
        resetInputOnSelection: resetInputOnSelection
          ? resetInputOnSelection
          : multiple,
      }}
      {...commonProps}
    />
  );
};

export default AutoComplete;
