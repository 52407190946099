import PropTypes from 'prop-types';

const Ombudsman = ({ width, color }) => {
  const styles = {
    fill: 'none',
    stroke: color || '#757575',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
  };

  return (
    <svg viewBox='0 0 40 25' width={width}>
      <g>
        <polygon style={styles} points='31,7.7 19.7,13.1 8.3,7.7 19.7,1.6 	' />
        <polyline
          style={styles}
          points='27.4,9.4 31,11.1 19.7,16.8 8.3,11.1 11.9,9.4 	'
        />
        <polyline
          style={styles}
          points='27.4,12.8 31,14.5 19.7,20.4 8.3,14.5 11.3,13.1 	'
        />
        <polyline
          style={styles}
          points='27.7,16.2 31,18 19.7,24.1 8.3,18 11.6,16.2 	'
        />
      </g>
    </svg>
  );
};

Ombudsman.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default Ombudsman;
