import type { TextFieldProps } from './TextField';
import TextField from './TextField';
import type { TextFieldFormikProps } from './TextFieldFormik';
import TextFieldFormik from './TextFieldFormik';

type Props = {
  useFormik?: boolean | null;
} & (TextFieldProps | TextFieldFormikProps);

// TODO We should deprecate this
export default ({ useFormik = true, ...props }: Props) =>
  useFormik ? <TextFieldFormik {...props} /> : <TextField {...props} />;
