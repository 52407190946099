import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring';

const candidateRoutes: RouteType[] = [
  {
    name: 'candidates',
    path: `${PATH}/candidates`,
    component: loadable(
      () => import('../../../components/candidate/CandidateScreenHeader'),
    ),
    exact: false,
    labelHeader: 'Banco de Candidatos',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.CANDIDATE.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'candidates.list',
        path: `${PATH}/candidates/list`,
        component: loadable(
          () => import('../../../components/candidate/CandidateList'),
        ),
        exact: false,
        labelHeader: ({ t }) => t('Hiring'),
        label: ({ t }) => t('Candidates'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'candidates.list.referral',
        path: `${PATH}/candidates/referral`,
        component: loadable(
          () =>
            import(
              '../../../components/hiringReferral/components/HiringReferralList'
            ),
        ),
        exact: true,
        label: ({ t }) => t('Referred Candidates'),
        labelHeader: ({ t }) => t('Referred Candidates'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.REFERRAL.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'candidates.maps',
        path: `${PATH}/candidates/maps`,
        component: loadable(
          () => import('../../../components/candidate/CandidatesMap'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Hiring'),
        label: ({ t }) => t('Map'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'candidates.tags',
        path: `${PATH}/candidates/tags`,
        component: loadable(
          () => import('../../../components/candidateTag/CandidateTagList'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Hiring'),
        label: 'Tags',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'candidates.dashboard',
        path: `${PATH}/candidates/dashboard`,
        component: loadable(
          () =>
            import(
              '../../../components/candidate/components/CandidateDashboard'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Hiring'),
        label: 'Dashboard',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'candidates.upload',
        path: `${PATH}/candidates/upload`,
        component: loadable(
          () =>
            import(
              '../../../components/user/import/importOld/CompanyImportCandidatesData'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Hiring'),
        label: ({ t }) => t('Import'),
        requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.IMPORT.ALL],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'candidate.referral',
    labelHeader: ({ t }) => t('Hiring'),
    label: ({ t }) => t('Candidate Referral'),
    path: `${PATH}/candidate/referral/add`,
    component: loadable(
      () =>
        import(
          '../../../components/hiringReferral/components/NewHiringReferral'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.REFERRAL.ALL,
      ROLES.HIRING.MY_REFERRAL.ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'candidates.view',
    path: `${PATH}/candidate/view/:id/tabs`,
    component: loadable(
      () => import('../../../components/candidate/detail/Candidate'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.CANDIDATE.ALL],
    requiredFeatures: [MODULES.HIRING],
    labelHeader: ({ t }) => t('Candidate'),
    routes: [
      // Used for tabbed routes
      {
        name: 'candidates.view.positionApplications',
        path: `${PATH}/candidate/view/:id/tabs/positionApplications`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        labelHeader: ({ t }) => t('Candidate'),
        label: ({ t }) => t('Position Applications'),
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/applications/PositionApplications'
            ),
        ),
      },
      {
        name: 'candidates.view.candidateApplications',
        path: `${PATH}/candidate/view/:id/tabs/candidateApplications`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        labelHeader: ({ t }) => t('Candidate'),
        label: ({ t }) => t('Candidate Applications'),
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/applications/CandidateApplications'
            ),
        ),
      },
      {
        name: 'candidates.view.interviews',
        path: `${PATH}/candidate/view/:id/tabs/interviews`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: false,
        label: ({ t }) => t('Interviews'),
        labelHeader: ({ t }) => t('Candidate'),
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/interviews/Interviews'
            ),
        ),
      },
      {
        name: 'candidates.view.exams',
        path: `${PATH}/candidate/view/:id/tabs/exams`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        label: ({ t }) => t('Exams'),
        labelHeader: ({ t }) => t('Candidate'),
        component: loadable(
          () =>
            import('../../../components/candidate/detail/jobExams/JobExams'),
        ),
      },
      {
        name: 'candidates.view.info',
        path: `${PATH}/candidate/view/:id/tabs/info`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: false,
        label: ({ t }) => t('Datas'),
        labelHeader: ({ t }) => t('Candidate'),
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/candidateInfo/CandidateInfo'
            ),
        ),
        modalRoutes: [
          {
            name: 'candidates.view.info.dependents.add',
            path: `${PATH}/candidate/view/:id/tabs/info/dependents/add`,
            component: loadable(
              () =>
                import(
                  '../../../components/candidate/detail/candidateInfo/DependentDetails'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.HIRING.ALL,
              ROLES.HIRING.CANDIDATE.ALL,
            ],
            requiredFeatures: [MODULES.HIRING],
            labelHeader: ({ t }) => t('Candidate'),
            modalTitle: 'Cadastro de Dependentes',
          },
          {
            name: 'candidates.info.dependents.view',
            path: `${PATH}/candidate/view/:id/tabs/info/dependents/view/:dependentId`,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.HIRING.ALL,
              ROLES.HIRING.CANDIDATE.ALL,
            ],
            requiredFeatures: [MODULES.HIRING],
            exact: true,
            component: loadable(
              () =>
                import(
                  '../../../components/candidate/detail/candidateInfo/DependentDetails'
                ),
            ),
            labelHeader: ({ t }) => t('Candidate'),
            modalTitle: 'Edição de Dependente',
          },
        ],
      },
      {
        name: 'candidates.view.allInfo',
        path: `${PATH}/candidate/view/:id/tabs/allInfo/:jobRequestId?`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: false,
        label: ({ t }) => t('All Datas'),
        labelHeader: ({ t }) => t('Hiring'),
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/candidateInfo/CandidateAllInfo'
            ),
        ),
      },
      {
        name: 'candidates.view.documents',
        path: `${PATH}/candidate/view/:id/tabs/documents`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        label: ({ t }) => t('Documents'),
        labelHeader: ({ t }) => t('Hiring'),
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/documents/CandidateDocuments'
            ),
        ),
      },
      {
        name: 'candidates.view.messages',
        path: `${PATH}/candidate/view/:id/tabs/messages`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        label: ({ t }) => t('Messages'),
        labelHeader: ({ t }) => t('Hiring'),
        component: loadable(
          () =>
            import('../../../components/candidate/detail/CandidateMessages'),
        ),
      },
      {
        name: 'candidates.view.jobExperiences',
        path: `${PATH}/candidate/view/:id/tabs/jobExperiences`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        exact: true,
        labelHeader: ({ t }) => t('Candidate'),
        label: 'Experiência Profissional',
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/jobExperiences/JobExperiences'
            ),
        ),
      },
      {
        name: 'candidates.view.exams.candidateAnswers',
        path: `${PATH}/candidate/view/:id/tabs/exams/candidateAnswers/:applicationId`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: ({ t }) => t('Hiring'),
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/jobExams/JobExamCandidateAnswers'
            ),
        ),
      },
      {
        name: 'candidates.view.documents.view',
        path: `${PATH}/candidate/view/:id/tabs/documents/:code`,
        component: loadable(
          () =>
            import(
              '../../../components/candidate/detail/documents/DocumentDetails'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Hiring'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.CANDIDATE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'candidate.evaluation.edit',
    path: `${PATH}/candidate/evaluation/:id/edit`,
    component: loadable(
      () =>
        import('../../../components/candidate/detail/interviews/InterviewEdit'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Hiring'),
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.CANDIDATE.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
];

export default candidateRoutes;
