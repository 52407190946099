import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

import { routeTo } from '../../../router/utils';

export const group = ({ t }) => {
  return [
    {
      label: t('Groups'),
      icon: <GroupOutlinedIcon />,
      link: routeTo('feedbacks.groups'),
    },
  ];
};

export const groupAdmin = ({ t }) => {
  return [
    {
      label: t('Groups'),
      icon: <GroupOutlinedIcon />,
      items: [
        {
          label: t('Dashboard'),
          link: routeTo('feedback.groups.admin.dashboard'),
        },
        {
          label: t('Groups'),
          link: routeTo('feedback.groups.admin'),
        },
        {
          label: t('all_feedbacks'),
          link: routeTo('feedback.groups.admin.list'),
        },
        {
          label: t('all_external_feedbacks'),
          link: routeTo('feedback.groups.admin.external'),
        },
        {
          label: t('Settings'),
          link: routeTo('feedback.groups.admin.settings'),
        },
      ],
    },
  ];
};
