/**
 * @generated SignedSource<<ab5884f11d03499e1cf8e358a8e627fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserAvatar_user$data = {
  readonly name: string | null | undefined;
  readonly profileImage: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "UserAvatar_user";
};
export type UserAvatar_user$key = {
  readonly " $data"?: UserAvatar_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "height"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "width"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserAvatar_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "profileImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "height",
              "variableName": "height"
            },
            {
              "kind": "Variable",
              "name": "width",
              "variableName": "width"
            }
          ],
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8d8a19e31da7985cb97c9dbc1ed71850";

export default node;
