import { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { ActionButton } from '@feedback/ui';

import { getNamePlaceholder } from '../utils';
import { CandidateSelectField } from '../selects';

import type { DialogAddCandidates_query } from './__generated__/DialogAddCandidates_query.graphql';

const StyledChip = styled(Chip)`
  margin-right: 10px;
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shouldAddCandidate?: (candidate: any) => boolean;
  addCandidates: () => void;
  query: DialogAddCandidates_query;
};
class DialogAddCandidates extends Component<Props> {
  state = {
    toAddCandidates: [],
  };

  static defaultProps = {
    addCandidates: () => {},
    shouldAddCandidate: () => true,
    onClose: () => {},
  };

  onDeleteToAddCandidate = (candidate) => {
    const { toAddCandidates } = this.state;
    const chipToDelete = toAddCandidates.indexOf(candidate);

    toAddCandidates.splice(chipToDelete, 1);
    this.setState({ toAddCandidates });
  };

  onAdd = () => {
    this.props.addCandidates(this.state.toAddCandidates);

    this.setState({
      toAddCandidates: [],
    });
  };

  onCloseDialog = () => {
    const { onClose } = this.props;

    this.setState({
      toAddCandidates: [],
    });

    onClose && onClose();
  };

  setToAddCandidate = (candidate) => {
    if (Object.keys(candidate).length === 0) {
      return;
    }

    const { toAddCandidates } = this.state;
    const index = toAddCandidates.findIndex(({ id }) => id === candidate.id);

    if (!this.props.shouldAddCandidate) {
      this.setState({ toAddCandidates: [...toAddCandidates, candidate] });

      return;
    }

    if (index === -1 && this.props.shouldAddCandidate(candidate)) {
      this.setState({ toAddCandidates: [...toAddCandidates, candidate] });
    }
  };

  render() {
    const { query, isOpen } = this.props;
    const { toAddCandidates } = this.state;

    return (
      <Dialog
        // fullScreen={fullScreen}
        open={isOpen}
        onClose={this.onCloseDialog}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          Adicionar candidatos
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              'Selecione quantos candidatos quiser e clique em "Adicionar" para incluí-los todos ao mesmo tempo'
            }
          </DialogContentText>
          <CandidateSelectField
            floatingLabelText={null}
            query={query}
            displayUnderline={false}
            onChange={this.setToAddCandidate}
          />
          <div>
            {toAddCandidates.map((candidate, i) => {
              return (
                <StyledChip
                  key={i}
                  onDelete={() => this.onDeleteToAddCandidate(candidate)}
                  label={candidate.name}
                  avatar={
                    <Avatar
                      width={50}
                      alt={candidate.name || ''}
                      src={
                        candidate?.profileImage ||
                        getNamePlaceholder(candidate.name)
                      }
                    />
                  }
                  variant='outlined'
                />
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <ActionButton onClick={this.onCloseDialog} color='secondary'>
            Cancelar
          </ActionButton>
          <ActionButton color='primary' onClick={this.onAdd}>
            Adicionar
          </ActionButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default createFragmentContainer(DialogAddCandidates, {
  query: graphql`
    fragment DialogAddCandidates_query on Query {
      ...CandidateSelectField_query
    }
  `,
});
