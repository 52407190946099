import PropTypes from 'prop-types';
import styled from 'styled-components';

const CardFooter = styled.div`
  margin-top: 40px;
  margin-right: -40px;
  margin-bottom: -40px;
  margin-left: -40px;
  display: flex;
  justify-content: flex-end;
  padding: 15px 40px;
  border-top: 1px solid #eee;
`;

CardFooter.propTypes = {
  children: PropTypes.node,
};

export default CardFooter;
