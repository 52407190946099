import { Card, ActionButton, CardActions } from '@feedback/ui';
import { Flex, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

const DomainNotFound = ({ domainName }) => {
  const { t } = useTranslation();

  return (
    <Flex flex={1} alignItems='center' justifyContent='center' height='90vh'>
      <Card
        minWidth='450px'
        alignItems='center'
        display='flex'
        flexDirection='column'
        p='20px'
      >
        <Text fontSize={23} mt={10}>
          {t('Ops team {{domainName}}', { domainName })}
        </Text>
        <Text mb={10}>{t('was not found')}</Text>
        <CardActions alignItems='center'>
          <ActionButton link='https://feedback.house' external={true}>
            {t('Create your team')}
          </ActionButton>
        </CardActions>
      </Card>
    </Flex>
  );
};

export default DomainNotFound;
