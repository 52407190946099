import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring';

const landingPageRoutes: RouteType[] = [
  {
    name: 'hiring.landingPage',
    path: `${PATH}/landingPages`,
    labelHeader: 'Página Trabalhe Conosco',
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.LANDING_PAGE.ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'hiring.landingPage.list',
        path: `${PATH}/landingPages/all`,
        label: 'Links',
        labelHeader: 'Página Trabalhe Conosco',
        component: loadable(
          () =>
            import('../../../components/hiring/landingPage/LandingPageList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.LANDING_PAGE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'hiring.images',
        path: `${PATH}/landingPages/images`,
        label: 'Banner',
        labelHeader: 'Página Trabalhe Conosco',
        component: loadable(
          () => import('../../../components/hiringBanner/HiringEditImage'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.LANDING_PAGE.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'hiring.landingPage.detail',
    path: `${PATH}/landingPage/detail/:id`,
    labelHeader: 'Página Trabalhe Conosco - Detalhes',
    component: loadable(
      () => import('../../../components/hiring/landingPage/LandingPageDetail'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.LANDING_PAGE.ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'hiring.landingPage.add',
    path: `${PATH}/landingPage/add`,
    labelHeader: 'Página Trabalhe Conosco - Adicionar',
    component: loadable(
      () => import('../../../components/hiring/landingPage/LandingPageAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.LANDING_PAGE.ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'hiring.landingPage.edit',
    path: `${PATH}/landingPage/edit/:id`,
    labelHeader: 'Página Trabalhe Conosco - Editar',
    component: loadable(
      () => import('../../../components/hiring/landingPage/LandingPageEdit'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.LANDING_PAGE.ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
  },
];

export default landingPageRoutes;
