import type { UploadParams, UploadResponse } from './types';
import {
  FILE_TYPES,
  findHeaders,
  generateHeader,
  generatePreSignedFileUpload,
} from './helper';

// TODO: add mutation inside package, useUpload hook
export const upload = async ({
  fileType,
  headerType,
  mutation,
  file,
}: UploadParams): Promise<UploadResponse> => {
  return new Promise<UploadResponse>((resolve) => {
    let blob = file || null;

    // file can be related to a id
    if (!blob) {
      const control = document.getElementById('file');
      const files = control && control.files;

      blob = files && files[0];
    }

    if (!blob) {
      resolve({ error: 'Invalid file' });
    }

    const fileReader = new FileReader();

    fileReader.onloadend = async (element) => {
      const header = generateHeader(element?.target?.result);
      // eslint-disable-next-line
      console.log('header', header);

      if (!Object.keys(FILE_TYPES).includes(headerType)) {
        resolve({ error: 'Invalid header type' });
      }

      const headers = findHeaders(headerType);

      if (headers.indexOf(header) > -1) {
        resolve(generatePreSignedFileUpload(blob, fileType, mutation));
      } else {
        resolve({ error: 'Tipo de arquivo não suportado' });
      }
    };

    blob && fileReader.readAsArrayBuffer(blob);
  });
};

export default upload;
