import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import UserAvatar from './UserAvatar';

import type { UserAvatarWithScore_user } from './__generated__/UserAvatarWithScore_user.graphql';

const Name = styled.div`
  font-size: 16px;
  color: #607389;
  font-weight: 600;
`;

const Title = styled.div`
  font-size: 14px;
  color: #607389;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 30px;
  height: 30px;
  border-radius: 3.3px;
  background-color: #607389;
  color: #fff;
  margin-top: 5px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type Props = {
  user: UserAvatarWithScore_user;
};

class UserAvatarWithScore extends Component<Props> {
  render() {
    const { user, size, withName } = this.props;
    const score = user?.meetingRatingsAverage || '-';

    return (
      <Wrapper>
        <Column>
          <UserAvatar size={size} user={user} />
          <Badge>{score}</Badge>
        </Column>
        {withName && (
          <NameWrapper>
            <Name> {user.name} </Name>
            <Title> {user.jobTitle} </Title>
          </NameWrapper>
        )}
      </Wrapper>
    );
  }
}

export default createFragmentContainer(UserAvatarWithScore, {
  user: graphql`
    fragment UserAvatarWithScore_user on User {
      ...UserAvatar_user
      name
      jobTitle
      meetingRatingsAverage
    }
  `,
});
