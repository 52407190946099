import styled from 'styled-components';

export const FormCommon = styled.div`
  background-color: '#FFFFFF';
  box-shadow: rgba(0, 0, 0, 0.056863) 0px 7px 8px,
    rgba(0, 0, 0, 0.227451) 0px 0px 0px;
  border-width: 1;
  border-style: 'solid';
  border-color: '#E7ECEA';
  padding: 20;
  padding-top: 50;
`;

export const FormCommonContainer = styled.div`
  display: 'flex';
  flex-wrap: 'wrap';
`;

export const FormCommonActions = styled.div`
  display: 'flex';
  justify-content: 'flex-end';
  margin-top: 5;
  padding-right: 8;
  border-top-style: 'solid';
  border-top-width: 1;
  padding-top: 15;
  border-color: '#ECECEC';
`;
