import type { ComponentType } from 'react';
import { useEffect } from 'react';

import { createFragmentContainer, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';

import { MODULES } from '@feedback/roles';

import { createQueryRendererModern } from '../../relay/index';
import { routeTo } from '../utils';

import { usePermission } from '../../security/usePermission';

import { getLogidRouteCallback } from '../../components/auth/login/LoginForm';
import { getToken } from '../../security/auth';

import type { RediredictIfAuthenticatedMiddleware_query$data } from './__generated__/RediredictIfAuthenticatedMiddleware_query.graphql';

type Props = {
  query: RediredictIfAuthenticatedMiddleware_query$data;
};
const redirectIfAuthenticatedMiddleware = (
  Component: ComponentType<any>,
): ComponentType<any> => {
  const RediredictIfAuthenticatedMiddleware = (props: Props) => {
    const { query } = props;
    const history = useHistory();

    const { hasFeature } = usePermission(query.me);

    const redirectIfAuthenticated = () => {
      if (query.me) {
        if (hasFeature([MODULES.LOGID])) {
          const callbackUrl = getLogidRouteCallback();

          if (callbackUrl) {
            const token = getToken();

            const logidCallbackUrl = `${callbackUrl}?token=${token}`;

            return (window.location.href = logidCallbackUrl);
          }
        }

        history.push(routeTo('home'));
      }
    };

    useEffect(() => {
      redirectIfAuthenticated();
    });

    if (query.me) {
      return null;
    }

    return <Component {...props} />;
  };

  const RediredictIfAuthenticatedMiddlewareFragmentContainer =
    createFragmentContainer(RediredictIfAuthenticatedMiddleware, {
      query: graphql`
        fragment RediredictIfAuthenticatedMiddleware_query on Query {
          me {
            id
            ...usePermissionFragment
          }
        }
      `,
    });

  return createQueryRendererModern(
    RediredictIfAuthenticatedMiddlewareFragmentContainer,
    {
      query: graphql`
        query RediredictIfAuthenticatedMiddlewareQuery {
          ...RediredictIfAuthenticatedMiddleware_query
        }
      `,
      loadingView: null,
    },
  );
};

export default redirectIfAuthenticatedMiddleware;
