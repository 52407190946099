type Props = {
  width: number;
  height?: number;
};

const FeedbackLogo = ({ width, height }: Props) => {
  return (
    <svg width={width} height={height} viewBox='0 0 300 297'>
      <defs>
        <linearGradient
          x1='86.7845118%'
          y1='93.4685396%'
          x2='10.0405093%'
          y2='5.5792298%'
          id='linearGradient-1'
        >
          <stop stopColor='#19BAE5' offset='0%' />
          <stop stopColor='#236AAD' offset='100%' />
        </linearGradient>
      </defs>
      <g
        id='logo-feedback-icons'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M118.609642,4.62691168e-15 L178.390358,0 C219.633514,-7.57624497e-15 234.589279,4.29426893 249.667161,12.3580083 C264.745043,20.4217476 276.578252,32.2549573 284.641992,47.3328389 C292.705731,62.4107206 297,77.3664858 297,118.609642 L297,178.390358 C297,219.633514 292.705731,234.589279 284.641992,249.667161 C276.578252,264.745043 264.745043,276.578252 249.667161,284.641992 C234.589279,292.705731 219.633514,297 178.390358,297 L0,297 L3.19464768e-15,118.609642 C-1.8561823e-15,77.3664858 4.29426893,62.4107206 12.3580083,47.3328389 C20.4217476,32.2549573 32.2549573,20.4217476 47.3328389,12.3580083 C62.4107206,4.29426893 77.3664858,1.22031567e-14 118.609642,4.62691168e-15 Z'
          id='Rectangle-2-Copy-2'
          fill='url(#linearGradient-1)'
          fillRule='nonzero'
        />
        <path
          d='M296,214.941068 C293.940168,229.599627 290.413737,239.673208 285.532675,248.790627 C277.311166,264.147729 265.246434,276.200071 249.873547,284.41314 C234.500661,292.626207 219.25228,297 177.202183,297 L166.159344,297 L83,220.52741 L84.0010279,168.976017 L84.0010279,144.548889 L111.47849,114.522831 C107.537752,115.973581 110.087532,112.84204 119.127833,105.12821 C128.168134,97.4143795 132.688285,93.5574641 132.688285,93.5574641 L145.524472,77 L234.44738,155.830226 L296,214.941068 Z'
          id='Combined-Shape-Copy'
          fillOpacity='0.648352582'
          fill='#1184BA'
          fillRule='nonzero'
        />
        <g
          id='web-page-home-(1)-copy'
          transform='translate(64.000000, 73.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <path
            d='M5.80320154,89.9204612 L19.4874359,89.9204612 L19.4874359,145.089088 C19.4874359,148.34009 22.1265161,151 25.3520586,151 L65.4270947,151 C68.6526371,151 71.2917173,148.34009 71.2917173,145.089088 L71.2917173,104.697741 L95.7275298,104.697741 L95.7275298,145.089088 C95.7275298,148.34009 98.36661,151 101.592152,151 L139.712199,151 C142.937742,151 145.576822,148.34009 145.576822,145.089088 L145.576822,89.9204612 L162.193368,89.9204612 C164.539217,89.9204612 166.689463,88.4427332 167.569157,86.2755148 C168.44885,84.1082963 167.960017,81.5467852 166.298604,79.871911 L89.8629072,1.74947468 C87.6146869,-0.516490784 83.9006559,-0.614890082 81.6524356,1.65107538 L1.79593941,79.7735117 C0.0365526293,81.4483859 -0.452280914,84.0095493 0.427412477,86.2755148 C1.30710587,88.5411325 3.4573525,89.9204612 5.80320154,89.9204612 Z M85.562069,14.260789 L148.117928,78.0986374 L139.711854,78.0986374 C136.486312,78.0986374 133.847232,80.7585478 133.847232,84.0095493 L133.847232,139.178176 L107.45643,139.178176 L107.45643,98.786829 C107.45643,95.5358275 104.81735,92.8759171 101.591807,92.8759171 L65.4270947,92.8759171 C62.2015523,92.8759171 59.5624721,95.5358275 59.5624721,98.786829 L59.5624721,139.178176 L31.2166812,139.178176 L31.2166812,84.0095493 C31.2166812,80.7585478 28.577601,78.0986374 25.3520586,78.0986374 L20.2695006,78.0986374 L85.562069,14.260789 Z'
            id='Shape'
          />
        </g>
      </g>
    </svg>
  );
};

FeedbackLogo.defaultProps = {
  height: 30,
  width: 30,
};

export default FeedbackLogo;
