/**
 * @generated SignedSource<<5e443b7903284115a221683e35c636b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobExamQuestionSelectFieldRefetchQuery$variables = {
  jobExamId?: string | null | undefined;
};
export type JobExamQuestionSelectFieldRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobExamQuestionSelectField_query">;
};
export type JobExamQuestionSelectFieldRefetchQuery = {
  response: JobExamQuestionSelectFieldRefetchQuery$data;
  variables: JobExamQuestionSelectFieldRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobExamId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobExamQuestionSelectFieldRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "jobExamId",
            "variableName": "jobExamId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "JobExamQuestionSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobExamQuestionSelectFieldRefetchQuery",
    "selections": [
      {
        "alias": "jobExam",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "jobExamId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionWeight",
                "kind": "LinkedField",
                "name": "questionsWeight",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobExamQuestion",
                    "kind": "LinkedField",
                    "name": "question",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "JobExam",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b23aaad94f5da05b4a7164e22860202",
    "id": null,
    "metadata": {},
    "name": "JobExamQuestionSelectFieldRefetchQuery",
    "operationKind": "query",
    "text": "query JobExamQuestionSelectFieldRefetchQuery(\n  $jobExamId: ID\n) {\n  ...JobExamQuestionSelectField_query_1b2Sey\n}\n\nfragment JobExamQuestionSelectField_query_1b2Sey on Query {\n  jobExam: node(id: $jobExamId) {\n    __typename\n    id\n    ... on JobExam {\n      questionsWeight {\n        question {\n          id\n          title\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a02082b4f00b4a56d24b5a22a46eb8e1";

export default node;
