import moment from 'moment';
import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { DayJourneySelectField_query } from './__generated__/DayJourneySelectField_query.graphql';

type Props = {
  query: DayJourneySelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: DayJourney;
};

type DayJourney = {
  id: string;
  name: string;
};

type State = {
  input: string;
};

class DayJourneySelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Dia de Jornada',
    name: 'dayJourney',
  };

  getItemFromNode = (node) => ({
    id: node.id,
    name: node.inHour
      ? `${moment(node.inHour).format('HH:mm')}h - ${moment(node.out).format(
          'HH:mm',
        )}h`
      : node.name,
  });

  render() {
    const { query, relay, ...props } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={query.dayJourneys}
        getItemFromNode={this.getItemFromNode}
        {...props}
      />
    );
  }
}

const DayJourneySelectFieldFragmentContainer = createRefetchContainer(
  DayJourneySelectField,
  {
    query: graphql`
      fragment DayJourneySelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 300 }
        after: { type: String }
      ) {
        dayJourneys(first: $count, after: $after)
          @connection(key: "DayJourneySelectField_dayJourneys", filters: []) {
          count

          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              inHour: in
              out
            }
          }
        }
      }
    `,
  },
  graphql`
    query DayJourneySelectFieldQuery($count: Int, $after: String) {
      ...DayJourneySelectField_query @arguments(count: $count, after: $after)
    }
  `,
);

export default DayJourneySelectFieldFragmentContainer;
