import PropTypes from 'prop-types';

import Icon from './logo/Icon';
import Text from './logo/Text';

const Logo = ({ width, color }) => {
  const iconWidth = width * 0.25;
  const margin = width * 0.05;
  const textWidth = width - iconWidth - margin;

  return (
    <div style={styles.wrapper}>
      <Icon color={color} width={iconWidth} />

      <div style={{ marginLeft: margin }}>
        <Text color={color} width={textWidth} />
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
};

Logo.defaultProps = {
  width: 150,
  color: '#FFFFFF',
};

Logo.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default Logo;
