import Icon from '@material-ui/icons/ChevronRight';

import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { routeTo } from '../../../../router/utils/index';

import PermissionFlag from './PermissionFlag';

import type { HomePermissionLink_user } from './__generated__/HomePermissionLink_user.graphql';

const normalLineHeightMixin = css`
  line-height: normal;
`;

const ChevronRight = styled(Icon)`
  && {
    transition: transform 0.2s ease;
    color: ${(props) => props.theme.button.backgroundColor};
    font-size: 34px;
  }
`;

const LinkHighlight = styled.span`
  color: ${(props) => props.theme.highlightColor};
  font-weight: 600;
  margin-right: 6px;
`;

const StyledLink = styled(({ noLineHeight, ...props }) => <Link {...props} />)`
  display: flex;
  align-items: center;
  white-space: pre;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.primaryDarkerColor};
  ${(props) => (props.noLineHeight ? normalLineHeightMixin : '')} &:hover {
    > ${ChevronRight} {
      transform: translateX(5px);
    }
  }
`;

const InfoLinkItem = styled.li`
  margin: 0;
`;

type Props = {
  route: string;
  highlightText: string;
  children: React.ReactNode;
  user: HomePermissionLink_user;
};

const HomePermissionLink = ({
  route,
  user,
  highlightText,
  children,
}: Props) => {
  return (
    <PermissionFlag routes={[route]} user={user}>
      <InfoLinkItem>
        <StyledLink to={routeTo(route)}>
          <LinkHighlight>{highlightText}</LinkHighlight>
          {children} <ChevronRight />
        </StyledLink>
      </InfoLinkItem>
    </PermissionFlag>
  );
};

export default createFragmentContainer(HomePermissionLink, {
  user: graphql`
    fragment HomePermissionLink_user on User {
      ...PermissionFlag_user
    }
  `,
});
