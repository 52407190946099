import Icon from '@material-ui/core/Icon';
import _Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import debounce from 'lodash/debounce';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getQuery } from '../../router/utils';

const Input = styled(_Input)`
  && {
    width: 268px;
    font-size: 13px;
    color: ${(props) => props.theme.primaryDarkerColor};
  }
`;

type Props = {
  defaultValue?: string;
  onSearchTermChange: (search: string) => any;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
};

class SearchField extends Component<Props> {
  debouncedHandleSearch: (search: string) => void;

  state = {
    value: getQuery().search || '',
  };

  constructor(props: Props) {
    super(props);
    this.debouncedHandleSearch = debounce(this.handleSearch, 1300);
  }

  handleSearch = (searchedValue: string) =>
    this.props.onSearchTermChange(searchedValue);

  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.target.value;

    return this.setState({ value }, () => this.debouncedHandleSearch(value));
  };

  render() {
    // style so we can use styled(SearchField) to change their style
    // eslint-disable-next-line
    const {
      defaultValue,
      placeholder,
      onSearchTermChange,
      handleLoading,
      staticContext,
      ...props
    } = this.props;

    const { value } = this.state;

    return (
      <Input
        type='search'
        name='search'
        value={value}
        onChange={this.onChange}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position='end'>
            <Icon>search</Icon>
          </InputAdornment>
        }
        {...props}
      />
    );
  }
}

export default withRouter(SearchField);
