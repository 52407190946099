import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { useFragment, graphql } from 'react-relay';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { useSidebar } from '@feedback/ui';

import Support from '../support/Support';
import { safeGoBack } from '../../router/utils';

import Notification from './notification/Notification';
import User from './User';

import type {
  Header_query,
  Header_query$key,
} from './__generated__/Header_query.graphql';
import HeaderTrialWarning from './HeaderTrialWarning';

const MenuIconWrapper = styled(IconButton)`
  color: ${(props) => props.theme.richHeaderTextColor};
`;

const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

const DesktopItemsWrapper = styled.div`
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
`;

const StyledAppBar = styled(AppBar)`
  background: ${(props) =>
    `linear-gradient(to right,${props.theme.richHeaderColor1},${props.theme.richHeaderColor2})`};
  color: ${(props) => props.theme.richHeaderTextColor};
  z-index: 1201;
`;

const StyledToolbar = styled(Toolbar)`
  height: 60px;
  min-height: 0;
`;

type RightIconsProps = {
  query: Header_query;
};
const RightIcons = ({ query }: RightIconsProps) => (
  <RightWrapper>
    <DesktopItemsWrapper>
      <Support user={query.me!} />
    </DesktopItemsWrapper>
    <Notification />
    <User me={query.me} />
  </RightWrapper>
);

type Props = {
  query: Header_query;
};

const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const PATHS = [
  '/home',
  '/home/',
  '/home/openpix',
  '/home/openpix/start',
  '/home/hr',
];

const Header = (props: Props) => {
  const { sidebarToggle } = useSidebar();
  const location = useLocation();
  const history = useHistory();

  const query = useFragment<Header_query$key>(
    graphql`
      fragment Header_query on Query {
        me {
          ...HeaderTrialWarning_me
          ...User_me
          ...Support_user
        }
      }
    `,
    props.query,
  );

  const { me } = query;

  const onMenuClick = () => {
    safeGoBack(history, 'home');
  };

  if (!me) {
    return null;
  }

  return (
    <>
      <ElevationScroll>
        <StyledAppBar position='sticky'>
          <StyledToolbar disableGutters>
            {PATHS.includes(location.pathname) ? (
              <MenuIconWrapper onClick={sidebarToggle}>
                <MenuIcon fontSize='large' />
              </MenuIconWrapper>
            ) : (
              <MenuIconWrapper onClick={onMenuClick}>
                <ArrowBackOutlinedIcon fontSize='large' />
              </MenuIconWrapper>
            )}
            <RightIcons query={query} />
          </StyledToolbar>
        </StyledAppBar>
      </ElevationScroll>
      <HeaderTrialWarning me={me} />
    </>
  );
};

export default Header;
