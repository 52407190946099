import styled from 'styled-components';

import Toolbar from './Toolbar';

type Props = {
  className?: string;
  title?: string;
  subtitle?: string;
  onRefresh?: () => void;
  children: React.ReactNode;
  style?: any;
};

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#A3A3A3',
    margin: 0,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 300,
    color: '#929292',
    marginTop: 5,
  },
  titleContainer: {
    padding: '0 0 15px',
    boxSizing: 'border-box',
    minHeight: 48, // Because of buttons
  },
};

const Card = ({
  className,
  style,
  title,
  subtitle,
  onRefresh,
  children,
  ...props
}: Props) => (
  <div style={style} className={className}>
    {(title || subtitle) && (
      <Toolbar
        title={title}
        subtitle={subtitle}
        onRefresh={onRefresh}
        titleStyle={styles.title}
        subtitleStyle={styles.subtitle}
        titleContainerStyle={styles.titleContainer}
        {...props}
      />
    )}

    {children}
  </div>
);

export default styled(Card)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: ${(props) =>
    typeof props.padding !== 'undefined'
      ? props.padding
      : '20px 20px 40px 20px'};
  background-color: ${(props) =>
    typeof props.backgroundColor !== 'undefined'
      ? props.backgroundColor
      : '#ffffff'};
  box-sizing: border-box;
  height: 100%;
`;

export { default as CardHeader } from './card/CardHeader';
export { default as CardFooter } from './card/CardFooter';
export { default as CardTitle } from './card/CardTitle';
