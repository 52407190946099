import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/planning';

export const businessDivision: RouteType[] = [
  {
    name: 'businessDivision',
    path: `${PATH}/businessDivisions`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Unidades de Negócio',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.BUSINESS_DIVISION.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'businessDivision.list',
        path: `${PATH}/businessDivisions/list`,
        labelHeader: ({ t }) => t('Business Division'),
        component: loadable(
          () =>
            import('../../../components/businessDivision/BusinessDivisionList'),
        ),
        exact: false,
        label: 'Lista',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.BUSINESS_DIVISION.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
        modalRoutes: [
          {
            name: 'businessDivision.add',
            path: `${PATH}/businessDivisions/list/add`,
            labelHeader: 'Business Division - Adicionar',
            component: loadable(
              () =>
                import(
                  '../../../components/businessDivision/BusinessDivisionAdd'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.BUSINESS_DIVISION.ALL,
            ],
            requiredFeatures: [MODULES.PLANNING],
          },
        ],
      },
      {
        name: 'businessDivision.import',
        path: `${PATH}/businessDivisions/import`,
        labelHeader: 'Unidades de Negócio',
        label: 'Importar',
        component: loadable(
          () =>
            import(
              '../../../components/planning/import/BusinessDivisionImport'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.IMPORT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
  {
    name: 'myBusinessDivision.list',
    path: `${PATH}/planning/myBusinessDivision`,
    labelHeader: 'Minhas unidades de negócio',
    component: loadable(
      () =>
        import('../../../components/businessDivision/MyBusinessDivisionList'),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [MODULES.CORE],
  },
  {
    name: 'businessDivision.view',
    path: `${PATH}/businessDivision/:id`,
    labelHeader: 'Unidade de Negócio - Detalhes',
    component: loadable(
      () => import('../../../components/businessDivision/BusinessDivision'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'businessDivision.costRevenueCenters',
        path: `${PATH}/businessDivision/:id/costRevenueCenters`,
        labelHeader: 'Unidade de Negócio - Áreas/Locais',
        component: loadable(
          () =>
            import(
              '../../../components/businessDivision/BusinessDivisionCostRevenueCenters'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        label: 'Áreas/Locais',
      },
      {
        name: 'businessDivision.headCounts',
        path: `${PATH}/businessDivision/:id/headCounts`,
        labelHeader: 'Unidade de Negócio - HeadCounts',
        component: loadable(
          () =>
            import(
              '../../../components/businessDivision/BusinessDivisionHeadCounts'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        label: 'Headcounts',
      },
      {
        name: 'businessDivision.users',
        path: `${PATH}/businessDivision/:id/users`,
        labelHeader: ({ t }) => t('Business Division - Users'),
        component: loadable(
          () =>
            import(
              '../../../components/businessDivision/BusinessDivisionUsers'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        label: ({ t }) => t('Users'),
      },
      {
        name: 'businessDivision.edit',
        path: `${PATH}/businessDivision/:id/edit`,
        label: 'Ajustes',
        labelHeader: 'Unidade de Negócio - Ajustes',
        component: loadable(
          () =>
            import('../../../components/businessDivision/BusinessDivisionEdit'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
];

export default businessDivision;
