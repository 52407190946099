import { RadioGroup as _RadioGroup } from '@feedback/form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Label = styled.p`
  font-size: 14px;
  letter-spacing: normal;
  text-align: left;
  color: #607389;
  margin: 0;
  font-weight: 600;
`;

const RadioGroup = styled(_RadioGroup)`
  && {
    flex-direction: row;
  }
`;

type Props = {
  name?: string;
  label?: string;
};

const ActiveRadio = ({ name, label }: Props) => {
  const { t } = useTranslation();

  const radioOptions = [
    {
      value: true,
      label: t('Yes'),
    },
    {
      value: false,
      label: t('No'),
    },
  ];

  return (
    <>
      <Label>{label || 'Is it active?'}</Label>
      <RadioGroup name={name || 'isActive'} options={radioOptions} />
    </>
  );
};

export default ActiveRadio;
