import { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import _IconButton from '@material-ui/core/IconButton';

import { Loading } from '@feedback/ui';

import { routeTo } from '../../../router/utils/index';
import { UserAvatar } from '../../common/index';

import { createQueryRendererModern } from '../../../relay';

import type { Notification_query } from './__generated__/Notification_query.graphql';

import UserNotificationMarkAllAsOpenedMutation from './UserNotificationMarkAllAsOpenedMutation';
import UserNotificationMarkAsReadMutation from './UserNotificationMarkAsReadMutation';

const IconButton = styled(_IconButton)`
  color: ${(props) => props.theme.richHeaderTextColor};
`;

const ContentPopover = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-weight: 600;
`;

const Item = styled(MenuItem)`
  background-color: ${(p) => p.bgcolor} !important;
  min-height: 50px !important;
  border-top: solid 1px #e8e8e8 !important;
  &:hover {
    background-color: rgba(96, 115, 137, 0.08) !important;
  }
`;

const List = styled(MenuList)`
  max-height: 450px;
  overflow: auto !important;
  padding: 0 !important;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.primary.main};
    height: 140px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
`;

const Message = styled.p`
  display: flex;
  display: -webkit-box;
  max-height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #607389;
  margin-left: 15px;
  word-wrap: break-word;
  line-break: normal;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const LoaderContainer = styled(Item)`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background-color: #f8f8f8 !important;
  cursor: default !important;
`;

const ScrollLoader = () => (
  <LoaderContainer>
    <Loading size={28} />
  </LoaderContainer>
);

type Props = {
  query: Notification_query;
  relay: RelayRefetchProp;
};
const Notification = ({ query, relay }: Props) => {
  const [anchor, setAnchor] = useState(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { me } = query;

  const { t } = useTranslation();
  const history = useHistory();

  const formatParams = (params: Record<string, unknown>) =>
    params.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {});

  const renderIcon = () => {
    if (!me) {
      return null;
    }

    const { unreadNotificationsCount } = me;

    return (
      <IconButton onClick={handleClick} edge='end'>
        <Badge badgeContent={unreadNotificationsCount} color={'primary'}>
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </IconButton>
    );
  };

  const handleNotificationClick =
    ({ url, urlWeb, params, id, wasRead }) =>
    () => {
      if (!wasRead) {
        UserNotificationMarkAsReadMutation.commit({ id });
      }

      setAnchor(null);

      if (!urlWeb && url) {
        return window.open(url, '_blank');
      } else {
        history.push(routeTo(urlWeb, formatParams(params)));
      }
    };

  const renderItem = (node) => (
    <Item
      bgcolor={!!node.wasRead ? '#f8f8f8' : 'rgba(96, 115, 137, 0.18)'}
      key={node.id}
      onClick={handleNotificationClick(node)}
    >
      {node.creator && <UserAvatar size={30} user={node.creator} />}
      <Message>{node.body}</Message>
    </Item>
  );

  const handleClick = (event) => {
    // send mutation
    UserNotificationMarkAllAsOpenedMutation.commit({});

    if (!anchor) {
      setAnchor(event.currentTarget);

      const refetchVariables = (fragmentVariables) => ({
        ...fragmentVariables,
        first: 10,
      });

      relay.refetch(refetchVariables, null, () => {}, { force: true });

      return;
    }

    setAnchor(null);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleLoadMore = () => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const TOTAL_REFETCH_ITEMS = 100;

    if (!query.me) {
      return;
    }

    const { notifications } = query.me;
    const { endCursor, hasNextPage } = notifications.pageInfo;

    if (!hasNextPage) {
      return;
    }

    const total = notifications.edges.length + TOTAL_REFETCH_ITEMS;
    const renderVariables = { first: total };
    const refetchVariables = (fragmentVariables) => ({
      ...fragmentVariables,
      first: TOTAL_REFETCH_ITEMS,
      after: endCursor,
    });

    relay.refetch(refetchVariables, renderVariables, () => {
      setIsFetching(false);
    });
  };

  // isValidNotification = (edge): boolean %checks => {
  const isValidNotification = (edge): boolean => {
    if (!edge) {
      return false;
    }

    const { node } = edge;
    const { body, url, urlWeb, params } = node;

    if (!body) {
      return false;
    }

    if (body.length === 0) {
      // eslint-disable-next-line
      // console.log('not valid body empty: ', urlWeb, params);
      return false;
    }

    try {
      routeTo(urlWeb, formatParams(params));
    } catch (err) {
      // eslint-disable-next-line
      // console.log('not valid: ', err, urlWeb, params);

      if (url.includes('https')) {
        return true;
      }

      return false;
    }

    return true;
  };

  if (!me) {
    return null;
  }

  const { notifications } = me;
  const { edges, pageInfo } = notifications;

  return (
    <>
      {renderIcon()}
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <ContentPopover>
          <Header>{t('Notifications')}</Header>
          <List>
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={pageInfo.hasNextPage}
              loader={isFetching ? <ScrollLoader key={0} /> : null}
              useWindow={false}
              threshold={100}
            >
              {edges
                .filter(isValidNotification)
                .map(({ node }) => renderItem(node))}
            </InfiniteScroll>
          </List>

          {/*<Footer>Ver todas</Footer>*/}
        </ContentPopover>
      </Popover>
    </>
  );
};

const NotificationRefetchContainer = createRefetchContainer(
  Notification,
  {
    query: graphql`
      fragment Notification_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 300 }
        after: { type: String }
      ) {
        me {
          unreadNotificationsCount
          notifications(first: $first, after: $after)
            @connection(key: "Notification_notifications", filters: []) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                body
                creator {
                  ...UserAvatar_user
                }
                url
                urlWeb
                params {
                  key
                  value
                }
                #sentAt
                #openedAtWeb
                wasRead
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query NotificationRefetchQuery($first: Int, $after: String) {
      ...Notification_query @arguments(first: $first, after: $after)
    }
  `,
);

export default createQueryRendererModern(NotificationRefetchContainer, {
  query: graphql`
    query NotificationQuery {
      ...Notification_query
    }
  `,
  loadingView: null,
});
