import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

import _Avatar from './avatar/Avatar';

const Avatar = styled(_Avatar)<{ onClick?: () => void }>`
  padding-right: 10px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;

// TODO - consume from @feedback/ui
export const Card = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  height: 60px;
  margin: 10px 0px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const TextInner = styled.p<{ bold?: boolean }>`
  font-size: ${({ bold }) => (bold ? '14px' : '12px')};
  font-weight: ${({ bold }) => (bold ? '600' : '300')};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Column = styled.div<{ width?: string }>`
  ${layout}
  ${flexbox}
  overflow: hidden;
  padding-left: 10px;
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  flex: 1;
`;

type Column = {
  title?: string;
  content?: string;
  width?: string;
  render?: React.ReactNode;
};

type Props = {
  imageUrl?: string;
  name: string;
  columns: Column[];
  onClickAvatar?: () => void;
  className?: string;

  // optional, row can have no selection
  onClickSelect?: (e: Event) => void;
  selected?: boolean;
  selectOnLeft?: boolean;
  rightAction?: React.ReactNode;
};

const Text = ({ children, ...props }) => (
  <TextInner {...props}>{children || '--'}</TextInner>
);

const UserRow = (props) => {
  const {
    imageUrl,
    name,
    onClickAvatar,
    onClickSelect,
    selected,
    selectOnLeft,
    rightAction,
    className,
    disabled,
    columns = [],
  } = props;

  return (
    <Card className={className}>
      {selectOnLeft && onClickSelect && (
        <Checkbox
          onClick={onClickSelect}
          checked={selected}
          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
          indeterminateIcon={<RemoveCircleOutlineOutlinedIcon />}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          disabled={disabled}
          inputProps={{
            'data-testid': `checkbox-user-${name?.replace(/ /g, '') || ''}`,
          }}
        />
      )}
      <Avatar
        name={name}
        imageUrl={imageUrl}
        size={45}
        onClick={onClickAvatar}
      />
      <Info>
        {columns.map(({ title, content, render, ...props }, index) => {
          if (render) {
            return (
              <Column key={index} {...props}>
                {render}
              </Column>
            );
          }

          return (
            <Column key={index} {...props}>
              <Text bold>{title}</Text>
              <Text>{content}</Text>
            </Column>
          );
        })}
      </Info>
      {!selectOnLeft && onClickSelect && (
        <Checkbox
          onClick={onClickSelect}
          checked={selected}
          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
          indeterminateIcon={<RemoveCircleOutlineOutlinedIcon />}
          icon={<RadioButtonUncheckedOutlinedIcon />}
        />
      )}
      {rightAction}
    </Card>
  );
};

export default UserRow;
