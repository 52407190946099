// @flow

import styled, { withTheme } from 'styled-components';

const SVG = styled.svg`
  min-width: ${(props) => props.width}px;
`;

type Props = {
  size?: number;
  color?: string;
  viewBox?: string;
  theme: Record<string, any>;
};

const CheckboxChecked = ({ size, color, viewBox, theme }: Props) => {
  return (
    <SVG viewBox={viewBox} width={size} height={size}>
      <circle
        fill={color || theme.primaryLighterColor}
        cx='50'
        cy='50'
        r='50'
      />
    </SVG>
  );
};

CheckboxChecked.defaultProps = {
  viewBox: '0 0 100 100',
  size: 20,
};

export default withTheme(CheckboxChecked);
