import { commitMutation as relayCommitMutation } from 'react-relay';
import type { Disposable, Environment, MutationConfig, OperationBase, OperationDefaults } from 'relay-runtime';

// It returns a promise if no callbacks is passed
export const commitMutation = <T extends OperationBase = OperationDefaults>(
  environment: Environment,
  config: MutationConfig,
): Disposable | Promise<T['response']> => {
  if (!config.onCompleted && !config.onError) {
    return new Promise((resolve, reject) => {
      const onCompleted = (payload, errors) => {
        if (errors) {
          reject(errors[0]);

          return;
        }

        resolve(payload);
      };

      const onError = (error) => {
        reject(error);
      };

      return relayCommitMutation(environment, {
        ...config,
        onCompleted,
        onError,
      });
    });
  }

  return relayCommitMutation(environment, config);
};
