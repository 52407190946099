import type { RouteType } from '@feedback/router';

const myRoutes: Array<RouteType> = [];

export const appendRoutes = (routes: RouteType) => {
  myRoutes.push(routes);
};

export const getRoutes = () => {
  return myRoutes;
};

export type { RouteName } from '@feedback/router';
