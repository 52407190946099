const Success = (props) => {
  return (
    <svg
      id='prefix__Camada_1'
      x={0}
      y={0}
      viewBox='0 0 146 146'
      xmlSpace='preserve'
      {...props}
    >
      <style />
      <g id='prefix__Componente_2_1'>
        <linearGradient
          id='prefix__Elipse_28-2_2_'
          gradientUnits='userSpaceOnUse'
          x1={-205.699}
          y1={264.158}
          x2={-205.699}
          y2={263.158}
          gradientTransform='matrix(-146 0 0 146 -29959.025 -38421.04)'
        >
          <stop offset={0} stopColor='#30ad23' />
          <stop offset={1} stopColor='#6dec5f' />
        </linearGradient>
        <circle
          id='prefix__Elipse_28-2_1_'
          cx={73}
          cy={73}
          r={73}
          fill='url(#prefix__Elipse_28-2_2_)'
        />
      </g>
      <path
        id='prefix__Caminho_9'
        d='M121.03 40.87l-3.62-3.7a6.691 6.691 0 00-4.77-2.03c-1.79.02-3.5.71-4.8 1.93L57.86 86.03c-.19.19-.49.19-.68 0L38.49 67.35c-2.68-2.55-6.89-2.55-9.56 0l-3.66 3.66a6.778 6.778 0 000 9.56l27.38 27.38a6.782 6.782 0 009.51.05l58.76-57.57a6.76 6.76 0 00.11-9.56c0 .01 0 0 0 0z'
        fill='#fff'
      />
    </svg>
  );
};

export default Success;
