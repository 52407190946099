import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useWindowSize } from 'use-window-size-hook';
// import Paper from '@material-ui/core/Paper';

import { Fragment } from 'react';

import Group from './Group';

import { useSidebar } from './index';

const StyledList = styled(List)`
  width: 239px;
  padding-top: 0;
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 200ms ease-out;

  //Styling for all Sidebar
  .Mui-selected {
    font-weight: 600;
  }
  .MuiListItemIcon-root {
    min-width: 30px;
  }
  //Style for the nested, 2nds level items
  .MuiCollapse-container .MuiListItem-root {
    padding-left: 52px;
  }
  .MuiTypography-root {
    font-weight: inherit;
  }
  .MuiList-root {
    a {
      padding-left: 52px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  height: 60px;
  justify-content: center;
`;

export type ItemType = {
  items?: ItemType[];
  link: string;
  label: string;
  separator?: boolean;
  icon: React.ComponentType;
};

export type SidebarProps = {
  items: ItemType[];
  header?: React.ReactNode;
  projectType: string;
};

export const Sidebar = ({ items, header }: SidebarProps) => {
  const { isSidebarOpen, sidebarClose } = useSidebar();
  const { width } = useWindowSize();

  const isMobile = width < 600;
  const drawerType = isMobile ? 'temporary' : 'persistent';

  const renderGroups = (items: ItemType[]) => {
    return items.map((item, index) => {
      const { items: groupItems, link, label, icon, separator } = item;

      if (separator) {
        return (
          <Fragment key={index}>
            <ListItem>
              <ListItemText primary={label} />
            </ListItem>
            {groupItems && renderGroups(groupItems)}
          </Fragment>
        );
      }

      return (
        <Group
          key={index}
          label={label}
          icon={icon}
          link={link}
          items={groupItems}
          isMobile={isMobile}
        />
      );
    });
  };

  return (
    <Drawer variant={drawerType} open={isSidebarOpen} onClose={sidebarClose}>
      {header && <Header>{header}</Header>}
      <Divider />
      <StyledList open={isSidebarOpen}>{renderGroups(items)}</StyledList>
    </Drawer>
  );
};
