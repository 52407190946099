import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Checkbox = ({ ...props }) => (
  <FormControlLabel
    label={props.label}
    control={
      <MaterialCheckbox
        color='primary'
        checked={props.value}
        onCheck={props.onChange}
        {...props}
      />
    }
  />
);

export default Checkbox;
