import { ADMIN_ROLE, ROLES } from '@feedback/roles';
import TrendingUpIcon from '@material-ui/icons/TrendingUpOutlined';

import { routeTo } from '../../../../router/utils';

export const transactions = ({ t, hasRole }) => {
  const canSeeReport = hasRole([
    ADMIN_ROLE,
    ROLES.OPEN_PIX.ADMIN.ALL,
    ROLES.OPEN_PIX.TRANSACTIONS,
    ROLES.OPEN_PIX.REFUND,
    ROLES.OPEN_PIX.REPORT,
  ]);

  const transactionRoute = {
    label: t('Transactions'),
    icon: <TrendingUpIcon />,
  };

  if (canSeeReport) {
    return [
      { ...transactionRoute, link: routeTo('openpix.transaction.report') },
    ];
  }

  return [
    {
      ...transactionRoute,
      link: routeTo('openpix.transaction.list'),
    },
  ];
};
