import { useMemo } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { getRoutes } from './routes';
import { drawRoutes } from './utils';

const ClientRouter = () => {
  const routesMemo = useMemo(() => {
    return drawRoutes(getRoutes());
  }, []);

  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>{routesMemo}</Switch>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default ClientRouter;
