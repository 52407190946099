import { useState, useEffect } from 'react';

import type {
  FieldHookConfig,
  FieldInputProps,
  FieldMetaProps,
  FieldHelperProps,
} from 'formik';
import { useField } from 'formik';
//eslint-disable-next-line
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_DELAY = 300;

export function useFastField<Val>(
  propsOrFieldName: string | FieldHookConfig<Val>,
): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] {
  const [field, meta, helpers] = useField<Val>(propsOrFieldName);
  const [value, setValue] = useState<Val>(field.value);
  const { onBlur, onChange } = field;
  const { setValue: helpersSetValue } = helpers;
  const { shouldResync = true } = propsOrFieldName;

  const currentFieldValue = field.value;

  // resync field.value
  useEffect(() => {
    if (shouldResync) {
      if (currentFieldValue !== value) {
        setValue(currentFieldValue);
      }
    }
  }, [currentFieldValue, shouldResync]);

  const onSync = useDebouncedCallback((e) => {
    onChange(e);
    onBlur(e);
  }, DEBOUNCE_DELAY);

  const onSyncValue = useDebouncedCallback((val: Val) => {
    helpersSetValue(val);
    helpers.setTouched(true);
  }, DEBOUNCE_DELAY);

  // monkey patch formik field
  field.value = value;
  //eslint-disable-next-line
  field.onChange = (e: React.ChangeEvent<any>): void => {
    if (e && e.currentTarget) {
      setValue(e.currentTarget.value);
    }

    onSync(e);
  };

  //eslint-disable-next-line
  field.onBlur = (e: any): void => {
    onChange(e);
    onBlur(e);
  };

  helpers.setValue = (val: Val) => {
    setValue(val);
    onSyncValue(val);
  };

  return [field, meta, helpers];
}
