import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  color: #9e9e9e;
  margin-bottom: 0;
`;

const Main = styled.div`
  text-align: left;
  color: ${(props) => props.color || props.theme.primaryDarkerColor};
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
`;

type Props = {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  color?: string;
};

const CardTitle = ({ children, title, description, color }: Props) => (
  <Wrapper>
    <Main color={color}>
      <Title>{title}</Title>
      {!!description && <Description>{description}</Description>}
    </Main>
    {children}
  </Wrapper>
);

export default CardTitle;
