import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { graphql } from 'react-relay';

import { concatPermissions, getPermissionsFromRoutes, hasFeature, hasRole } from '../../security';
import { routeTo } from '../utils';

import type { useAuthenticated_user } from './__generated__/useAuthenticated_user.graphql';

// eslint-disable-next-line
graphql`
  fragment useAuthenticated_user on User {
    company {
      features
    }
    isAdmin
    allRoles
    ...permissionsUserFragment @relay(mask: false)
  }
`;

export const useAuthenticated = (user: useAuthenticated_user, company: any) => {
  const history = useHistory();

  const isAuthenticated = !!user;

  const redirectIfNotAllowed = () => {
    const { pathname } = location;

    if (!company) {
      // handled by useDomainName
      return;
    }

    if (!user) {
      return history.push(routeTo('forbidden'));
    }

    const { requiredRoles, requiredFeatures } = concatPermissions(getPermissionsFromRoutes([pathname]));

    if (!hasFeature(user, requiredFeatures)) {
      return history.push(routeTo('notFound'));
    }

    if (!location.pathname.startsWith('/home/forbidden') && !hasRole(user, requiredRoles)) {
      return history.push(routeTo('forbidden'));
    }
  };

  const redirectIfNotAuthenticated = () => {
    if (!company) {
      // handled by useDomainName
      return;
    }

    if (!user && !location.pathname.startsWith('/login') && !location.pathname.startsWith('/signUp')) {
      history.push({
        pathname: routeTo('auth.login'),
        state: { nextPathname: location.pathname },
      });
    }

    if (user) {
      redirectIfNotAllowed();
    }
  };

  useEffect(() => {
    redirectIfNotAuthenticated();
  });

  return isAuthenticated;
};
