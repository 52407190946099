/**
 * @generated SignedSource<<595b008539c3291c352b6590ca43d9e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInCompanyMiddlewareQuery$variables = {
  nick: string;
};
export type UserInCompanyMiddlewareQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserInCompanyMiddleware_query">;
};
export type UserInCompanyMiddlewareQuery = {
  response: UserInCompanyMiddlewareQuery$data;
  variables: UserInCompanyMiddlewareQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nick"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserInCompanyMiddlewareQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserInCompanyMiddleware_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserInCompanyMiddlewareQuery",
    "selections": [
      {
        "alias": "node",
        "args": [
          {
            "kind": "Variable",
            "name": "nick",
            "variableName": "nick"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodeNick",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibleExternally",
                "storageKey": null
              }
            ],
            "type": "Group",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyDomainName",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameFriendly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "logo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "fitIn",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(fitIn:true)"
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "features",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83855e9e759d08c3e0f406a1c96412e7",
    "id": null,
    "metadata": {},
    "name": "UserInCompanyMiddlewareQuery",
    "operationKind": "query",
    "text": "query UserInCompanyMiddlewareQuery(\n  $nick: String!\n) {\n  ...UserInCompanyMiddleware_query\n}\n\nfragment UserInCompanyMiddleware_query on Query {\n  node: nodeNick(nick: $nick) {\n    __typename\n    ... on User {\n      id\n    }\n    ... on Group {\n      id\n      visibleExternally\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  company: companyDomainName {\n    id\n    _id\n    name\n    nameFriendly\n    logo {\n      url(fitIn: true)\n      id\n    }\n    features\n  }\n}\n"
  }
};
})();

(node as any).hash = "f02ab1803e11300bae48da97b7ee8a21";

export default node;
