import styled from 'styled-components';

const Label = styled.span`
  text-align: center;
  color: #607389;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
`;

const PageLabel = ({ children }) => <Label>{children}</Label>;

export default PageLabel;
