import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import { ResourceSelectField } from '@feedback/select';

import { useTranslation } from 'react-i18next';

import type { ApplicationSelectField_query } from './__generated__/ApplicationSelectField_query.graphql';

type Application = {
  id: string;
  name: string;
};

type ApplicationSelectFieldProps = {
  query: ApplicationSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  fullWidth?: boolean;
  value?: Application;
  hasAddress?: boolean | null;
  hintText?: string;
  name?: string;
};

const ApplicationSelectField = (props: ApplicationSelectFieldProps) => {
  const { t } = useTranslation();

  const getItemFromNode = ({ id, name }: Application) => {
    if (!id && !name) {
      return;
    }

    return {
      id,
      name,
    };
  };

  const { filters = {} } = props;

  const getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      filters: {
        ...filters,
        search,
      },
    };
  };

  const getRenderVariables = (value) => {
    const { search } = value;

    return {
      filters: {
        ...filters,
        search,
      },
    };
  };

  const {
    hintText = t('Search Contributor'),
    name = 'application',
    query,
    relay,
    environment,
    ...rest
  } = props;

  return (
    <ResourceSelectField
      getItemFromNode={getItemFromNode}
      getFilterFragmentVariables={getFilterFragmentVariables}
      getRenderVariables={getRenderVariables}
      relay={relay}
      connection={query.me.company.applications}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

export default createRefetchContainer(
  ApplicationSelectField,
  {
    query: graphql`
      fragment ApplicationSelectField_query on Query
      @argumentDefinitions(first: { type: Int, defaultValue: 300 }) {
        me {
          company {
            applications(first: $first)
              @connection(
                key: "ApplicationSelectField_applications"
                filters: []
              ) {
              endCursorOffset
              startCursorOffset

              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                cursor
                node {
                  id
                  name
                  description
                  isActive
                  createdAt
                  type
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query ApplicationSelectFieldQuery($first: Int) {
      ...ApplicationSelectField_query @arguments(first: $first)
    }
  `,
);
