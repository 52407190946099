import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';

import groupRoutes from '../groupRoutes';

const PATH = '/home/feedbacks';

const feedbacksRoutes: RouteType[] = [
  {
    name: 'feedbacks.request',
    path: `${PATH}/request`,
    labelHeader: ({ t }) => t('request_feedback'),
    component: loadable(
      () =>
        import(
          '../../../components/feedback/feedbackRequest/RequestFeedbackList'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.REQUEST_FEEDBACK,
      ROLES.FEEDBACK.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedbacks.request.new',
    path: `${PATH}/request/new/:id`,
    labelHeader: ({ t }) => t('Request {{Feedback}}'),
    component: loadable(
      () =>
        import(
          '../../../components/feedback/feedbackRequest/RequestFeedbackAdd'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.REQUEST_FEEDBACK,
      ROLES.FEEDBACK.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedbacks.give.root',
    path: `${PATH}/give`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContent'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('{{Feedback}}'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.GIVE_FEEDBACK,
      ROLES.FEEDBACK.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
    routes: [
      {
        name: 'feedbacks.give',
        path: `${PATH}/give`,
        label: ({ t }) => t('{{Feedback}}'),
        labelHeader: ({ t }) => t('{{Feedback}}'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/feedbackRequest/FeedbackRequestList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.FEEDBACK.ALL,
          ROLES.FEEDBACK.GIVE_FEEDBACK,
          ROLES.FEEDBACK.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
    ],
  },
  {
    name: 'feedbacks.give.selfreview',
    path: `${PATH}/give/selfreview/:id`,
    labelHeader: ({ t }) => t('Self-evaluation'),
    component: loadable(
      () => import('../../../components/feedback/FeedbackAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.SELF_REVIEW,
      ROLES.FEEDBACK.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedbacks.give.new',
    path: `${PATH}/give/new/:id`,
    labelHeader: ({ t }) => t('Send {{Feedback}}'),
    component: loadable(
      () => import('../../../components/feedback/FeedbackAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.GIVE_FEEDBACK,
      ROLES.FEEDBACK.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedbacks.give.respond',
    path: `${PATH}/give/respond/:id`,
    labelHeader: ({ t }) => t('Send {{Feedback}}'),
    component: loadable(
      () => import('../../../components/feedback/respond/FeedbackRespond'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.GIVE_FEEDBACK,
      ROLES.FEEDBACK.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedbacks.view',
    path: `${PATH}/view/:id`,
    labelHeader: ({ t }) => t('Send {{Feedback}}'),
    component: loadable(
      () => import('../../../components/feedback/FeedbackAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedback.external.dashboard',
    path: `${PATH}/external/dashboard`,
    labelHeader: ({ t }) => t('External {{Feedback}} Report'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.ANALYTICS.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
    routes: [
      {
        name: 'feedback.external.dashboard.charts',
        path: `${PATH}/external/dashboard/charts`,
        label: ({ t }) => t('External Reports'),
        labelHeader: ({ t }) => t('External {{Feedback}} Report'),
        component: loadable(
          () =>
            import(
              '../../../components/externalFeedback/dashboard/FeedbackExternalDashboard'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
      },
      {
        name: 'feedbacks.external.list.all.users',
        path: `${PATH}/external/dashboard/users`,
        labelHeader: ({ t }) => t('All User External {{Feedbacks}}'),
        label: ({ t }) => t('List by Users'),
        component: loadable(
          () =>
            import(
              '../../../components/externalFeedback/AllExternalFeedbackList'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_EXTERNAL.ADMIN.ALL],
        requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
      },
      {
        name: 'feedbacks.external.list.all.groups',
        path: `${PATH}/external/dashboard/groups`,
        labelHeader: ({ t }) => t('All Group External {{Feedbacks}}'),
        label: ({ t }) => t('List by Groups'),
        component: loadable(
          () =>
            import(
              '../../../components/externalGroupFeedback/AllExternalGroupFeedbackList'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_GROUP.ADMIN.ALL],
        requiredFeatures: [MODULES.FEEDBACK_GROUP],
      },
    ],
  },
  ...groupRoutes,
  {
    name: 'feedback.dashboard',
    path: `${PATH}/dashboard`,
    labelHeader: ({ t }) => t('{{Feedbacks}} Reports'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.ANALYTICS.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
    routes: [
      {
        name: 'feedback.dashboard.charts',
        path: `${PATH}/dashboard/charts`,
        label: 'Dashboard',
        labelHeader: ({ t }) => t('{{Feedback}} Report'),
        component: loadable(
          () =>
            import('../../../components/feedback/dashboard/FeedbackDashboard'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedback.dashboard.matrix',
        path: `${PATH}/dashboard/matrix`,
        label: ({ t }) => t('Matrix'),
        labelHeader: ({ t }) => t('{{Feedback}} Matrix'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/dashboard/matrix/FeedbackMatrixUsers'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [],
      },
      {
        name: 'feedbacks.list.all.users',
        path: `${PATH}/dashboard/users/list`,
        label: ({ t }) => t('List per users'),
        labelHeader: ({ t }) => t('All Users {{Feedbacks}}'),
        component: loadable(
          () =>
            import('../../../components/feedback/AllUserFeedbacksAdminList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedbacks.list.all.request.pending',
        path: `${PATH}/dashboard/pending/list`,
        label: ({ t }) => t('Pending {{Feedbacks}}'),
        labelHeader: ({ t }) => t('All pending {{Feedbacks}}'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/feedbackRequest/dashboard/AllPendingFeedbackRequestAdminList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ADMIN.ALL,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedbacks.list.all.groups',
        path: `${PATH}/dashboard/groups/list`,
        label: ({ t }) => t('List per Groups'),
        labelHeader: ({ t }) => t('All Group {{Feedbacks}}'),
        component: loadable(
          () =>
            import('../../../components/feedback/AllGroupFeedbacksAdminList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedback.teams',
        path: `${PATH}/dashboard/orgchart`,
        labelHeader: ({ t }) => t('Org Chart'),
        label: ({ t }) => t('Org Chart'),
        component: loadable(
          () => import('../../../components/team/TeamsAnalytic'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
    ],
  },
  {
    name: 'feedback.settings',
    path: `${PATH}/settings`,
    labelHeader: ({ t }) => t('{{Feedback}} Settings'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.ANALYTICS.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
    routes: [
      {
        name: 'company.feedbackTopics',
        path: `${PATH}/settings/topics`,
        label: ({ t }) => t('Topics'),
        labelHeader: ({ t }) => t('{{Feedback}} Settings'),
        component: loadable(
          () => import('../../../components/topic/CompanyFeedbackTopicList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedback.general',
        path: `${PATH}/settings/customization/general`,
        label: ({ t }) => t('Reactions'),
        labelHeader: ({ t }) => t('{{Feedback}} Settings'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/settings/FeedbackGeneralSettings'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedback.internalFeedback',
        path: `${PATH}/settings/customization/feedback`,
        label: ({ t }) => t('Internal Feedback'),
        labelHeader: ({ t }) => t('{{Feedback}} Settings'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/settings/FeedbackCustomizationSettings'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
      {
        name: 'feedback.externalFeedback',
        path: `${PATH}/settings/customization/external-feedback`,
        label: ({ t }) => t('External Feedback'),
        labelHeader: ({ t }) => t('{{Feedback}} Settings'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/settings/FeedbackExternalCustomizationSettings'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
      },
      {
        name: 'feedback.customization',
        path: `${PATH}/settings/customization/custom`,
        label: ({ t }) => t('Customization'),
        labelHeader: ({ t }) => t('{{Feedback}} Settings'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/settings/FeedbackCustomization'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK, MODULES.FEEDBACK_EXTERNAL],
      },
      {
        name: 'feedback.engagement',
        path: `${PATH}/settings/engagement`,
        label: ({ t }) => t('Engagement'),
        labelHeader: ({ t }) => t('{{Feedback}} Engagement'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/engagement/FeedbackEngagementList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK, MODULES.FEEDBACK_EXTERNAL],
      },
    ],
  },
  {
    name: 'company.feedbackTopics.new',
    path: `${PATH}/topics/new`,
    labelHeader: ({ t }) => t('Create Topic'),
    component: loadable(
      () => import('../../../components/topic/CompanyFeedbackTopicAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.ANALYTICS.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
  },
  {
    name: 'feedback.users',
    path: `${PATH}/users`,
    labelHeader: ({ t }) => t('Users'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.ANALYTICS.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
    routes: [
      {
        name: 'feedback.users',
        path: `${PATH}/users`,
        labelHeader: ({ t }) => t('Users'),
        component: loadable(
          () => import('../../../components/feedback/users/FeedbackUserList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ANALYTICS.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
    ],
  },
  {
    name: 'myFeedbacks',
    path: `${PATH}/myFeedbacks`,
    labelHeader: ({ t }) => t('Reports'),
    component: loadable(
      () => import('../../../components/user/UserFeedbacksDetails'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK.ALL,
      ROLES.FEEDBACK.ADMIN.ALL,
      ROLES.FEEDBACK.FEEDBACK.ALL,
      ROLES.FEEDBACK.USER.ALL,
      ROLES.FEEDBACK.SELF_REVIEW,
      ROLES.FEEDBACK.FEEDBACK_RECEIVED,
      ROLES.FEEDBACK.FEEDBACK_SENT,
      ROLES.FEEDBACK.USER.ALL,
      ROLES.FEEDBACK.FEEDBACK_RECEIVED_REPORT,
      ROLES.FEEDBACK.FEEDBACK_SENT_REPORT,
    ],
    requiredFeatures: [MODULES.FEEDBACK],
    routes: [
      {
        name: 'users.evolution',
        label: ({ t }) => t('Evolution'),
        labelHeader: ({ t }) => t('Reports'),
        path: `${PATH}/myFeedbacks/evolution`,
        component: loadable(
          () =>
            import(
              '../../../components/feedback/dashboard/userEvolution/UserEvolutionTabView'
            ),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ADMIN.ALL,
          ROLES.FEEDBACK.FEEDBACK.ALL,
          ROLES.FEEDBACK.USER.ALL,
          ROLES.FEEDBACK.FEEDBACK_SENT,
          ROLES.FEEDBACK.FEEDBACK_RECEIVED,
          ROLES.FEEDBACK.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
        routes: [
          {
            name: 'users.evolution.received',
            path: `${PATH}/myFeedbacks/evolution/received`,
            label: ({ t }) => t('Received'),
            labelHeader: ({ t }) => t('Reports'),
            component: loadable(
              () =>
                import(
                  '../../../components/feedback/dashboard/userEvolution/UserEvolutionReceive'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK.ALL,
              ROLES.FEEDBACK.ADMIN.ALL,
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.USER.ALL,
              ROLES.FEEDBACK.FEEDBACK_RECEIVED,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
          {
            name: 'users.evolutionSent',
            path: `${PATH}/myFeedbacks/evolution/sent`,
            label: ({ t }) => t('Sent'),
            labelHeader: ({ t }) => t('Reports'),
            component: loadable(
              () =>
                import(
                  '../../../components/feedback/dashboard/userEvolution/UserEvolutionSented'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK.ALL,
              ROLES.FEEDBACK.ADMIN.ALL,
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.USER.ALL,
              ROLES.FEEDBACK.FEEDBACK_SENT,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
        ],
      },
      {
        name: 'feedbacks',
        labelHeader: ({ t }) => t('My {{Feedbacks}}'),
        label: ({ t }) => t('{{Feedbacks}}'),
        path: `${PATH}/myFeedbacks/feedbacks`,
        component: loadable(
          () =>
            import(
              '../../../components/feedback/feedbackList/FeedbackListTabView'
            ),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ADMIN.ALL,
          ROLES.FEEDBACK.FEEDBACK.ALL,
          ROLES.FEEDBACK.USER.ALL,
          ROLES.FEEDBACK.FEEDBACK_RECEIVED_REPORT,
          ROLES.FEEDBACK.FEEDBACK_SENT_REPORT,
          ROLES.FEEDBACK.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
        routes: [
          {
            name: 'feedbacks.received',
            path: `${PATH}/myFeedbacks/feedbacks/received`,
            label: ({ t }) => t('Received'),
            labelHeader: ({ t }) => t('My {{Feedbacks}} received'),
            component: loadable(
              () => import('../../../components/feedback/FeedbackListReceived'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK.ALL,
              ROLES.FEEDBACK.ADMIN.ALL,
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.FEEDBACK_RECEIVED_REPORT,
              ROLES.FEEDBACK.USER.ALL,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
          {
            name: 'feedbacks.sent',
            path: `${PATH}/myFeedbacks/feedbacks/sent`,
            label: ({ t }) => t('Sent'),
            labelHeader: ({ t }) => t('My {{Feedbacks}} sent'),
            component: loadable(
              () =>
                import(
                  '../../../components/feedback/feedbackList/FeedbackListSent'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK.ALL,
              ROLES.FEEDBACK.ADMIN.ALL,
              ROLES.FEEDBACK.FEEDBACK.ALL,
              ROLES.FEEDBACK.FEEDBACK_SENT_REPORT,
              ROLES.FEEDBACK.USER.ALL,
            ],
            requiredFeatures: [MODULES.FEEDBACK],
          },
        ],
      },
      {
        name: 'feedbacks.selfReview',
        path: `${PATH}/myFeedbacks/selfReview`,
        label: ({ t }) => t('Self Review'),
        labelHeader: ({ t }) => t('My Self Reviews'),
        component: loadable(
          () =>
            import(
              '../../../components/feedback/feedbackSelfReview/FeedbackSelfReviewList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK.ALL,
          ROLES.FEEDBACK.ADMIN.ALL,
          ROLES.FEEDBACK.FEEDBACK.ALL,
          ROLES.FEEDBACK.SELF_REVIEW,
          ROLES.FEEDBACK.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK],
      },
    ],
  },
  {
    name: 'externalFeedbacks',
    path: `${PATH}/externalFeedbacks`,
    labelHeader: ({ t }) => t('External {{Feedbacks}}'),
    component: loadable(
      () =>
        import('../../../components/externalFeedback/ExternalFeedbackDetails'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_EXTERNAL.ALL,
      ROLES.FEEDBACK_EXTERNAL.ADMIN.ALL,
      ROLES.FEEDBACK_EXTERNAL.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
    routes: [
      {
        name: 'feedbacks.external.evolution',
        path: `${PATH}/externalFeedbacks/evolution`,
        label: ({ t }) => t('Evolution'),
        labelHeader: ({ t }) => t('External {{Feedbacks}}'),
        component: loadable(
          () =>
            import(
              '../../../components/externalFeedback/MeExternalFeedbackEvolution'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_EXTERNAL.ALL,
          ROLES.FEEDBACK_EXTERNAL.ADMIN.ALL,
          ROLES.FEEDBACK_EXTERNAL.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
      },
      {
        name: 'feedbacks.external.received',
        path: `${PATH}/externalFeedbacks/list`,
        label: ({ t }) => t('{{Feedbacks}}'),
        labelHeader: ({ t }) => t('External {{Feedbacks}}'),
        component: loadable(
          () =>
            import(
              '../../../components/externalFeedback/MeExternalFeedbackList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_EXTERNAL.ALL,
          ROLES.FEEDBACK_EXTERNAL.ADMIN.ALL,
          ROLES.FEEDBACK_EXTERNAL.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_EXTERNAL],
      },
    ],
  },
];

export default feedbacksRoutes;
