import { useMemo } from 'react';

import { usePrevious } from '@feedback/hooks';

import { hasPermission } from '../../security';

import type { permissionsUserFragment } from '../../security/__generated__/permissionsUserFragment.graphql';

import type { RouteType } from './index';
import { drawRoutes } from './index';

export const filterRoutes = (me, routes: RouteType[]) => {
  if (!me) {
    return routes;
  }

  const filtered = routes.reduce((acc, route) => {
    if (
      !hasPermission(
        me,
        route.requiredFeatures,
        route.requiredRoles,
        route.requiredProjectsTypes,
      )
    ) {
      return acc;
    }

    const filterChildren = (field: string, children: RouteType[]) => {
      if (!children) {
        return {};
      }

      return {
        [field]: filterRoutes(me, children),
      };
    };

    return [
      ...acc,
      {
        ...route,
        ...filterChildren('routes', route.routes),
        ...filterChildren('modalRoutes', route.modalRoutes),
      },
    ];
  }, []);

  return filtered;
};

const arrayCompare = (a: string[], b: string[]) => {
  if (a.length !== b.length) {
    return false;
  }

  for (const item of a) {
    if (b.indexOf(item) === -1) {
      return false;
    }
  }

  return true;
};

export const useFilterRoutes = (
  me: permissionsUserFragment,
  routes: RouteType,
) => {
  const mePrevious = usePrevious(me);

  const mePermissions = [...(me?.allRoles || []), me?.company?.features || []];
  const mePreviousPermissions = [
    ...(mePrevious?.allRoles || []),
    mePrevious?.company?.features || [],
  ];

  const permissionChanged = arrayCompare(mePermissions, mePreviousPermissions);

  return useMemo(() => {
    const filtered = filterRoutes(me, routes);

    return drawRoutes(filtered);
  }, [permissionChanged]);
};
