import { Sidebar as _Sidebar } from '@feedback/ui';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import { useTheme } from '@feedback/hooks';
import { useTranslation } from 'react-i18next';

// import { createQueryRendererModern } from '../../relay';

import items from './menuItems';

import type { Sidebar_query$key } from './__generated__/Sidebar_query.graphql';
import SidebarHeader from './SidebarHeader';

export type Props = {
  query: Sidebar_query$key;
};
const Sidebar = (props: Props) => {
  const query = useFragment<Sidebar_query$key>(
    graphql`
      fragment Sidebar_query on Query {
      ...menuItems_query
      me {
        ...permissionsUserFragment @relay(mask: false)
        ...SidebarHeader_me
        id
        name
        isAdmin
        allRoles
        hasGoalGroup
        isResponsibleByCostRevenueCenters
        isResponsibleForSomeCase
        isInNineBox
        hasNineBoxReportAvailable
        hasDataExports
        businessDivisions(first: 1) {
          edges {
            node {
              id
            }
          }
        }
        myTeam(first: 3) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              name
              nick
            }
          }
        }
        company {
          projectType
          features
          nameFriendly
          projectType
          ...Brand_company
          preferences {
            complaint {
              module {
                name
              }
              hideMenu
            }
            feedback {
              module {
                name
                pluralName
              }
              allowFeedbackRequest
              allowSelfReview
            }
            complaintExternal {
              module {
                name
              }
            }
          }
        }
      }
      version
    }
    `
  , props.query);

  const theme = useTheme();
  const { t } = useTranslation();
  const { me } = query;

  const sidebarItems = useMemo(() => {
    return items({ menuItemsFragmentRef: query, t });
  }, [query, t]);

  return <_Sidebar header={<SidebarHeader me={me} />} items={sidebarItems} theme={theme} />;
};

export default Sidebar;

// TODO - needs a shimmer
// export default
//   createQueryRendererModern(SidebarFragmentContainer, Sidebar, {
//     query: graphql`
//       query SidebarQuery {
//         ...Sidebar_query
//       }
//     `,
//     loadingView: null,
//   });
