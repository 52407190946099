/**
 * @generated SignedSource<<7764a52a093c4c972936d3b1e41013f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type CompanyProjectType = "FEEDBACK_HOUSE" | "LOGID" | "OPEN_PIX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type menuItems_query$data = {
  readonly me: {
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly businessDivisions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly company: {
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly nameFriendly: string | null | undefined;
      readonly preferences: {
        readonly complaint: {
          readonly hideMenu: boolean | null | undefined;
          readonly module: {
            readonly name: string;
          };
        };
        readonly complaintExternal: {
          readonly module: {
            readonly name: string;
          };
        };
        readonly feedback: {
          readonly allowFeedbackRequest: boolean | null | undefined;
          readonly allowSelfReview: boolean | null | undefined;
          readonly module: {
            readonly name: string;
            readonly pluralName: string;
          };
        };
      };
      readonly projectType: CompanyProjectType | null | undefined;
    } | null | undefined;
    readonly feedbacksReceivedCount: number;
    readonly hasDataExports: boolean;
    readonly hasGoalGroup: boolean;
    readonly hasNineBoxReportAvailable: boolean;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly isInNineBox: boolean;
    readonly isResponsibleByCostRevenueCenters: boolean;
    readonly isResponsibleForSomeCase: boolean | null | undefined;
    readonly myTeam: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null | undefined;
          readonly nick: string | null | undefined;
        };
      } | null | undefined>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly pendingFeedbackRequestCount: number;
    readonly pendingFeedbackRequestSentCount: number;
    readonly pendingUserManagerInvitationsCount: number;
    readonly pendingUserManagerTransfersCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionFragment">;
  } | null | undefined;
  readonly " $fragmentType": "menuItems_query";
};
export type menuItems_query$key = {
  readonly " $data"?: menuItems_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"menuItems_query">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "menuItems_query"
};

(node as any).hash = "088a690ef9340e5cc8f7d098256c3a4c";

export default node;
