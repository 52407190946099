const Flag = ({ width, color, viewBox }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={viewBox}
      width={width}
      height='24px'
    >
      <g fill={color} fillRule='nonzero'>
        <path d='M2.526.45A1.426 1.426 0 0 0 1.48.017C1.07.017.722.16.433.45A1.426 1.426 0 0 0 0 1.496c0 .555.246.979.74 1.272V17.4c0 .1.036.187.11.26.073.073.16.11.26.11h.74c.1 0 .186-.036.26-.11a.355.355 0 0 0 .11-.26V2.768c.493-.293.739-.717.739-1.272 0-.408-.145-.757-.433-1.046zM19.754 1.716a.712.712 0 0 0-.52-.22c-.1 0-.31.081-.63.243-.32.162-.659.343-1.017.543-.358.2-.782.382-1.271.544-.49.161-.943.242-1.359.242-.385 0-.724-.073-1.017-.22-.971-.454-1.814-.793-2.531-1.017a7.726 7.726 0 0 0-2.312-.335c-1.426 0-3.048.463-4.866 1.387-.447.224-.752.39-.914.497-.238.178-.358.39-.358.636v8.577c0 .2.073.374.22.52a.71.71 0 0 0 .52.22c.123 0 .25-.035.381-.104 2.096-1.126 3.884-1.688 5.364-1.688.562 0 1.111.085 1.647.254.535.17.98.355 1.335.555.354.2.772.385 1.254.555.482.17.957.254 1.427.254 1.187 0 2.609-.447 4.266-1.34.208-.109.36-.214.456-.319.097-.104.145-.252.145-.445v-8.82a.71.71 0 0 0-.22-.519z' />
      </g>
    </svg>
  );
};

Flag.defaultProps = {
  color: '#607389',
  viewBox: '0 0 17 17',
  height: 24,
};

export default Flag;
