import loadable from '@loadable/component';

import {
  ADMIN_ROLE,
  COMPANY_PROJECT_TYPES_ENUM,
  MODULES,
  ROLES,
} from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring';

const myInterviews = (name: string, path: string) => ({
  name,
  path,
  component: loadable(
    () => import('../../../components/interviews/MyInterviews'),
  ),
  exact: false,
  labelHeader: 'Entrevistas',
  label: 'Minhas Entrevistas',
  requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.INTERVIEW.ALL],
  requiredFeatures: [MODULES.HIRING],
  requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
});

const interviewRoutes: RouteType[] = [
  {
    name: 'candidates.view.interview.schedule',
    path: `${PATH}/candidate/view/:id/interviewSchedule`,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    labelHeader: 'Contratação',
    exact: true,
    component: loadable(
      () => import('../../../components/interviews/InterviewScheduleAdd'),
    ),
  },
  {
    name: 'candidates.view.evaluation.add',
    path: `${PATH}/candidate/:id/interviews/add/:interviewScheduleId?`,
    component: loadable(
      () =>
        import('../../../components/candidate/detail/interviews/InterviewAdd'),
    ),
    exact: true,
    labelHeader: 'Contratação',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.CANDIDATE.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'candidates.jobApplication.evaluation.add',
    path: `${PATH}/candidate/:id/interviews/:jobApplication/add`,
    component: loadable(
      () =>
        import('../../../components/candidate/detail/interviews/InterviewAdd'),
    ),
    exact: true,
    labelHeader: 'Contratação',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.CANDIDATE.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'interviews',
    path: `${PATH}/interviews`,
    component: loadable(
      () => import('../../../components/interviews/InterviewsTab'),
    ),
    exact: false,
    labelHeader: 'Gestão de Entrevistas',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
    routes: [
      {
        ...myInterviews(
          'interviews.myInterviews',
          `${PATH}/interviews/myInterviews`,
        ),
      },
      {
        name: 'interviews.interviewsScheduleCalendar',
        path: `${PATH}/interviews/interviewsScheduleCalendar`,
        component: loadable(
          () =>
            import('../../../components/interviews/InterviewsScheduleCalendar'),
        ),
        exact: true,
        labelHeader: 'Entrevistas',
        label: 'Calendário',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.INTERVIEW.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'interviews.scheduledInterviews',
        path: `${PATH}/interviews/scheduledInterviews`,
        component: loadable(
          () => import('../../../components/interviews/ScheduledInterviews'),
        ),
        exact: false,
        labelHeader: 'Entrevistas',
        label: 'Todas Entrevistas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.INTERVIEW.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
    ],
  },
  {
    name: 'myInterviews',
    path: `${PATH}/myInterviews`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Minhas Entrevistas',
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        ...myInterviews(
          'myInterviews.myInterviews',
          `${PATH}/myInterviews/myInterviews`,
        ),
      },
    ],
  },
  {
    name: 'interview.schedule.edit',
    path: `${PATH}/interviewSchedule/:id/edit`,
    labelHeader: 'Entrevista Agendada - Editar',
    component: loadable(
      () => import('../../../components/interviews/InterviewScheduleEdit'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'interview.schedule.add',
    path: `${PATH}/interviewSchedule/add/:id?`,
    requiredRoles: [ADMIN_ROLE, ROLES.HIRING.ALL, ROLES.HIRING.INTERVIEW.ALL],
    requiredFeatures: [MODULES.HIRING],
    labelHeader: 'Contratação',
    exact: true,
    component: loadable(
      () => import('../../../components/interviews/InterviewScheduleAdd'),
    ),
  },
];

export default interviewRoutes;
