import { commitMutation, graphql } from 'react-relay';

import { Environment } from '../../../relay/index';

import type {
  UserEditProfileImageInput,
  UserEditProfileImageMutationResponse,
} from './__generated__/UserEditProfileImageMutation.graphql';

const mutation = graphql`
  mutation UserEditProfileImageMutation($input: UserEditProfileImageInput!) {
    UserEditProfileImage(input: $input) {
      user {
        id
        profileImage {
          url(width: 50, height: 50)
        }
      }
      me {
        id
        profileImage {
          url
        }
      }
      error
    }
  }
`;

const commit = (
  input: UserEditProfileImageInput,
  onCompleted: (response: UserEditProfileImageMutationResponse) => void,
  onError: (error: Error) => void,
) => {
  const variables = {
    input,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
};

export default { commit };
