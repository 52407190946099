import styled from 'styled-components';

type Props = {
  lat: number;
  lng: number;
};

const Marker = styled.div<Props>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  background-color: ${(props) => (props.onClick ? '#bd3d3a' : '#000')};
  border: 1px solid rgb(255, 255, 255, 0.9);
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
    border-color: #bc0303;
  }
`;

export default Marker;
