import styled from 'styled-components';

import type { RouteComponentProps } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';

import ProfilePicBadge from './ProfilePicBadge';

const UserContainer = styled.div`
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  > div:first-child {
    padding: 0;
  }
  p {
    font-size: 12px;
    color: #9e9e9e;
    margin: 0;
  }
  > div.subBox {
    text-align: center;
    a {
      font-size: 10px;
      color: #9e9e9e;
      text-decoration: none;
      margin: 0;
    }
  }
`;

const SmallerText = styled.p`
  font-size: 9px;
  color: #9e9e9e;
  height: 12px;
  margin: 0;
  span {
    font-weight: bold;
    font-size: 12px;
    color: #808080;
  }
`;

function truncate(str: string, n: number): string | null {
  if (!str) {
    return '';
  }

  return str.length > n ? `${str.substring(0, n)}.` : str;
}

type OwnProps = {
  name: string;
  jobTitle: string;
  image: string;
  width: number;
  nfs: string;
  userUrl: string;
  height: number;
};

type Props = OwnProps & RouteComponentProps;

const UserIdCard = ({
  name,
  jobTitle,
  image,
  width,
  nfs,
  userUrl,
  height,
  history,
  ...props
}: Props) => {
  return (
    <UserContainer
      height={height}
      onClick={() => history.push(userUrl)}
      {...props}
    >
      <ProfilePicBadge size={width} profileImage={image} />
      {name ? <p>{truncate(name, 15)}</p> : <p>{'Anônimo'}</p>}
      {jobTitle && <p>{jobTitle}</p>}
      {nfs && (
        <div className='subBox'>
          <SmallerText>
            Nota Média: <span>{nfs}</span>
          </SmallerText>
          <Link to={userUrl}>Ver detalhes</Link>
        </div>
      )}
    </UserContainer>
  );
};

UserIdCard.defaultProps = {
  jobTitle: '',
  image: null,
  height: 120,
};

export default withRouter(UserIdCard);
