import { upload } from './upload';

export {
  IMAGE_HEADERS,
  FILE_HEADERS,
  FILE_TYPES,
  toType,
  findHeaders,
  findAllHeaders,
  generateFileName,
  generateHeader,
  secureFile,
  postFile,
} from './helper';

export { getFileHeader } from './getFileHeader';

export default upload;
export { EPreSignedUploadFileType } from './EPreSignedUploadFileType';

export type { UploadResponse } from './types';
