import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { HeadCostRevenueCenterSelectField_query } from './__generated__/HeadCostRevenueCenterSelectField_query.graphql';

type Props = {
  query: HeadCostRevenueCenterSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  fullWidth?: boolean;
  value?: CostRevenueCenter;
  hasAddress?: boolean | null;
};

type CostRevenueCenter = {
  id: string;
  name: string;
};

class HeadCostRevenueCenterSelectField extends Component<Props> {
  static defaultProps = {
    hintText: 'Buscar Mãe',
    name: 'headCostRevenueCenter',
  };

  getItemFromNode = ({ id, name }) => {
    return {
      id,
      name,
    };
  };

  getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      search,
    };
  };

  render() {
    const { query, relay, ...props } = this.props;

    return (
      <ResourceSelectField
        getFragmentVariables={this.getFilterFragmentVariables}
        relay={relay}
        getItemFromNode={this.getItemFromNode}
        connection={query.headCostRevenueCenters}
        {...props}
      />
    );
  }
}

const HeadCostRevenueCenterSelectFieldFragmentContainer =
  createRefetchContainer(
    HeadCostRevenueCenterSelectField,
    {
      query: graphql`
        fragment HeadCostRevenueCenterSelectField_query on Query
        @argumentDefinitions(
          first: { type: Int, defaultValue: 30 }
          search: { type: String }
          after: { type: String }
        ) {
          headCostRevenueCenters(first: $first, search: $search, after: $after)
            @connection(
              key: "HeadCostRevenueCenterSelectField_headCostRevenueCenters"
            ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
    },
    graphql`
      query HeadCostRevenueCenterSelectFieldQuery(
        $first: Int
        $search: String
        $after: String
      ) {
        ...HeadCostRevenueCenterSelectField_query
          @arguments(first: $first, search: $search, after: $after)
      }
    `,
  );

export default HeadCostRevenueCenterSelectFieldFragmentContainer;
