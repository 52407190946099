import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { JobPostingSelectField_query } from './__generated__/JobPostingSelectField_query.graphql';

type Props = {
  query: JobPostingSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: JobPosting;
};

type JobPosting = {
  id: string;
  title: string;
};

type State = {
  input: string;
  search: string;
};

class JobPostingSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Anúncio',
    name: 'jobPosting',
  };

  getItemFromNode = (node) => ({
    id: node.id,
    name: node.title,
  });

  render() {
    const {
      query,
      relay, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={query.jobPostings}
        getItemFromNode={this.getItemFromNode}
        {...props}
      />
    );
  }
}

const JobPostingSelectFieldFragmentContainer = createRefetchContainer(
  JobPostingSelectField,
  {
    query: graphql`
      fragment JobPostingSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 30 }
        search: { type: String, defaultValue: "" }
        jobRoleIds: { type: "[ID]" }
        after: { type: String }
      ) {
        jobPostings(
          first: $first
          jobRoleIds: $jobRoleIds
          search: $search
          after: $after
        ) @connection(key: "JobPostingSelectField_jobPostings", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobPostingSelectFieldQuery(
      $first: Int
      $search: String
      $jobRoleIds: [ID]
      $after: String
    ) {
      ...JobPostingSelectField_query
        @arguments(
          first: $first
          jobRoleIds: $jobRoleIds
          search: $search
          after: $after
        )
    }
  `,
);

export default JobPostingSelectFieldFragmentContainer;
