type Props = {
  color?: string;
  width: string;
};
const Trash = ({ width = '24px', color = '#757575' }: Props) => (
  <svg width={width} viewBox='0 0 24 24'>
    <g fill={color} fillRule='nonzero'>
      <path d='M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 002 2h8a2 2 0 002-2V7H6v12z' />
    </g>
  </svg>
);

export default Trash;
