/**
 * @generated SignedSource<<4e6d66fa22d6f5ace9ebfbbbf7bde907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type FeedbackFormItemTypeEnum = "IDENTIFIED" | "PROFILE" | "RATINGS" | "REACTION" | "SCORE" | "TEXT" | "TOPIC" | "%future added value";
export type FeedbackTopicModulesTypeEnum = "EXTERNAL" | "INTERNAL" | "ONE_TO_ONE" | "USER_OBJECTIVE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedbackUtils_company$data = {
  readonly preferences: {
    readonly feedback: {
      readonly alwaysAnonymous: boolean | null | undefined;
      readonly formItems: ReadonlyArray<{
        readonly optional: boolean | null | undefined;
        readonly params: ReadonlyArray<{
          readonly key: string | null | undefined;
          readonly value: string | null | undefined;
        } | null | undefined> | null | undefined;
        readonly type: FeedbackFormItemTypeEnum | null | undefined;
      } | null | undefined>;
      readonly formType: string | null | undefined;
      readonly scoreScale: {
        readonly initialScoreValue: number | null | undefined;
        readonly max: number;
        readonly min: number;
      };
    };
    readonly feedbackExternal: {
      readonly alwaysAnonymous: boolean | null | undefined;
      readonly formItems: ReadonlyArray<{
        readonly optional: boolean | null | undefined;
        readonly params: ReadonlyArray<{
          readonly key: string | null | undefined;
          readonly value: string | null | undefined;
        } | null | undefined> | null | undefined;
        readonly type: FeedbackFormItemTypeEnum | null | undefined;
      } | null | undefined>;
      readonly formType: string | null | undefined;
      readonly scoreScale: {
        readonly initialScoreValue: number | null | undefined;
        readonly max: number;
        readonly min: number;
      };
    };
    readonly feedbackExternalGroup: {
      readonly alwaysAnonymous: boolean | null | undefined;
      readonly formItems: ReadonlyArray<{
        readonly optional: boolean | null | undefined;
        readonly params: ReadonlyArray<{
          readonly key: string | null | undefined;
          readonly value: string | null | undefined;
        } | null | undefined> | null | undefined;
        readonly type: FeedbackFormItemTypeEnum | null | undefined;
      } | null | undefined>;
      readonly formType: string | null | undefined;
      readonly scoreScale: {
        readonly initialScoreValue: number | null | undefined;
        readonly max: number;
        readonly min: number;
      };
    };
    readonly feedbackGroup: {
      readonly formItems: ReadonlyArray<{
        readonly params: ReadonlyArray<{
          readonly key: string | null | undefined;
          readonly value: string | null | undefined;
        } | null | undefined> | null | undefined;
        readonly type: FeedbackFormItemTypeEnum | null | undefined;
      } | null | undefined>;
      readonly scoreScale: {
        readonly initialScoreValue: number | null | undefined;
        readonly max: number;
        readonly min: number;
      };
    };
  };
  readonly utilsTopics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isDisabled: boolean | null | undefined;
        readonly modules: ReadonlyArray<FeedbackTopicModulesTypeEnum | null | undefined> | null | undefined;
        readonly onlyForManagers: boolean | null | undefined;
        readonly title: string | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly " $fragmentType": "FeedbackUtils_company";
};
export type FeedbackUtils_company$key = {
  readonly " $data"?: FeedbackUtils_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackUtils_company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "FeedbackUtils_company"
};

(node as any).hash = "35ce0201a6bccee68f12765b81e94ba3";

export default node;
