const Bradesco = ({ width = 150 }) => {
  return (
    <svg width={width} viewBox='0 0 300 300'>
      <g fillRule='evenodd' clipRule='evenodd'>
        <path
          d='M0 0h306.064v303.923H179.477v-125.91H151.73v125.91h-14.74v-85.964h-13.871v85.964H0V0zm10.402 105.94c16.182-7.233 32.366-14.47 48.555-21.709 64.99-90.722 149.417-56.375 179.477-13.025C168.49 26.343 116.755 49.211 91.038 79.89c196.535-20.414 245.667 99.419 139.592 143.277 93.926-75.547-25.436-137.2-149.995-131.122-29.922 55.282-20.1 96.095 7.801 132.858C58.352 201.839 38.4 168.129 54.622 96.387c-14.741 3.187-29.482 6.37-44.22 9.553z'
          fill='#cc2229'
        />
      </g>
    </svg>
  );
};

export default Bradesco;
