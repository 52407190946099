import IconButton from '@material-ui/core/IconButton';
import _Snackbar from '@material-ui/core/Snackbar';
import _SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import type { ReactNode } from 'react';
import { useState } from 'react';

import styled from 'styled-components';

import SnackbarContext from './SnackbarContext';

const SnackbarContent = styled(_SnackbarContent)`
  background-color: #fff;
  color: #000;
  margin-bottom: 45px;
`;

const Action = ({
  handleClose,
}: {
  handleClose: (evt: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <IconButton
      key='close'
      aria-label='Close'
      color='primary'
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  );
};

const Content = ({
  handleClose,
  message,
  action,
}: {
  handleClose: (evt: React.MouseEvent<HTMLElement>) => void;
  message: string;
  action?: React.ReactElement<any>;
}) => {
  return (
    <SnackbarContent
      message={message}
      action={action || <Action handleClose={handleClose} />}
    />
  );
};

type SnackbarProps = {
  children: ReactNode;
};
const Snackbar = ({ children }: SnackbarProps) => {
  const handleSnackbarConfirm = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage('');
  };

  const [message, setMessage] = useState('');
  const [action, setAction] = useState(
    <Action handleClose={handleSnackbarConfirm} />,
  );

  const [duration, setDuration] = useState(8000);
  const [onClick, setOnClick] = useState<(() => void) | null>(null);

  const handleSnackbar = ({
    message,
    action = <Action handleClose={handleSnackbarConfirm} />,
    duration = 4000,
    onActionClick = null,
  }: {
    message: string;
    action?: React.ReactNode;
    duration: number;
    onActionClick?: (() => void) | null;
  }) => {
    setMessage(message);
    setAction(action);
    setDuration(duration);
    setOnClick(onActionClick);
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar: handleSnackbar,
      }}
    >
      <_Snackbar
        open={!!message}
        autoHideDuration={duration}
        onClose={handleSnackbarConfirm}
      >
        <Content
          handleClose={onClick || handleSnackbarConfirm}
          message={message}
          action={action}
        />
      </_Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default Snackbar;
