import PropTypes from 'prop-types';

const Checklist = ({ className, width }) => {
  return (
    <div className={className}>
      <svg width={width} viewBox='0 0 65 65'>
        <g fill='#000' fillRule='nonzero' opacity='.3'>
          <path d='M28 16h13v5H28z' />
          <path d='M5.125 57.383V7.617c0-1.4 1.149-2.539 2.562-2.539h36.626c1.413 0 2.562 1.14 2.562 2.54V32.49L52 27.268V7.618C52 3.416 48.552 0 44.313 0H7.687C3.448 0 0 3.417 0 7.617v49.766C0 61.583 3.448 65 7.687 65h19.562l1.165-5.078H7.687c-1.413 0-2.562-1.14-2.562-2.54z' />
          <path d='M28 30h13v5H28zM54.126 32L36.97 49.41a8.906 8.906 0 0 0-2.167 3.475l-.038.115L32 65l12.19-2.694.121-.04a9.064 9.064 0 0 0 3.535-2.15L65 42.71 54.126 32zm-9.9 24.563a3.83 3.83 0 0 1-.302.264l-3.606-3.561a3.81 3.81 0 0 1 .276-.304L54.18 39.174l3.644 3.588-13.598 13.8zM22.327 26l-5.993 6.883-2.982-2.836L10 33.662 16.666 40 26 29.28zM22.327 10l-5.993 6.883-2.982-2.836L10 17.662 16.666 24 26 13.28zM13.352 44.047L10 47.662 16.666 54 26 43.28 22.327 40l-5.993 6.883z' />
        </g>
      </svg>
    </div>
  );
};

Checklist.propTypes = {
  width: PropTypes.number.isRequired,
};

export default Checklist;
