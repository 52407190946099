import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/social';

const socialRoutes: RouteType[] = [
  {
    name: 'social.post.detail',
    path: `${PATH}/post/:id`,
    labelHeader: 'Social',
    label: 'Social',
    component: loadable(() => import('../../../components/social/PostDetail')),
    exact: true,
    requiredFeatures: [MODULES.SOCIAL],
    requiredRoles: [ROLES.SOCIAL.NEWSFEED.ALL],
  },
  {
    name: 'social.settings',
    path: `${PATH}/analytics`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    labelHeader: 'Gestão de comunicação interna',
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.SOCIAL.SEE_ALL_POSTS.ALL,
      ROLES.SOCIAL.ANALYTICS.ALL,
    ],
    requiredFeatures: [MODULES.SOCIAL],
    routes: [
      {
        name: 'social.analytics.dashboard',
        path: `${PATH}/analytics/dashboard`,
        labelHeader: 'Dashboard - Social',
        label: 'Dashboard',
        component: loadable(
          () => import('../../../components/social/analytics/SocialDashboard'),
        ),
        exact: true,
        requiredFeatures: [MODULES.SOCIAL],
        requiredRoles: [ADMIN_ROLE, ROLES.SOCIAL.ANALYTICS.ALL],
      },
      {
        name: 'social.post.all',
        path: `${PATH}/analytics/allposts`,
        labelHeader: 'Todos os posts - Social',
        label: 'Todos os posts',
        component: loadable(
          () => import('../../../components/social/AllPosts'),
        ),
        exact: true,
        requiredFeatures: [MODULES.SOCIAL],
        requiredRoles: [ADMIN_ROLE, ROLES.SOCIAL.SEE_ALL_POSTS.ALL],
      },
      {
        name: 'social.settings.notification',
        path: `${PATH}/analytics/settings`,
        labelHeader: 'Settings - Social',
        label: 'Ajustes',
        component: loadable(
          () => import('../../../components/social/settings/SocialSettings'),
        ),
        exact: true,
        requiredFeatures: [MODULES.SOCIAL],
        requiredRoles: [ADMIN_ROLE],
      },
    ],
  },
];

export default socialRoutes;
