import { TextField } from '@feedback/form';
import { ActionButton } from '@feedback/ui';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { createFragmentContainer, graphql } from 'react-relay';

import type { LoginTextField_company } from './__generated__/LoginTextField_company.graphql';

type Props = {
  company: LoginTextField_company;
};
const LoginTextField = ({ company }: Props) => {
  const { enableEmail, enableTaxID, enableEnrollment } =
    company.preferences.core.login;

  const { t } = useTranslation();
  const formik = useFormikContext();
  const { isValid } = formik;

  if (!enableEmail && !enableTaxID && !enableEnrollment) {
    return null;
  }

  return (
    <>
      <TextField
        name='email'
        label={t('Professional email or CPF')}
        inputProps={{ 'data-testid': 'fieldEmail' }}
        variant='outlined'
        size='small'
      />
      <ActionButton
        disabled={!isValid}
        type='submit'
        data-testid='btn-continue'
        color='secondary'
      >
        {t('Login by Email or TaxID')}
      </ActionButton>
    </>
  );
};

export default createFragmentContainer(LoginTextField, {
  company: graphql`
    fragment LoginTextField_company on Company {
      preferences {
        core {
          login {
            enableEmail
            enableTaxID
            enableEnrollment
          }
        }
      }
    }
  `,
});
