import type { MissingFieldHandler } from 'relay-runtime/lib/store/RelayStoreTypes';

// missingFieldHandlers debug only
export const missingFieldHandlers: ReadonlyArray<MissingFieldHandler> = [
  {
    kind: 'scalar',
    handle(field, record, args, store) {
      // eslint-disable-next-line
      console.log('missing scalar', {
        field,
        record,
        args,
        store,
      });

      return undefined;
    },
  },
  {
    kind: 'linked',
    handle(field, record, args, store) {
      // eslint-disable-next-line
      console.log('missing linked', {
        field,
        record,
        args,
        store,
      });

      return undefined;
    },
  },
  {
    kind: 'pluralLinked',
    handle(field, record, args, store) {
      // eslint-disable-next-line
      console.log('missing pluralLinked', {
        field,
        record,
        args,
        store,
      });

      return undefined;
    },
  },
];
