import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { PerformanceReviewSelectField_query } from './__generated__/PerformanceReviewSelectField_query.graphql';

type Props = {
  query: PerformanceReviewSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  fullWidth?: boolean;
  value?: PerformanceReview;
};

type PerformanceReview = {
  id: string;
  name: string;
};
const PerformanceReviewSelectField = ({
  hintText = 'Buscar Avaliação de Desempenho',
  name = 'performanceReview',
  query,
  relay,
  ...rest
}: Props) => {
  const getItemFromNode = ({ id, title }) => {
    return {
      id,
      name: title,
    };
  };

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.performanceReviews}
      getItemFromNode={getItemFromNode}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

const PerformanceReviewSelectFieldRefetchContainer = createRefetchContainer(
  PerformanceReviewSelectField,
  {
    query: graphql`
      fragment PerformanceReviewSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 50 }
        after: { type: String }
        filters: { type: PerformanceReviewFilter }
      ) {
        performanceReviews(first: $first, after: $after, filters: $filters)
          @connection(
            key: "PerformanceReviewSelectField_performanceReviews"
            filters: ["filters"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `,
  },
  graphql`
    query PerformanceReviewSelectFieldQuery(
      $first: Int
      $after: String
      $filters: PerformanceReviewFilter
    ) {
      ...PerformanceReviewSelectField_query
        @arguments(first: $first, after: $after, filters: $filters)
    }
  `,
);

export default PerformanceReviewSelectFieldRefetchContainer;
