import * as Sentry from '@sentry/browser';

import shouldUseSentry from '../shouldUseSentry';

import UserLogoutMutation from './UserLogoutMutation';

export const TOKEN_KEY = 'tokenFeedbackAdmin';

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function logout() {
  const onCompleted = ({ UserLogout }) => {
    if (!UserLogout || UserLogout.error) {
      // eslint-disable-next-line
      console.log('UserLogoutError: ', UserLogout.error);
    }

    updateToken();
  };

  const onError = (error) => {
    // eslint-disable-next-line
    console.log('UserLogoutError: ', error);

    updateToken();
  };

  const token = getToken();

  if (token) {
    UserLogoutMutation.commit({ token }, onCompleted, onError);
  } else {
    updateToken();
  }
}

export function updateToken(token: string | null = '', shouldReload = true) {
  localStorage.setItem(TOKEN_KEY, token);

  if (!token || token === '' || token === null) {
    localStorage.removeItem(TOKEN_KEY);
    if (shouldUseSentry) {
      Sentry.configureScope((scope) => {
        scope.setUser(null);
      });
    }

    if (shouldReload) {
      // TODO - redirect to login
      window.location.reload();
    }
  }
}

export const getDomainName = () => {
  // Used for debug on Staging
  // if (process.env.FEEDBACK_ENV === 'development') {
  //   const host = window.location.host.split(':')[0];
  //
  //   return host.replace('feedback.local', 'feedback.house');
  // }
  return window.location.hostname;
};

export const isValidRedirectDomainName = () => {
  const domainName = getDomainName();

  const validRedirectDomains = [
    'redirect-service.feedback.house',
    'redirect-service-staging.feedback.house',
    'redirect-service.feedback.local:7001',
  ];

  return validRedirectDomains.indexOf(domainName) > -1;
};
