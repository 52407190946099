import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/journey';

// TODO: Create roles and modules

const journeyRoutes: RouteType[] = [
  {
    name: 'journey.root',
    path: `${PATH}`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Jornada',
    requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'dayJourney.list',
        path: `${PATH}/dayJourney`,
        label: 'Dia de Jornada',
        labelHeader: 'Jornada',
        component: loadable(
          () => import('../../../components/journey/dayJourney/DayJourneyList'),
        ),
        exact: false,
        requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
        requiredFeatures: [MODULES.PLANNING],
        modalRoutes: [
          {
            name: 'dayJourney.add',
            modalTitle: 'Criar dia de jornada',
            labelHeader: 'Jornada',
            path: `${PATH}/dayJourney/add`,
            component: loadable(
              () =>
                import('../../../components/journey/dayJourney/DayJourneyAdd'),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
            requiredFeatures: [MODULES.PLANNING],
          },
          {
            name: 'dayJourney.edit',
            modalTitle: 'Editar dia de jornada',
            labelHeader: 'Jornada',
            path: `${PATH}/dayJourney/edit/:id`,
            component: loadable(
              () =>
                import('../../../components/journey/dayJourney/DayJourneyEdit'),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
            requiredFeatures: [MODULES.PLANNING],
          },
        ],
      },
      {
        name: 'journeySchedule.list',
        path: `${PATH}/journeySchedule`,
        label: 'Cronograma de Jornada',
        labelHeader: 'Jornada',
        component: loadable(
          () =>
            import(
              '../../../components/journey/journeySchedule/JourneyScheduleList'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
  {
    name: 'journeySchedule.add',
    path: `${PATH}/journeySchedule/add`,
    labelHeader: 'Cronograma de Jornada - Adicionar',
    component: loadable(
      () =>
        import(
          '../../../components/journey/journeySchedule/JourneyScheduleAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
    requiredFeatures: [MODULES.PLANNING],
  },
  {
    name: 'journeySchedule.view',
    path: `${PATH}/journeySchedule/view/:id`,
    labelHeader: 'Cronograma de Jornada - Detalhes',
    component: loadable(
      () =>
        import('../../../components/journey/journeySchedule/JourneySchedule'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'journeySchedule.edit',
        path: `${PATH}/journeySchedule/view/:id/edit`,
        labelHeader: 'Cronograma de Jornada - Detalhes',
        requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
        requiredFeatures: [MODULES.PLANNING],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/journey/journeySchedule/JourneyScheduleEdit'
            ),
        ),
        label: 'CRONOGRAMA',
      },
      {
        name: 'journeySchedule.users',
        path: `${PATH}/journeySchedule/view/:id/users`,
        labelHeader: 'Cronograma de Jornada - Detalhes',
        requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
        requiredFeatures: [MODULES.PLANNING, MODULES.TEMP],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/journey/journeySchedule/JourneyScheduleUsersList'
            ),
        ),
        label: 'USUÁRIOS',
      },
    ],
    modalRoutes: [
      {
        name: 'userJourneySchedule.add',
        path: `${PATH}/journeySchedule/view/:id/user/add`,
        labelHeader: 'Cronograma de Jornada - Vincular Usuário',
        component: loadable(
          () =>
            import(
              '../../../components/journey/journeySchedule/UserJourneyScheduleAdd'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PLANNING.ALL],
        requiredFeatures: [MODULES.PLANNING, MODULES.TEMP],
      },
    ],
  },
];

export default journeyRoutes;
