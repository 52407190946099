import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@material-ui/core';
import { useFormikContext } from 'formik';

import get from 'lodash/get';
import { useState } from 'react';

type Option = {
  value: string;
  label?: string | React.Component;
  disabled?: boolean;
};

export type RadioGroupProps = {
  name: string;
  options: Option[];
  value: boolean | string | Record<string, unknown>;
  onChange?: (
    value: boolean | string | Record<string, unknown>,
  ) => Record<string, unknown> | unknown;
  className: string;
  radio?: React.Component;
  useFormik?: boolean;
  row?: boolean;
  color?: string;
};

const RadioGroup = ({
  name,
  options,
  value,
  onChange,
  className,
  useFormik = true,
  color = 'primary',
  row = false,
}: RadioGroupProps) => {
  const [itsValue, setItsValue] = useState<string>('');

  const formik = useFormikContext();

  const formikEnabled = useFormik && formik && 'setFieldValue' in formik;

  const normalize = (value: string | boolean) => {
    if (value === 'true' || value === 'false') {
      return value === 'true' ? true : false;
    }

    return value;
  };

  const booleanToString = (value: string | boolean) =>
    typeof value === 'boolean' ? value.toString() : value;

  const handleChange = (value: string) => {
    // update state with string
    setItsValue(value);

    const normalizedValue: string | boolean = normalize(value);

    if (formikEnabled) {
      formik.setFieldValue(name, normalizedValue);
    }

    onChange && onChange(normalizedValue);
  };

  const getSelectedValue = () => {
    if (formikEnabled) {
      return get(formik.values, name, '');
    }

    return value;
  };

  const currentValue = booleanToString(getSelectedValue()) || itsValue;

  const getTestId = (value) => {
    if (typeof value === 'boolean') {
      return `${name}-${booleanToString(value)}`;
    }

    return `${name}-${value}`;
  };

  return (
    <MUIRadioGroup
      name={name}
      color={color}
      value={currentValue}
      onChange={(event) => handleChange(event.target.value)}
      className={className}
      row={row}
    >
      {options.map(({ value, label, disabled }, index) => (
        <FormControlLabel
          key={index}
          color={color}
          value={booleanToString(value)}
          control={<Radio color={color} />}
          label={label || value}
          disabled={disabled || false}
          data-testid={getTestId(value)}
        />
      ))}
    </MUIRadioGroup>
  );
};

export default RadioGroup;
