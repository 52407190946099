import PropTypes from 'prop-types';

const GiveFeedback = ({ width, color }) => {
  const styles = {
    fill: color || '#757575',
  };

  return (
    <svg viewBox='0 0 40 25' width={width}>
      <g>
        <g fill={color}>
          <path
            style={styles}
            d='M26.4,7.8V2.5c0-0.3-0.2-0.5-0.4-0.7c-0.2-0.1-0.5-0.1-0.8,0C20,5.5,16.9,6,14.9,6H9c-2.3,0-4.2,1.9-4.2,4.2
            v1.6C4.8,14.1,6.7,16,9,16h2.8l3.1,7.4c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1c0.4-0.2,0.6-0.6,0.4-1L13.4,16h1.5
            c7.2,0,10.1,3.8,10.2,4c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.4,0.5-0.7v-5.3c1.5-0.3,2.6-1.6,2.6-3.2
            C29,9.4,27.9,8.1,26.4,7.8z M6.3,11.8v-1.6c0-1.5,1.2-2.7,2.7-2.7h2.6v7H9C7.5,14.5,6.3,13.3,6.3,11.8z M24.9,17.8
            c-1.6-1.3-4.9-3.2-10.1-3.2H13v-7h1.8C17,7.5,20,7,24.9,3.9V17.8z M26.4,12.7V9.4c0.6,0.3,1.1,0.9,1.1,1.7
            C27.5,11.7,27.1,12.4,26.4,12.7z'
          />
          <path
            style={styles}
            d='M33.5,10.3h-2.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7
            C34.3,10.6,34,10.3,33.5,10.3z'
          />
          <path
            style={styles}
            d='M29.6,8.7C29.8,9,30,9.2,30.3,9.2c0.1,0,0.2,0,0.3,0L33.4,8c0.4-0.2,0.6-0.6,0.4-1c-0.2-0.4-0.6-0.6-1-0.4
            l-2.8,1.1C29.7,7.9,29.5,8.3,29.6,8.7z'
          />
          <path
            style={styles}
            d='M33.4,14l-2.8-1.1c-0.4-0.2-0.8,0-1,0.4c-0.2,0.4,0,0.8,0.4,1l2.8,1.1c0.1,0,0.2,0,0.3,0
            c0.3,0,0.6-0.2,0.7-0.5C34,14.6,33.8,14.1,33.4,14z'
          />
        </g>
      </g>
    </svg>
  );
};

GiveFeedback.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default GiveFeedback;
