import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { PositionApplicationStatusSelectField_query } from './__generated__/PositionApplicationStatusSelectField_query.graphql';

type Props = {
  query: PositionApplicationStatusSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: PositionApplicationStatus;
};

const toIdName = (positionApplicationStatus) => ({
  id: positionApplicationStatus.id,
  name: positionApplicationStatus.status,
});

type PositionApplicationStatus = {
  id: string;
  name: string;
};

type State = {
  input: string;
  search: string;
};
class PositionApplicationStatusSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Status',
    name: 'positionApplicationStatus',
  };

  render() {
    const { query, relay, onChange, ...props } = this.props;
    const { statuses } = query;

    const connection = {
      count: statuses.length,
      edges: statuses.map((node) => ({ node: toIdName(node) })),
    };

    return (
      <ResourceSelectField
        relay={relay}
        connection={connection}
        onChange={onChange}
        {...props}
      />
    );
  }
}

const PositionApplicationStatusSelectFieldFragmentContainer =
  createRefetchContainer(
    PositionApplicationStatusSelectField,
    {
      query: graphql`
        fragment PositionApplicationStatusSelectField_query on Query
        @argumentDefinitions(
          filters: {
            type: "PositionApplicationStatusFilter"
            defaultValue: { isActive: true }
          }
        ) {
          statuses: positionApplicationStatuses(filters: $filters) {
            id
            status
            position
          }
        }
      `,
    },
    graphql`
      query PositionApplicationStatusSelectFieldQuery(
        $filters: PositionApplicationStatusFilter
      ) {
        ...PositionApplicationStatusSelectField_query
          @arguments(filters: $filters)
      }
    `,
  );

export default PositionApplicationStatusSelectFieldFragmentContainer;
