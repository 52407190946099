/**
 * @generated SignedSource<<f1aabfe6f2214971184d08a3009df646>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectionEnum = "ASC" | "DESC" | "%future added value";
export type UserSortEnum = "CREATED_AT" | "NAME" | "%future added value";
export type UserFilter = {
  AND?: ReadonlyArray<UserFilter | null | undefined> | null | undefined;
  OR?: ReadonlyArray<UserFilter | null | undefined> | null | undefined;
  birthDate?: string | null | undefined;
  businessDivision?: string | null | undefined;
  costRevenueCenter?: string | null | undefined;
  exclude?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeBusinessDivision?: string | null | undefined;
  excludeCostRevenueCenters?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeGoalGroups?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeGroups?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeManagers?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeNineBoxes?: ReadonlyArray<string | null | undefined> | null | undefined;
  excludeRoleGroups?: ReadonlyArray<string | null | undefined> | null | undefined;
  feedback?: FeedbackUserFilter | null | undefined;
  gender?: string | null | undefined;
  goalGroup?: string | null | undefined;
  group?: string | null | undefined;
  hasAnswerCheckin?: boolean | null | undefined;
  hasAnsweredPoll?: boolean | null | undefined;
  hasGoalGroup?: boolean | null | undefined;
  hasHeadCount?: boolean | null | undefined;
  hasLoggedIn?: boolean | null | undefined;
  hasPendingTransfer?: boolean | null | undefined;
  hasPerformanceReview?: boolean | null | undefined;
  hasPerformanceReviewer?: UserPerformanceReviewOnUserFilter | null | undefined;
  include?: ReadonlyArray<string | null | undefined> | null | undefined;
  includeMe?: boolean | null | undefined;
  indirectManager?: string | null | undefined;
  invitedToPoll?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  isBlocked?: boolean | null | undefined;
  isEmailVerified?: boolean | null | undefined;
  isInPerformanceReview?: string | null | undefined;
  isManager?: boolean | null | undefined;
  isNotInNineBox?: string | null | undefined;
  isNotInPerformanceReview?: string | null | undefined;
  jobTitle?: ReadonlyArray<string | null | undefined> | null | undefined;
  manager?: string | null | undefined;
  notInvitedToPoll?: string | null | undefined;
  oneOnOne?: OneOnOneOnUserFilter | null | undefined;
  onlyActive?: boolean | null | undefined;
  orderBy?: ReadonlyArray<UserOrdering> | null | undefined;
  possibleDuplicated?: boolean | null | undefined;
  role?: string | null | undefined;
  roleGroup?: string | null | undefined;
  search?: string | null | undefined;
  showAll?: boolean | null | undefined;
  tags_in?: ReadonlyArray<string | null | undefined> | null | undefined;
  userObjective?: UserObjectiveOnUserFilter | null | undefined;
  v2?: boolean | null | undefined;
};
export type UserOrdering = {
  direction: DirectionEnum;
  sort: UserSortEnum;
};
export type UserPerformanceReviewOnUserFilter = {
  reviewId?: string | null | undefined;
  reviewerId?: string | null | undefined;
};
export type UserObjectiveOnUserFilter = {
  date_gte?: string | null | undefined;
  date_lte?: string | null | undefined;
  feedbackTopic?: string | null | undefined;
  hasUserObjective?: boolean | null | undefined;
  tags?: string | null | undefined;
  task?: boolean | null | undefined;
  userObjectiveProgress?: boolean | null | undefined;
};
export type OneOnOneOnUserFilter = {
  createdBy?: string | null | undefined;
  date_gte?: string | null | undefined;
  date_lte?: string | null | undefined;
  feedbackTopic?: string | null | undefined;
  fromUser?: string | null | undefined;
  hasOneOnOne?: boolean | null | undefined;
  toUser?: string | null | undefined;
};
export type FeedbackUserFilter = {
  date?: DateFilter | null | undefined;
  meanScore?: RangeFilter | null | undefined;
};
export type DateFilter = {
  begin?: string | null | undefined;
  end?: string | null | undefined;
  start?: string | null | undefined;
};
export type RangeFilter = {
  max?: number | null | undefined;
  min?: number | null | undefined;
};
export type UserSelectFieldQuery$variables = {
  filters?: UserFilter | null | undefined;
  first?: number | null | undefined;
  groupIdsNotIn?: ReadonlyArray<string | null | undefined> | null | undefined;
  hasHeadCount?: boolean | null | undefined;
  search?: string | null | undefined;
  searchWithViewer?: boolean | null | undefined;
  userIdNotIn?: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type UserSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserSelectField_query">;
};
export type UserSelectFieldQuery = {
  response: UserSelectFieldQuery$data;
  variables: UserSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupIdsNotIn"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasHeadCount"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchWithViewer"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIdNotIn"
},
v7 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "groupIdsNotIn",
    "variableName": "groupIdsNotIn"
  },
  {
    "kind": "Variable",
    "name": "hasHeadCount",
    "variableName": "hasHeadCount"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "searchWithViewer",
    "variableName": "searchWithViewer"
  },
  {
    "kind": "Variable",
    "name": "userIdNotIn",
    "variableName": "userIdNotIn"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Correlation",
  "kind": "LinkedField",
  "name": "correlations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeeID",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSelectFieldQuery",
    "selections": [
      {
        "args": (v7/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserSelectFieldQuery",
    "selections": [
      {
        "alias": "usersSelected",
        "args": (v7/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "manager",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "usersSelected",
        "args": (v7/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "UserSelectField_usersSelected",
        "kind": "LinkedHandle",
        "name": "users"
      }
    ]
  },
  "params": {
    "cacheID": "feffbc5b4cc8d7a83a199db4bb1d5226",
    "id": null,
    "metadata": {},
    "name": "UserSelectFieldQuery",
    "operationKind": "query",
    "text": "query UserSelectFieldQuery(\n  $first: Int\n  $search: String\n  $searchWithViewer: Boolean\n  $hasHeadCount: Boolean\n  $groupIdsNotIn: [ID]\n  $userIdNotIn: [ID]\n  $filters: UserFilter\n) {\n  ...UserSelectField_query_6rB2a\n}\n\nfragment UserSelectField_query_6rB2a on Query {\n  usersSelected: users(first: $first, search: $search, searchWithViewer: $searchWithViewer, hasHeadCount: $hasHeadCount, groupIdsNotIn: $groupIdsNotIn, userIdNotIn: $userIdNotIn, filters: $filters) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        email\n        active\n        profileImage {\n          url\n          id\n        }\n        correlations {\n          employeeID\n        }\n        manager {\n          id\n          name\n          email\n          active\n          profileImage {\n            url\n            id\n          }\n          correlations {\n            employeeID\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a632526ff051164c6630d0f16879c0b";

export default node;
