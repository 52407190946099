import type { History } from 'history';

export type RouteParamsType = {
  [key: string]: string;
};
export type RouteName = string;

export const safeGoBackRouter = (routeTo: (name: RouteName, params: RouteParamsType, query?: {}) => string) => (
  history: History,
  alternativeRoute: string,
  args: Record<string, any> = {},
) => {
  if (history.length > 2) {
    history.goBack();

    return;
  }

  history.push(routeTo(alternativeRoute, args));
};
