import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../utils';


const PATH = '/home/feedbacks';

const requiredFeedbackAndGroup = [
  {
    $or: [
      {
        $and: [MODULES.FEEDBACK, MODULES.FEEDBACK_GROUP],
      },
      {
        $and: [MODULES.FEEDBACK, MODULES.GROUP],
      },
    ],
  },
];

const requiredFeedbackExternalAndGroup = [
  {
    $or: [
      {
        $and: [MODULES.FEEDBACK_EXTERNAL, MODULES.FEEDBACK_GROUP],
      },
      {
        $and: [MODULES.FEEDBACK_EXTERNAL, MODULES.GROUP],
      },
    ],
  },
];

const requiredFeedbackOrExternalAndGroup = [
  {
    $or: [
      {
        $and: [MODULES.FEEDBACK, MODULES.FEEDBACK_GROUP],
      },
      {
        $and: [MODULES.FEEDBACK, MODULES.GROUP],
      },
      {
        $and: [MODULES.FEEDBACK_EXTERNAL, MODULES.FEEDBACK_GROUP],
      },
      {
        $and: [MODULES.FEEDBACK_EXTERNAL, MODULES.GROUP],
      },
    ],
  },
];

const groupRoutes: RouteType[] = [
  {
    name: 'feedbacks.groups',
    path: `${PATH}/groupslist`,
    labelHeader: ({ t }) => t('Groups'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.FEEDBACK_GROUP.USER.ALL,
      ROLES.SOCIAL.NEWSFEED.ALL,
      ROLES.GROUP.USER.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.SOCIAL, MODULES.GROUP],
    routes: [
      {
        name: 'feedback.mygroups',
        path: `${PATH}/groupslist/myGroups`,
        label: ({ t }) => t('My Groups'),
        labelHeader: ({ t }) => t('My Groups'),
        component: loadable(() => import('../../components/group/MyGroupList')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.USER.ALL,
          ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
          ROLES.SOCIAL.NEWSFEED.ALL,
          ROLES.GROUP.USER.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [
          MODULES.FEEDBACK_GROUP,
          MODULES.SOCIAL,
          MODULES.GROUP,
        ],
        modalRoutes: [
          {
            name: 'feedback.myGroups.new',
            path: `${PATH}/groupslist/myGroups/new`,
            labelHeader: ({ t }) => t('My Groups'),
            component: loadable(
              () => import('../../components/group/add/GroupAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.USER.ALL,
              ROLES.FEEDBACK_GROUP.ADMIN.ALL,
              ROLES.SOCIAL.NEWSFEED.ALL,
              ROLES.GROUP.ADMIN.ALL,
              ROLES.GROUP.ALL,
              ROLES.GROUP.CREATE,
            ],
            requiredFeatures: [
              MODULES.FEEDBACK_GROUP,
              MODULES.SOCIAL,
              MODULES.GROUP,
            ],
          },
        ],
      },
      {
        name: 'feedback.groups',
        path: `${PATH}/groupslist/all`,
        label: ({ t }) => t('All Groups'),
        labelHeader: ({ t }) => t('All Groups'),
        component: loadable(() => import('../../components/group/GroupList')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.USER,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.SOCIAL.NEWSFEED.ALL,
          ROLES.GROUP.USER.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [
          MODULES.FEEDBACK_GROUP,
          MODULES.SOCIAL,
          MODULES.GROUP,
        ],
        modalRoutes: [
          {
            name: 'feedback.groups.new',
            path: `${PATH}/groupslist/all/new`,
            labelHeader: ({ t }) => t('All Groups'),
            component: loadable(
              () => import('../../components/group/add/GroupAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.USER.ALL,
              ROLES.FEEDBACK_GROUP.ADMIN.ALL,
              ROLES.SOCIAL.NEWSFEED.ALL,
              ROLES.GROUP.ADMIN.ALL,
              ROLES.GROUP.CREATE,
            ],
            requiredFeatures: [
              MODULES.FEEDBACK_GROUP,
              MODULES.SOCIAL,
              MODULES.GROUP,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'feedback.groups.detail',
    path: `${PATH}/groups/detail/:id`,
    labelHeader: 'Grupo - Detalhes',
    component: loadable(
      () => import('../../components/group/detail/GroupDetail'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.FEEDBACK_GROUP.USER.ALL,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.SOCIAL.NEWSFEED.ALL,
      ROLES.GROUP.USER.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.SOCIAL, MODULES.GROUP],
    routes: [
      {
        name: 'feedback.groups.detail.newsfeed',
        labelHeader: ({ t }) => t('Group - Social Posts'),
        path: `${PATH}/groups/detail/:id/newsfeed`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
          ROLES.SOCIAL.NEWSFEED.ALL,
          ROLES.GROUP.USER.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [MODULES.SOCIAL, MODULES.GROUP],
        exact: false,
        component: loadable(
          () => import('../../components/group/detail/newsfeed/GroupNewsfeed'),
        ),
        label: ({ t }) => t('Newsfeed'),
      },
      {
        name: 'feedback.groups.detail.feedback',
        labelHeader: ({ t }) => t('Group - Details'),
        label: ({ t }) => t('{{Feedbacks}}'),
        path: `${PATH}/groups/detail/:id/feedbacks`,
        component: loadable(() => import('../../components/common/TabView')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.USER.ALL,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
        ],
        requiredFeatures: requiredFeedbackAndGroup,
        routes: [
          {
            name: 'feedback.groups.detail.evolution',
            path: `${PATH}/groups/detail/:id/feedbacks/evolution`,
            label: ({ t }) => t('Evolution'),
            labelHeader: ({ t }) => t('Group - Details'),
            component: loadable(
              () =>
                import(
                  '../../components/group/detail/dashboard/GroupEvolution'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.ALL,
              ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
            ],
            requiredFeatures: requiredFeedbackAndGroup,
          },
          {
            name: 'feedback.groups.detail.feedback.list',
            path: `${PATH}/groups/detail/:id/feedbacks/list`,
            label: ({ t }) => t('List'),
            labelHeader: ({ t }) => t('Group - Details'),
            component: loadable(
              () => import('../../components/group/detail/GroupFeedbacksTab'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.ALL,
              ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
            ],
            requiredFeatures: requiredFeedbackAndGroup,
            modalRoutes: [
              {
                name: 'feedback.groups.detail.feedbacks.feedback',
                labelHeader: ({ t }) => t('Group - Details'),
                path: `${PATH}/groups/detail/:id/feedbacks/list/:feedbackId`,
                exact: true,
                component: loadable(
                  () =>
                    import(
                      '../../components/group/detail/GroupFeedbackDetailModal'
                    ),
                ),
                requiredRoles: [
                  ADMIN_ROLE,
                  ROLES.FEEDBACK_GROUP.ALL,
                  ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
                ],
                requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
              },
            ],
          },
        ],
      },
      {
        name: 'feedback.groups.detail.feedbackExternal',
        labelHeader: ({ t }) => t('Group - Details'),
        label: ({ t }) => t('External {{Feedbacks}}'),
        path: `${PATH}/groups/detail/:id/feedbackExternal`,
        component: loadable(() => import('../../components/common/TabView')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
          ROLES.FEEDBACK_EXTERNAL.ALL,
          ROLES.FEEDBACK_EXTERNAL.USER.ALL,
        ],
        requiredFeatures: requiredFeedbackExternalAndGroup,
        routes: [
          {
            name: 'feedback.groups.detail.feedbackExternal.evolution',
            path: `${PATH}/groups/detail/:id/feedbackExternal/evolution`,
            label: ({ t }) => t('External Evolution'),
            labelHeader: ({ t }) => t('Group - Details'),
            component: loadable(
              () =>
                import(
                  '../../components/externalGroupFeedback/dashboard/ExternalGroupFeedbackEvolution'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.ALL,
              ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
              ROLES.FEEDBACK_EXTERNAL.ALL,
              ROLES.FEEDBACK_EXTERNAL.USER.ALL,
            ],
            requiredFeatures: requiredFeedbackExternalAndGroup,
          },
          {
            name: 'feedback.groups.detail.feedbackExternal.list',
            path: `${PATH}/groups/detail/:id/feedbackExternal/list`,
            label: ({ t }) => t('List'),
            labelHeader: ({ t }) => t('Group - Details'),
            component: loadable(
              () =>
                import(
                  '../../components/externalGroupFeedback/ExternalGroupFeedbackList'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.ALL,
              ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
              ROLES.FEEDBACK_EXTERNAL.ALL,
              ROLES.FEEDBACK_EXTERNAL.USER.ALL,
            ],
            requiredFeatures: requiredFeedbackExternalAndGroup,
          },
        ],
      },
      {
        name: 'feedback.groups.detail.members',
        labelHeader: ({ t }) => t('Group - Details'),
        path: `${PATH}/groups/detail/:id/members`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
          ROLES.GROUP.USER.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [
          MODULES.FEEDBACK_GROUP,
          MODULES.SOCIAL,
          MODULES.GROUP,
        ],
        exact: false,
        component: loadable(
          () => import('../../components/group/detail/GroupMembers'),
        ),
        label: ({ t }) => t('Members'),
      },
      {
        name: 'feedback.groups.detail.edit',
        labelHeader: ({ t }) => t('Group - Details'),
        path: `${PATH}/groups/detail/:id/edit`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
          ROLES.SOCIAL.NEWSFEED.ALL,
          ROLES.GROUP.USER.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [
          MODULES.FEEDBACK_GROUP,
          MODULES.SOCIAL,
          MODULES.GROUP,
        ],
        exact: false,
        component: loadable(
          () => import('../../components/group/detail/GroupEditTab'),
        ),
        label: ({ t }) => t('Settings'),
      },
    ],
  },
  {
    name: 'feedback.groups.new.feedback',
    path: `${PATH}/groups/:id/feedback/:requestId?`,
    labelHeader: ({ t }) => t('Group - Details'),
    component: loadable(
      () => import('../../components/group/add/GroupAddFeedbackForm'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.FEEDBACK_GROUP.FEEDBACK.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
  },
  {
    name: 'feedback.groups.admin.settings',
    path: `${PATH}/groups/admin/settings`,
    labelHeader: ({ t }) => t('feedback_group_settings'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: requiredFeedbackOrExternalAndGroup,
    routes: [
      {
        name: 'feedback.groups.admin.internalFeedback',
        path: `${PATH}/groups/admin/settings/customization/feedback`,
        label: ({ t }) => t('Internal Feedback'),
        labelHeader: ({ t }) => t('feedback_group_settings'),
        component: loadable(
          () =>
            import(
              '../../components/group/settings/GroupFeedbackCustomizationSettings'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.FEEDBACK_GROUP.ALL,
        ],
        requiredFeatures: requiredFeedbackAndGroup,
      },
      {
        name: 'feedback.groups.admin.externalFeedback',
        path: `${PATH}/groups/admin/settings/customization/external-feedback`,
        label: ({ t }) => t('External Feedback'),
        labelHeader: ({ t }) => t('feedback_group_settings'),
        component: loadable(
          () =>
            import(
              '../../components/group/settings/ExternalGroupFeedbackCustomizationSettings'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.FEEDBACK_GROUP.ALL,
        ],
        requiredFeatures: requiredFeedbackExternalAndGroup,
      },
    ],
  },
  {
    name: 'feedback.groups.admin.dashboard',
    path: `${PATH}/groups/admin/dashboard`,
    label: ({ t }) => t('Groups'),
    labelHeader: ({ t }) => t('Dashboard - Groups'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
    routes: [
      {
        name: 'feedback.groups.admin.dashboard',
        path: `${PATH}/groups/admin/dashboard`,
        label: ({ t }) => t('Groups'),
        labelHeader: ({ t }) => t('Dashboard - Groups'),
        component: loadable(
          () =>
            import(
              '../../components/feedback/dashboard/group/GroupFeedbackDashboard'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
      },
    ],
  },
  {
    name: 'feedback.groups.admin.list',
    path: `${PATH}/groups/admin/list`,
    label: ({ t }) => t('Groups'),
    labelHeader: ({ t }) => t('All Group {{Feedbacks}}'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: requiredFeedbackAndGroup,
    routes: [
      {
        name: 'feedback.groups.admin.list',
        path: `${PATH}/groups/admin/list`,
        label: ({ t }) => t('Groups'),
        labelHeader: ({ t }) => t('All Group {{Feedbacks}}'),
        component: loadable(
          () => import('../../components/feedback/AllGroupFeedbacksAdminList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: requiredFeedbackAndGroup,
      },
    ],
  },
  {
    name: 'feedback.groups.admin.external',
    path: `${PATH}/groups/admin/externalList`,
    label: ({ t }) => t('Groups'),
    labelHeader: ({ t }) => t('All External {{Feedbacks}} Group'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: requiredFeedbackExternalAndGroup,
    routes: [
      {
        name: 'feedback.groups.admin.external',
        path: `${PATH}/groups/admin/externalList`,
        label: ({ t }) => t('Groups'),
        labelHeader: ({ t }) => t('All External {{Feedbacks}} Group'),
        component: loadable(
          () =>
            import(
              '../../components/externalGroupFeedback/AllExternalGroupFeedbackList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: requiredFeedbackExternalAndGroup,
      },
    ],
  },
  {
    name: 'feedback.groups.admin',
    path: `${PATH}/groups/admin/all`,
    label: ({ t }) => t('Groups'),
    labelHeader: ({ t }) => t('Groups'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_GROUP.ADMIN.ALL,
      ROLES.FEEDBACK_GROUP.ALL,
      ROLES.GROUP.ADMIN.ALL,
      ROLES.GROUP.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
    routes: [
      {
        name: 'feedback.groups.admin',
        path: `${PATH}/groups/admin/all`,
        label: ({ t }) => t('Groups'),
        labelHeader: ({ t }) => t('Groups'),
        component: loadable(() => import('../../components/group/GroupList')),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_GROUP.ADMIN.ALL,
          ROLES.FEEDBACK_GROUP.ALL,
          ROLES.GROUP.ADMIN.ALL,
          ROLES.GROUP.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
        modalRoutes: [
          {
            name: 'feedback.groups.admin.new',
            path: `${PATH}/groups/admin/all/new`,
            labelHeader: ({ t }) => t('Groups'),
            component: loadable(
              () => import('../../components/group/add/GroupAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.FEEDBACK_GROUP.ADMIN.ALL,
              ROLES.FEEDBACK_GROUP.ALL,
              ROLES.GROUP.ADMIN.ALL,
              ROLES.GROUP.ALL,
              ROLES.GROUP.CREATE,
            ],
            requiredFeatures: [MODULES.FEEDBACK_GROUP, MODULES.GROUP],
          },
        ],
      },
    ],
  },
];

export default groupRoutes;
