import Button from '@material-ui/core/Button';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import UserAvatar from './avatar/UserAvatar';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
`;

const Label = styled.p`
  width: 210px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.primary.dark};
  font-size: 18;
`;

const ImageCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const EditButton = styled(Button)`
  padding: 0 !important;
  position: absolute !important;
  bottom: -4px !important;
  left: -4px !important;
  min-height: 0 !important;
  color: ${(props) => props.theme.button.textColor} !important;
  background: #0000002e !important;
  padding: 0px 20px 10px 20px !important;
`;

const FileInput = styled.input`
  display: none;
`;

const ImageInput = ({ user, onImageChange }) => (
  <Container>
    <Label>Foto de Perfil</Label>
    <ImageCircle>
      <UserAvatar size={80} user={user} />
      <FileInput
        onChange={onImageChange}
        type='file'
        name='pic'
        id='pic'
        accept='image/*'
      />
      <label htmlFor='pic'>
        <EditButton component={'span'}>Editar</EditButton>
      </label>
    </ImageCircle>
  </Container>
);

export default createFragmentContainer(ImageInput, {
  user: graphql`
    fragment ImageInput_user on User {
      ...UserAvatar_user
    }
  `,
});
