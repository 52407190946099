import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { CandidateSelectField_query } from './__generated__/CandidateSelectField_query.graphql';

type Props = {
  query: CandidateSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: Candidate;
};

type Candidate = {
  id: string;
  name: string;
  profileImage: Record<string, any>;
};

type State = {
  input: string;
  search: string;
};

class CandidateSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Candidato',
    name: 'candidate',
  };

  getItemFromNode = (node) => ({
    id: node.id,
    name: node.name,
    profileImage: node.profileImage?.url,
  });

  render() {
    const { query, relay, ...props } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={query.allCandidates}
        getItemFromNode={this.getItemFromNode}
        {...props}
      />
    );
  }
}

const CandidateSelectFieldFragmentContainer = createRefetchContainer(
  CandidateSelectField,
  {
    query: graphql`
      fragment CandidateSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 30 }
        search: { type: String, defaultValue: "" }
        after: { type: String }
      ) {
        allCandidates: candidates(
          first: $count
          search: $search
          after: $after
        ) @connection(key: "CandidateSelectField_allCandidates", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              profileImage {
                url
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query CandidateSelectFieldQuery(
      $count: Int
      $search: String
      $after: String
    ) {
      ...CandidateSelectField_query
        @arguments(count: $count, search: $search, after: $after)
    }
  `,
);

export default CandidateSelectFieldFragmentContainer;
