import Icon from '@material-ui/core/Icon';
import styled from 'styled-components';

type Fn = () => {};

type Props = {
  onDisplayChange: Fn;
  isDisplayList: boolean;
};

const StyledIcon = styled(Icon)`
  && {
    margin: 0 10px;
    font-size: 30px;
    color: ${(props) => props.theme.primaryDarkerColor};
  }
`;

const Wrap = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;

const DisplayOptions = ({ isDisplayList, onDisplayChange }: Props) => (
  <Wrap onClick={onDisplayChange}>
    <StyledIcon>{isDisplayList ? 'apps' : 'list'}</StyledIcon>
  </Wrap>
);

export default DisplayOptions;
