import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';

import { routeTo } from '../../../router/utils';

export const hiring = ({ t }) => [
  {
    label: t('Hiring'),
    icon: <BusinessCenterOutlinedIcon />,
    items: [
      {
        label: t('Candidates'),
        link: routeTo('candidates.list'),
      },
      {
        label: t('Interviews'),
        link: routeTo('interviews.myInterviews'),
      },
      {
        label: t('Group Interviews'),
        link: routeTo('groupInterview'),
      },
      {
        label: t('Job Postings'),
        link: routeTo('jobPosting'),
      },
      {
        label: t('Job Requests'),
        link: routeTo('jobRequest'),
      },
      {
        label: t('Home Work With Us'),
        link: routeTo('hiring.landingPage'),
      },
      {
        label: t('Exams'),
        link: routeTo('jobExam.list'),
      },
      {
        label: t('Settings'),
        link: routeTo('hiring.requiredDocs'),
      },
    ],
  },
];
