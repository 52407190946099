import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { space } from 'styled-system';

const DangerButton = styled(Button)`
  margin-left: 8px;
  text-transform: none;
  padding: 8px 16px;
  line-height: 1;
  border: 1px solid #cb2431;
  && {
    color: ${({ variant }) =>
      variant === 'outlined' ? '#cb2431' : '#fff'} !important;
    background-color: ${({ variant }) =>
      variant === 'outlined' ? 'transparent' : '#cb2431'} !important;
    &:hover {
      color: white !important;
      background-color: #cb2431 !important;
    }
  }
  &:disabled {
    color: ${({ variant }) =>
      variant === 'outlined' ? '#c1c1c1' : '#fff'} !important;
    border: 1px solid #c1c1c1 !important;
    background-color: ${({ variant }) =>
      variant === 'outlined' ? 'transparent' : '#c1c1c1'} !important;
    &:hover {
      color: #c1c1c1 !important;
      background-color: #c1c1c1 !important;
    }
  }
  ${space}
`;

export default DangerButton;
