import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/planning';

const costRevenueCenter: RouteType[] = [
  {
    name: 'costRevenueCenter',
    path: `${PATH}/costRevenueCenters`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Planning Areas or Locations'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
    ],
    requiredFeatures: [MODULES.CORE],
    routes: [
      {
        name: 'costRevenueCenter.list',
        path: `${PATH}/costRevenueCenters/list`,
        labelHeader: ({ t }) => t('Areas/Locations'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/list/CostRevenueCenterList'
            ),
        ),
        exact: false,
        label: ({ t }) => t('List'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
        modalRoutes: [
          {
            name: 'costRevenueCenter.add',
            path: `${PATH}/costRevenueCenters/list/add`,
            labelHeader: ({ t }) => t('Areas/Locations - Add'),
            component: loadable(
              () =>
                import(
                  '../../../components/costRevenueCenter/add/CostRevenueCenterAdd'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
            ],
            requiredFeatures: [MODULES.CORE],
          },
        ],
      },
      {
        name: 'costRevenueCenter.hierarchy',
        path: `${PATH}/costRevenueCenters/hierarchy`,
        labelHeader: ({ t }) => t('Areas/Locations'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/CostRevenueCenterHierarchy'
            ),
        ),
        exact: false,
        label: ({ t }) => t('Hierarchy'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
      },
      {
        name: 'costRevenueCenter.pivot',
        path: `${PATH}/costRevenueCenters/pivot`,
        labelHeader: 'Areas/Locations',
        label: ({ t }) => t('Dynamic Table'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/CostRevenueCenterPivotTable'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
      },
      {
        name: 'costRevenueCenter.map',
        path: `${PATH}/costRevenueCenters/map`,
        labelHeader: 'Areas/Locations',
        label: ({ t }) => t('Map'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/CostRevenueCenterMap'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.IMPORT.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
      },
      {
        name: 'costRevenueCenter.orgchart',
        path: `${PATH}/costRevenueCenters/orgchart`,
        labelHeader: ({ t }) => t('Areas/Locations'),
        requiredRoles: [],
        requiredFeatures: [MODULES.CORE],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/orgChart/RootAreaOrgChartDetail'
            ),
        ),
        label: ({ t }) => t('Chart'),
      },
      {
        name: 'costRevenueCenter.import',
        path: `${PATH}/costRevenueCenters/import`,
        labelHeader: ({ t }) => t('Areas/Locations'),
        label: ({ t }) => t('Import'),
        component: loadable(
          () =>
            import(
              '../../../components/planning/import/CostRevenueCenterImportStepper'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.IMPORT.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
      },
      {
        name: 'costRevenueCenter.export',
        path: `${PATH}/costRevenueCenters/export`,
        labelHeader: ({ t }) => t('Areas/Locations'),
        label: ({ t }) => t('Exports'),
        component: loadable(
          () =>
            import(
              '../../../components/planning/export/CostRevenueCenterExport'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.EXPORT.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
      },
    ],
  },
  {
    name: 'costRevenueCenter.requisitions.add',
    path: `${PATH}/requisitions/:id/add`,
    labelHeader: ({ t }) => t('Requisition - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/costRevenueCenter/components/CostRevenueCenterJobRequestListAdd'
        ),
    ),
    requiredFeatures: [MODULES.PLANNING],
    exact: true,
  },
  {
    name: 'planning.myCostRevenueCenters',
    path: `${PATH}/planning/myAreas`,
    labelHeader: ({ t }) => t('My Areas/Locations'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.CORE],
    routes: [
      {
        name: 'planning.myCostRevenueCenters',
        path: `${PATH}/planning/myAreas`,
        labelHeader: ({ t }) => t('My Areas/Locations'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/MyCostRevenueCenterList'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.CORE],
      },
    ],
  },
  {
    name: 'costRevenueCenter.view',
    path: `${PATH}/costRevenueCenter/:id`,
    labelHeader: ({ t }) => t('Areas/Locations - Details'),
    component: loadable(
      () =>
        import(
          '../../../components/costRevenueCenter/detail/CostRevenueCenter'
        ),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.CORE],
    routes: [
      {
        name: 'costRevenueCenter.headcount.list',
        path: `${PATH}/costRevenueCenter/:id/headCountList`,
        labelHeader: ({ t }) => t('Areas/Locations - Details'),
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/CostRevenueCenterHeadCountList'
            ),
        ),
        label: 'Headcounts',
      },
      {
        name: 'costRevenueCenter.candidateApplications.list',
        path: `${PATH}/costRevenueCenter/:id/candidateApplicationList`,
        labelHeader: ({ t }) => t('Areas/Locations - Applications'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/CostRevenueCenterApplications'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        label: ({ t }) => t('Applications'),
      },
      {
        name: 'costRevenueCenter.headcount.indicators',
        path: `${PATH}/costRevenueCenter/:id/headCountIndicators`,
        labelHeader: ({ t }) => t('Areas/Locations - Details'),
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        exact: true,
        component: loadable(
          () => import('../../../components/headCount/HeadCountIndicators'),
        ),
        label: ({ t }) => t('Indicators'),
      },
      {
        name: 'costRevenueCenter.headcount.requisitions',
        path: `${PATH}/costRevenueCenter/:id/requisitions`,
        labelHeader: ({ t }) => t('Areas/Locations - Details'),
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/components/CostRevenueCenterJobRequestsList'
            ),
        ),
        label: ({ t }) => t('Requests'),
      },
      {
        name: 'costRevenueCenter.users',
        path: `${PATH}/costRevenueCenter/:id/users`,
        labelHeader: ({ t }) => t('Users'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/CostRevenueCenterUsers'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.CORE],
        label: ({ t }) => t('Users'),
      },
      {
        name: 'costRevenueCenter.detail.orgchart',
        path: `${PATH}/costRevenueCenter/:id/orgchart`,
        labelHeader: ({ t }) => t('Areas/Locations - Details'),
        requiredRoles: [],
        requiredFeatures: [MODULES.CORE],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/orgChart/AreaOrgChartDetail'
            ),
        ),
        label: ({ t }) => t('Chart'),
      },
      {
        name: 'costRevenueCenter.edit',
        path: `${PATH}/costRevenueCenter/:id/edit`,
        labelHeader: ({ t }) => t('Areas/Locations - Details'),
        component: loadable(
          () =>
            import(
              '../../../components/costRevenueCenter/adjust/CostRevenueCenterEdit'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.CORE],
        label: ({ t }) => t('Settings'),
      },
    ],
    modalRoutes: [
      {
        name: 'costRevenueCenter.headCount.add',
        path: `${PATH}/costRevenueCenter/:id/headCount/add`,
        labelHeader: ({ t }) => t('Areas/Locations - Details'),
        component: loadable(
          () => import('../../../components/headCount/HeadCountAdd'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
];

export const costRevenueCenterResponsibleTabs = ['costRevenueCenter.users'];

export default costRevenueCenter;
