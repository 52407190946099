import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import { useTranslation } from 'react-i18next';

import type { JobRequestSelectField_query } from './__generated__/JobRequestSelectField_query.graphql';

type Props = {
  query: JobRequestSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: JobRequest;
};

type JobPosting = {
  title: string;
};

type CostRevenueCenter = {
  name: string;
};

type JobRequest = {
  id: string;
  jobPosting: JobPosting;
  costRevenueCenter: CostRevenueCenter;
  status: string;
};
const JobRequestSelectField = (props: Props) => {
  const { t } = useTranslation();

  const getItemFromNode = ({
    id,
    jobPosting,
    costRevenueCenter,
    requestId,
  }) => {
    if (!jobPosting && !costRevenueCenter) {
      return;
    }

    const title = jobPosting?.title;
    const crcName = costRevenueCenter?.name;

    return {
      id,
      name: `${requestId} - ${title ? title + ' - ' : ''}${
        crcName ? crcName : ''
      }`,
    };
  };

  // TODO - review status
  // const status = 'AVAILABLE';

  const getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      filters: {
        search,
        // status_in: [status],
      },
    };
  };

  const getRenderVariables = (value) => {
    const { search } = value;

    return {
      filters: {
        search,
        // status_in: [status],
      },
    };
  };

  const {
    hintText = t('Search Job Request'),
    name = 'jobRequest',
    query,
    relay,
    ...rest
  } = props;

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.jobRequests}
      getItemFromNode={getItemFromNode}
      getFilterFragmentVariables={getFilterFragmentVariables}
      getRenderVariables={getRenderVariables}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

export default createRefetchContainer(
  JobRequestSelectField,
  {
    query: graphql`
      fragment JobRequestSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 1 }
        after: { type: String }
        filters: { type: JobRequestFilter }
      ) {
        jobRequests(first: $first, after: $after, filter: $filters)
          @connection(
            key: "JobRequestSelectField_jobRequests"
            filters: ["filter"]
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              requestId
              jobPosting {
                title
              }
              costRevenueCenter {
                name
              }
              status
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobRequestSelectFieldRefetchQuery(
      $first: Int
      $after: String
      $filters: JobRequestFilter
    ) {
      ...JobRequestSelectField_query
        @arguments(first: $first, after: $after, filters: $filters)
    }
  `,
);
