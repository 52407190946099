/**
 * @generated SignedSource<<b19ca2d93c4f786fa9dbc6ed5351d097>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePermissionLink_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PermissionFlag_user">;
  readonly " $fragmentType": "HomePermissionLink_user";
};
export type HomePermissionLink_user$key = {
  readonly " $data"?: HomePermissionLink_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePermissionLink_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePermissionLink_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PermissionFlag_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "00af24048993a8ef3872a862e6718f7d";

export default node;
