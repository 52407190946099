import DateRangeIcon from '@material-ui/icons/DateRange';

import { routeTo } from '../../../router/utils';

export const planningAdmin = ({ t }) => [
  {
    label: t('Planning'),
    icon: <DateRangeIcon />,
    items: [
      {
        label: t('Areas/Locations'),
        link: routeTo('costRevenueCenter.list'),
      },
      {
        label: t('Headcounts'),
        link: routeTo('headcount.list'),
      },
      {
        label: t('Job Roles'),
        link: routeTo('jobRoles'),
      },
      {
        label: t('Business Division'),
        link: routeTo('businessDivision.list'),
      },
      {
        label: t('Journeys'),
        link: routeTo('journey.root'),
      },
    ],
  },
];
