import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

import { routeTo } from '../../../router/utils';

export const pdi = ({ t }) => [
  {
    label: t('PDI'),
    icon: <TrendingUpOutlinedIcon />,
    link: routeTo('pdi.list'),
  },
];

export const pdiAdmin = ({ t }) => [
  {
    label: t('PDI'),
    icon: <TrendingUpOutlinedIcon />,
    link: routeTo('pdi.admin.list'),
  },
];
