import 'core-js/stable';
import 'isomorphic-fetch';
import 'raf/polyfill';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/browser';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';

import RelayFeatureFlags from 'relay-runtime/lib/util/RelayFeatureFlags';

import shouldUseSentry from './shouldUseSentry';

import authenticationRoutes, {
  redirectLoginRoutes,
} from './router/routes/root/authenticationRoutes';
import externalRoutes from './router/routes/root/externalRoutes';
import redirectRoutes from './router/routes/root/redirectRoutes';
import rootRoutes from './router/routes/root/rootRoutes';
import signUpRoutes from './router/routes/root/signUpRoutes';
import requiredRoutes from './router/routes/root/requiredRoutes';

import Main from './Main';
import { appendRoutes } from './router/routes';

// TODO - disable containers suspense for now
RelayFeatureFlags.ENABLE_RELAY_CONTAINERS_SUSPENSE = false;

// add all root routes to routes
appendRoutes(authenticationRoutes);
appendRoutes(redirectLoginRoutes);
appendRoutes(signUpRoutes);
appendRoutes(requiredRoutes);
appendRoutes(rootRoutes);
externalRoutes.forEach(appendRoutes);
appendRoutes(redirectRoutes);

// setup sentry
if (shouldUseSentry) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.FEEDBACK_ENV,
  });

  Sentry.configureScope((scope) => {
    scope.setTag('git_commit', process.env.COMMIT_SHA);
  });
}

ReactGA.initialize(process.env.GA_ID);

const rootEl = document.getElementById('root');

const runApp = () => {
  if (rootEl) {
    render(
      <StrictMode>
        <Main />
      </StrictMode>,
      rootEl,
    );
  } else {
    throw new Error('wrong rootEl');
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/home/sw.js');
  }
};

// Intl polyfill
if (!global.Intl) {
  (async () => {
    try {
      await import('intl'); // eslint-disable-line no-undef

      await import('intl/locale-data/jsonp/pt-BR.js');
      import('intl/locale-data/jsonp/en.js');
    } catch (err) {
      // eslint-disable-next-line
      console.log('error loading intl polyfill: ', err);
    }

    runApp();
  })();
} else {
  runApp();
}
