import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../utils/index';


const PATH = '/home/pdi';
const PATH_ADMIN = '/home/pdi/admin';

const pdiRoutes: RouteType[] = [
  {
    name: 'pdi',
    path: `${PATH}/overview`,
    labelHeader: ({ t }) => t('PDI'),
    component: loadable(() => import('../../components/pdi/PdiDetails')),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.USER.ALL],
    requiredFeatures: [MODULES.PDI],
    routes: [
      {
        name: 'pdi.list',
        path: `${PATH}/overview/list`,
        labelHeader: ({ t }) => t('PDI'),
        component: loadable(() => import('../../components/pdi/PdiList')),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PDI.USER.ALL],
        requiredFeatures: [MODULES.PDI],
      },
    ],
  },
  {
    name: 'pdi.new',
    path: `${PATH}/new`,
    labelHeader: ({ t }) => t('PDI'),
    component: loadable(() => import('../../components/pdi/form/PdiAddTemp')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.USER.ALL],
    requiredFeatures: [MODULES.PDI],
  },
  {
    name: 'pdi.newTemp',
    path: `${PATH}/newTemp`,
    labelHeader: ({ t }) => t('PDI temp'),
    component: loadable(() => import('../../components/pdi/form/PdiAdd')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.USER.ALL],
    requiredFeatures: [MODULES.PDI, MODULES.TEMP],
  },
  {
    name: 'pdi.editTemp',
    path: `${PATH}/editTemp/:id`,
    labelHeader: ({ t }) => t('PDI'),
    component: loadable(() => import('../../components/pdi/form/PdiAdd')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.USER.ALL],
    requiredFeatures: [MODULES.PDI, MODULES.TEMP],
  },
  {
    name: 'pdi.edit',
    path: `${PATH}/edit/:id`,
    labelHeader: ({ t }) => t('PDI'),
    component: loadable(() => import('../../components/pdi/form/PdiAddTemp')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.USER.ALL],
    requiredFeatures: [MODULES.PDI],
  },
  {
    name: 'pdi.admin',
    path: `${PATH_ADMIN}/overview`,
    labelHeader: ({ t }) => t('PDI'),
    component: loadable(() => import('../../components/pdi/AdminPdiDetails')),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.ADMIN.ALL],
    requiredFeatures: [MODULES.PDI],
    routes: [
      {
        name: 'pdi.admin.list',
        path: `${PATH_ADMIN}/overview/list`,
        labelHeader: ({ t }) => t('PDI'),
        label: ({ t }) => t('List'),
        component: loadable(
          () => import('../../components/pdi/AdminPdiUserList'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PDI.ADMIN.ALL],
        requiredFeatures: [MODULES.PDI],
      },
      {
        name: 'pdi.admin.topics',
        path: `${PATH_ADMIN}/overview/topics`,
        labelHeader: ({ t }) => t('Topics'),
        label: ({ t }) => t('Topics'),
        component: loadable(
          () => import('../../components/pdi/AdminPdiTopicList'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PDI.ADMIN.ALL],
        requiredFeatures: [MODULES.PDI],
      },
      {
        name: 'pdi.admin.tags',
        path: `${PATH_ADMIN}/overview/tags`,
        labelHeader: ({ t }) => t('Tags'),
        label: ({ t }) => t('Tags'),
        component: loadable(
          () => import('../../components/pdi/AdminPdiTagList'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PDI.ADMIN.ALL],
        requiredFeatures: [MODULES.PDI],
      },
      {
        name: 'pdi.admin.form',
        path: `${PATH_ADMIN}/overview/form`,
        labelHeader: ({ t }) => t('Form'),
        label: ({ t }) => t('Form'),
        component: loadable(() => import('../../components/pdi/AdminPdiForm')),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PDI.ADMIN.ALL],
        requiredFeatures: [MODULES.PDI],
      },
    ],
  },
  {
    name: 'pdi.admin.topics.new',
    path: `${PATH_ADMIN}/topics/add`,
    labelHeader: ({ t }) => t('PDI'),
    component: loadable(() => import('../../components/pdi/AdminPdiTopicAdd')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PDI.ADMIN.ALL],
    requiredFeatures: [MODULES.PDI],
  },
];

export default pdiRoutes;
