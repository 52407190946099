import styled from 'styled-components';

import type { CircularProgressProps } from '@material-ui/core/CircularProgress';
import _CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const CircularProgress = styled(_CircularProgress)`
  stroke-linecap: round;
  color: #b2b2b2 !important;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export type LoadingProps = {
  fullScreen?: boolean;
  wrappedInDialog?: boolean;
  size?: number;
} & CircularProgressProps;

const Loading = ({ fullScreen, wrappedInDialog, size }: LoadingProps) => {
  const progress = <CircularProgress size={size} data-testid='loading' />;

  if (fullScreen) {
    return <Container>{progress}</Container>;
  }

  if (wrappedInDialog) {
    return (
      <Dialog open={true}>
        <DialogContent>{progress}</DialogContent>
      </Dialog>
    );
  }

  return progress;
};

export default Loading;
