import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { routeTo } from '../../router/utils';

import type { Brand_company } from './__generated__/Brand_company.graphql';

const BrandWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const BrandWithImageWrapper = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const BrandWithoutImageWrapper = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000000;
`;

const BrandTitle = styled.span`
  margin: 0;
  font-size: 20px;
  max-width: 210px;
`;

const BrandImage = styled.img`
  object-fit: contain;
  max-width: 210px;
  max-height: 40px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

type Props = {
  company: Brand_company;
  withLink: boolean;
};
const Brand = (props: Props) => {
  const company = useFragment(
    graphql`
      fragment Brand_company on Company {
        id
        nameFriendly
        logo {
          url(fitIn: true)
        }
      }
    `,
    props.company,
  );

  const { withLink = true } = props;

  return (
    <BrandWrapper>
      <LinkWrapper withLink={withLink}>
        {company?.logo ? (
          <BrandWithImage company={company} />
        ) : (
          <BrandWithoutImage company={company} />
        )}
      </LinkWrapper>
    </BrandWrapper>
  );
};

const LinkWrapper = ({ withLink, children }) =>
  withLink ? (
    <StyledLink to={{ pathname: routeTo('home') }}>{children}</StyledLink>
  ) : (
    children
  );

const BrandWithImage = ({ company }) => (
  <BrandWithImageWrapper>
    <BrandImage src={company?.logo?.url} title={company?.nameFriendly} />
  </BrandWithImageWrapper>
);

const BrandWithoutImage = ({ company }) => (
  <BrandWithoutImageWrapper>
    <BrandTitle>{company?.nameFriendly}</BrandTitle>
  </BrandWithoutImageWrapper>
);

export default Brand;
