import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { JobRoleSelectField_query } from './__generated__/JobRoleSelectField_query.graphql';

type Props = {
  query: JobRoleSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: JobRole;
  resetInputOnSelection?: boolean;
};

type JobRole = {
  id: string;
  name: string;
};

type State = {
  input: string;
  search: string;
};

class JobRoleSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Cargo',
    name: 'jobRole',
  };

  render() {
    const {
      query,
      relay, // eslint-disable-line no-unused-vars
      resetInputOnSelection = false,
      ...props
    } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={query.jobRoles}
        resetInputOnSelection={resetInputOnSelection}
        {...props}
      />
    );
  }
}

const JobRoleSelectFieldFragmentContainer = createRefetchContainer(
  JobRoleSelectField,
  {
    query: graphql`
      fragment JobRoleSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 30 }
        search: { type: String, defaultValue: "" }
        after: { type: String }
        isActive: { type: Boolean, defaultValue: true }
      ) {
        jobRoles(
          first: $count
          search: $search
          after: $after
          isActive: $isActive
        ) @connection(key: "JobRoleSelectField_jobRoles", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobRoleSelectFieldQuery(
      $count: Int
      $search: String
      $after: String
      $isActive: Boolean
    ) {
      ...JobRoleSelectField_query
        @arguments(
          count: $count
          search: $search
          after: $after
          isActive: $isActive
        )
    }
  `,
);

export default JobRoleSelectFieldFragmentContainer;
