/**
 * @generated SignedSource<<c18148d49b43516bfb447a470c42f453>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoleFlag_user$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly " $fragmentType": "RoleFlag_user";
};
export type RoleFlag_user$key = {
  readonly " $data"?: RoleFlag_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoleFlag_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleFlag_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allRoles",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0d3a89bc9533f7256d49e9f990cf2df1";

export default node;
