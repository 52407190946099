import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { Unpacked } from '../../types';

import type { TagsSelectField_query } from './__generated__/TagsSelectField_query.graphql';

type TagType = Unpacked<TagsSelectField_query['tags']['edges']>['node'];

type Props = {
  query: TagsSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: TagType;
};

type State = {
  search: string;
};

class TagsSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Tags',
    name: 'tags',
  };

  getItemFromNode = ({ id, value }) => {
    return {
      id,
      name: value,
    };
  };

  toNode = (tag: TagType) => ({
    id: tag.label,
    value: `${tag.label} (${tag.total})`,
  });

  getConnection = () => {
    const { query } = this.props;
    const { tags } = query;

    if (!tags || !Array.isArray(tags.edges) || tags.edges.length < 1) {
      return { count: 0, edges: [] };
    }

    const { edges } = tags;

    return {
      count: edges.length,
      edges: edges.map(({ node }) => ({ node: this.toNode(node) })),
    };
  };

  getFilterFragmentVariables = (state) => {
    const { search } = state;

    return {
      search,
    };
  };

  getRenderVariables = (state) => {
    const { search } = state;

    return {
      search,
    };
  };

  render() {
    const { query, relay, ...props } = this.props;
    const { tags } = query;

    const emptyMessage =
      !tags || !tags.edges || tags.edges.length < 1
        ? 'Não existem tags'
        : 'Escolha uma tag';

    return (
      <ResourceSelectField
        relay={relay}
        getFilterFragmentVariables={this.getFilterFragmentVariables}
        getRenderVariables={this.getRenderVariables}
        connection={this.getConnection()}
        getItemFromNode={this.getItemFromNode}
        emptyMessage={emptyMessage}
        {...props}
      />
    );
  }
}

const TagsSelectFieldRefetchContainer = createRefetchContainer(
  TagsSelectField,
  {
    query: graphql`
      fragment TagsSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 30 }
        model: { type: "ModelsTagsEnum!" }
        search: { type: String, defaultValue: "" }
      ) {
        tags(first: $first, model: $model, search: $search)
          @connection(key: "TagsSelectField_tags", filters: ["search"]) {
          count

          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              label
              total
            }
          }
        }
      }
    `,
  },
  graphql`
    query TagsSelectFieldRefetchQuery(
      $first: Int
      $model: ModelsTagsEnum!
      $search: String
    ) {
      ...TagsSelectField_query
        @arguments(first: $first, model: $model, search: $search)
    }
  `,
);

export default TagsSelectFieldRefetchContainer;
