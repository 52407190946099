import styled from 'styled-components';

const Wrapper = styled.div<{ width?: string; height?: number }>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width || '90px'};
  background-color: rgba(96, 115, 137, 0.26);
  border-radius: 8px;
  overflow: hidden;
`;

const Progress = styled.div<{
  percent?: number;
  barColor: string;
  height?: number;
}>`
  width: ${(props) => props.percent}%;
  height: ${(props) => props.height}px;
  transition: width 1s;
  background-color: ${(props) =>
    props?.theme?.button?.backgroundColor || props.barColor || '#309dd1'};
`;

type Props = {
  percent: number;
  height?: number;
  width?: string;
  active?: boolean;
  barColor?: string;
};
const ProgressBar = ({
  percent,
  width,
  active = true,
  barColor,
  height = 10,
  ...props
}: Props) => {
  const defaultBarColor = !active
    ? 'rgba(96, 115, 137, 0.4);'
    : percent >= 100
    ? '#7ed321'
    : '#309dd1';

  return (
    <div {...props}>
      <Wrapper className='ProgressBar__wrapper' height={height} width={width}>
        <Progress
          height={height}
          className='ProgressBar__progress'
          percent={Math.min(percent, 100)}
          barColor={barColor || defaultBarColor}
        />
      </Wrapper>
    </div>
  );
};

export default ProgressBar;
