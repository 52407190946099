import { createAction, handleActions } from 'redux-actions';

type LoginState = {
  fromEmail?: boolean | null;
  company?: CompanyType | null;
  user?: User | null;
};

export type User = {
  id: string | null;
  email: string | null;
  exists: boolean;
};

export type CompanyType = {
  id?: string | null;
  name?: string | null;
  nameFriendly?: string | null;
  domainName?: string | null;
  logo?: string | null
};
export type Logo = {
  url: string | null;
};

const initialState: LoginState = {
  fromEmail: true,
  company: {
    id: null,
    domainName: null,
    name: null,
    nameFriendly: null,
    logo: null,
  },
  user: {
    id: null,
    email: null,
    exists: false,
  },
};

const TYPES = {
  CLEAR: 'LOGIN:CLEAR',
  SET_COMPANY: 'LOGIN:SET_COMPANY',
  SET_USER: 'LOGIN:SET_USER',
};

export const clearLogin = createAction(TYPES.CLEAR);

export const setCompany = createAction(TYPES.SET_COMPANY);

export const setUser = createAction<LoginState>(TYPES.SET_USER);

export default handleActions(
  {
    [TYPES.CLEAR]: () => initialState,

    [TYPES.SET_COMPANY]: (state, { payload }) => ({
      ...state,
      company: {
        ...state.company,
        ...payload,
      },
    }),

    [TYPES.SET_USER]: (state, { payload }) => ({
      ...state,
      fromEmail: payload.fromEmail,
      email: payload.email,
      user: {
        ...payload.user,
        id: payload.userId,
        email: payload.email,
        exists: payload.userExists,
      },
    }),
  },
  initialState,
);
