import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import ReactGA from 'react-ga';

import { SidebarProvider } from '@feedback/ui';
import { usePageView } from '@feedback/hooks';
import { MODULES } from '@feedback/roles';

import { getDomainName, updateToken } from '../../security/auth';
import { usePermission } from '../../security/usePermission';

import { createQueryRendererModern } from '../../relay';

import shouldUseSentry from '../../shouldUseSentry';

import type { RouteType } from '../../router/utils';

import { useSWReg } from '../../firebase/useSWReg';

import Header from '../header/Header';
import { useCrisp } from '../support/useCrisp';

import Sidebar from '../sidebar/Sidebar';

import { useAuthenticated } from '../../router/middlewares/useAuthenticated';
import { useDomainName } from '../../router/middlewares/useDomainName';
import DomainNotFound from '../core/DomainNotFound';

import { useShouldChangePassword } from '../../router/middlewares/useShouldChangePassword';
import { useShouldUserAcceptAgreement } from '../../router/middlewares/useShouldUserAcceptAgreement';

// eslint-disable-next-line
import { filterRoutes, useFilterRoutes } from '../../router/utils/filterRoutes';

import type { AdminApp_query } from './__generated__/AdminApp_query.graphql';

import LayoutWrapper from './LayoutWrapper';
import GeoLocation from './geoLocation/GeoLocation';

import ContentBoundary from './ContentBoundary';

type Props = {
  query: AdminApp_query;
  routes: RouteType;
};
const AdminApp = ({ query, routes }: Props) => {
  const { me, company } = query;

  // manages service worker registration and push
  useSWReg();
  usePageView();

  const { hasFeature } = usePermission(me);

  const routesMemo = useFilterRoutes(me, routes);

  const validDomainName = useDomainName(company);
  const isAuthenticated = useAuthenticated(me, company);
  const shouldChangePassword = useShouldChangePassword(me);
  const shouldUserAcceptAgreement = useShouldUserAcceptAgreement(me);

  useEffect(() => {
    if (!validDomainName) {
      return;
    }

    if (!me) {
      updateToken(null);

      return;
    }

    if (shouldUseSentry) {
      // https://docs.sentry.io/clients/javascript/usage/#tracking-users
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: me._id,
          name: me.name,
          companyId: me.company._id,
          companyName: me.company.name,
        });
        scope.setTag('companyId', me.company._id);
        scope.setTag('companyName', me.company.name);
      });
    }

    ReactGA.set({ userId: me._id });
  }, [me]);

  useCrisp({
    userRef: me,
  });

  if (!validDomainName) {
    return <DomainNotFound domainName={getDomainName()} />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (shouldChangePassword) {
    return null;
  }

  if (shouldUserAcceptAgreement) {
    return null;
  }

  if (!me) {
    return null;
  }

  return (
    <SidebarProvider>
      <LayoutWrapper
        header={<Header query={query} />}
        sidebar={<Sidebar query={query} />}
      >
        <ContentBoundary>{routesMemo}</ContentBoundary>
      </LayoutWrapper>
      {hasFeature([MODULES.TEMP]) && <GeoLocation />}
    </SidebarProvider>
  );
};

const AdminAppFragmentContainer = createFragmentContainer(AdminApp, {
  query: graphql`
    fragment AdminApp_query on Query {
      me {
        ...useAuthenticated_user @relay(mask: false)
        ...useCrisp_user
        ...permissionsUserFragment @relay(mask: false)
        ...usePermissionFragment
        _id
        id
        name
        company {
          _id
          name
          preferences {
            core {
              agreementTerm {
                active
              }
            }
          }
        }
        shouldChangePassword
        hasAcceptAgreementTerm
      }
      company: companyDomainName {
        ...useDomainName_company @relay(mask: false)
      }
      ...Header_query
      ...Sidebar_query
    }
  `,
});

export default createQueryRendererModern(AdminAppFragmentContainer, {
  query: graphql`
    query AdminAppQuery {
      ...AdminApp_query
    }
  `,
});
