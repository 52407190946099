import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { getTime } from 'date-fns';
import mime from 'mime-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  outline: none;

  & > div {
    flex: 1;
  }
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 14px 6px;
  margin-bottom: 24px;
  border: 1px solid rgba(96, 115, 137, 0.7);
  border-radius: 4px;
  outline: none;
`;

const DropzoneLabel = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.button.backgroundColor};
`;

const FilePreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 380px;
  margin-bottom: 6px;
  padding-left: 10px;
  border: 1px solid rgba(225, 225, 225, 0.8);
  border-radius: 4px;
  height: 30px;
  font-size: 14px;
  font-weight: 600;
`;

const FileName = styled.p`
  margin: 0;
  max-width: 325px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// https://stackoverflow.com/a/4212908/3995248
export const allowedMimeTypes = [
  'audio/mp3',
  'audio/ogg',
  'audio/wav',
  'video/mp4',
  'image/*',
  'application/pdf',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

type OnDropFileArgs = {
  files: File[];
  multiple?: boolean;
  onChange: (files: File | File[]) => void;
};

type RenderPreviewArgs = {
  file?: File;
  files: File[];
  multiple: boolean | null;
  onRemoveFile?: (index?: number) => void;
  allowRemoval: boolean | null;
};

type File = any;

type Props = {
  file?: File;
  files?: File[];
  multiple?: boolean;
  placeholder: string;
  onChange: (files: File | File[]) => void;
  onRemoveFile: (index?: number) => void;
  allowRemoval?: boolean;
};

const FileUpload = ({
  file,
  files = [],
  multiple = true,
  placeholder = 'Clique aqui para anexar um arquivo',
  onRemoveFile,
  onChange,
  allowRemoval = true,
  ...rest
}: Props) => {
  const generateFileName = (file: File, index: number): string => {
    const extension = mime.extension(file.type);

    return `${getTime(new Date())}-${index}.${extension}`;
  };

  const secureFiles = (files: File[]): File[] => {
    return files.map((file, index) => {
      const fileName = generateFileName(file, index);

      const options = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        type: file.type,
      };

      return new File([file], fileName, options);
    });
  };

  const onDropAccepted = ({ multiple, onChange, files }: OnDropFileArgs) => {
    const securedFiles = secureFiles(files);

    if (multiple) {
      return onChange(securedFiles);
    }

    return onChange(securedFiles[0]);
  };

  const renderPreview = ({
    file,
    files,
    multiple,
    onRemoveFile,
    allowRemoval,
  }: RenderPreviewArgs) => {
    if (multiple && files.length) {
      return files.map((file, index) => (
        <FilePreview key={index}>
          <FileName>{file.name}</FileName>
          {onRemoveFile && (
            <IconButton onClick={() => onRemoveFile(index)}>
              <CloseIcon />
            </IconButton>
          )}
        </FilePreview>
      ));
    }

    if (file) {
      return (
        <FilePreview>
          <FileName>{file.name}</FileName>

          {onRemoveFile && !!allowRemoval && (
            <IconButton onClick={onRemoveFile}>
              <CloseIcon />
            </IconButton>
          )}
        </FilePreview>
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      onDropAccepted({
        multiple,
        onChange,
        files,
      });
    },
    accept: allowedMimeTypes.join(', '),
  });

  return (
    <Wrapper {...rest}>
      <DropzoneContainer {...getRootProps()}>
        <input data-testid='input-drop' {...getInputProps()} />
        <AttachFileIcon />
        <DropzoneLabel>{placeholder}</DropzoneLabel>
      </DropzoneContainer>
      {renderPreview({
        file,
        files,
        multiple,
        onRemoveFile,
        allowRemoval,
      })}
    </Wrapper>
  );
};

export default FileUpload;
