type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const Profile = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <path
        fill={color}
        fillRule='nonzero'
        d='M13.333 5.167a2.49 2.49 0 0 0 2.492-2.5 2.497 2.497 0 0 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5zm-6.666 0a2.49 2.49 0 0 0 2.491-2.5 2.497 2.497 0 0 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5zm0 1.666c-1.942 0-5.834.975-5.834 2.917v2.083H12.5V9.75c0-1.942-3.892-2.917-5.833-2.917zm6.666 0c-.241 0-.516.017-.808.042.967.7 1.642 1.642 1.642 2.875v2.083h5V9.75c0-1.942-3.892-2.917-5.834-2.917z'
      />
    </svg>
  );
};

Profile.defaultProps = {
  color: '#607389',
  viewBox: '0 0 20 12',
  height: 24,
};

export default Profile;
