import config from '../config';

const firebaseConfig = {
  messagingSenderId: config.FIREBASE_MESSAGING_SEND_ID,
  apiKey: 'AIzaSyAaHyOry_KhR_SYXbe17PVnzUMC6_5N_Cg',
  authDomain: 'feedback-f859f.firebaseapp.com',
  databaseURL: 'https://feedback-f859f.firebaseio.com',
  projectId: 'feedback-f859f',
  storageBucket: 'feedback-f859f.appspot.com',
  appId: '1:402684375599:web:c1908520baeffee3368cda',
  measurementId: 'G-GKLYSM8ZLX',
};

export default firebaseConfig;
