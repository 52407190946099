import { createFragmentContainer, graphql } from 'react-relay';
import HelpIcon from '@material-ui/icons/Help';
import _IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

import { MODULES } from '@feedback/roles';

import { FeatureFlag } from '../common';

import type { Support_user } from './__generated__/Support_user.graphql';

const IconButton = styled(_IconButton)`
  color: ${(props) => props.theme.richHeaderTextColor};
`;

type Props = {
  user: Support_user;
};
const Support = ({ user }: Props) => {
  const handleClick = () => {
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(['do', 'chat:open']);
  };

  return (
    <FeatureFlag user={user!} features={[MODULES.SUPPORT]}>
      <IconButton onClick={handleClick} edge='end'>
        <HelpIcon />
      </IconButton>
    </FeatureFlag>
  );
};

export default createFragmentContainer(Support, {
  user: graphql`
    fragment Support_user on User {
      ...FeatureFlag_user
    }
  `,
});
