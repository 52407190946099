import { Redirect, Route } from 'react-router-dom';

import type { ReactNode } from 'react';

import type { RouteType } from './types';

export const ROUTE_TYPES = {
  REDIRECT: 'REDIRECT',
};

export const drawRoutes = (
  routes: RouteType[],
  company: Record<string, unknown> | null,
  componentProps: Record<string, unknown> | null,
): ReactNode[] => {
  return routes.map((route, index) => {
    if (route.type && route.type === ROUTE_TYPES.REDIRECT) {
      return (
        <Redirect
          key={index}
          from={route.from}
          to={route.to}
          exact={route.exact}
        />
      );
    }

    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          <route.component
            {...componentProps}
            {...props}
            company={company}
            routes={route.routes}
            modalRoutes={route.modalRoutes}
            currentRoute={route}
          />
        )}
      />
    );
  });
};
