const Warning = (props) => {
  return (
    <svg
      id='prefix__Camada_1'
      x={0}
      y={0}
      viewBox='0 0 154.69 154.69'
      xmlSpace='preserve'
      {...props}
    >
      <style />
      <g transform='translate(.005 .005)' id='prefix__Componente_3_1'>
        <linearGradient
          id='prefix__Elipse_28-2_2_'
          gradientUnits='userSpaceOnUse'
          x1={-191.544}
          y1={572.38}
          x2={-191.527}
          y2={571.238}
          gradientTransform='matrix(149.38 40.04 40.04 -149.38 5791.042 93173.883)'
        >
          <stop offset={0} stopColor='#ef5454' />
          <stop offset={1} stopColor='#fd0101' />
        </linearGradient>
        <circle
          id='prefix__Elipse_28-2'
          cx={77.34}
          cy={77.34}
          r={77.33}
          fill='url(#prefix__Elipse_28-2_2_)'
        />
      </g>
      <path
        id='prefix__Caminho_61'
        d='M65.09 126.15a15.06 15.06 0 01-4.86-11.32c-.12-4.27 1.66-8.38 4.86-11.22 3.43-3 7.88-4.58 12.43-4.4 5.07 0 9.2 1.47 12.38 4.4a14.57 14.57 0 014.78 11.22c.07 4.29-1.69 8.41-4.86 11.32a17.1 17.1 0 01-12.31 4.63c-4.58.17-9.06-1.5-12.42-4.63zm3.35-102.99H86.6c4.1 0 7.43 3.32 7.43 7.42 0 .21-.01.42-.03.63l-4.5 52.71c-.33 3.84-3.55 6.78-7.4 6.78h-9.27c-3.86 0-7.07-2.96-7.39-6.8l-4.39-52.71c-.34-4.08 2.69-7.67 6.77-8.01.21-.01.42-.02.62-.02z'
        fill='#fff'
      />
    </svg>
  );
};

export default Warning;
