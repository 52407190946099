import { Marker } from '@feedback/map';
import { Component } from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';

import { routeTo } from '../../router/utils';

const BoxText = styled.div`
  flex-direction: column;
  position: absolute;
  width: 155px;
  background-color: #607389;
  left: 10px;
  bottom: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  cursor: pointer;
`;

const Name = styled.p`
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 4px;
  text-align: center;
`;

const AddressLabel = styled.p`
  margin-top: 2px;
  text-align: center;
`;

type Address = {
  complement: string | null;
  state: string | null;
  street: string | null;
  city?: string | null;
};

type Props = {
  lat: number;
  lng: number;
  costRevenueCenter?: string;
  isOpen: boolean;
  onClick: () => void;
  address?: Address | null;
  costRevenueCenterId: string;
};

class CostRevenuePin extends Component<Props> {
  static defaultProps = {
    isOpen: false,
    onClick: () => {},
  };

  formatAddress = (address: Address) => {
    const complement =
      address.complement && typeof address.complement === 'string'
        ? `, ${address.complement}`
        : '';

    const state =
      address.state && typeof address.state === 'string'
        ? `, ${address.state}`
        : '';

    return `${address.street || ''}${complement} - ${
      address.city || ''
    }${state}`;
  };

  render() {
    const {
      lat,
      lng,
      name,
      address,
      isOpen,
      onClick,
      costRevenueCenterId,
      history,
    } = this.props;

    return (
      <Marker lat={lat} lng={lng} onClick={onClick}>
        {isOpen && (
          <BoxText
            onClick={() =>
              history.push(
                routeTo('costRevenueCenter.view', { id: costRevenueCenterId }),
              )
            }
          >
            <Name>{name}</Name>
            <AddressLabel>
              {address && this.formatAddress(address)}
            </AddressLabel>
          </BoxText>
        )}
      </Marker>
    );
  }
}

export default withRouter(CostRevenuePin);
