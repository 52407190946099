import { IoBarChartOutline } from 'react-icons/io5';

import { routeTo } from '../../../router/utils';

export const performance = ({ t }) => [
  {
    label: t('Evaluations'),
    icon: <IoBarChartOutline />,
    link: routeTo('user.performance'),
  },
];

export const performanceAdmin = ({ t }) => [
  {
    label: t('Evaluations'),
    icon: <IoBarChartOutline />,
    link: routeTo('performance.list.evaluations'),
  },
];
