import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';

import reducers from '../reducers/index';

const logger = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
  collapsed: true,
  duration: true,
  diff: true,
});

const store = createStore(reducers, compose(applyMiddleware(logger)));

//
if (module.hot) {
  module.hot.accept(() => {
    const nextRootReducer = require('../reducers/index').default; // eslint-disable-line global-require

    store.replaceReducer(nextRootReducer);
  });
}

export default store;
