import type { ReactNode } from 'react';
import { useState, createContext } from 'react';

type SidebarContextType = [boolean, (isOpen: boolean) => void];

export const SidebarContext = createContext<SidebarContextType>([]);

type Props = {
  children: ReactNode;
};
export function SidebarProvider({ children }: Props) {
  const getWidth = () => window.innerWidth > 600;

  const [isOpen, setIsOpen] = useState<boolean>(getWidth());

  return (
    <SidebarContext.Provider value={[isOpen, setIsOpen]}>
      {children}
    </SidebarContext.Provider>
  );
}
