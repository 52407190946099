import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import { useTranslation } from 'react-i18next';

import { ResourceSelectField } from '@feedback/select';

import type { ComplaintSubjectSelectField_query } from './__generated__/ComplaintSubjectSelectField_query.graphql';

type Props = {
  query: ComplaintSubjectSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: Value;
};

type Value = {
  id: string;
  name: string;
};

const ComplaintSubjectSelectField = (props: Props) => {
  const { t } = useTranslation();

  const {
    query,
    relay, // eslint-disable-line no-unused-vars
    hintText = t('Search Topic'),
    name = 'subject',
    active,
    complaintModule,
    ...rest
  } = props;

  const getFilterFragmentVariables = () => {
    return {
      active,
      complaintModule,
    };
  };

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.complaintSubjects}
      hintText={hintText}
      name={name}
      getFilterFragmentVariables={getFilterFragmentVariables}
      {...rest}
    />
  );
};

const ComplaintSubjectSelectFieldFragmentContainer = createRefetchContainer(
  ComplaintSubjectSelectField,
  {
    query: graphql`
      fragment ComplaintSubjectSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 30 }
        after: { type: String }
        active: { type: Boolean, defaultValue: true }
        complaintModule: {
          type: ComplaintSubjectModule
          defaultValue: INTERNAL
        }
      ) {
        complaintSubjects(
          first: $first
          after: $after
          active: $active
          complaintModule: $complaintModule
        )
          @connection(
            key: "ComplaintSubjectSelectField_complaintSubjects"
            filters: []
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query ComplaintSubjectSelectFieldQuery(
      $first: Int
      $after: String
      $active: Boolean
      $complaintModule: ComplaintSubjectModule
    ) {
      ...ComplaintSubjectSelectField_query
        @arguments(
          first: $first
          after: $after
          active: $active
          complaintModule: $complaintModule
        )
    }
  `,
);

export default ComplaintSubjectSelectFieldFragmentContainer;
