import loadable from '@loadable/component';
import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

const PATH = '/home/api';

export const apiPluginsRoutes = [
  {
    name: 'applications',
    path: `${PATH}`,
    labelHeader: ({ t }) => t('API/Plugins'),
    label: ({ t }) => t('API/Plugins'),
    component: loadable(
      () => import('../../../components/applications/ApiPluginsHeader'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.ADJUST, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'applications.list',
        path: `${PATH}/list`,
        labelHeader: ({ t }) => t('API/Plugins'),
        label: ({ t }) => t('API/Plugins'),
        component: loadable(
          () => import('../../../components/applications/ApiSettings'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.ADJUST, MODULES.OPEN_PIX],
      },
    ],
  },
];

export default apiPluginsRoutes;
