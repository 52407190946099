import { graphql, useFragment } from 'react-relay';

import { OpenPixLogoColor, WooviLogo } from '@feedback/ui';

import { COMPANY_PROJECT_TYPES_ENUM } from '@feedback/roles';

import Brand from '../header/Brand';

import FeatureTemp from '../common/v2/featureFlag/FeatureTemp';

import type { SidebarHeader_me$key } from './__generated__/SidebarHeader_me.graphql';

export type Props = {
  me: SidebarHeader_me$key;
};
const SidebarHeader = (props: Props) => {
  const me = useFragment<SidebarHeader_me$key>(
    graphql`
      fragment SidebarHeader_me on User {
        company {
          projectType
          ...Brand_company
        }
        ...FeatureTemp_user
      }
    `,
    props.me,
  );

  const { company } = me;

  if (company.projectType === COMPANY_PROJECT_TYPES_ENUM.OPEN_PIX) {
    return (
      <FeatureTemp
        user={me}
        fallbackComponent={<OpenPixLogoColor height={'30px'} />}
      >
        <WooviLogo height={'30px'} />
      </FeatureTemp>
    );

    return <OpenPixLogoColor height={'30px'} />;
  }

  return <Brand company={company} />;
};

export default SidebarHeader;
