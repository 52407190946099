import loadable from '@loadable/component';

import { MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../utils/index';


const PATH = '/home/nineBox';

const nineBoxRoutes: RouteType[] = [
  {
    name: 'nineBox.user.requests',
    path: `${PATH}/user/requests`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    label: ({ t }) => t('Requests'),
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.USER.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
    routes: [
      {
        name: 'nineBox.user.requests',
        path: `${PATH}/user/requests`,
        component: loadable(
          () =>
            import(
              '../../components/nineBox/request/NineBoxEvaluationRequests'
            ),
        ),
        exact: true,
        label: ({ t }) => t('Requests'),
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.USER.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
      },
    ],
  },
  {
    name: 'nineBox.user.report',
    path: `${PATH}/user/report/:id`,
    component: loadable(
      () => import('../../components/nineBox/user/NineBoxUserReportList'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.USER.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
  },
  {
    name: 'nineBox.evaluation.init',
    path: `${PATH}/evaluation/:nineBoxId/init/:revieweeId?`,
    component: loadable(
      () => import('../../components/nineBox/request/scoreForm/InitStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.USER.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
  },
  {
    name: 'nineBox.evaluation.score',
    path: `${PATH}/evaluation/:nineBoxId/score/:revieweeId?`,
    component: loadable(
      () =>
        import('../../components/nineBox/request/scoreForm/NineBoxScoreStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.USER.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
  },
  {
    name: 'nineBox.evaluation.end',
    path: `${PATH}/evaluation/:nineBoxId/end/:revieweeId?`,
    component: loadable(
      () => import('../../components/nineBox/request/scoreForm/EndStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.USER.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
  },
  {
    name: 'nineBox.home',
    path: `${PATH}/admin`,
    component: loadable(
      () => import('../../components/nineBox/home/NineBoxHome'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
    routes: [
      {
        name: 'nineBox.home.list',
        path: `${PATH}/admin/list`,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        label: ({ t }) => t('List'),
        component: loadable(
          () => import('../../components/nineBox/home/NineBoxHomeList'),
        ),
        exact: true,
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
      },
      {
        name: 'nineBox.home.settings',
        path: `${PATH}/admin/settings`,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () =>
            import(
              '../../components/nineBox/settings/NineBoxModulePreferences'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
      },
    ],
    modalRoutes: [
      {
        name: 'nineBox.home.create',
        path: `${PATH}/admin/create`,
        component: loadable(
          () => import('../../components/nineBox/home/CreateNineBoxModal'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
      },
    ],
  },
  {
    name: 'nineBox.detail',
    path: `${PATH}/detail/:id`,
    component: loadable(
      () => import('../../components/nineBox/detail/NineBoxDetail'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('{{NineBox}}'),
    requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
    requiredFeatures: [MODULES.NINE_BOX],
    routes: [
      {
        name: 'nineBox.detail.box',
        path: `${PATH}/detail/:id/box`,
        component: loadable(
          () => import('../../components/nineBox/detail/box/NineBoxDetailBox'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
        label: 'Box',
      },
      {
        name: 'nineBox.detail.report',
        path: `${PATH}/detail/:id/report`,
        component: loadable(
          () => import('../../components/nineBox/detail/report/NineBoxReport'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
        label: 'Relatório',
      },
      {
        name: 'nineBox.detail.users',
        path: `${PATH}/detail/:id/users`,
        component: loadable(
          () =>
            import('../../components/nineBox/detail/users/NineBoxDetailUsers'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
        label: 'Usuários',
      },
      {
        name: 'nineBox.detail.requests',
        path: `${PATH}/detail/:id/requests`,
        component: loadable(
          () =>
            import(
              '../../components/nineBox/detail/requests/NineBoxRequestList'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
        label: 'Solicitações',
      },
      {
        name: 'nineBox.detail.settings',
        path: `${PATH}/detail/:id/settings`,
        component: loadable(
          () =>
            import(
              '../../components/nineBox/detail/settings/NineBoxDetailSettings'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('{{NineBox}}'),
        requiredRoles: [ROLES.NINE_BOX.ADMIN.ALL],
        requiredFeatures: [MODULES.NINE_BOX],
        label: 'Ajustes',
      },
    ],
  },
];

export default nineBoxRoutes;
