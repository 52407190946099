import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import UserAvatar from '../../avatar/UserAvatar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 15px;
`;

const UserDetails = ({ user }) => (
  <Wrapper>
    <UserAvatar user={user} />
  </Wrapper>
);

export default createFragmentContainer(UserDetails, {
  user: graphql`
    fragment UserDetails_user on User {
      ...UserAvatar_user
    }
  `,
});
