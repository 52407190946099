import PropTypes from 'prop-types';

import styled from 'styled-components';

import config from './config';

const Column = styled.div`
  box-sizing: border-box;
  padding: ${config.gutter};
  flex: 0 0 ${({ small }) => (small * 100) / config.cols}%;

  @media only screen and (min-width: 481px) {
    flex: 0 0 ${({ medium }) => (medium * 100) / config.cols}%;
  }

  @media only screen and (min-width: 481px) {
    flex: 0 0 ${({ large }) => (large * 100) / config.cols}%;
  }
`;

type Props = {
  children: React.ReactNode | null;
  small: number;
  medium: number;
  large: number;
};
const Col = ({ children, ...sizes }: Props) => (
  <Column {...sizes}>{children}</Column>
);

Col.defaultProps = {
  small: config.cols,
  medium: config.cols,
  large: config.cols,
};

Col.propTypes = {
  children: PropTypes.node,
  small: PropTypes.number,
  medium: PropTypes.number,
  large: PropTypes.number,
};

export default Col;
