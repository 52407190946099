import type { ReactNode } from 'react';
import { Component } from 'react';
import type {
  GraphQLTaggedNode,
  Variables } from 'react-relay';
import {
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay';

import { Loading } from '@feedback/ui';

import ErrorView from '../components/common/ErrorView';

type Config = {
  query: GraphQLTaggedNode;
  queriesParams?: (props: any) => Record<string, unknown> | any | null;
  variables?: Variables;
  loadingView?: ReactNode | null;
  renderLoadingView?: (props: any) => ReactNode;
  getFragmentProps?: (
    fragmentProps: Record<string, unknown> | any,
  ) => Record<string, unknown> | any;
  shouldUpdate?: boolean;
  fetchPolicy?: string;
  shouldComponentUpdate?: (prevProps, nextProps) => boolean;
};

export default function createQueryRenderer(
  FragmentComponent: React.ComponentType<any>,
  config: Config,
): React.ComponentType<any> {
  const { query, queriesParams } = config;

  const getVariables = (props) =>
    queriesParams ? queriesParams(props) : config.variables;

  class QueryRendererWrapper extends Component<any> {
    state = {};

    shouldComponentUpdate(nProps) {
      const { location, shouldUpdate } = this.props;
      const { location: nLocation } = nProps;

      if (shouldUpdate) {
        return true;
      }

      if (config.shouldComponentUpdate) {
        return config.shouldComponentUpdate(this.props, nProps);
      }

      if (!location || !nLocation) {
        return true;
      }

      const diffPathname = location.pathname !== nLocation.pathname;

      // uptade only if the pathname changes
      if (diffPathname) {
        return true;
      }

      return false;
    }

    static getDerivedStateFromProps(props) {
      const newVariables = getVariables(props);

      return {
        variables: newVariables,
      };
    }

    render() {
      const { variables } = this.state;
      const { environment } = this.props;

      return (
        <QueryRenderer
          environment={environment}
          fetchPolicy={config.fetchPolicy || 'store-and-network'}
          query={query}
          variables={variables}
          render={({ error, props, retry }) => {
            if (error) {
              return <ErrorView error={error} retry={retry} />;
            }

            if (props) {
              const fragmentProps = config.getFragmentProps
                ? config.getFragmentProps(props)
                : { query: props };

              return <FragmentComponent {...this.props} {...fragmentProps} />;
            }

            if (config.loadingView !== undefined) {
              return config.loadingView;
            }

            if (config.renderLoadingView !== undefined) {
              return config.renderLoadingView(this.props);
            }

            return <Loading fullScreen={true} />;
          }}
        />
      );
    }
  }

  // hacky to keep shouldComponentUpdate
  const QueryRendererEnvironmentWrapper = (wrapperProps: any) => {
    const environment = useRelayEnvironment();

    return <QueryRendererWrapper {...wrapperProps} environment={environment} />;
  };

  QueryRendererEnvironmentWrapper.displayName = `createQueryRendererModern(${
    Component.displayName || Component.name
  })`;

  return QueryRendererEnvironmentWrapper;
}
