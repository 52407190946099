import firebase from 'firebase/app';
import 'firebase/messaging';
import { useEffect, useRef } from 'react';

import { useMutation } from 'react-relay';

import config from '../config';

import firebaseConfig from './firebaseConfig';
import type { PushTokenAddMutation } from './__generated__/PushTokenAddMutation.graphql';
import { PushTokenAdd, USER_PUSHENDPOINT_TYPE } from './PushTokenAddMutation';

export const useSWReg = () => {
  const onMessage = useRef<firebase.Unsubscribe>(null);

  const [pushTokenAdd] = useMutation<PushTokenAddMutation>(PushTokenAdd);

  useEffect(() => {
    // TODO - use workbox-window
    const registerSW = async () => {
      if (!firebase.messaging.isSupported()) {
        return;
      }

      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.getRegistration(
          '/home/sw.js',
        );

        if (!registration) {
          return;
        }

        const shouldInitializeApp = !firebase.apps.length;

        if (shouldInitializeApp) {
          firebase.initializeApp(firebaseConfig);
        }

        const messaging = firebase.messaging();

        try {
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            const token = await messaging.getToken({
              vapidKey: config.FIREBASE_VAPID,
              serviceWorkerRegistration: registration,
            });

            if (!token) {
              return;
            }

            const pushConfig = {
              variables: {
                input: {
                  token,
                  os: USER_PUSHENDPOINT_TYPE.WEB,
                },
              },
            };

            pushTokenAdd(pushConfig);
          }
        } catch (err) {
          if (err.code === 'messaging/permission-blocked') {
            // eslint-disable-next-line
            console.log('Please Unblock Notification Request Manually: ', err);
          } else {
            // eslint-disable-next-line
            console.log('getToken err: ', err);
          }
        }

        onMessage.current = messaging.onMessage((payload) => {
          // eslint-disable-next-line
          console.log('Notification Received', payload);

          const { data } = payload;

          // TODO - show local notification
          // this is the function that gets triggered when you receive a
          // push notification while you’re on the page. So you can
          // create a corresponding UI for you to have the push
          // notification handled.

          registration.showNotification(data.title, {
            vibrate: [200],
            // icon: '/img/apple-icon-144x144-precomposed.png',
            // badge: '/img/badge.png',
            body: data.body,
            title: data.title,
            // timestamp: data.from, notificationData.timestamp,
            // image: notificationData.image,
            // tag: notificationData.tag,
            data,
            // If we don't set a tag and set renotify to true this'll throw an error
            // renotify: notificationData.renotify || !!notificationData.tag,
          });
        });

        navigator.serviceWorker.addEventListener('message', (event) => {
          // eslint-disable-next-line
          console.log('SW message: ', event);
        });
      }
    };

    registerSW();

    return () => {
      if (onMessage.current) {
        onMessage.current();
      }
    };
  }, []);
};
