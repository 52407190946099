import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

import { routeTo } from '../../../router/utils';

export const career = ({ t }) => [
  {
    label: t('Careers'),
    icon: <AssignmentTurnedInOutlinedIcon />,
    items: [
      {
        label: t('My Carrer'),
        link: routeTo('career.myCareer.profile'),
      },
    ],
  },
];
