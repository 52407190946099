import idx from 'idx';
import moment from 'moment';

// eslint-disable-next-line
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// A function that each address component by the key
export const getTypeAddress = (
  response: string,
  type: string,
  field: string,
) => {
  return (
    idx(
      response[0].address_components.filter(
        (c) => c.types.indexOf(type) !== -1,
      )[0],
      (_) => _[field],
    ) || ''
  );
};

// gets two letters to serve as placeholder for profile pic
export const getNamePlaceholder = (name: string) => {
  if (!name) {
    return '?';
  }

  const isLetter = (letter: string) => letter.match(/[a-z]/i);

  const initials = name
    .split(' ')
    .map((word) => (isLetter(word.substring(0, 1)) ? word.substring(0, 1) : ''))
    .slice(0, 2)
    .join('');

  if (initials === '') {
    return '?';
  }

  return initials;
};

export const formatFloat = (n: number | null, decimal = 2, nanValue = '--') => {
  if (typeof n !== 'number') {
    return nanValue;
  }

  if (Number.isInteger(n)) {
    return n.toFixed(0);
  }

  return n.toFixed(decimal);
};

export const handleNumberMask = (value: any) => {
  if (typeof value === 'string') {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{6})$/, '$1.$2');
    value = value.replace(/(\d)(\d{3})$/, '$1.$2');

    return value;
  }

  return value;
};

export const isEmptyOrNull = (value: any) => {
  if (value === '' || value === null) {
    return true;
  }

  return false;
};

type Address = {
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
};

export const formatAddress = (address: Address) => {
  if (!address) {
    return '';
  }

  const street = idx(address, (_) => _.street) || '';
  const number = idx(address, (_) => _.number) || '';
  const complement = idx(address, (_) => _.complement) || '';
  const neighborhood = idx(address, (_) => _.neighborhood) || '';
  const city = idx(address, (_) => _.city) || '';
  const state = idx(address, (_) => _.state) || '';
  const core = `${street ? street : ''}${number ? ', ' + number : ''}${
    complement ? ', ' + complement : ''
  }${street || number || complement ? '. ' : ''}`;

  return `${core}${neighborhood ? neighborhood + '.' : ''} ${city ? city : ''}${
    state ? ', ' + state : ''
  }.`;
};

export const removeStringFromArray = (
  value: string,
  array: string[],
): string[] => {
  const valueIndex = array.findIndex((element) => element === value);

  // not found
  if (valueIndex === -1) {
    return array;
  }

  // array without value
  return [...array.slice(0, valueIndex), ...array.slice(valueIndex + 1)];
};

export const addStringToArray = (
  value: string,
  array: string[],
  allowRepeat = false,
): string[] => {
  if (!allowRepeat) {
    const valueIndex = array.findIndex((element) => element === value);

    // string already is on array
    if (valueIndex > -1) {
      return array;
    }
  }

  return [...array, value];
};

type CompareFn = <T>(a: T, b: T) => boolean;
const compareEqual: CompareFn = (a: any, b: any): boolean => a === b;

export const filterUnique = (
  items: any[],
  compare: CompareFn = compareEqual,
): any[] =>
  items.filter(
    (elem, index, arr) => arr.findIndex((a) => compare(a, elem)) === index,
  );

export const isValidDatetime = (datetime: string | Date | undefined) =>
  datetime && moment(datetime, 'YYYY-MM-DDTHH:mm:ss.sssZ').isValid();
