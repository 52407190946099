/**
 * @generated SignedSource<<eb84946a708e472a9523eb80ed1e1fac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PositionApplicationStatusSelectField_query$data = {
  readonly statuses: ReadonlyArray<{
    readonly id: string;
    readonly position: number | null | undefined;
    readonly status: string | null | undefined;
  } | null | undefined>;
  readonly " $fragmentType": "PositionApplicationStatusSelectField_query";
};
export type PositionApplicationStatusSelectField_query$key = {
  readonly " $data"?: PositionApplicationStatusSelectField_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"PositionApplicationStatusSelectField_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {
        "isActive": true
      },
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PositionApplicationStatusSelectField_query",
  "selections": [
    {
      "alias": "statuses",
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "concreteType": "PositionApplicationStatus",
      "kind": "LinkedField",
      "name": "positionApplicationStatuses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "position",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7afb7502f775c4abb760765f0d1aef3c";

export default node;
