/**
 * @generated SignedSource<<2c8918ff443aaacdcbbde90f09e6401f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CompanyProjectType = "FEEDBACK_HOUSE" | "LOGID" | "OPEN_PIX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SidebarHeader_me$data = {
  readonly company: {
    readonly projectType: CompanyProjectType | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Brand_company">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureTemp_user">;
  readonly " $fragmentType": "SidebarHeader_me";
};
export type SidebarHeader_me$key = {
  readonly " $data"?: SidebarHeader_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarHeader_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarHeader_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Brand_company"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureTemp_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "83c065bdb698c65d00b6387a7272d0d6";

export default node;
