/**
 * @generated SignedSource<<225302c476eb0ab98057d577bb3bf4e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalaryRangeSelectFieldQuery$variables = {
  count?: number | null | undefined;
  search?: string | null | undefined;
};
export type SalaryRangeSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SalaryRangeSelectField_query">;
};
export type SalaryRangeSelectFieldQuery = {
  response: SalaryRangeSelectFieldQuery$data;
  variables: SalaryRangeSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalaryRangeSelectFieldQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SalaryRangeSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalaryRangeSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SalaryRangeConnection",
        "kind": "LinkedField",
        "name": "salaryRanges",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SalaryRangeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalaryRange",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "search"
        ],
        "handle": "connection",
        "key": "SalaryRangeSelectField_salaryRanges",
        "kind": "LinkedHandle",
        "name": "salaryRanges"
      }
    ]
  },
  "params": {
    "cacheID": "43fe79afc6558f28f964d9141fd0ee92",
    "id": null,
    "metadata": {},
    "name": "SalaryRangeSelectFieldQuery",
    "operationKind": "query",
    "text": "query SalaryRangeSelectFieldQuery(\n  $count: Int\n  $search: String\n) {\n  ...SalaryRangeSelectField_query_3DeKgW\n}\n\nfragment SalaryRangeSelectField_query_3DeKgW on Query {\n  salaryRanges(first: $count, search: $search) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3ca74afa49cdd657822303a1eebb475";

export default node;
