import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import { useTranslation } from 'react-i18next';

import type { JobTitleSelectField_query } from './__generated__/JobTitleSelectField_query.graphql';

type Props = {
  query: JobTitleSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  fullWidth?: boolean;
  value?: BusinessDivision;
};

type BusinessDivision = {
  id: string;
  name: string;
};

const JobTitleSelectField = (props: Props) => {
  const { t } = useTranslation();

  const {
    query,
    relay,
    hintText = t('Search Job Title'),
    name = 'jobTitle',
    environment,
    ...rest
  } = props;

  const { jobTitles } = query;

  const getItemFromNode = ({ name }) => {
    return {
      id: name,
      name,
    };
  };

  return (
    <ResourceSelectField
      relay={relay}
      connection={jobTitles}
      getItemFromNode={getItemFromNode}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

const JobTitleSelectFieldRefetchContainer = createRefetchContainer(
  JobTitleSelectField,
  {
    query: graphql`
      fragment JobTitleSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 50 }
        search: { type: String }
        after: { type: String }
      ) {
        jobTitles(first: $count, search: $search, after: $after)
          @connection(key: "JobTitleSelectField_jobTitles", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobTitleSelectFieldQuery(
      $count: Int
      $search: String
      $after: String
    ) {
      ...JobTitleSelectField_query
        @arguments(count: $count, search: $search, after: $after)
    }
  `,
);

export default JobTitleSelectFieldRefetchContainer;
