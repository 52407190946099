type Props = {
  width: number;
  height: number;
  color: string;
};
const FeedbackRequest = ({ width = 30, height = 30, color }: Props) => {
  return (
    <svg width={width} height={height} viewBox='0 0 25 23'>
      <g
        transform='translate(-576.000000, -477.000000) translate(588.500000, 488.500000) scale(-1, 1) translate(-588.500000, -488.500000) translate(576.000000, 477.000000)'
        fill={color}
        stroke='none'
        strokeWidth={1}
        fillRule='evenodd'
      >
        <path
          d='M22.803 0H2.197C.986 0 0 .967 0 2.156v14.375c0 1.19.986 2.157 2.197 2.157h2.246v3.593c0 .291.179.553.453.664a.743.743 0 0 0 .798-.156l4.18-4.102h12.929c1.211 0 2.197-.967 2.197-2.156V2.156C25 .967 24.014 0 22.803 0zm.732 16.531a.727.727 0 0 1-.732.719H9.57a.74.74 0 0 0-.518.21l-3.144 3.086v-2.577a.726.726 0 0 0-.732-.719H2.197a.727.727 0 0 1-.732-.719V2.156c0-.396.328-.719.732-.719h20.606c.404 0 .732.323.732.72V16.53z'
          fillRule='nonzero'
        />
        <path d='M7.555 8.635l-.017.003h7.456L12.65 6.312a.601.601 0 0 1-.178-.43.6.6 0 0 1 .178-.428l.365-.36a.608.608 0 0 1 .43-.177c.163 0 .316.062.43.176l4.019 3.979a.595.595 0 0 1 .177.427.595.595 0 0 1-.177.429l-4.018 3.98a.608.608 0 0 1-.43.175.608.608 0 0 1-.431-.176l-.365-.361a.595.595 0 0 1 0-.845l2.37-2.34H7.547a.628.628 0 0 1-.618-.619v-.51c0-.333.29-.597.626-.597z' />
      </g>
    </svg>
  );
};

export default FeedbackRequest;
