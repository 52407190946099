import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';

import { applicationRoutes } from './applicationRoutes';

export const EXPORT_ROUTE_MODULES = [
  MODULES.FEEDBACK,
  MODULES.FEEDBACK_ONE_TO_ONE,
  MODULES.HIRING,
  MODULES.NINE_BOX,
  MODULES.PLANNING,
  MODULES.PERFORMANCE_REVIEW,
  MODULES.PERMISSIONS,
  MODULES.RESEARCH,
];

export const EXPORT_ROUTE_ROLES = [
  ADMIN_ROLE,

  ROLES.FEEDBACK.ALL,
  ROLES.FEEDBACK.ANALYTICS.ALL,

  ROLES.FEEDBACK_ONE_TO_ONE.ALL,
  ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,

  ROLES.HIRING.CANDIDATE.ALL,
  ROLES.HIRING.GROUP_INTERVIEW.ALL,
  ROLES.HIRING.REFERRAL.ALL,

  ROLES.NINE_BOX.ADMIN.ALL,

  ROLES.PLANNING.ALL,
  ROLES.PLANNING.HEADCOUNT.ALL,
  ROLES.PLANNING.JOB_ROLE.ALL,

  ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,

  ROLES.PERMISSIONS.USER.ALL,
  ROLES.PERMISSIONS.USERS.LIST,

  ROLES.RESEARCH.ALL,
  ROLES.RESEARCH.ADMIN.ALL,
];

const PATH = '/home/settings';

const companyRoutes: RouteType[] = [
  {
    name: 'settings',
    path: `${PATH}/tabs`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    labelHeader: ({ t }) => t('Settings'),
    exact: false,
    requiredRoles: [ROLES.ADJUST.ALL, ROLES.CORE.LOGIN, ...EXPORT_ROUTE_ROLES],
    requiredFeatures: [MODULES.ADJUST, MODULES.CORE, ...EXPORT_ROUTE_MODULES],
    routes: [
      {
        name: 'settings.data',
        path: `${PATH}/tabs/data`,
        component: loadable(
          () => import('../../../components/company/data/CompanyData'),
        ),
        exact: false,
        labelHeader: ({ t }) => t('Company'),
        requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL],
        requiredFeatures: [MODULES.ADJUST],
        label: ({ t }) => t('Data'),
        modalRoutes: [
          {
            name: 'settings.data.emailDomains.add',
            label: ({ t }) => t('Authorized E-Mails'),
            modalTitle: ({ t }) => t('New E-Mail Domain'),
            path: `${PATH}/tabs/data/email-domain-add`,
            component: loadable(
              () =>
                import(
                  '../../../components/company/CompanyEmailDomainAddDialog'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL],
            requiredFeatures: [MODULES.ADJUST],
          },
        ],
      },
      //TODO: Is this route used?
      {
        name: 'settings.preferences',
        path: `${PATH}/tabs/preferences`,
        component: loadable(
          () =>
            import(
              '../../../components/company/preferences/CompanyPreferences'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Company'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.TEMP],
        label: ({ t }) => t('Customizations'),
      },
      {
        name: 'settings.exports',
        path: `${PATH}/tabs/exports`,
        labelHeader: ({ t }) => t('Exports'),
        label: ({ t }) => t('Exports'),
        component: loadable(
          () => import('../../../components/dataExport/ExportList'),
        ),
        exact: false,
        requiredRoles: [...EXPORT_ROUTE_ROLES],
        requiredFeatures: [...EXPORT_ROUTE_MODULES],
        modalRoutes: [
          {
            //TODO: Is this used anymore?
            name: 'settings.exports.new',
            path: `${PATH}/exports/new`,
            labelHeader: ({ t }) => t('Exports'),
            modalTitle: ({ t }) => t('New Export'),
            component: loadable(
              () => import('../../../components/dataExport/ExportAdd'),
            ),
            exact: true,
            requiredRoles: [...EXPORT_ROUTE_ROLES],
            requiredFeatures: [...EXPORT_ROUTE_MODULES],
          },
        ],
      },
      {
        name: 'settings.email.preview',
        path: `${PATH}/tabs/emailPreview`,
        component: loadable(
          () => import('../../../components/email/EmailPreview'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Company'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.TEMP],
        label: ({ t }) => t('Email Preview'),
      },
    ],
  },
  ...applicationRoutes,
  {
    name: 'settings.imports',
    path: `${PATH}/imports`,
    labelHeader: ({ t }) => t('Imports'),
    component: loadable(
      () => import('../../../components/dataImport/ImportList'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL],
    requiredFeatures: [MODULES.ADJUST],
  },
  //TODO: Decide if we whant to keep this, or add a list
  {
    name: 'settings.imports.details',
    path: `${PATH}/imports/:id/:type`,
    labelHeader: ({ t }) => t('Imports'),
    component: loadable(
      () => import('../../../components/dataImport/DataImportDetail'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL],
    requiredFeatures: [MODULES.ADJUST],
    routes: [
      {
        name: 'settings.imports.details.errors',
        path: `${PATH}/imports/:id/:type/errors`,
        component: loadable(
          () => import('../../../components/dataImport/DataImportErrorList'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Imports - Errors'),
        requiredRoles: [ADMIN_ROLE, ROLES.ADJUST.ALL],
        requiredFeatures: [MODULES.ADJUST],
        label: ({ t }) => t('Errors'),
      },
    ],
  },
];

export default companyRoutes;
