// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const NewOmbudsman = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <g fill={color} fillRule='nonzero'>
        <path d='M10 12.51V4.827c-.216.02-.43.054-.648.064H3.125a.627.627 0 0 0-.625.63v.629H.625A.627.627 0 0 0 0 6.78v3.776c0 .347.28.63.625.63H2.5v.629c0 .347.28.63.625.63h.379l2.512 6.745a1.246 1.246 0 0 0 1.17.809c.334 0 .652-.133.879-.373l1.501-1.499c.384-.386.475-.99.23-1.457L7.7 12.445h1.66c.215.01.427.044.641.064zM19.899.304a.61.61 0 0 0-.297-.26c-.003-.001-.006-.006-.01-.008-.012-.003-.023.003-.035 0C19.5.018 19.446 0 19.384 0c-.042 0-.079.016-.12.025-.023.005-.045.006-.068.014a.61.61 0 0 0-.27.178c-.005.005-.012.007-.017.012a12.912 12.912 0 0 1-7.53 4.55v8.029a12.91 12.91 0 0 1 7.53 4.55c.005.006.013.008.02.016a.599.599 0 0 0 .152.12c.019.012.034.028.052.037a.623.623 0 0 0 .46.019c.003-.001.006-.007.01-.008a.6.6 0 0 0 .257-.2l.039-.06a.616.616 0 0 0 .101-.324V.628a.616.616 0 0 0-.101-.324z' />
      </g>
    </svg>
  );
};

NewOmbudsman.defaultProps = {
  color: '#607389',
  viewBox: '0 0 20 20',
  height: 24,
};

export default NewOmbudsman;
