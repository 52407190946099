/**
 * @generated SignedSource<<25521d150834b6284fb1207b87349cd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobExamAnswerSelectField_query$data = {
  readonly jobExamQuestion: {
    readonly id: string;
    readonly options?: ReadonlyArray<{
      readonly value: string;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "JobExamAnswerSelectField_query";
};
export type JobExamAnswerSelectField_query$key = {
  readonly " $data"?: JobExamAnswerSelectField_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobExamAnswerSelectField_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jobExamQuestionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobExamAnswerSelectField_query",
  "selections": [
    {
      "alias": "jobExamQuestion",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "jobExamQuestionId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JobExamQuestionOption",
              "kind": "LinkedField",
              "name": "options",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "JobExamQuestion",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "699df2ba0554214fce6ae4ba5f9c1d62";

export default node;
