import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import upload from '@feedback/upload';
import { useSnackbar, Loading } from '@feedback/ui';

import PreSignedFileUploadMutation from '../../mutations/PreSignedFileUploadMutation';
import UserEditProfileImage from '../user/mutation/UserEditProfileImageMutation';

import { getMutationCallbacks } from '../../relay';

import UserAvatar from './avatar/UserAvatar';

import type { UserDetailsImageInput_user } from './__generated__/UserDetailsImageInput_user.graphql';

const ACCEPTED_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

const FileInput = styled.input`
  display: none;
`;

const Spacer = styled.div`
  position: relative;
  margin-right: 20px;
  width: 60px;
`;

const IconLinkWrapper = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  position: absolute;
  bottom: 0;
  height: 20px;
  width: 60px;
  line-height: 20px;
  justify-content: center;
  font-size: 10px;
`;

type Props = {
  user: UserDetailsImageInput_user;
};

const defaultErrorMessage = 'Não foi possível atualizar sua imagem';

const UserDetailsImageInput = ({ user }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const setUploadedFileUrl = (fileUrl: string, fields: any) => {
    const input = {
      id: user.id,
      url: fileUrl,
      contentType: fields['Content-Type'],
    };

    const { onCompleted, onError } = getMutationCallbacks({
      name: 'UserEditProfileImage',
      showSnackbar,
      success: t('Profile image updated successfully'),
      error: t('Error uploading profile image'),
      afterCompleted: () => {
        setIsLoading(false);
      },
      afterError: () => {
        setIsLoading(false);
      },
    });

    UserEditProfileImage.commit(input, onCompleted, onError);
  };

  const onDrop = async (acceptedFiles) => {
    setIsLoading(true);

    const [blob] = acceptedFiles;

    const { error, file } = await upload({
      file: blob,
      fileType: 'USER_PROFILE_IMAGE',
      headerType: 'IMAGE',
      mutation: PreSignedFileUploadMutation,
    });

    if (error || !file) {
      setIsLoading(false);
      showSnackbar({ message: defaultErrorMessage });

      return;
    }

    setUploadedFileUrl(file.url, file.fields);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    noClick: true,
    onFileDialogCancel: () => {},
  });

  if (isLoading) {
    return (
      <Spacer>
        <Loading size={60} />
      </Spacer>
    );
  }

  return (
    <Spacer {...getRootProps()} onClick={open}>
      <UserAvatar size={60} user={user} />
      <IconLinkWrapper>editar</IconLinkWrapper>
      <FileInput
        type='file'
        name='file'
        id='file'
        className='inputfile'
        accept={ACCEPTED_TYPES.join(',')}
        {...getInputProps()}
      />
    </Spacer>
  );
};

export default createFragmentContainer(UserDetailsImageInput, {
  user: graphql`
    fragment UserDetailsImageInput_user on User {
      id
      ...UserAvatar_user
    }
  `,
});
