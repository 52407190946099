/**
 * @generated SignedSource<<5498de89c897decdb68dbd2e4113910d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginApp_query$data = {
  readonly company: {
    readonly _id: string;
    readonly id: string;
    readonly logo: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly name: string | null | undefined;
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "LoginApp_query";
};
export type LoginApp_query$key = {
  readonly " $data"?: LoginApp_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoginApp_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoginApp_query",
  "selections": [
    {
      "alias": "company",
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companyDomainName",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nameFriendly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "fitIn",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "url",
              "storageKey": "url(fitIn:true)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "330b62bcb4a734dfc8f65f36533d0b40";

export default node;
