/**
 * @generated SignedSource<<4f4b8be9c9ac6f08846470796c5f9841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Providers_query$data = {
  readonly company: {
    readonly lang: string | null | undefined;
    readonly preferences: {
      readonly complaint: {
        readonly module: {
          readonly name: string;
        };
      };
      readonly complaintExternal: {
        readonly module: {
          readonly name: string;
        };
        readonly title: string;
      };
      readonly feedback: {
        readonly module: {
          readonly name: string;
          readonly pluralName: string;
        };
      };
      readonly feedbackOneToOne: {
        readonly module: {
          readonly name: string;
        };
      };
      readonly nineBox: {
        readonly module: {
          readonly name: string;
        };
      };
      readonly theme: {
        readonly button: {
          readonly backgroundColor: string | null | undefined;
          readonly textColor: string | null | undefined;
        };
        readonly darkGray: string;
        readonly graphLighterColor: string;
        readonly highlightColor: string;
        readonly id: string;
        readonly lightGray: string;
        readonly mediumGray: string;
        readonly name: string | null | undefined;
        readonly primaryDarkerColor: string;
        readonly primaryLighterColor: string;
        readonly richHeaderColor1: string;
        readonly richHeaderColor2: string;
        readonly richHeaderTextColor: string;
      };
    };
  } | null | undefined;
  readonly me: {
    readonly lang: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Providers_query";
};
export type Providers_query$key = {
  readonly " $data"?: Providers_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"Providers_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Providers_query",
  "selections": [
    {
      "alias": "company",
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companyDomainName",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyPreferences",
          "kind": "LinkedField",
          "name": "preferences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyPreferencesFeedback",
              "kind": "LinkedField",
              "name": "feedback",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FeedbackModulePreferences",
                  "kind": "LinkedField",
                  "name": "module",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pluralName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyPreferencesFeedbackOneToOne",
              "kind": "LinkedField",
              "name": "feedbackOneToOne",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FeedbackOneToOneModulePreferences",
                  "kind": "LinkedField",
                  "name": "module",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyComplaintPreferences",
              "kind": "LinkedField",
              "name": "complaint",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ComplaintModulePreferences",
                  "kind": "LinkedField",
                  "name": "module",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyComplaintExternalPreferences",
              "kind": "LinkedField",
              "name": "complaintExternal",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ComplaintExternalModulePreferences",
                  "kind": "LinkedField",
                  "name": "module",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyPreferencesNineBox",
              "kind": "LinkedField",
              "name": "nineBox",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NineBoxModulePreferences",
                  "kind": "LinkedField",
                  "name": "module",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Theme",
              "kind": "LinkedField",
              "name": "theme",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryDarkerColor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryLighterColor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Style",
                  "kind": "LinkedField",
                  "name": "button",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "backgroundColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "textColor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richHeaderColor1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richHeaderColor2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richHeaderTextColor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "darkGray",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "highlightColor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mediumGray",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lightGray",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "graphLighterColor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "77a82d0b998aa4f3dfdf4f11f98aedb3";

export default node;
