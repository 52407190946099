import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProfilePicBadge from './ProfilePicBadge';

const CardContainer = styled.div`
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  padding: 5px;
  height: ${(props) => (props.height ? `${props.height}px` : '80px')};
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  display: relative;
  display: flex;
  margin: 2.5px;
  width: 100%;
  margin-bottom: 20px;
  > div {
    flex-grow: 1;
    margin: 10px 10px;
    > div {
      flex-grow: 1;
    }
  }
  > div:first-child {
    display: inline-flex;
    > div:first-child {
      width: 250px;
    }
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  font-size: 10px;
  margin-top: 10px;
  color: #9e9e9e;
  > p {
    margin-bottom: 5px !important;
  }
  span {
    color: #4890e5;
  }
  height: 30px;
  .jobTitle {
    font-size: 12px;
    color: #000000;
  }
`;

const Profile = styled.div`
  margin-left: -10px;
  div {
    display: inline-block;
    vertical-align: top;
    p {
      margin: 0;
    }
  }
`;

const UserCard = ({
  height,
  name,
  profileImage,
  jobTitle,
  message,
  email,
  active,
  width,
  onClick,
}) => {
  return (
    <CardContainer height={height} active={active} onClick={onClick}>
      <div>
        <Profile>
          <ProfilePicBadge size={width * 0.7} profileImage={profileImage} />
          <TextContainer>
            <p className='jobTitle'>{name}</p>
            <p>{jobTitle}</p>
            {email && <p>{email}</p>}
          </TextContainer>
        </Profile>
        {message && <TextContainer>{message}</TextContainer>}
      </div>
    </CardContainer>
  );
};

UserCard.defaultProps = {
  profileImage: null,
  jobTitle: null,
  email: '',
  width: 80,
  height: null,
};

UserCard.propTypes = {
  name: PropTypes.string,
  height: PropTypes.number,
  profileImage: PropTypes.string,
  jobTitle: PropTypes.string,
  email: PropTypes.string,
  active: PropTypes.bool,
};

export default UserCard;
