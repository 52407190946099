import loadable from '@loadable/component';

import type { RouteType } from '../utils';

const PATH = '/home';

const homeRoutes: RouteType[] = [
  {
    name: 'home',
    path: PATH,
    labelHeader: 'Home',
    component: loadable(() => import('../../components/home/Home')),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
    requiredProjectType: [],
  },
  {
    name: 'home.feedback',
    exact: true,
    path: `${PATH}/hr`,
    component: loadable(() => import('../../components/home/HomeHR')),
  },
  {
    name: 'status',
    path: `${PATH}/status`,
    labelHeader: 'Status',
    component: loadable(() => import('../../components/core/Status')),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'forbidden',
    path: `${PATH}/forbidden`,
    component: loadable(() => import('../components/Forbidden')),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
];

export default homeRoutes;
