import { graphql, useFragment } from 'react-relay';

import { usePermission } from '../../../../security/usePermission';

import type { FeatureFlag_user$key } from './__generated__/FeatureFlag_user.graphql';

const DefaultFallback = () => <></>;

export type FeatureFlagProps = {
  routes?: string[];
  features?: string[];
  roles?: string[];
  projectsTypes?: string[];
  children: React.ReactNode;
  fallbackComponent?: React.ReactNode;
  user: FeatureFlag_user$key;
};

const FeatureFlag = ({
  children,
  fallbackComponent,
  routes,
  features = [],
  roles = [],
  projectsTypes = [],
  ...props
}: FeatureFlagProps) => {
  const user = useFragment(
    graphql`
      fragment FeatureFlag_user on User {
        ...usePermissionFragment
      }
    `,
    props.user,
  );

  const { hasPermission } = usePermission(user);
  const userHasPermission = hasPermission(features, roles, projectsTypes);

  if (!userHasPermission) {
    return fallbackComponent !== undefined ? (
      fallbackComponent
    ) : (
      <DefaultFallback />
    );
  }

  return children;
};

export default FeatureFlag;
