import loadable from '@loadable/component';

import {
  ADMIN_ROLE,
  COMPANY_PROJECT_TYPES_ENUM,
  MODULES,
  ROLES,
} from '@feedback/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/permissions';

const permissionsRoutes: RouteType[] = [
  {
    name: 'permissions.users',
    path: `${PATH}/tabs/`,
    labelHeader: ({ t }) => t('Permissions'),
    component: loadable(() => import('../../../components/user/UserDetails')),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERMISSIONS.USER.ALL,
      ROLES.PERMISSIONS.USERS.LIST,
      ROLES.PERMISSIONS.USERS.TRANSFER,
      ROLES.PERMISSIONS.USERS.IMPORT,
      ROLES.OPEN_PIX.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.users.list',
        path: `${PATH}/tabs/users-list`,
        labelHeader: ({ t }) => t('Users'),
        component: loadable(
          () => import('../../../components/user/UserListAll'),
        ),
        label: ({ t }) => t('Users'),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.PERMISSIONS.USERS.LIST,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
      {
        name: 'manager.incomplete.transfers',
        path: `${PATH}/tabs/users-transfers`,
        label: ({ t }) => t('Transfers'),
        component: loadable(
          () => import('../../../components/manager/IncompleteTransfersList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.PERMISSIONS.USERS.TRANSFER,
        ],
        requiredFeatures: [MODULES.PERMISSIONS],
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },

      {
        name: 'permissions.all.list',
        path: `${PATH}/tabs/permissions-list`,
        labelHeader: ({ t }) => t('Permissions List'),
        label: ({ t }) => t('Permissions List'),
        component: loadable(
          () => import('../../../components/accessControl/roles/RoleList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.ROLE_GROUP.ALL,
          ROLES.PERMISSIONS.USER.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },

      {
        name: 'permissions.all.groups',
        path: `${PATH}/tabs/groups-list`,
        labelHeader: ({ t }) => t('Permission Groups'),
        label: ({ t }) => t('Permission Groups'),
        component: loadable(
          () =>
            import('../../../components/accessControl/roleGroup/RoleGroupList'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },

      {
        name: 'permissions.users.import.users.import',
        path: `${PATH}/tabs/users-import`,
        labelHeader: ({ t }) => t('Import'),
        component: loadable(
          () => import('../../../components/user/import/UsersImportStepper'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.PERMISSIONS.USERS.IMPORT,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [],
        label: ({ t }) => t('Import'),
      },
    ],
  },

  {
    name: 'permissions.user',
    path: `${PATH}/user/:id`,
    labelHeader: ({ t }) => t('User Permissions'),
    component: loadable(
      () =>
        import('../../../components/accessControl/userRoles/UserRoleDetails'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERMISSIONS.USER.ALL,
      ROLES.OPEN_PIX.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.user.edit',
        path: `${PATH}/user/:id/edit`,
        labelHeader: ({ t }) => t('Edit User'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        exact: true,
        component: loadable(
          () => import('../../../components/user/edit/UserEdit'),
        ),
        label: ({ t }) => t('Data'),
      },
      {
        name: 'permissions.user.roles',
        path: `${PATH}/user/:id/roles`,
        labelHeader: ({ t }) => t('User Permissions'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        exact: true,
        component: loadable(
          () => import('../../../components/accessControl/userRoles/UserRoles'),
        ),
        label: ({ t }) => t('Permissions'),
      },
      {
        name: 'permissions.user.groups',
        path: `${PATH}/user/:id/groups`,
        labelHeader: ({ t }) => t('User Permissions'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        exact: false,
        component: loadable(
          () =>
            import(
              '../../../components/accessControl/userRoles/UserRoleGroupsList'
            ),
        ),
        label: ({ t }) => t('Permissions Group'),
        modalRoutes: [
          {
            name: 'permissions.user.groups.remove',
            path: `${PATH}/user/:id/groups/remove/:roleGroupId`,
            labelHeader: ({ t }) => t('User Permissions'),
            component: loadable(
              () =>
                import(
                  '../../../components/accessControl/userRoles/UserRoleGroupRemove'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PERMISSIONS.USER.ALL,
              ROLES.OPEN_PIX.ADMIN.ALL,
            ],
            requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
          },
          {
            name: 'permissions.user.groups.add',
            path: `${PATH}/user/:id/groups/add`,
            labelHeader: ({ t }) => t('User Permissions'),
            modalTitle: ({ t }) => t('Select a Group'),
            component: loadable(
              () =>
                import(
                  '../../../components/accessControl/userRoles/UserRoleGroupAdd'
                ),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PERMISSIONS.USER.ALL,
              ROLES.OPEN_PIX.ADMIN.ALL,
            ],
            requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
          },
        ],
      },
      {
        name: 'permissions.user.session',
        path: `${PATH}/user/:id/session`,
        labelHeader: ({ t }) => t('Sessions'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.USER.ALL,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        exact: true,
        component: loadable(
          () => import('../../../components/user/session/UserSessionTokenList'),
        ),
        label: ({ t }) => t('Sessions'),
      },
      {
        name: 'permissions.user.orgchart',
        path: `${PATH}/user/:id/orgchart`,
        labelHeader: ({ t }) => t('Edit User'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.USER.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
        exact: true,
        component: loadable(
          () => import('../../../components/team/UserOrgChart'),
        ),
        label: ({ t }) => t('Charts'),
      },
    ],
  },
  {
    name: 'permissions.list.detail',
    path: `${PATH}/role/:role`,
    labelHeader: ({ t }) => t('Permissions List'),
    component: loadable(
      () => import('../../../components/accessControl/roles/RoleListDetails'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERMISSIONS.ROLE_GROUP.ALL,
      ROLES.PERMISSIONS.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.list.users',
        path: `${PATH}/role/:role`,
        labelHeader: ({ t }) => t('Permissions List'),
        component: loadable(
          () => import('../../../components/accessControl/roles/RoleListUsers'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERMISSIONS.ROLE_GROUP.ALL,
          ROLES.PERMISSIONS.USER.ALL,
        ],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        label: ({ t }) => t('Users'),
      },
    ],
  },
  {
    name: 'permissions.groups.add',
    path: `${PATH}/groups/add`,
    labelHeader: ({ t }) => t('Permissions Group'),
    component: loadable(
      () => import('../../../components/accessControl/roleGroup/RoleGroupAdd'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
  },
  {
    name: 'permissions.groups.detail',
    path: `${PATH}/group/:id`,
    labelHeader: ({ t }) => t('Group Permissions'),
    component: loadable(
      () =>
        import('../../../components/accessControl/roleGroup/RoleGroupDetail'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.groups.users',
        path: `${PATH}/group/:id/users`,
        labelHeader: ({ t }) => t('Group Permissions'),
        component: loadable(
          () =>
            import(
              '../../../components/accessControl/roleGroup/RoleGroupUsersList'
            ),
        ),
        exact: false,
        label: ({ t }) => t('Users'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        modalRoutes: [
          {
            name: 'permissions.groups.user.remove',
            path: `${PATH}/group/:id/users/remove/:userId`,
            labelHeader: ({ t }) => t('Group Permissions'),
            component: loadable(
              () =>
                import(
                  '../../../components/accessControl/roleGroup/RoleGroupUserRemove'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.USER.ALL],
            requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
          },
        ],
      },
      {
        name: 'permissions.groups.edit',
        path: `${PATH}/group/:id/edit`,
        labelHeader: ({ t }) => t('Group Permissions'),
        component: loadable(
          () =>
            import('../../../components/accessControl/roleGroup/RoleGroupEdit'),
        ),
        exact: true,
        label: ({ t }) => t('Permissions'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
      {
        name: 'permissions.groups.settings',
        path: `${PATH}/group/:id/settings`,
        labelHeader: ({ t }) => t('Group Permissions'),
        component: loadable(
          () =>
            import(
              '../../../components/accessControl/roleGroup/RoleGroupSettings'
            ),
        ),
        exact: false,
        label: ({ t }) => t('Settings'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        modalRoutes: [
          {
            name: 'permissions.groups.remove',
            path: `${PATH}/group/:id/settings/remove`,
            labelHeader: ({ t }) => t('Group Permissions'),
            component: loadable(
              () =>
                import(
                  '../../../components/accessControl/roleGroup/RoleGroupRemove'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.PERMISSIONS.ROLE_GROUP.ALL],
            requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
          },
        ],
      },
    ],
  },
];

export default permissionsRoutes;
