import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { GroupInterviewSelectField_query } from './__generated__/GroupInterviewSelectField_query.graphql';

type Props = {
  query: GroupInterviewSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: GroupInterview;
};

type GroupInterview = {
  id: string;
  name: string;
};

const GroupInterviewSelectField = (props: Props) => {
  const {
    query,
    relay,
    hintText = 'Buscar Dinâmica',
    name = 'groupInterview',
    ...rest
  } = props;

  const getItemFromNode = (node) => ({
    id: node.id,
    name: node.name,
    hasActiveGroupInterviewRooms: node.hasActiveGroupInterviewRooms,
  });

  return (
    <ResourceSelectField
      relay={relay}
      connection={query.groupInterviews}
      getItemFromNode={getItemFromNode}
      hintText={hintText}
      name={name}
      {...rest}
    />
  );
};

export default createRefetchContainer(
  GroupInterviewSelectField,
  {
    query: graphql`
      fragment GroupInterviewSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 300 }
        search: { type: String, defaultValue: "" }
        after: { type: String }
      ) {
        groupInterviews(first: $first, search: $search, after: $after)
          @connection(
            key: "GroupInterviewSelectField_groupInterviews"
            filters: []
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              hasActiveGroupInterviewRooms
            }
          }
        }
      }
    `,
  },
  graphql`
    query GroupInterviewSelectFieldQuery(
      $first: Int
      $search: String
      $after: String
    ) {
      ...GroupInterviewSelectField_query
        @arguments(first: $first, search: $search, after: $after)
    }
  `,
);
