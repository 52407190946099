/**
 * @generated SignedSource<<2f33218a6fb38f466f56c80f22c7ca5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectionEnum = "ASC" | "DESC" | "%future added value";
export type GoalGroupFilterDateEnumType = "CREATION" | "EXAMINATION" | "FINISHED" | "%future added value";
export type GoalGroupSortEnum = "DATE_APPROVAL_CALCULATE_LIMIT" | "DATE_APPROVAL_LIMIT" | "DATE_CALCULATE_LIMIT" | "DATE_CREATED_LIMIT" | "DATE_END" | "DATE_START" | "TITLE" | "%future added value";
export type GoalGroupFilter = {
  AND?: ReadonlyArray<GoalGroupFilter | null | undefined> | null | undefined;
  OR?: ReadonlyArray<GoalGroupFilter | null | undefined> | null | undefined;
  area?: string | null | undefined;
  creator?: string | null | undefined;
  date?: GoalGroupFilterDateEnumType | null | undefined;
  dateApprovalCalculateLimit_gt?: string | null | undefined;
  dateApprovalCalculateLimit_gte?: string | null | undefined;
  dateApprovalCalculateLimit_lt?: string | null | undefined;
  dateApprovalCalculateLimit_lte?: string | null | undefined;
  dateApprovalLimit_gt?: string | null | undefined;
  dateApprovalLimit_gte?: string | null | undefined;
  dateApprovalLimit_lt?: string | null | undefined;
  dateApprovalLimit_lte?: string | null | undefined;
  dateCalculateLimit_gt?: string | null | undefined;
  dateCalculateLimit_gte?: string | null | undefined;
  dateCalculateLimit_lt?: string | null | undefined;
  dateCalculateLimit_lte?: string | null | undefined;
  dateCreateLimit_gt?: string | null | undefined;
  dateCreateLimit_gte?: string | null | undefined;
  dateCreateLimit_lt?: string | null | undefined;
  dateCreateLimit_lte?: string | null | undefined;
  dateEnd_gt?: string | null | undefined;
  dateEnd_gte?: string | null | undefined;
  dateEnd_lt?: string | null | undefined;
  dateEnd_lte?: string | null | undefined;
  dateStart_gt?: string | null | undefined;
  dateStart_gte?: string | null | undefined;
  dateStart_lt?: string | null | undefined;
  dateStart_lte?: string | null | undefined;
  include?: ReadonlyArray<string | null | undefined> | null | undefined;
  isActivated?: boolean | null | undefined;
  isCurrent?: boolean | null | undefined;
  orderBy?: ReadonlyArray<GoalGroupOrdering> | null | undefined;
  search?: string | null | undefined;
  user?: string | null | undefined;
};
export type GoalGroupOrdering = {
  direction: DirectionEnum;
  sort: GoalGroupSortEnum;
};
export type GoalGroupSelectFieldQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  goalGroupFilters?: GoalGroupFilter | null | undefined;
};
export type GoalGroupSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GoalGroupSelectField_query">;
};
export type GoalGroupSelectFieldQuery = {
  response: GoalGroupSelectFieldQuery$data;
  variables: GoalGroupSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "goalGroupFilters"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "goalGroupFilters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GoalGroupSelectFieldQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "goalGroupFilters",
            "variableName": "goalGroupFilters"
          }
        ],
        "kind": "FragmentSpread",
        "name": "GoalGroupSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GoalGroupSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GoalGroupConnection",
                "kind": "LinkedField",
                "name": "goalGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoExtended",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GoalGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GoalGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": "name",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "GoalGroupSelectField_goalGroups",
                "kind": "LinkedHandle",
                "name": "goalGroups"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "177ae22a439f78ba6eb65f86a950f128",
    "id": null,
    "metadata": {},
    "name": "GoalGroupSelectFieldQuery",
    "operationKind": "query",
    "text": "query GoalGroupSelectFieldQuery(\n  $count: Int\n  $after: String\n  $goalGroupFilters: GoalGroupFilter\n) {\n  ...GoalGroupSelectField_query_2FU5DI\n}\n\nfragment GoalGroupSelectField_query_2FU5DI on Query {\n  me {\n    company {\n      goalGroups(first: $count, after: $after, filters: $goalGroupFilters) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          node {\n            id\n            name: title\n            __typename\n          }\n          cursor\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b646148e24139733d3404b6f20d8a37";

export default node;
