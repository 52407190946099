import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { useFragment, graphql } from 'react-relay';
import { COMPANY_PROJECT_TYPES_ENUM } from '@feedback/roles';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '@feedback/ui';

import moment from 'moment/moment';

import type { HeaderTrialWarning_me$key } from './__generated__/HeaderTrialWarning_me.graphql';

const Warning = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: yellow;
`;

type HeaderTrialWarningProps = {
  me: HeaderTrialWarning_me$key;
};

const HeaderTrialWarning = (props: HeaderTrialWarningProps) => {
  const { t } = useTranslation();
  const me = useFragment<HeaderTrialWarning_me$key>(
    graphql`
      fragment HeaderTrialWarning_me on User {
        company {
          trialDate
          projectType
        }
      }
    `,
    props.me,
  );

  const { company } = me;

  if (company?.projectType !== COMPANY_PROJECT_TYPES_ENUM.OPEN_PIX) {
    return null;
  }

  const isTrialValid = () => {
    const trialDate = moment(company?.trialDate).startOf('day');
    const todayDate = moment().startOf('day');

    const isSame = moment(todayDate).isSame(trialDate);

    if (isSame) {
      return isSame;
    }

    const isTrialValid = moment(todayDate).isBefore(trialDate);

    return isTrialValid;
  };

  if (!company?.trialDate) {
    return null;
  }

  if (isTrialValid()) {
    return null;
  }

  const handleClick = () => {
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(['do', 'chat:open']);
  };

  return (
    <Warning>
      <Text>{t('Trial expired. Please contact support')}</Text>
      <ActionButton variant='text' onClick={handleClick}>{t('Contact support')}</ActionButton>
    </Warning>
  );
};

export default HeaderTrialWarning;
