/**
 * @generated SignedSource<<49b9b4ef59f2898d4a05570931df2186>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BusinessDivisionFilter = {
  search?: string | null | undefined;
};
export type BusinessDivisionSelectFieldQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  filters?: BusinessDivisionFilter | null | undefined;
};
export type BusinessDivisionSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BusinessDivisionSelectField_query">;
};
export type BusinessDivisionSelectFieldQuery = {
  response: BusinessDivisionSelectFieldQuery$data;
  variables: BusinessDivisionSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessDivisionSelectFieldQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "BusinessDivisionSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BusinessDivisionSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "BusinessDivisionConnection",
        "kind": "LinkedField",
        "name": "businessDivisions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessDivisionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessDivision",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "BusinessDivisionSelectField_businessDivisions",
        "kind": "LinkedHandle",
        "name": "businessDivisions"
      }
    ]
  },
  "params": {
    "cacheID": "6d7f2ef75d2c024d877bd3ad44905abb",
    "id": null,
    "metadata": {},
    "name": "BusinessDivisionSelectFieldQuery",
    "operationKind": "query",
    "text": "query BusinessDivisionSelectFieldQuery(\n  $count: Int\n  $filters: BusinessDivisionFilter\n  $after: String\n) {\n  ...BusinessDivisionSelectField_query_1NP3V8\n}\n\nfragment BusinessDivisionSelectField_query_1NP3V8 on Query {\n  businessDivisions(first: $count, filters: $filters, after: $after) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58e55fd27aeb13d518ac7eb6e8fd986b";

export default node;
