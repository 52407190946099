import PropTypes from 'prop-types';

import config from './config';

type Props = {
  children: React.ReactNode;
  alignItems: string;
  justifyContent: string;
};
const Row = ({ children, alignItems, justifyContent }: Props) => {
  const styles = {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems,
    justifyContent,
    flexWrap: 'wrap',
    marginLeft: `-${config.gutter}`,
    marginRight: `-${config.gutter}`,
  };

  return <div style={styles}>{children}</div>;
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
};

export default Row;
