/**
 * @generated SignedSource<<2a0fa8cac1c6ffc423c2c25a451c7292>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectionEnum = "ASC" | "DESC" | "%future added value";
export type PerformanceReviewSortEnum = "CREATED_AT" | "SCORE" | "TITLE" | "%future added value";
export type PerformanceReviewFilter = {
  active?: boolean | null | undefined;
  date?: DateFilter | null | undefined;
  orderBy?: ReadonlyArray<PerformanceReviewOrdering> | null | undefined;
  score?: RangeFilter | null | undefined;
  search?: string | null | undefined;
  status?: string | null | undefined;
};
export type PerformanceReviewOrdering = {
  direction: DirectionEnum;
  sort: PerformanceReviewSortEnum;
};
export type DateFilter = {
  begin?: string | null | undefined;
  end?: string | null | undefined;
  start?: string | null | undefined;
};
export type RangeFilter = {
  max?: number | null | undefined;
  min?: number | null | undefined;
};
export type PerformanceReviewSelectFieldQuery$variables = {
  after?: string | null | undefined;
  filters?: PerformanceReviewFilter | null | undefined;
  first?: number | null | undefined;
};
export type PerformanceReviewSelectFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PerformanceReviewSelectField_query">;
};
export type PerformanceReviewSelectFieldQuery = {
  response: PerformanceReviewSelectFieldQuery$data;
  variables: PerformanceReviewSelectFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceReviewSelectFieldQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "PerformanceReviewSelectField_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PerformanceReviewSelectFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PerformanceReviewConnection",
        "kind": "LinkedField",
        "name": "performanceReviews",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PerformanceReviewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PerformanceReview",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "PerformanceReviewSelectField_performanceReviews",
        "kind": "LinkedHandle",
        "name": "performanceReviews"
      }
    ]
  },
  "params": {
    "cacheID": "eca9f149c9ae12ec65d71ae39b9c3b8e",
    "id": null,
    "metadata": {},
    "name": "PerformanceReviewSelectFieldQuery",
    "operationKind": "query",
    "text": "query PerformanceReviewSelectFieldQuery(\n  $first: Int\n  $after: String\n  $filters: PerformanceReviewFilter\n) {\n  ...PerformanceReviewSelectField_query_1XoDko\n}\n\nfragment PerformanceReviewSelectField_query_1XoDko on Query {\n  performanceReviews(first: $first, after: $after, filters: $filters) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        title\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f037c7009afa9199c7fe914632e8e03a";

export default node;
