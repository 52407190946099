import { createGlobalStyle } from 'styled-components';

import { hexToRgb } from '../helpers';

const hexToRGBString = (hex: string): string => hexToRgb(hex).join(',');

const primary = '#03D69D';
const secondary = '#09B889';

const ThemeDefault = {
  name: 'Woovi',
  primaryBackgroundColor: '#f8f8f8',
  darkGray: '#607389',
  mediumGray: '#e8e8e8',
  lightGray: '#f8f8f8',
  primaryFont: 'Open Sans',
  highlightColor: primary,
  primaryDarkerColor: '#607389',
  primaryLighterColor: primary,
  graphLighterColor: primary,
  progressBarColor: '#309DD1',
  progressBarDoneColor: '#7ED321',
  button: {
    backgroundColor: primary,
    textColor: '#FFFFFF',
  },
  menuBadges: {
    backgroundColor: '#73bf45',
    textColor: '#ffffff',
  },
  richHeaderColor1: primary,
  richHeaderColor2: secondary,
  richHeaderTextColor: '#FFFFFF',
  simpleHeader: {
    backgroundColor: '#ffffff',
    textColor: '#607389',
  },
};

//TODO: Deprecate this
export const ThemeGlobal = createGlobalStyle`
    html, body {
      color: ${(props) => props.theme.primaryDarkerColor};
    }
  `;

export const ThemeMuiTheme = (extra) => {
  const theme = {
    ...ThemeDefault,
    ...extra,
  };

  return {
    ...theme,
    typography: {
      fontFamily: [
        '"Open Sans"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 12,
    },
    palette: {
      primary: {
        main: theme.primaryLighterColor,
      },
      secondary: {
        main: theme.primaryDarkerColor,
      },
      error: {
        main: '#FC615D',
      },
      // Comment so we start using MUI default text colors
      // text: {
      //   primary: theme.primaryDarkerColor,
      //   secondary: `rgba(${hexToRGBString(theme.primaryDarkerColor)}, 0.8)`,
      // },
    },
    // we can also override any comp
    overrides: {
      MuiButton: {
        textPrimary: {
          color: theme.primaryDarkerColor,
        },
        textSecondary: {
          color: theme.primaryDarkerColor,
          opacity: 0.6,
        },
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule
          color: '#309DD1', // Some CSS
        },
        label: {
          fontWeight: 600,
        },
      },
      // MUI Chip
      MuiChip: {
        root: {
          color: theme.primaryDarkerColor,
          backgroundColor: `rgba(${hexToRGBString(
            theme.primaryDarkerColor,
          )}, 0.12)`,
          '&:hover, &:focus': {
            backgroundColor: `rgba(${hexToRGBString(
              theme.primaryDarkerColor,
            )}, 0.24)`,
          },
        },
        icon: {
          color: 'unset',
        },
        clickable: {
          '&:hover, &:focus': {
            backgroundColor: `rgba(${hexToRGBString(
              theme.primaryDarkerColor,
            )}, 0.24)`,
          },
        },
        outlined: {
          borderColor: `rgba(${hexToRGBString(theme.primaryDarkerColor)}, 0.4)`,
        },
      },
      // Set icon defaults
      MuiIcon: {
        colorPrimary: {
          color: theme.primaryLighterColor,
        },
        colorSecondary: {
          color: theme.primaryDarkerColor,
        },
        colorError: {
          color: '#BB6771',
        },
        colorDisabled: {
          color: theme.primaryDarkerColor,
          opacity: 0.7,
        },
      },
      // theme MuiExpansionPanel to our design
      MuiExpansionPanel: {
        root: {
          border: 'none',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
          borderRadius: 2,
          margin: '10px 0',
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            border: 'none',
            margin: '10px 0',
          },
        },
      },
      MuiExpansionPanelSummary: {
        root: {
          minHeight: 0,
          '&$expanded': {
            minHeight: 0,
          },
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
          paddingRight: 32,
          '& > :last-child': {
            paddingRight: 0,
          },
        },
      },
      MuiExpansionPanelActions: {
        root: {
          borderTop: `1px solid rgba(${hexToRGBString(
            theme.primaryDarkerColor,
          )}, 0.12)`,
          padding: '8px 4px',
        },
      },
      MuiAvatar: {
        root: {
          fontWeight: 'normal',
        },
      },
    },
    props: {
      MuiFormControl: {
        margin: 'normal',
      },
    },
  };
};
