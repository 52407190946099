import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../utils/index';

const PATH = '/home/performance/admin';
const CREATE_PATH = '/home/performance/create';
const DETAIL_PATH = `/home/performance/detail/:id`;

const USER_DETAIL_PATH = `/home/myPerformance/:id`;
const USER_PATH = '/home/performance/user';
const MANAGER_DETAIL_PATH = `/home/performance/manager/:reviewId/detail/:userId`;
const MANAGER_REPORT_PATH = `/home/performance/manager/report/:id`;

const FORM_PATH = `/home/performance/form`;

const REQUEST_REVIEW_PATH = `/home/performance/requestReviews`;

const performanceRoutes: RouteType[] = [
  {
    name: 'performance.create',
    path: `${CREATE_PATH}`,
    component: loadable(
      () => import('../../components/performance/CreatePerformance'),
    ),
    exact: true,
    label: ({ t }) => t('Performance Review'),
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'performance.list',
    path: `${PATH}`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Evaluations'),
    requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
    routes: [
      {
        name: 'performance.list.evaluations',
        path: `${PATH}/evaluations`,
        label: ({ t }) => t('Evaluations'),
        component: loadable(
          () => import('../../components/performance/PerformanceList'),
        ),
        exact: false,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        modalRoutes: [
          {
            name: 'performance.list.add',
            path: `${PATH}/evaluations/add`,
            component: loadable(
              () =>
                import('../../components/performance/CreatePerformanceModal'),
            ),
            exact: true,
            labelHeader: ({ t }) => t('Performance Review'),
            modalTitle: ({ t }) => t('Create Performance Review'),
            requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
            requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
          },
          {
            name: 'performance.list.duplicate',
            path: `${PATH}/evaluations/duplicate/:id`,
            component: loadable(
              () =>
                import(
                  '../../components/performance/duplicate/DuplicatePerformanceModal'
                ),
            ),
            exact: true,
            labelHeader: ({ t }) => t('Performance Review'),
            modalTitle: ({ t }) => t('Duplicate Review'),
            requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
            requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
          },
        ],
      },
      {
        name: 'performance.requests',
        path: `${PATH}/requests`,
        labelHeader: ({ t }) =>
          `${t('Performance Review')} - ${t('Solicitations')}`,
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/requests/PerformanceReviewRequestAllList'
            ),
        ),
        label: ({ t }) => t('Solicitations'),
      },
      {
        name: 'performance.requests.pivot',
        path: `${PATH}/pivot`,
        labelHeader: ({ t }) =>
          `${t('Performance Review')} - ${t('Solicitations')}`,
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/requests/PerformanceReviewAllRequestPivotTable'
            ),
        ),
        label: ({ t }) => t('Pivot Table'),
      },
      {
        name: 'performance.users',
        path: `${PATH}/users`,
        labelHeader: ({ t }) => `${t('Performance Review')} - ${t('Users')}`,
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/users/PerformanceReviewListByUser'
            ),
        ),
        label: ({ t }) => t('Users'),
      },
    ],
  },
  {
    name: 'performance.detail.view',
    path: `${DETAIL_PATH}`,
    labelHeader: ({ t }) => t('Performance Review'),
    component: loadable(
      () => import('../../components/performance/detail/PerformanceDetail'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
    routes: [
      {
        name: 'performance.detail.view.form',
        path: `${DETAIL_PATH}/form`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: false,
        component: loadable(
          () =>
            import(
              '../../components/performance/formSettings/PerformanceDetailForm'
            ),
        ),
        label: ({ t }) => t('Form'),
        modalRoutes: [
          {
            name: 'performance.detail.view.form.move',
            path: `${DETAIL_PATH}/form/:topicId/move`,
            component: loadable(
              () =>
                import(
                  '../../components/performance/formSettings/MoveTopicModal'
                ),
            ),
            exact: true,
            labelHeader: ({ t }) => t('Performance Review'),
            modalTitle: ({ t }) => t('Move to'),
            requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
            requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
          },
        ],
      },
      {
        name: 'performance.detail.view.evaluate',
        path: `${DETAIL_PATH}/evaluate`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: false,
        component: loadable(
          () =>
            import(
              '../../components/performance/evaluate/PerformanceDetailEvaluate'
            ),
        ),
        label: ({ t }) => t('Evaluation'),
        modalRoutes: [
          {
            name: 'performance.justification.add',
            path: `${DETAIL_PATH}/evaluate/:userReviewId/justify`,
            component: loadable(
              () =>
                import('../../components/performance/AddJustificationModal'),
            ),
            exact: true,
            labelHeader: ({ t }) => t('Performance Review'),
            modalTitle: ({ t }) => t('Justify your grade'),
            requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
            requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
          },
        ],
      },
      {
        name: 'performance.detail.view.status',
        path: `${DETAIL_PATH}/status`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/status/PerformanceDetailStatus'
            ),
        ),
        label: 'Status',
      },
      {
        name: 'performance.detail.view.users',
        path: `${DETAIL_PATH}/users`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/detailUsers/PerformanceDetailUsers'
            ),
        ),
        label: ({ t }) => t('Evaluated'),
      },
      {
        name: 'performance.detail.view.requests',
        path: `${DETAIL_PATH}/requests`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/requests/PerformanceReviewDetailRequestList'
            ),
        ),
        label: ({ t }) => t('Solicitations'),
      },
      {
        name: 'performance.detail.view.pivot',
        path: `${DETAIL_PATH}/pivot`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/requests/PerformanceReviewDetailRequestPivotTable'
            ),
        ),
        label: ({ t }) => t('Pivot Table'),
      },
      {
        name: 'performance.detail.view.customize',
        path: `${DETAIL_PATH}/customize`,
        labelHeader: ({ t }) => t('Performance Review'),
        label: ({ t }) => t('Customization'),
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/customization/PerformanceDetailCustomize'
            ),
        ),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
      },
      {
        name: 'performance.detail.view.engagement',
        labelHeader: ({ t }) =>
          `${t('Performance Review')} - ${t('Engagement')}`,
        label: ({ t }) => t('Engagement'),
        path: `${DETAIL_PATH}/engagement`,
        component: loadable(
          () =>
            import(
              '../../components/performance/engagement/PerformanceReviewEngagement'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
      },
      {
        name: 'performance.detail.view.settings',
        path: `${DETAIL_PATH}/settings`,
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/performance/settings/PerformanceDetailSettings'
            ),
        ),
        label: ({ t }) => t('Settings'),
      },
    ],
  },
  {
    name: 'performance.user.detail',
    path: `${USER_DETAIL_PATH}`,
    component: loadable(
      () => import('../../components/performance/UserPerformanceReviewDetail'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [ADMIN_ROLE, ROLES.PERFORMANCE_REVIEW.ADMIN.ALL],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'user.performance',
    path: `${USER_PATH}`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Evaluations'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
    routes: [
      {
        name: 'user.performance.list',
        path: `${USER_PATH}/list`,
        component: loadable(
          () => import('../../components/performance/user/UserPerformanceList'),
        ),
        exact: true,
        label: ({ t }) => t('My evaluations'),
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
          ROLES.PERFORMANCE_REVIEW.USER.ALL,
        ],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
      },
      {
        name: 'user.performance.requests',
        path: `${USER_PATH}/requests`,
        component: loadable(
          () =>
            import(
              '../../components/performance/user/reviewRequests/ReviewRequestList'
            ),
        ),
        exact: true,
        label: ({ t }) => t('Evaluate Contributors'),
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
          ROLES.PERFORMANCE_REVIEW.USER.ALL,
        ],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
      },
      {
        name: 'user.performance.managerRequestList',
        path: `${USER_PATH}/manager/requests`,
        component: loadable(
          () =>
            import(
              '../../components/performance/user/reviewRequests/manager/ManagerReviewRequestList'
            ),
        ),
        exact: true,
        label: ({ t }) => t('Approve Solicitations (only managers)'),
        labelHeader: ({ t }) => t('Performance Review'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
          ROLES.PERFORMANCE_REVIEW.USER.ALL,
        ],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
      },
    ],
  },
  {
    name: 'user.performance.report',
    path: `/home/performance/report/user/:userReviewId`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    label: ({ t }) => `${t('Performance Review')} - ${t('Individual Report')}`,
    labelHeader: ({ t }) =>
      `${t('Performance Review')} - ${t('Individual Report')}`,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.MANAGER.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
    routes: [
      {
        name: 'user.performance.report.details',
        path: `/home/performance/report/user/:userReviewId`,
        component: loadable(
          () =>
            import(
              '../../components/performance/user/individualReport/IndividualPerformanceReviewReport'
            ),
        ),
        exact: true,
        label: ({ t }) =>
          `${t('Performance Review')} - ${t('Individual Report')}`,
        labelHeader: ({ t }) =>
          `${t('Performance Review')} - ${t('Individual Report')}`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
          ROLES.PERFORMANCE_REVIEW.MANAGER.ALL,
          ROLES.PERFORMANCE_REVIEW.USER.ALL,
        ],
        requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
      },
    ],
  },
  {
    name: 'user.performance.requestReviews',
    path: `${REQUEST_REVIEW_PATH}/:userReviewId/requests`,
    component: loadable(
      () =>
        import(
          '../../components/performance/user/reviewersSelection/UserReviewersSelectionList'
        ),
    ),
    exact: true,
    label: ({ t }) => t('Performance Review'),
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'user.performance.admin.requestReviews',
    path: `${REQUEST_REVIEW_PATH}/admin/:userReviewId/requests`,
    component: loadable(
      () =>
        import(
          '../../components/performance/settings/AdminReviewersSelectionList'
        ),
    ),
    exact: true,
    label: ({ t }) => t('Performance Review'),
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'user.performance.detail',
    path: `${USER_PATH}/myReview/:id`,
    component: loadable(
      () =>
        import('../../components/performance/user/MyPerformanceReviewDetail'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'user.performance.manager.detail',
    path: `${MANAGER_DETAIL_PATH}`,
    component: loadable(
      () =>
        import(
          '../../components/performance/manager/ManagerPerformanceReviewDetail'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },

  // FORM
  {
    name: 'performance.form.init',
    path: `${FORM_PATH}/init/:reviewId/:requestId?`,
    component: loadable(
      () => import('../../components/performance/form/InitialStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'performance.form.step',
    path: `${FORM_PATH}/score/:reviewId/:topic/:requestId?`,
    component: loadable(
      () => import('../../components/performance/form/ScoreStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'performance.form.final',
    path: `${FORM_PATH}/final/:reviewId/:requestId?`,
    component: loadable(
      () => import('../../components/performance/form/FinalStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'performance.form.success',
    path: `${FORM_PATH}/success/:reviewId`,
    component: loadable(
      () => import('../../components/performance/form/SuccessStep'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'performance.scores.edit',
    path: `${FORM_PATH}/scores/edit/:requestId`,
    component: loadable(
      () =>
        import(
          '../../components/performance/user/reviewRequests/components/ReviewScoresEdit'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'performance.scores.details',
    path: `${FORM_PATH}/scores/details/:requestId`,
    component: loadable(
      () =>
        import(
          '../../components/performance/user/reviewRequests/components/ReviewScoresDetails'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Performance Review'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PERFORMANCE_REVIEW.ADMIN.ALL,
      ROLES.PERFORMANCE_REVIEW.USER.ALL,
    ],
    requiredFeatures: [MODULES.PERFORMANCE_REVIEW],
  },
  {
    name: 'manager.view.performanceReviewReport',
    labelHeader: ({ t }) => t('Performance Review Report'),
    path: `${MANAGER_REPORT_PATH}/performanceReview`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
    label: ({ t }) => t('Performance Review Report'),
    routes: [
      {
        name: 'manager.view.performanceReviewReport.report',
        labelHeader: ({ t }) => t('Report'),
        path: `${MANAGER_REPORT_PATH}/performanceReview/report`,
        component: loadable(
          () =>
            import(
              '../../components/performance/manager/ManagerPerformanceReport'
            ),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [],
        label: ({ t }) => t('Report'),
      },
    ],
  },
];

export const detailRoutes = [
  'performance.detail.view.analytics',
  'performance.detail.view.form',
  'performance.detail.view.evaluate',
  'performance.detail.view.status',
  'performance.detail.view.users',
  'performance.detail.view.requests',
  'performance.detail.view.settings',
  'performance.detail.view.customize',
  'performance.detail.view.engagement',
  'performance.justification.add',
  'performance.detail.view.pivot',
];

export default performanceRoutes;
