import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { JourneyScheduleSelectField_query } from './__generated__/JourneyScheduleSelectField_query.graphql';

type Props = {
  query: JourneyScheduleSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: JourneySchedule;
};

type JourneySchedule = {
  id: string;
  name: string;
};

type State = {
  input: string;
};

class JourneyScheduleSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Cronograma de Jornada',
    name: 'journeySchedule',
  };

  getItemFromNode = ({ id, name }) => ({
    id,
    name,
  });

  render() {
    const { query, relay, ...props } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={query.journeySchedules}
        getItemFromNode={this.getItemFromNode}
        {...props}
      />
    );
  }
}

const JourneyScheduleSelectFieldFragmentContainer = createRefetchContainer(
  JourneyScheduleSelectField,
  {
    query: graphql`
      fragment JourneyScheduleSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 300 }
        after: { type: String }
      ) {
        journeySchedules(first: $count, after: $after)
          @connection(
            key: "JourneyScheduleSelectField_journeySchedules"
            filters: []
          ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query JourneyScheduleSelectFieldQuery($count: Int, $after: String) {
      ...JourneyScheduleSelectField_query
        @arguments(count: $count, after: $after)
    }
  `,
);

export default JourneyScheduleSelectFieldFragmentContainer;
