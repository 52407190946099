// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const MiniTrophy = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <path
        fill={color}
        fillRule='nonzero'
        d='M21.588 3.706a1.23 1.23 0 0 0-.897-.364h-3.8V2.094a1.99 1.99 0 0 0-.62-1.47 2.048 2.048 0 0 0-1.492-.611h-7.6c-.581 0-1.078.203-1.492.61a1.99 1.99 0 0 0-.62 1.47v1.25h-3.8c-.352 0-.651.12-.898.363C.123 3.95 0 4.244 0 4.591v1.665c0 .615.183 1.235.548 1.86a6.222 6.222 0 0 0 1.478 1.69c.62.504 1.38.926 2.282 1.269.902.343 1.85.536 2.844.579.37.468.787.88 1.254 1.235.334.295.565.61.692.944.128.333.192.721.192 1.164 0 .468-.134.862-.403 1.183-.268.321-.697.481-1.286.481-.66 0-1.247.198-1.762.592-.514.395-.772.891-.772 1.49v.832a.402.402 0 0 0 .422.416h10.98a.403.403 0 0 0 .422-.416v-.832c0-.599-.258-1.095-.773-1.49a2.819 2.819 0 0 0-1.761-.592c-.59 0-1.019-.16-1.287-.481-.268-.32-.402-.715-.402-1.183 0-.443.064-.83.191-1.164.128-.335.36-.649.693-.944a6.983 6.983 0 0 0 1.253-1.235 9.065 9.065 0 0 0 2.845-.579c.9-.343 1.662-.765 2.282-1.268a6.225 6.225 0 0 0 1.478-1.691c.365-.625.548-1.245.548-1.86V4.59c0-.347-.124-.642-.37-.885zM2.936 8.363c-.831-.728-1.247-1.43-1.247-2.107V5.007h3.378c0 1.813.326 3.421.977 4.826-1.24-.252-2.277-.742-3.108-1.47zm17.332-2.107c0 .676-.415 1.379-1.246 2.107-.832.728-1.868 1.219-3.108 1.47.651-1.405.976-3.013.976-4.826h3.378v1.249z'
      />
    </svg>
  );
};

MiniTrophy.defaultProps = {
  color: '#607389',
  viewBox: '3 0 16 22',
  height: 24,
};

export default MiniTrophy;
