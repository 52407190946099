import { useMemo } from 'react';
import styled from 'styled-components';

import { usePageView } from '@feedback/hooks';

import { drawRoutes } from '../../router/utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom left, #d9e0e9, #fff, #d9e0e9);
  position: relative;
`;

const RedirectRoot = ({ routes }) => {
  usePageView();

  const routesMemo = useMemo(() => {
    return drawRoutes(routes);
  }, []);

  return <Container>{routesMemo}</Container>;
};

export default RedirectRoot;
