import { Marker as _Marker } from '@feedback/map';
import { Component } from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';

import { routeTo } from '../../router/utils';

const BoxText = styled.div`
  flex-direction: column;
  position: absolute;
  width: 155px;
  background-color: #607389;
  left: 10px;
  bottom: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  cursor: pointer;
`;

const Name = styled.p`
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 4px;
  text-align: center;
`;

const Marker = styled(_Marker)`
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  background-color: #607389;
`;

type Props = {
  lat: number;
  lng: number;
  name: string;
  candidateId: string;
  isOpen: boolean;
  onClick: () => void;
};

class CostRevenuePin extends Component<Props> {
  static defaultProps = {
    isOpen: false,
    onClick: () => {},
  };

  render() {
    const {
      lat,
      lng,
      onClick,
      isOpen,
      name,
      candidateId,
      status,
      className,
      history,
    } = this.props;

    return (
      <Marker lat={lat} lng={lng} onClick={onClick} className={className}>
        {isOpen && (
          <BoxText
            onClick={() =>
              history.push(routeTo('candidates.view', { id: candidateId }))
            }
          >
            <Name>{name}</Name>
            {status && <p>Status: {status}</p>}
          </BoxText>
        )}
      </Marker>
    );
  }
}

export default withRouter(CostRevenuePin);
