import { format as dateFnsFormat } from 'date-fns';
import {
  enUS as enLocale,
  es as esLocale,
  pt as ptLocale,
} from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, es, ptBR } from '@feedback/i18n';

import type { modulesNamesFragment } from '../router/__generated__/modulesNamesFragment.graphql';
import {
  getFeedbackModuleName,
  getFeedbackModulePluralName,
  getFeedbackOneToOneModuleName,
  getNineBoxModuleName,
  getOmbudsmanExternalModuleName,
  getOmbudsmanModuleName,
} from '../router/modulesNames';

const locales = {
  pt: ptLocale,
  'pt-BR': ptLocale,
  'en-US': enLocale,
  en: enLocale,
  es: esLocale,
};

export const interpolationFormat = (value, format, lang) => {
  if (!value || value === '' || value === undefined || value === null) {
    return '';
  }

  if (!value.date) {
    return value;
  }

  const { date, fmt } = value;

  try {
    return dateFnsFormat(date, fmt, {
      locale: lang in locales ? locales[lang] : ptLocale,
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log('invalid date: ', date, fmt, err);

    return '';
  }
};

type HandleInterpolationResult = {
  [key: string]: string;
};

const handleInterpolation = (
  company: modulesNamesFragment,
): HandleInterpolationResult => {
  const feedbackModuleName = getFeedbackModuleName(company);
  const feedbackModulePluralName = getFeedbackModulePluralName(company);
  const feedbackOneToOne = getFeedbackOneToOneModuleName(company);
  const ombudsmanName = getOmbudsmanModuleName(company);
  const ombudsmanExternalName = getOmbudsmanExternalModuleName(company);
  const nineBoxName = getNineBoxModuleName(company);

  return {
    Feedback: feedbackModuleName,
    feedback: feedbackModuleName.toLowerCase(),
    Feedbacks: feedbackModulePluralName,
    feedbacks: feedbackModulePluralName.toLowerCase(),

    FeedbackOneToOne: feedbackOneToOne,
    OneOnOne: feedbackOneToOne,

    ombudsman: ombudsmanName,
    ombudsmanExternal: ombudsmanExternalName,

    NineBox: nineBoxName,
  };
};

const i18nInterpolation = (company: modulesNamesFragment) => {
  i18n.use(LanguageDetector).init({
    resources: {
      en: {
        translation: en,
      },
      'pt-BR': {
        translation: ptBR,
      },
      es: {
        translation: es,
      },
    },
    fallbackLng: 'ptBR',
    debug: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: interpolationFormat,
    },

    react: {
      useSuspense: true,
    },

    missingInterpolationHandler: (text: string, value: string[]) => {
      const key = (value && value[1]) || '';
      const interpolation = handleInterpolation(company);

      return interpolation[key];
    },
  });

  return i18n;
};

export default i18nInterpolation;
