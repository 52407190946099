import { AutoComplete } from '@feedback/select';

export type Props = {
  options: Record<string, any>[];
  onInputChange: (value: Record<string, any>) => void;
  inputValue: string;
  value: Record<string, any>[] | Record<string, any>;
  hintText?: string;
  onChange: (value: Record<string, any> | Record<string, any>[]) => void;
  fullWidth?: boolean;
  initialValue: string;
  getStringFromItem: (item: Record<string, any>) => string;
  multiple?: boolean;
};
const SelectField = ({
  options,
  onInputChange,
  value,
  hintText,
  onChange,
  fullWidth,
  inputValue,
  initialValue,
  getStringFromItem,
  multiple,
  ...props
}: Props) => {
  return (
    <AutoComplete
      multiple={false}
      items={
        options
          ? options.map((opt) => ({
              label: opt.name || opt.primaryText,
              value: opt.id,
              ...opt,
            }))
          : []
      }
      label={hintText}
      onInputChange={
        onInputChange ? (name, value) => onInputChange(value) : undefined
      }
      onChange={(name, value) =>
        multiple ? onChange([value]) : onChange(value)
      }
      inputValue={inputValue}
      getStringFromItem={getStringFromItem}
      fullWidth={fullWidth}
      selectedItems={value}
      initialValue={initialValue}
      {...props}
    />
  );
};

SelectField.defaultProps = {
  fullWidth: true,
  getStringFromItem: (item) => {
    if (item) {
      if (typeof item.name === 'string') {
        return item.name;
      }

      if (typeof item.label === 'string') {
        return item.label;
      }

      if (typeof item.primaryText === 'string') {
        return item.primaryText;
      }
    }

    return '';
  },
};

export default SelectField;
