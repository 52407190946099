import loadable from '@loadable/component';

import type { RouteType } from '../../utils';

// Template
import routeHandlers from '../routeHandlers';

import RedirectRoot from '../../../components/redirect/RedirectRoot';

const PATH = '/redirect';

const redirectRoutes: RouteType = {
  path: `${PATH}`,
  name: 'redirectRoutes',
  exact: false,
  component: RedirectRoot,
  routes: [
    {
      name: 'redirect.microsoft',
      path: `${PATH}/microsoft`,
      component: loadable(
        () => import('../../../components/redirect/MicrosoftRedirect'),
      ),
      exact: true,
    },
    ...routeHandlers(PATH),
  ],
};

export default redirectRoutes;
