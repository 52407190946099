import loadable from '@loadable/component';

// Template
import RequiredTemplate from '../../../components/user/required/requiredTemplate';

const PATH = '/required';

const requiredRoutes = {
  name: 'required',
  path: PATH,
  component: RequiredTemplate,
  exact: false,
  requiredFeatures: [],
  routes: [
    {
      name: 'required.shouldChangePassword',
      path: `${PATH}/shouldChangePassword`,
      component: loadable(
        () => import('../../../components/user/required/ShouldChangePassword'),
      ),
      requiredFeatures: [],
      exact: true,
    },
    {
      name: 'required.agreement',
      path: `${PATH}/agreementTerm`,
      component: loadable(
        () =>
          import('../../../components/user/required/ShouldAcceptAgreementTerm'),
      ),
      requiredFeatures: [],
      exact: true,
    },
  ],
};

export default requiredRoutes;
