import type { FormikHelpers } from 'formik';
import { useFormik, FormikProvider } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { Divider as _Divider } from '@material-ui/core';
import { Flex, Text } from 'rebass';
import queryString from 'query-string';

import { Card } from '@feedback/ui';

import { COMPANY_PROJECT_TYPES_ENUM } from '@feedback/roles';

import { useSnackbar } from 'notistack';

import { useQueryParam } from 'use-query-params';

import { createQueryRendererModern } from '../../../relay';

import { getFiltersFromLocation, routeTo } from '../../../router/utils';

import { setUser } from '../../../redux/modules/login';

import { CompanyLogo, Wrapper } from './components';
import { LoginValidateEmail } from './mutations/LoginValidateEmailMutation';

import type { LoginForm_query$data } from './__generated__/LoginForm_query.graphql';
import LoginTextField from './LoginTextField';
import LoginSamlField from './LoginSamlField';
import type {
  LoginValidateEmailMutation,
  LoginValidateEmailMutationResponse,
} from './mutations/__generated__/LoginValidateEmailMutation.graphql';

const ContentForm = styled.form`
  padding: 20px;
  min-height: 350px;
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Divider = styled(_Divider)`
  background-color: #ccc;
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
`;

const Label = styled.span`
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
`;

export const getLogidRouteCallback = () => {
  const { callbackUrl } = getFiltersFromLocation(['callbackUrl']);

  return callbackUrl;
};

type Values = {
  email: string;
};
type Props = {
  query: LoginForm_query$data;
};
const LoginForm = ({ query}: Props) => {
  const { company } = query;
  const { enableEmail, enableTaxID, enableEnrollment } =
    company.preferences.core.login;

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [errorParam] = useQueryParam('error');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorParam) {
      enqueueSnackbar(errorParam);
    }
  }, [errorParam]);

  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);

  const [loginValidateEmail] =
    useMutation<LoginValidateEmailMutation>(LoginValidateEmail);

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    const { setSubmitting } = formikHelpers;
    const { email } = values;

    const defaultErrorMessage = t('Error when doing login');

    const onCompleted = ({
      LoginValidateEmail,
    }: LoginValidateEmailMutationResponse) => {
      const { userExists, fromEmail } = LoginValidateEmail;

      setSubmitting(false);

      if (!LoginValidateEmail || LoginValidateEmail.error) {
        enqueueSnackbar(
          (LoginValidateEmail && LoginValidateEmail.error) ||
            defaultErrorMessage,
        );

        return;
      }

      dispatch(
        setUser({
          email,
          userExists,
          fromEmail,
        }),
      );

      const nextPathname = location.state && location.state.nextPathname;

      if (!userExists) {
        return history.push({
          pathname: routeTo('signUp.emailConfirmation'),
        });
      }

      const callbackUrl = getLogidRouteCallback();

      if (callbackUrl) {
        return history.push({
          pathname: routeTo('auth.withPassword'),
          search: queryString.stringify({ callbackUrl }),
          state: { nextPathname },
        });
      }

      return history.push({
        pathname: routeTo('auth.withPassword'),
        state: { nextPathname },
      });
    };

    const onError = () => {
      enqueueSnackbar(defaultErrorMessage);

      setSubmitting(false);
    };

    const input = {
      email,
      companyId: login.company.id,
    };

    const config = {
      variables: {
        input,
      },
      onCompleted,
      onError,
    };

    loginValidateEmail(config);
  };

  const initialValues = useMemo(
    () => ({
      email: '',
    }),
    [],
  );

  const formikbag = useFormik<Values>({
    initialValues,
    validateOnMount: true,
    validationSchema: yup.object().shape({
      email: yup.string().required(t('login.validations.emailRequired')),
    }),
    onSubmit,
  });

  const { handleSubmit } = formikbag;

  const hasSamlEnabled = () => {
    const { core } = company.preferences;
    const { authConfigs } = core;

    const validActiveConfigs = authConfigs.filter((config) => config.active);

    if (validActiveConfigs.length === 0) {
      return false;
    }

    return true;
  };

  const hasSSOenabled = () => {
    return hasSamlEnabled();
  };

  const hasEmailFieldEnabled = () => {
    return enableEmail || enableTaxID || enableEnrollment;
  };

  const renderSSOOptions = () => {
    if (!hasSSOenabled()) {
      return null;
    }

    return (
      <>
        <LoginSamlField company={company} />
        {hasEmailFieldEnabled() && (
          <Flex flex={1} alignItems='center' mt='15px' mb='15px'>
            <Divider />
            <Text>{t('or')}</Text>
            <Divider />
          </Flex>
        )}
      </>
    );
  };

  return (
    <FormikProvider value={formikbag}>
      <Wrapper
        renderFooter={
          company.projectType === COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE
        }
      >
        <Card>
          <ContentForm onSubmit={handleSubmit}>
            <CompanyLogo company={login.company} />
            <Label>{t('doLogin')}</Label>
            {renderSSOOptions()}
            <LoginTextField company={company} />
          </ContentForm>
        </Card>
      </Wrapper>
    </FormikProvider>
  );
};

const LoginFormFragmentContainer = createFragmentContainer(LoginForm, {
  query: graphql`
    fragment LoginForm_query on Query {
      company: companyDomainName {
        ...LoginTextField_company
        ...LoginSamlField_company
        hasHRModule
        projectType
        preferences {
          core {
            authConfigs {
              type
              buttonLabel
              active
            }
            login {
              enableSaml
              enableSaml2
              enableEmail
              enableTaxID
              enableEnrollment
            }
            integration {
              microsoft {
                tenant
              }
            }
          }
        }
      }
    }
  `,
});

export default createQueryRendererModern(LoginFormFragmentContainer, {
  query: graphql`
    query LoginFormQuery {
      ...LoginForm_query
    }
  `,
});
