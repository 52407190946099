import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { GoalGroupSelectField_query } from './__generated__/GoalGroupSelectField_query.graphql';

type Props = {
  query: GoalGroupSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: GoalGroup;
};

type GoalGroup = {
  id: string;
  name: string;
};

type State = {
  input: string;
  search: string;
};
class GoalGroupSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar Grupo de Metas',
    name: 'goalGroup',
  };

  render() {
    const {
      query: {
        me: { company },
      },
      relay, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={company.goalGroups}
        {...props}
      />
    );
  }
}

const GoalGroupSelectFieldFragmentContainer = createRefetchContainer(
  GoalGroupSelectField,
  {
    query: graphql`
      fragment GoalGroupSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 30 }
        after: { type: String }
        goalGroupFilters: {
          type: "GoalGroupFilter"
          defaultValue: { isActivated: true, search: "" }
        }
      ) {
        me {
          company {
            goalGroups(
              first: $count
              after: $after
              filters: $goalGroupFilters
            ) @connection(key: "GoalGroupSelectField_goalGroups", filters: []) {
              pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  name: title
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query GoalGroupSelectFieldQuery(
      $count: Int
      $after: String
      $goalGroupFilters: GoalGroupFilter
    ) {
      ...GoalGroupSelectField_query
        @arguments(
          count: $count
          after: $after
          goalGroupFilters: $goalGroupFilters
        )
    }
  `,
);

export default GoalGroupSelectFieldFragmentContainer;
