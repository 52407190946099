import loadable from '@loadable/component';

import { MODULES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/career';

const careerRoutes: RouteType[] = [
  {
    name: 'career.myCareer',
    path: `${PATH}/myCareer`,
    component: loadable(() => import('../../../components/career/MyCareer')),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.TEMP],
    labelHeader: 'Carreiras',
    routes: [
      {
        name: 'career.myCareer.profile',
        path: `${PATH}/myCareer/profile`,
        requiredRoles: [],
        requiredFeatures: [MODULES.TEMP],
        exact: false,
        labelHeader: 'Carreiras',
        label: 'Perfil',
        component: loadable(
          () => import('../../../components/career/CareerProfile'),
        ),
        modalRoutes: [
          {
            name: 'career.myCareer.profile.add.jobExperience',
            path: `${PATH}/myCareer/profile/jobExperience/add`,
            labelHeader: 'Carreiras',
            component: loadable(
              () => import('../../../components/career/JobExperienceAdd'),
            ),
            modalTitle: 'Adicionar Experiência',
            exact: true,
            requiredRoles: [],
            requiredFeatures: [MODULES.TEMP],
          },
          {
            name: 'career.myCareer.profile.add.careerFormation',
            path: `${PATH}/myCareer/profile/careerFormation/add`,
            labelHeader: 'Carreiras',
            component: loadable(
              () => import('../../../components/career/CareerFormationAdd'),
            ),
            modalTitle: 'Adicionar Formação',
            exact: true,
            requiredRoles: [],
            requiredFeatures: [MODULES.TEMP],
          },
          {
            name: 'career.myCareer.profile.add.careerCourse',
            path: `${PATH}/myCareer/profile/careerCourse/add`,
            labelHeader: 'Carreiras',
            component: loadable(
              () => import('../../../components/career/CareerCourseAdd'),
            ),
            modalTitle: 'Adicionar Curso',
            exact: true,
            requiredRoles: [],
            requiredFeatures: [MODULES.TEMP],
          },
        ],
      },
    ],
  },
];

export default careerRoutes;
