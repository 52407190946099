import styled from 'styled-components';

import { SVG } from '@feedback/ui';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Label = styled.span`
  color: #607389;
  font-size: 10px;
`;

const Footer = () => (
  <Content>
    <Label>Powered by</Label>
    <a href='https://feedback.house/' target='_blank' rel='noopener noreferrer'>
      <SVG.FeedbackLogo width={90} />
    </a>
  </Content>
);

export default Footer;
