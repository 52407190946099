import { graphql } from 'react-relay';

export const LoginValidateEmail = graphql`
  mutation LoginValidateEmailMutation($input: LoginValidateEmailInput!) {
    LoginValidateEmail(input: $input) {
      userExists
      error
      fromEmail
    }
  }
`;
