import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/feedbackRanking';
const PATH_GROUP = '/home/feedbackRankingGroup';

const feedbackRankingRoutes: RouteType[] = [
  {
    name: 'feedbackRanking',
    path: `${PATH}`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Reconhecimento',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_RANKING.ALL,
      ROLES.FEEDBACK_RANKING.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_RANKING],
    routes: [
      {
        name: 'feedbackRanking.monthly',
        path: `${PATH}/monthly`,
        component: loadable(
          () =>
            import(
              '../../../components/feedbackRanking/FeedbackRankingMonthly'
            ),
        ),
        exact: true,
        label: 'Mensal',
        labelHeader: 'Reconhecimento',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_RANKING.ALL,
          ROLES.FEEDBACK_RANKING.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_RANKING],
      },
      {
        name: 'feedbackRanking.yearly',
        path: `${PATH}/yearly`,
        component: loadable(
          () =>
            import('../../../components/feedbackRanking/FeedbackRankingYearly'),
        ),
        exact: true,
        label: 'Anual',
        labelHeader: 'Reconhecimento',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_RANKING.ALL,
          ROLES.FEEDBACK_RANKING.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_RANKING],
      },
    ],
  },
  {
    name: 'feedbackRanking.group',
    path: `${PATH_GROUP}`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Reconhecimento Grupos',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_RANKING.ALL,
      ROLES.FEEDBACK_RANKING.USER.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_RANKING],
    routes: [
      {
        name: 'feedbackRanking..groups.monthly',
        path: `${PATH_GROUP}/monthly`,
        component: loadable(
          () =>
            import(
              '../../../components/feedbackRanking/groups/FeedbackRankingGroupMonthly'
            ),
        ),
        exact: true,
        label: 'Mensal',
        labelHeader: 'Reconhecimento',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_RANKING.ALL,
          ROLES.FEEDBACK_RANKING.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_RANKING],
      },
      {
        name: 'feedbackRanking.groups.yearly',
        path: `${PATH_GROUP}/yearly`,
        component: loadable(
          () =>
            import(
              '../../../components/feedbackRanking/groups/FeedbackRankingGroupYearly'
            ),
        ),
        exact: true,
        label: 'Anual',
        labelHeader: 'Reconhecimento',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_RANKING.ALL,
          ROLES.FEEDBACK_RANKING.USER.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_RANKING],
      },
    ],
  },
];

export default feedbackRankingRoutes;
