import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #7c8289;
  font-weight: 600;
  font-size: 18px;
  height: 200px;
`;

const EmptyMessage = ({ children }) => <Wrapper>{children}</Wrapper>;

export default EmptyMessage;
