import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/planning';

const headCountRoutes: RouteType[] = [
  {
    name: 'headcount',
    path: `${PATH}/headcounts`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: 'Planejamento de Headcount/Quadro',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.HEADCOUNT.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'headcount.list',
        path: `${PATH}/headcounts/list`,
        label: 'Lista',
        labelHeader: 'HeadCount',
        component: loadable(
          () => import('../../../components/headCount/HeadCountList'),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.HEADCOUNT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
        modalRoutes: [
          {
            name: 'headcount.list.add',
            path: `${PATH}/headcounts/list/add`,
            labelHeader: 'HeadCount - Adicionar',
            component: loadable(
              () => import('../../../components/headCount/AdminHeadCountAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.HEADCOUNT.ALL,
            ],
            requiredFeatures: [MODULES.PLANNING],
          },
        ],
      },
      {
        name: 'headcount.pivot',
        path: `${PATH}/headcounts/pivot`,
        label: 'Tabela Dinâmica',
        labelHeader: 'Headcount',
        component: loadable(
          () => import('../../../components/headCount/HeadCountPivotTable'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.HEADCOUNT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },

      {
        name: 'headcount.import',
        path: `${PATH}/headcounts/import`,
        labelHeader: 'HeadCount',
        label: 'Importar',
        component: loadable(
          () => import('../../../components/planning/import/HeadCountImport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.IMPORT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
      {
        name: 'headcount.export',
        path: `${PATH}/headcounts/export`,
        labelHeader: 'HeadCount',
        label: 'Exportações',
        component: loadable(
          () => import('../../../components/planning/export/HeadCountExport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.EXPORT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
  {
    name: 'headCount.view',
    path: `${PATH}/headCount/view/:id`,
    labelHeader: 'Headcount - Detalhes',
    component: loadable(
      () => import('../../../components/headCount/HeadCount'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.HEADCOUNT.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'headCount.detail',
        labelHeader: 'Headcount - Detalhes',
        label: 'Detalhes',
        path: `${PATH}/headCount/view/:id/detail`,
        component: loadable(
          () => import('../../../components/headCount/detail/HeadCountDetail'),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.HEADCOUNT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
        modalRoutes: [
          {
            name: 'headCount.editEmployee',
            path: `${PATH}/headCount/view/:id/detail/employee`,
            labelHeader: 'Headcount - Detalhes',
            modalTitle: 'Atribuir colaborador',
            component: loadable(
              () =>
                import('../../../components/headCount/HeadCountEditEmployee'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.PLANNING.ALL,
              ROLES.PLANNING.HEADCOUNT.ALL,
            ],
            requiredFeatures: [MODULES.PLANNING],
          },
        ],
      },
      {
        name: 'headCount.jobRequest',
        labelHeader: 'Headcount - Requisições',
        label: 'Requisições',
        path: `${PATH}/headCount/view/:id/jobRequest`,
        component: loadable(
          () => import('../../../components/headCount/HeadCountJobRequest'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.HEADCOUNT.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
  {
    name: 'headCount.addJobRequest',
    path: `${PATH}/headCountJobRequest/view/:id/:jobRoleId`,
    labelHeader: ({ t }) => t('Headcount - Requisitions'),
    component: loadable(
      () => import('../../../components/jobRequest/JobRequestAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.HEADCOUNT.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
  },
];

export default headCountRoutes;
