import styled from 'styled-components';

import { Footer } from '../../../common';

import Header from './Header';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 490px;
`;

type Props = {
  children: React.ReactNode;
  history: any;
  renderFooter?: boolean;
};

// eslint-disable-next-line
const Wrapper = ({ children, renderFooter = false, history }: Props) => (
  <Container>
    {!!history && <Header history={history} />}
    {children}
    {!!renderFooter && <Footer />}
  </Container>
);

export default Wrapper;
