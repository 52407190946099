import { useFragment, graphql } from 'react-relay';

import { MODULES } from '@feedback/roles';

import type { FeatureFlagProps } from './FeatureFlag';
import FeatureFlag from './FeatureFlag';
import type { FeatureTemp_user$key } from './__generated__/FeatureTemp_user.graphql';

type Props = {
  user: FeatureTemp_user$key;
} & FeatureFlagProps;

const FeatureTemp = ({ features, ...props }: Props) => {
  const user = useFragment<FeatureTemp_user$key>(
    graphql`
      fragment FeatureTemp_user on User {
        ...FeatureFlag_user
      }
    `,
    props.user,
  );

  return <FeatureFlag features={[MODULES.TEMP]} {...props} user={user} />;
};

export default FeatureTemp;
