import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';

import { routeTo } from '../../../router/utils';
import type { SideBarMenuItemProps } from '../menuItems';

export const ranking = ({ t }: SideBarMenuItemProps) => [
  {
    label: t('Recognition'),
    icon: <EmojiEventsOutlinedIcon />,
    items: [
      {
        label: t('Contributors'),
        link: routeTo('feedbackRanking'),
      },
      {
        label: t('Groups'),
        link: routeTo('feedbackRanking.group'),
      },
    ],
  },
];
