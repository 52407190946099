// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const Profile = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <g fill={color} fillRule='nonzero'>
        <circle
          cx='2.555'
          cy='2.501'
          r='2.5'
          transform='translate(6.611 4.167)'
        />
        <path d='M9.167 9.167a3.889 3.889 0 0 0-3.89 3.89h7.779a3.889 3.889 0 0 0-3.89-3.89z' />
        <path d='M19.756 18.577l-3.548-3.548a9.125 9.125 0 0 0 2.125-5.862c0-5.054-4.112-9.166-9.166-9.166C4.112 0 0 4.113 0 9.167c0 5.055 4.112 9.167 9.167 9.167 2.228 0 4.273-.8 5.863-2.126l3.547 3.547a.83.83 0 0 0 1.179 0 .833.833 0 0 0 0-1.178zm-18.09-9.41c0-4.135 3.365-7.5 7.5-7.5 4.136 0 7.5 3.365 7.5 7.5 0 4.136-3.364 7.5-7.5 7.5-4.135 0-7.5-3.364-7.5-7.5z' />
      </g>
    </svg>
  );
};

Profile.defaultProps = {
  color: '#607389',
  viewBox: '0 0 20 20',
  height: 24,
};

export default Profile;
