import loadable from '@loadable/component';

// Template
import LoginApp from '../../../components/auth/login/LoginApp';
import RediredictIfAuthenticatedMiddleware from '../../middlewares/RediredictIfAuthenticatedMiddleware';

import routeHandlers from '../routeHandlers';

const authRoutes = (path: string, prefix = '') => {
  return {
    path: `${path}`,
    name: `${prefix}authenticationRoutes`,
    exact: false,
    component: LoginApp,
    routes: [
      {
        name: `${prefix}auth.login`,
        path: `${path}`,
        component: RediredictIfAuthenticatedMiddleware(
          loadable(() => import('../../../components/auth/login/LoginForm')),
        ),
        exact: true,
      },
      {
        name: `${prefix}auth.withPassword`,
        path: `${path}/withPassword`,
        component: RediredictIfAuthenticatedMiddleware(
          loadable(
            () => import('../../../components/auth/login/LoginWithPassword'),
          ),
        ),
        exact: true,
      },
      {
        name: `${prefix}auth.withMagicToken`,
        path: `${path}/magicToken/:token`,
        component: loadable(
          () => import('../../../components/auth/login/LoginWithMagicToken'),
        ),
        exact: true,
      },
      {
        name: `${prefix}auth.magicLinkSent`,
        path: `${path}/magicLinkSent`,
        component: loadable(
          () => import('../../../components/auth/login/MagicLinkSent'),
        ),
        exact: true,
      },
      {
        name: `${prefix}auth.changePassword`,
        path: `${path}/changePassword`,
        component: loadable(
          () =>
            import(
              '../../../components/auth/login/changePassword/UserChangeForgottenPassword'
            ),
        ),
        exact: true,
      },
      {
        name: `${prefix}auth.forgotPassword`,
        path: `${path}/forgotPassword`,
        component: loadable(
          () => import('../../../components/auth/login/ForgotPassword'),
        ),
        exact: true,
      },
      ...routeHandlers(path),
    ],
  };
};

const authenticationRoutes = authRoutes('/login');

export const redirectLoginRoutes = authRoutes('/redirect-login', 'redirect.');

export default authenticationRoutes;
