import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../utils/index';


// WE NEED TO DEFINE WHICH ROUTE IS BETTER
const PATH = '/home/feedbacksOneToOne';
const NEWPATH = '/home/oneOnOne';

const oneToOneRoutes: RouteType[] = [
  {
    name: 'oneOnOne',
    path: `${NEWPATH}/overview`,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    component: loadable(
      () => import('../../components/oneOnOne/OneOnOneDetails'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
    routes: [
      {
        name: 'oneOnOne.myOneOnOne',
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        label: ({ t }) => t('Overview'),
        path: `${NEWPATH}/overview/myOneOnOne`,
        component: loadable(
          () => import('../../components/oneOnOne/MyOneOnOneListTabView'),
        ),
        exact: false,
        requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
        routes: [
          {
            name: 'oneOnOne.myOneOnOne.registered',
            path: `${NEWPATH}/overview/myOneOnOne/registered`,
            label: ({ t }) => t('Registered'),
            labelHeader: ({ t }) => t('{{FeedbackOneToOne}} - Registered'),
            component: loadable(
              () =>
                import(
                  '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneList'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
            requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
          },
          {
            name: 'oneOnOne.myOneOnOne.scheduled',
            path: `${NEWPATH}/overview/myOneOnOne/scheduled`,
            label: ({ t }) => t('Scheduled'),
            labelHeader: ({ t }) => t('{{FeedbackOneToOne}} - Scheduled'),
            component: loadable(
              () =>
                import(
                  '../../components/oneOnOne/oneOnOneSchedule/OneOnOneScheduleList'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
            requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
          },
          {
            name: 'oneOnOne.myOneOnOne.private',
            path: `${NEWPATH}/overview/myOneOnOne/private`,
            label: ({ t }) => t('Private'),
            labelHeader: ({ t }) => t('Private'),
            component: loadable(
              () =>
                import(
                  '../../components/oneOnOne/userFeedbackOneToOne/private/OneOnOnePrivateList'
                ),
            ),
            exact: true,
            requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
            requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
          },
        ],
      },
    ],
  },
  {
    name: 'oneOnOne.schedule.new',
    path: `${NEWPATH}/schedule/new`,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    component: loadable(
      () =>
        import(
          '../../components/oneOnOne/oneOnOneSchedule/OneOnOneScheduleAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
  },
  {
    name: 'oneOnOne.schedule.edit',
    path: `${NEWPATH}/schedule/edit/:id`,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    component: loadable(
      () =>
        import(
          '../../components/oneOnOne/oneOnOneSchedule/OneOnOneScheduleAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
  },
  {
    name: 'oneOnOne.register.new',
    path: `${NEWPATH}/register/new/:id?`,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    component: loadable(
      () =>
        import(
          '../../components/oneOnOne/userFeedbackOneToOne/form/OneOnOneRegisterAdd'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
  },
  {
    name: 'oneOnOne.register.edit',
    path: `${NEWPATH}/register/edit/:id/:admin?`,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    component: loadable(
      () =>
        import(
          '../../components/oneOnOne/userFeedbackOneToOne/form/OneOnOneRegisterEdit'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.FEEDBACK_ONE_TO_ONE.USER.ALL],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
  },

  {
    name: 'feedbacksOneToOne.dashboard.root',
    path: `${PATH}/report`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_ONE_TO_ONE.ALL,
      ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
    routes: [
      {
        name: 'feedbacksOneToOne.dashboard',
        path: `${PATH}/report/dashboard`,
        label: 'Dashboard',
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/dashboard/UserFeedbackOneToOneDashboard'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },
      {
        name: 'feedbacksOneToOne.all',
        path: `${PATH}/report/all`,
        label: ({ t }) => t('Registered'),
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneAdminList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },
      {
        name: 'feedbacksOneToOne.private',
        path: `${PATH}/report/private`,
        label: ({ t }) => t('Private'),
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/private/OneOnOneAdminList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },
      {
        name: 'oneOnOne.admin.schedules',
        path: `${PATH}/report/schedules`,
        label: ({ t }) => t('Schedules'),
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/oneOnOneSchedule/AdminOneOnOneScheduleList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },
      {
        name: 'feedbacksOneToOne.pivot1',
        path: `${PATH}/report/pivot1`,
        label: 'Tabela Dinâmica (v1)',
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneAdminPivot'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE, MODULES.TEMP],
      },
      {
        name: 'feedbacksOneToOne.pivot2',
        path: `${PATH}/report/pivot2`,
        label: 'Tabela Dinâmica (v2)',
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneAdminPivot2'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE, MODULES.TEMP],
      },
      {
        name: 'feedbacksOneToOne.pivot',
        path: `${PATH}/report/pivot`,
        label: 'Tabela Dinâmica',
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneAdminPivot3'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },

      {
        name: 'feedbacksOneToOne.topics',
        path: `${PATH}/report/topics`,
        label: 'Tópicos',
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneTopicList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },
      {
        name: 'feedbacksOneToOne.admin.users',
        path: `${PATH}/report/users`,
        label: ({ t }) => t('Users'),
        labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
        component: loadable(
          () =>
            import(
              '../../components/oneOnOne/userFeedbackOneToOne/admin/AdminOneOnOneUserList'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.FEEDBACK_ONE_TO_ONE.ALL,
          ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
      },
    ],
  },
  {
    name: 'feedbacksOneToOne.topics.new',
    path: `${PATH}/topics/add`,
    labelHeader: ({ t }) => t('{{FeedbackOneToOne}}'),
    component: loadable(
      () =>
        import(
          '../../components/oneOnOne/userFeedbackOneToOne/UserFeedbackOneToOneTopicAdd'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.FEEDBACK_ONE_TO_ONE.ALL,
      ROLES.FEEDBACK_ONE_TO_ONE.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.FEEDBACK_ONE_TO_ONE],
  },
];

export default oneToOneRoutes;
