import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';

import { routeTo } from '../../../router/utils';

export const feedbackOneToOne = ({ t }) => [
  {
    label: t('{{FeedbackOneToOne}}'),
    icon: <SyncAltOutlinedIcon />,
    link: routeTo('oneOnOne.myOneOnOne.registered'),
  },
];

export const feedbackOneToOneAdmin = ({ t }) => [
  {
    label: t('{{FeedbackOneToOne}}'),
    icon: <SyncAltOutlinedIcon />,
    link: routeTo('feedbacksOneToOne.dashboard'),
  },
];
