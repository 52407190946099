import loadable from '@loadable/component';
import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

const PATH = '/home/settings';

export const applicationRoutes = [
  {
    name: 'applications.add',
    path: `${PATH}/application/add`,
    labelHeader: ({ t }) => t('Application - Add'),
    label: ({ t }) => t('Application - Add'),
    component: loadable(
      () => import('../../../components/applications/ApplicationAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
  },
  {
    name: 'application.details',
    path: `${PATH}/application/detail/:id`,
    labelHeader: ({ t }) => t('Application Detail'),
    label: ({ t }) => t('Application Detail'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'application.details.data',
        path: `${PATH}/application/detail/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../../components/applications/data/ApplicationData'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.CORE.APPLICATION,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
      },
    ],
  },
];
