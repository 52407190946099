import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';

import { COMPANY_PROJECT_TYPES_ENUM } from '@feedback/roles';

import { routeTo } from '../../../router/utils';


export const vacancies = ({ t }) => [
  {
    label: t('Vacancies'),
    icon: <WorkOutlineOutlinedIcon />,
    requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
    items: [
      {
        label: t('My Referrals'),
        link: routeTo('myHiringReferral'),
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
      {
        label: t('My Interviews'),
        link: routeTo('myInterviews.myInterviews'),
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
      {
        label: t('Internal Jobs Posts'),
        link: routeTo('jobPosting.internal'),
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
      {
        label: t('My Internal Job Processes'),
        link: routeTo('myVacancies'),
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
      },
    ],
  },
];
