type Props = {
  width?: string;
  height?: string;
  showText?: boolean;
};

export default ({ width, height = '40px', showText = true }: Props) => {
  const fill1 = showText ? '#1F6D61' : '#fff';
  const fill2 = showText ? '#308E83' : '#fff';
  const fill3 = showText ? '#4AB7A8' : '#fff';
  const fill4 = showText ? '#96969A' : '#fff';

  return (
    <svg
      version='1.1'
      id='OpenPixLogo'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 1011.49 190.22'
    >
      <g fill={fill4} fillRule='nonzero' id='open'>
        <polygon points='689.08,40.26 688.99,40.61 689.25,40.44' />
        <path
          d='M382.68,76.9c0,12.64-1.85,23.79-5.54,33.44c-3.69,9.66-8.7,17.72-15.02,24.18
			c-6.32,6.46-13.67,11.32-22.05,14.59c-8.38,3.26-17.25,4.9-26.63,4.9c-9.37,0-18.25-1.64-26.63-4.9
			c-8.38-3.27-15.73-8.13-22.05-14.59c-6.32-6.46-11.33-14.52-15.02-24.18c-3.69-9.66-5.54-20.8-5.54-33.44
			c0-12.64,1.84-23.75,5.54-33.34c3.69-9.59,8.7-17.61,15.02-24.07c6.32-6.46,13.67-11.32,22.05-14.59C295.2,1.64,304.08,0,313.45,0
			c9.37,0,18.25,1.64,26.63,4.9c8.38,3.27,15.73,8.13,22.05,14.59c6.32,6.46,11.32,14.48,15.02,24.07
			C380.84,53.15,382.68,64.26,382.68,76.9 M367.35,76.9c0-9.94-1.28-18.85-3.83-26.73c-2.56-7.88-6.18-14.59-10.86-20.13
			c-4.69-5.54-10.33-9.76-16.94-12.67c-6.6-2.91-14.03-4.37-22.26-4.37c-8.24,0-15.66,1.46-22.26,4.37
			c-6.6,2.91-12.25,7.14-16.94,12.67c-4.69,5.54-8.31,12.25-10.86,20.13c-2.56,7.88-3.84,16.79-3.84,26.73
			c0,9.94,1.28,18.85,3.84,26.73c2.56,7.88,6.18,14.59,10.86,20.13c4.69,5.54,10.33,9.8,16.94,12.78c6.6,2.98,14.02,4.48,22.26,4.48
			c8.23,0,15.66-1.49,22.26-4.48c6.6-2.98,12.25-7.24,16.94-12.78c4.69-5.54,8.31-12.25,10.86-20.13
			C366.07,95.75,367.35,86.84,367.35,76.9'
        />
        <path
          d='M419.96,190.22h-13.85V44.52c4.12-1.42,9.37-2.77,15.76-4.05c6.39-1.28,14.49-1.92,24.28-1.92
			c8.09,0,15.44,1.31,22.05,3.94c6.6,2.63,12.28,6.43,17.04,11.39C490,58.86,493.69,64.9,496.32,72c2.63,7.1,3.94,15.12,3.94,24.07
			c0,8.38-1.1,16.08-3.3,23.11c-2.2,7.03-5.43,13.07-9.69,18.11c-4.26,5.04-9.48,8.98-15.66,11.82c-6.18,2.84-13.24,4.26-21.19,4.26
			c-7.24,0-13.53-1.03-18.85-3.09c-5.32-2.06-9.2-4.01-11.61-5.86V190.22z M419.96,131c1.28,1,2.87,2.06,4.79,3.2
			c1.92,1.14,4.15,2.2,6.71,3.2c2.55,1,5.32,1.81,8.31,2.45c2.98,0.64,6.1,0.96,9.37,0.96c6.82,0,12.53-1.17,17.15-3.52
			c4.61-2.34,8.34-5.54,11.18-9.58c2.84-4.05,4.9-8.81,6.18-14.27c1.28-5.46,1.92-11.25,1.92-17.36c0-14.49-3.62-25.6-10.86-33.34
			c-7.24-7.74-16.83-11.61-28.76-11.61c-6.82,0-12.32,0.32-16.51,0.96c-4.19,0.64-7.35,1.32-9.48,2.02V131z'
        />
        <path
          d='M515.6,95.64c0-9.66,1.38-18.07,4.15-25.24c2.77-7.17,6.39-13.17,10.87-18c4.47-4.83,9.58-8.41,15.34-10.76
			c5.75-2.34,11.68-3.52,17.79-3.52c13.49,0,24.25,4.4,32.27,13.21c8.02,8.81,12.04,22.37,12.04,40.68c0,1.14-0.04,2.27-0.11,3.41
			c-0.07,1.14-0.18,2.2-0.32,3.2H530.3c0.43,13.49,3.76,23.86,10.01,31.1c6.25,7.24,16.19,10.86,29.82,10.86
			c7.52,0,13.49-0.71,17.89-2.13c4.4-1.42,7.6-2.63,9.58-3.62l2.56,11.93c-1.99,1.14-5.72,2.49-11.18,4.05
			c-5.47,1.56-11.89,2.34-19.28,2.34c-9.66,0-17.89-1.42-24.71-4.26c-6.82-2.84-12.43-6.82-16.83-11.93
			c-4.4-5.11-7.6-11.18-9.59-18.21C516.59,111.72,515.6,104.02,515.6,95.64 M593.78,86.91c-0.29-11.5-2.98-20.45-8.09-26.84
			c-5.11-6.39-12.35-9.59-21.73-9.59c-4.97,0-9.41,1-13.31,2.98c-3.91,1.99-7.32,4.65-10.22,7.99c-2.91,3.34-5.19,7.21-6.82,11.61
			c-1.64,4.4-2.59,9.02-2.88,13.85H593.78z'
        />
        <path
          d='M630.84,44.52c4.12-1.13,9.65-2.41,16.61-3.83c6.96-1.42,15.48-2.13,25.56-2.13c8.38,0,15.37,1.21,20.98,3.62
			c5.61,2.42,10.08,5.86,13.42,10.33c3.34,4.47,5.71,9.84,7.14,16.08c1.42,6.25,2.13,13.14,2.13,20.66v61.56h-13.85V93.73
			c0-7.81-0.53-14.41-1.6-19.81c-1.07-5.39-2.84-9.8-5.33-13.21c-2.49-3.41-5.75-5.86-9.8-7.35c-4.05-1.49-9.13-2.24-15.23-2.24
			c-6.53,0-12.18,0.36-16.93,1.07c-4.76,0.71-7.85,1.35-9.27,1.92v96.71h-13.85V44.52z'
        />
      </g>

      <g fill={fill3} fillRule='nonzero' id='pix'>
        <path
          d='M782.08,1.92c20.87,0,36.43,3.98,46.65,11.93c10.23,7.96,15.34,19.25,15.34,33.87
			c0,8.38-1.49,15.52-4.47,21.41c-2.98,5.89-7.24,10.65-12.78,14.27c-5.54,3.62-12.32,6.25-20.34,7.88
			c-8.02,1.63-17.08,2.45-27.16,2.45h-20.02v57.09H744.8V5.96c5.11-1.42,11.11-2.45,18-3.09C769.69,2.24,776.11,1.92,782.08,1.92
			M782.72,14.48c-5.4,0-9.98,0.18-13.74,0.53c-3.76,0.36-7,0.68-9.69,0.96v65.18h18.32c7.81,0,14.87-0.46,21.19-1.38
			c6.32-0.92,11.72-2.63,16.19-5.11c4.47-2.48,7.91-5.93,10.33-10.33c2.41-4.4,3.62-10.01,3.62-16.83c0-6.53-1.32-11.93-3.94-16.19
			c-2.63-4.26-6.11-7.63-10.44-10.12c-4.33-2.48-9.27-4.22-14.81-5.22C794.22,14.98,788.54,14.48,782.72,14.48'
        />
        <rect x='862.81' y='3.2' width='14.49' height='147.62' />
        <path
          d='M992.75,3.2h16.19l-48.23,71.44c9.36,12.7,18.51,25.43,27.44,38.19c8.93,12.77,16.71,25.43,23.34,37.99H995.9
			c-2.2-4.29-4.97-9.04-8.3-14.26c-3.33-5.21-6.94-10.7-10.84-16.45c-3.9-5.75-7.94-11.51-12.12-17.26
			c-4.18-5.75-8.18-11.17-12.01-16.26c-3.86,5.08-7.89,10.5-12.11,16.25c-4.21,5.75-8.29,11.5-12.21,17.25
			c-3.93,5.75-7.57,11.24-10.93,16.45c-3.36,5.22-6.06,9.97-8.1,14.27h-15.16c6.64-12.58,14.44-25.22,23.39-37.93
			c8.95-12.71,18.05-25.36,27.29-37.93L896.46,3.2h16.62l39.79,59.69L992.75,3.2z'
        />
      </g>

      <g>
        <path
          fill={fill1}
          d='M188.42,128.42c-1.16-1.75-2.83-3.27-4.94-4.42l-17-9.33l-11.8-6.47l-11.84,6.49l11.79,6.48l14.74,8.09
			c4.38,2.41,4.38,7.58,0,9.97l-65.44,35.88c-5.3,2.91-12.28,2.91-17.56,0l-65.52-35.99c-4.38-2.41-4.38-7.57,0-9.97l14.69-8.06
			l11.84-6.49l-11.79-6.48l-11.85,6.5l-17,9.31c-4.24,2.32-6.77,6.18-6.74,10.28c0.01,2.04,0.65,4,1.81,5.75
			c1.16,1.75,2.83,3.28,4.94,4.43l79.63,43.64c5.3,2.9,12.27,2.9,17.55,0l79.56-43.6c4.24-2.32,6.77-6.17,6.74-10.28
			C190.21,132.13,189.58,130.16,188.42,128.42z'
        />
        <path
          fill={fill2}
          d='M188.42,89.33c-1.16-1.75-2.83-3.27-4.94-4.42l-17-9.33l-11.8-6.46l-11.84,6.49l11.79,6.48l14.74,8.09
			c4.38,2.41,4.38,7.58,0,9.97l-14.69,8.06l-11.84,6.49l-38.91,21.33c-5.3,2.91-12.28,2.91-17.56,0l-39-21.42l-11.79-6.48
			l-14.74-8.09c-4.38-2.41-4.38-7.57,0-9.97l14.69-8.06l11.84-6.49l-11.79-6.48l-11.85,6.5l-17,9.31C2.5,87.19-0.02,91.04,0,95.15
			c0.01,2.03,0.65,4.01,1.81,5.76c1.16,1.75,2.83,3.27,4.94,4.42l17,9.31l11.8,6.47l50.83,27.86c5.3,2.9,12.27,2.9,17.55,0
			l50.71-27.79l11.85-6.5l17-9.31c4.24-2.32,6.77-6.17,6.74-10.28C190.21,93.04,189.58,91.08,188.42,89.33z'
        />
        <path
          fill={fill3}
          d='M188.42,50.24c-1.16-1.75-2.83-3.28-4.94-4.43L103.85,2.17c-5.3-2.9-12.27-2.9-17.55,0L6.74,45.77
			C2.5,48.1-0.02,51.95,0,56.06c0.01,2.03,0.65,4,1.81,5.75c1.16,1.75,2.83,3.28,4.94,4.43l17,9.31l11.8,6.46l50.83,27.86
			c5.3,2.9,12.27,2.9,17.55,0l50.71-27.79l11.85-6.5l17-9.31c4.24-2.32,6.77-6.17,6.74-10.28
			C190.21,53.95,189.58,51.99,188.42,50.24z M169.37,61.06l-14.69,8.06l-11.84,6.49l-38.91,21.33c-5.3,2.91-12.28,2.91-17.56,0
			l-39-21.42l-11.79-6.48l-14.74-8.09c-4.38-2.41-4.38-7.57,0-9.97L86.29,15.1c5.3-2.9,12.28-2.9,17.56,0l65.52,35.99
			C173.75,53.5,173.75,58.67,169.37,61.06z'
        />
        <g fill={fill1}>
          <path
            d='M85.24,54.01c-4.94-2.94-9.92-5.81-14.82-8.81c-1.75-1.07-3.55-1.61-5.64-1.5
				c-1.14-0.11-2.23,0.08-3.32,0.73c-5.6,3.36-11.23,6.63-16.85,9.95c-2.1,1.25-2.07,3.06,0.06,4.25
				c5.51,3.13,11.06,6.2,16.52,9.41c3.36,1.98,6.64,1.71,9.85-0.06c4.78-2.66,9.47-5.5,14.2-8.24
				C87.93,58.18,87.94,55.61,85.24,54.01z M74.15,57.42l-8.42,4.69c-0.36,0.2-0.81,0.2-1.19-0.01l-8.38-4.76 
				c-0.78-0.44-0.78-1.58,0.01-2.03l8.51-4.78c0.37-0.22,0.83-0.2,1.2,0.01l8.29,4.85C74.95,55.85,74.94,56.98,74.15,57.42z'
          />
          <path
            d='M115.93,72.32L97.6,61.3c-1.15-0.7-2.58-0.71-3.73-0.04L75.03,72.12c-1.34,0.78-1.35,2.71-0.01,3.49
				l16.88,9.84c2.17,1.26,4.85,1.27,7.03,0.02l16.96-9.66C117.24,75.04,117.26,73.11,115.93,72.32z M104.87,74.82l-8.96,5.11
				c-0.29,0.17-0.65,0.17-0.92,0l-8.93-5.2c-0.62-0.36-0.61-1.25,0-1.61l8.68-5c0.53-0.3,1.19-0.3,1.71,0.02l8.45,5.07
				C105.49,73.58,105.49,74.47,104.87,74.82z'
          />
          <path
            d='M116.25,37.56L97.82,25.71c-1.17-0.7-2.64-0.71-3.82-0.04L75.07,36.31c-1.8,1.02-2.12,3.89-0.32,4.91
				l17.84,10.46c1.86,1.05,4.13,1.07,6,0.02l17.62-9.53C118,41.18,118.03,38.6,116.25,37.56z M104.39,40.27l-7.54,4.07
				c-0.8,0.44-1.77,0.43-2.56-0.01l-7.63-4.47c-0.77-0.44-0.64-1.67,0.14-2.11l8.09-4.55c0.52-0.29,1.14-0.28,1.64,0.02l7.88,5.07
				C105.17,38.73,105.16,39.84,104.39,40.27z'
          />
          <path
            d='M117.08,66.17l5.29,3.29c1.37,1.01,3.21,1.09,4.67,0.21l4.97-3.01c1.16-0.71,1.17-2.39,0.02-3.11l-5.97-3.72
				c-0.56-0.35-1.26-0.37-1.83-0.06l-6.96,3.89C116.3,64.17,116.2,65.52,117.08,66.17z'
          />
          <path
            d='M131.38,57.8l5.11,3.34c1.37,0.9,3.14,0.93,4.55,0.09l5.38-3.22c0.82-0.49,0.83-1.68,0.01-2.18l-5.58-3.41
				c-1.11-0.68-2.51-0.71-3.65-0.07l-5.75,3.24C130.61,56.07,130.57,57.27,131.38,57.8z'
          />
          <path
            d='M117.83,49.32l6.34,4.4c0.98,0.68,2.27,0.7,3.27,0.05l6.38-4.13c0.59-0.38,0.58-1.24-0.01-1.62l-6.7-4.24
				c-0.87-0.55-1.97-0.56-2.84-0.02l-6.39,3.94C117.28,48.08,117.26,48.92,117.83,49.32z'
          />
        </g>
      </g>
    </svg>
  );
};
