import { graphql, readInlineData } from 'react-relay';

import { MODULES } from '@feedback/roles';

import type { usePermissionFragment } from './__generated__/usePermissionFragment.graphql';

import type {
  RequiredFeaturesType,
  RequiredProjectsTypes,
  RequiredRolesType,
} from './permissions';
import { hasFeature, hasPermission, hasRole } from './permissions';

const fragmentUsePermissionFragment = graphql`
  fragment usePermissionFragment on User @inline {
    isAdmin
    allRoles
    company {
      features
      projectType
    }
  }
`;

export const usePermission = (userRef: usePermissionFragment) => {
  const user = readInlineData<usePermissionFragment>(
    fragmentUsePermissionFragment,
    userRef,
  );

  return {
    hasFeature: (requiredFeatures: RequiredFeaturesType) =>
      hasFeature(user, requiredFeatures),
    hasRole: (requiredRoles: RequiredRolesType) => hasRole(user, requiredRoles),
    hasPermission: (
      requiredFeatures?: RequiredFeaturesType,
      requiredRoles?: RequiredRolesType,
      requiredProjectsTypes: RequiredProjectsTypes = [],
    ) =>
      hasPermission(
        user,
        requiredFeatures,
        requiredRoles,
        requiredProjectsTypes,
      ),
    hasTemp: user ? hasFeature(user, [MODULES.TEMP]) : false,
  };
};
