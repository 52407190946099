import type { Moment } from 'moment';
import moment from 'moment';

const formatFilterValue = (value: string | { [key: string]: string } | Moment): string | object => {
  if (!value) {
    return value;
  }

  if (value instanceof moment || !!isValidDatetime(value)) {
    const formatted = moment(value);

    return formatted.isValid ? formatted.toISOString() : value;
  }

  if (typeof value === 'object') {
    return Object.keys(value).reduce((acc, key) => {
      return {
        ...acc,
        [key]: formatFilterValue(value[key]),
      };
    }, {});
  }

  return value;
};

export const isValidDatetime = (datetime?: string | Moment) =>
  datetime && moment(datetime, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid();

export const stringToBoolean = (value: string | unknown) => {
  if (!value || typeof value !== 'string') {
    return value;
  }

  if (value.indexOf('true') > -1) {
    return true;
  }

  if (value.indexOf('false') > -1) {
    return false;
  }

  return value;
};

// transform from query string to a value
export const stringToValue = (value: string, key: string) => {
  if (key === 'quantity') {
    return Number.parseInt(value, 10);
  }

  // check if value is isostring
  if (isValidDatetime(value)) {
    const formatted = moment(value);

    return formatted.isValid ? formatted.toISOString() : value;
  }

  return stringToBoolean(value);
};
