import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

import type { RouteType } from '../../utils';


const PATH = '/home/hiring/jobRequest';

const jobRequestRoutes: RouteType[] = [
  {
    name: 'jobRequest',
    path: `${PATH}/list`,
    labelHeader: 'Gestão de Requisições de Vagas',
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.JOB_REQUEST.ALL,
      ROLES.HIRING.JOB_REQUEST.LIST_ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'jobRequest.list',
        path: `${PATH}/list/all`,
        label: 'Lista',
        labelHeader: 'Requisições - Lista',
        component: loadable(
          () => import('../../../components/jobRequest/JobRequestList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
      },
      {
        name: 'jobRequest.agile',
        path: `${PATH}/list/agile`,
        label: 'Lista Ágil',
        labelHeader: 'Requisições - Lista Ágil',
        component: loadable(
          () => import('../../../components/jobRequest/JobRequestAgileList'),
        ),
        exact: false,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        modalRoutes: [
          {
            name: 'jobRequest.agile.add',
            path: `${PATH}/list/agile/select/:id/:candidateId?`,
            labelHeader: 'Requisições - Lista Ágil',
            component: loadable(
              () => import('../../../components/jobRequest/JobRequestAgileAdd'),
            ),
            exact: true,
            requiredRoles: [
              ADMIN_ROLE,
              ROLES.HIRING.ALL,
              ROLES.HIRING.JOB_REQUEST.ALL,
            ],
            requiredFeatures: [MODULES.HIRING],
            modalTitle: 'Adicionar/Mover Candidato',
          },
        ],
      },
    ],
  },
  {
    name: 'jobRequest.add',
    path: `${PATH}/add`,
    labelHeader: ({ t }) => t('Requisition - Add'),
    component: loadable(
      () => import('../../../components/jobRequest/form/JobRequestListAdd'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.JOB_REQUEST.ALL,
      ROLES.HIRING.JOB_REQUEST.CREATE,
    ],
    requiredFeatures: [MODULES.HIRING],
  },
  {
    name: 'jobRequest.view',
    path: `${PATH}/view/:id`,
    labelHeader: 'Requisição - Detalhes',
    component: loadable(
      () => import('../../../components/jobRequest/JobRequestDetail'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.HIRING.ALL,
      ROLES.HIRING.JOB_REQUEST.ALL,
      ROLES.HIRING.JOB_REQUEST.LIST_ALL,
    ],
    requiredFeatures: [MODULES.HIRING],
    routes: [
      {
        name: 'jobRequest.view.candidates',
        path: `${PATH}/view/:id/candidates`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Requisição - Detalhes',
        label: 'Candidatos do Processo',
        component: loadable(
          () =>
            import(
              '../../../components/jobRequest/applicants/JobRequestApplicants'
            ),
        ),
      },
      {
        name: 'jobRequest.view.geolocation',
        path: `${PATH}/view/:id/geolocation`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Requisição - Detalhes',
        label: 'Geolocalização',
        component: loadable(
          () => import('../../../components/jobRequest/JobRequestGeolocation'),
        ),
      },
      {
        name: 'jobRequest.view.similars',
        path: `${PATH}/view/:id/similars`,
        labelHeader: 'Requisição - Detalhes',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        label: 'Banco de Candidatos',
        component: loadable(
          () =>
            import('../../../components/jobRequest/detail/JobRequestSimilars'),
        ),
      },
      {
        name: 'jobRequest.view.recommendation',
        path: `${PATH}/view/:id/recommendation`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Requisição - Detalhes',
        label: 'Próximos',
        component: loadable(
          () =>
            import(
              '../../../components/jobRequest/detail/JobRequestRecommendation'
            ),
        ),
      },
      {
        name: 'jobRequest.view.settings',
        path: `${PATH}/view/:id/settings`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.HIRING.ALL,
          ROLES.HIRING.JOB_REQUEST.ALL,
          ROLES.HIRING.JOB_REQUEST.LIST_ALL,
        ],
        requiredFeatures: [MODULES.HIRING],
        labelHeader: 'Requisição - Ajustes',
        label: 'Ajustes',
        component: loadable(
          () =>
            import(
              '../../../components/jobRequest/detail/settings/JobRequestSettings'
            ),
        ),
      },
    ],
  },
];

export default jobRequestRoutes;
