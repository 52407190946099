import PropTypes from 'prop-types';

const LogoText = ({ width, color }) => {
  const styles = {
    fill: color || '#642265',
  };

  return (
    <svg viewBox='0 0 171.71 38.19' width={width}>
      <g>
        <g>
          <path
            style={styles}
            d='M9.49,14.77l-2,16.94L6.27,35.52a4.63,4.63,0,0,1-1.23,2,3.28,3.28,0,0,1-2.28.71H0L2.83,14.75l-1.34-.31a2.4,2.4,0,0,1-1-.55,1.17,1.17,0,0,1-.29-1.1l.35-2.67H3.4l.15-1.29A10.2,10.2,0,0,1,4.63,5.3,9.17,9.17,0,0,1,6.75,2.61a9.38,9.38,0,0,1,3-1.73A10.76,10.76,0,0,1,13.42.26a9.12,9.12,0,0,1,1.41.1,8,8,0,0,1,1.31.32L15.52,4.1a.91.91,0,0,1-.61.74,3,3,0,0,1-1,.18,3.86,3.86,0,0,0-2.69.9A4.89,4.89,0,0,0,10,9l-.13,1.07h4.61l-.61,4.64H9.49Z'
          />
          <path
            style={styles}
            d='M33.22,28.59a24,24,0,0,1-2.36,2,12.75,12.75,0,0,1-2.37,1.42,11.3,11.3,0,0,1-2.59.82,15.82,15.82,0,0,1-3,.26,9.73,9.73,0,0,1-3.71-.69,8.45,8.45,0,0,1-2.91-2,8.69,8.69,0,0,1-1.88-3,11.09,11.09,0,0,1-.67-3.94A15.47,15.47,0,0,1,14.16,20a14.86,14.86,0,0,1,1.16-3.25,13.51,13.51,0,0,1,1.85-2.8,12.12,12.12,0,0,1,2.47-2.2,11.49,11.49,0,0,1,6.48-1.95,9.29,9.29,0,0,1,3.23.52,7.57,7.57,0,0,1,2.38,1.37,5.92,5.92,0,0,1,1.47,1.9,4.85,4.85,0,0,1,.5,2.1,6.46,6.46,0,0,1-.62,2.84A5.85,5.85,0,0,1,31,20.79a14.39,14.39,0,0,1-4.1,1.71,41.15,41.15,0,0,1-6.48,1.09,5.08,5.08,0,0,0,1,3.4,3.7,3.7,0,0,0,2.88,1.07,7,7,0,0,0,1.56-.15A5.49,5.49,0,0,0,27,27.52a7.65,7.65,0,0,0,.89-.5l.76-.5a4,4,0,0,1,.75-.39,2.44,2.44,0,0,1,.9-.15,1.35,1.35,0,0,1,.64.16,2.14,2.14,0,0,1,.55.43Zm-7.5-14.24a4,4,0,0,0-3,1.35,8.36,8.36,0,0,0-1.88,3.69,29.47,29.47,0,0,0,3.42-.7,9,9,0,0,0,2-.8,2.5,2.5,0,0,0,1-.91,2.24,2.24,0,0,0,.25-1.05,1.61,1.61,0,0,0-.44-1.07A1.76,1.76,0,0,0,25.72,14.35Z'
          />
          <path
            style={styles}
            d='M54.91,28.59a24.19,24.19,0,0,1-2.36,2,12.77,12.77,0,0,1-2.37,1.42,11.26,11.26,0,0,1-2.59.82,15.82,15.82,0,0,1-3,.26,9.72,9.72,0,0,1-3.71-.69,8.44,8.44,0,0,1-2.91-2,8.69,8.69,0,0,1-1.88-3,11.07,11.07,0,0,1-.67-3.94A15.46,15.46,0,0,1,35.84,20,14.9,14.9,0,0,1,37,16.73a13.48,13.48,0,0,1,1.85-2.8,12.11,12.11,0,0,1,2.47-2.2A11.5,11.5,0,0,1,47.8,9.77,9.3,9.3,0,0,1,51,10.3a7.59,7.59,0,0,1,2.38,1.37,5.94,5.94,0,0,1,1.47,1.9,4.86,4.86,0,0,1,.5,2.1,6.48,6.48,0,0,1-.61,2.84,5.85,5.85,0,0,1-2.14,2.29,14.36,14.36,0,0,1-4.1,1.71,41.14,41.14,0,0,1-6.48,1.09,5.08,5.08,0,0,0,1,3.4A3.7,3.7,0,0,0,46,28.07a7,7,0,0,0,1.56-.15,5.46,5.46,0,0,0,1.15-.39,7.74,7.74,0,0,0,.89-.5l.76-.5a4,4,0,0,1,.76-.39A2.44,2.44,0,0,1,52,26a1.34,1.34,0,0,1,.64.16,2.11,2.11,0,0,1,.55.43Zm-7.5-14.24a4,4,0,0,0-3,1.35,8.38,8.38,0,0,0-1.89,3.69,29.31,29.31,0,0,0,3.42-.7,9,9,0,0,0,2-.8A2.5,2.5,0,0,0,49,17a2.25,2.25,0,0,0,.25-1.05,1.61,1.61,0,0,0-.44-1.07A1.76,1.76,0,0,0,47.41,14.35Z'
          />
          <path
            style={styles}
            d='M80.83,0,76.75,32.8H73.11A3,3,0,0,1,72,32.64a1.72,1.72,0,0,1-.68-.45,1.56,1.56,0,0,1-.35-.68,3.44,3.44,0,0,1-.1-.86V28.75A12.89,12.89,0,0,1,67.47,32a7.49,7.49,0,0,1-4,1.17,6.55,6.55,0,0,1-2.56-.49,5.15,5.15,0,0,1-2-1.55,7.59,7.59,0,0,1-1.32-2.68,14.2,14.2,0,0,1-.47-3.91,17.57,17.57,0,0,1,.42-3.85,17.91,17.91,0,0,1,1.16-3.52,15.8,15.8,0,0,1,1.78-3,12.53,12.53,0,0,1,2.27-2.35,10.41,10.41,0,0,1,2.62-1.52,7.68,7.68,0,0,1,2.85-.55,6.63,6.63,0,0,1,2.54.46,6.54,6.54,0,0,1,2,1.25L74.16,0ZM72.18,15.67a3.69,3.69,0,0,0-1.41-.74,5.78,5.78,0,0,0-1.48-.21,3.44,3.44,0,0,0-2.18.79,7,7,0,0,0-1.72,2.07,11.57,11.57,0,0,0-1.13,2.92,13.94,13.94,0,0,0-.41,3.35A5.93,5.93,0,0,0,64.49,27a1.94,1.94,0,0,0,1.69,1,2.57,2.57,0,0,0,1.46-.53A6.84,6.84,0,0,0,69.1,26a13.45,13.45,0,0,0,1.34-2.16,16.77,16.77,0,0,0,1.06-2.66Z'
          />
          <path
            style={styles}
            d='M90.06,13.65a12.31,12.31,0,0,1,3.3-2.88,7.4,7.4,0,0,1,3.78-1,6.52,6.52,0,0,1,2.57.49,5.12,5.12,0,0,1,2,1.55A7.58,7.58,0,0,1,103,14.45a14.15,14.15,0,0,1,.47,3.89,17.55,17.55,0,0,1-.91,5.66,16.68,16.68,0,0,1-2.44,4.72A12.32,12.32,0,0,1,96.6,32a8.1,8.1,0,0,1-4.2,1.19,6.46,6.46,0,0,1-3-.65,7.27,7.27,0,0,1-2.19-1.7l-.07.22a3.06,3.06,0,0,1-.79,1.35,2.36,2.36,0,0,1-1.58.43H81.09l4-32.8h6.67ZM88.39,27.19a3.86,3.86,0,0,0,1.42.75,5.64,5.64,0,0,0,1.49.22,3.45,3.45,0,0,0,2.18-.79,7,7,0,0,0,1.72-2.07,11.61,11.61,0,0,0,1.13-2.92,14,14,0,0,0,.4-3.35,6,6,0,0,0-.64-3.16,1.94,1.94,0,0,0-1.69-1,2.59,2.59,0,0,0-1.5.56,7.14,7.14,0,0,0-1.5,1.51,13.39,13.39,0,0,0-1.35,2.24,16.72,16.72,0,0,0-1,2.73Z'
          />
          <path
            style={styles}
            d='M118.3,29a11.49,11.49,0,0,1-3.1,3,6.87,6.87,0,0,1-3.78,1.11,5.91,5.91,0,0,1-2.37-.49,5.65,5.65,0,0,1-2-1.49,7.42,7.42,0,0,1-1.41-2.54A11.36,11.36,0,0,1,105.1,25a15.36,15.36,0,0,1,1.13-5.86,14.93,14.93,0,0,1,3.16-4.85A15.21,15.21,0,0,1,114.2,11a15,15,0,0,1,6.12-1.23,25.41,25.41,0,0,1,3.29.22,16.3,16.3,0,0,1,3.29.79L124.13,32.8h-3.64a3,3,0,0,1-1.06-.16,1.73,1.73,0,0,1-.68-.45,1.57,1.57,0,0,1-.35-.68,3.44,3.44,0,0,1-.1-.86V29Zm1.05-14.62a6.25,6.25,0,0,0-3,.75,7.41,7.41,0,0,0-2.36,2,9.62,9.62,0,0,0-1.53,3,12,12,0,0,0-.54,3.63,8.38,8.38,0,0,0,.18,1.86,4,4,0,0,0,.47,1.24,2,2,0,0,0,.7.69,1.83,1.83,0,0,0,.89.22,2.3,2.3,0,0,0,1.45-.56,6.27,6.27,0,0,0,1.33-1.52,13.41,13.41,0,0,0,1.15-2.26,21.52,21.52,0,0,0,.9-2.76l.81-6.29h-.42Z'
          />
          <path
            style={styles}
            d='M144.3,30.85a9.86,9.86,0,0,1-2.09,1.34,9.47,9.47,0,0,1-2.29.73,14.37,14.37,0,0,1-2.69.23,9.09,9.09,0,0,1-3.59-.68,7.67,7.67,0,0,1-2.73-1.93,8.7,8.7,0,0,1-1.74-3,12.13,12.13,0,0,1-.61-4,15.3,15.3,0,0,1,.91-5.28A13.8,13.8,0,0,1,132,13.87a12.26,12.26,0,0,1,3.87-3,10.9,10.9,0,0,1,4.91-1.11,9.06,9.06,0,0,1,3.91.78A8.19,8.19,0,0,1,147.63,13l-2.19,2.52a2.91,2.91,0,0,1-.55.43,1.29,1.29,0,0,1-.68.19,1.37,1.37,0,0,1-.73-.19l-.65-.4a5.55,5.55,0,0,0-.86-.39,3.88,3.88,0,0,0-1.31-.19,3.75,3.75,0,0,0-2,.58,5.61,5.61,0,0,0-1.7,1.68,9.13,9.13,0,0,0-1.2,2.7,13.49,13.49,0,0,0-.45,3.64,6.69,6.69,0,0,0,.26,2,4.13,4.13,0,0,0,.72,1.4,3.06,3.06,0,0,0,1.08.85,3.11,3.11,0,0,0,1.32.28,4.12,4.12,0,0,0,1.7-.29,6.53,6.53,0,0,0,1.13-.65l.9-.64a1.71,1.71,0,0,1,1-.3,1.34,1.34,0,0,1,.64.16,2.21,2.21,0,0,1,.55.41l1.75,2.06A20.94,20.94,0,0,1,144.3,30.85Z'
          />
          <path
            style={styles}
            d='M159,0l-2.26,18.38h.46a1.9,1.9,0,0,0,.91-.18,2.7,2.7,0,0,0,.71-.63l4.69-6.4a3.22,3.22,0,0,1,.93-.82,2.5,2.5,0,0,1,1.22-.27h6.1l-6.36,8.08a7.08,7.08,0,0,1-2.06,1.77,3.84,3.84,0,0,1,.79.88,8.9,8.9,0,0,1,.62,1.12l4.56,10.87h-5.92a2.5,2.5,0,0,1-1.2-.25,1.68,1.68,0,0,1-.74-.89l-3.16-8a1.54,1.54,0,0,0-.51-.71,1.79,1.79,0,0,0-.89-.17h-.7L154.92,32.8h-6.71l4-32.8Z'
          />
        </g>
      </g>
    </svg>
  );
};

LogoText.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default LogoText;
