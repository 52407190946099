/**
 * @generated SignedSource<<cd0576b6cd73d136068ea11c3ee9633b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserEditProfileImageInput = {
  clientMutationId?: string | null | undefined;
  contentType: string;
  id: string;
  url: string;
};
export type UserEditProfileImageMutation$variables = {
  input: UserEditProfileImageInput;
};
export type UserEditProfileImageMutation$data = {
  readonly UserEditProfileImage: {
    readonly error: string | null | undefined;
    readonly me: {
      readonly id: string;
      readonly profileImage: {
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly user: {
      readonly id: string;
      readonly profileImage: {
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UserEditProfileImageMutation = {
  response: UserEditProfileImageMutation$data;
  variables: UserEditProfileImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "height",
      "value": 50
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 50
    }
  ],
  "kind": "ScalarField",
  "name": "url",
  "storageKey": "url(height:50,width:50)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserEditProfileImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserEditProfileImagePayload",
        "kind": "LinkedField",
        "name": "UserEditProfileImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "profileImage",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "profileImage",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserEditProfileImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserEditProfileImagePayload",
        "kind": "LinkedField",
        "name": "UserEditProfileImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "profileImage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "profileImage",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffd63ae446b3ec4ba796d8e05e4c1a92",
    "id": null,
    "metadata": {},
    "name": "UserEditProfileImageMutation",
    "operationKind": "mutation",
    "text": "mutation UserEditProfileImageMutation(\n  $input: UserEditProfileImageInput!\n) {\n  UserEditProfileImage(input: $input) {\n    user {\n      id\n      profileImage {\n        url(width: 50, height: 50)\n        id\n      }\n    }\n    me {\n      id\n      profileImage {\n        url\n        id\n      }\n    }\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef9aca3b3100c23a0d490df160ee08f2";

export default node;
