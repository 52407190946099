import { getTime } from 'date-fns';
import mime from 'mime-types';

import type { UploadResponse } from './types';

export const generateFileName = (file: File, index: number): string => {
  const extension = mime.extension(file.type);

  return `${getTime(new Date())}-${index}.${extension}`;
};

export const secureFile = (file: File): File => {
  const fileName = generateFileName(file, 0);

  const options = {
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    type: file.type,
  };

  return new File([file], fileName, options);
};

export const postFile = async (
  PreSignedFileUpload,
  file: File,
): Promise<UploadResponse> => {
  const {
    payload: { url, fields },
  } = PreSignedFileUpload;

  const fieldsParsed = JSON.parse(fields);

  const formData = new FormData();

  // See https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-HTTPPOSTForms.html, the order is important!
  Object.keys(fieldsParsed).reduce((acc, fieldName) => {
    acc.append(fieldName, fieldsParsed[fieldName]);

    return acc;
  }, formData);

  // eslint-disable-next-line
  console.log('fields: ', fieldsParsed);

  await formData.append('file', file);

  try {
    const result = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    // get location of the uploaded file
    const fileUrl = result.headers.get('Location');

    for (const pair of result.headers.entries()) {
      // eslint-disable-next-line
      console.log(pair[0] + ': ' + pair[1]);
    }

    // eslint-disable-next-line
    console.log('fileUrl: ', result, result.headers, fileUrl);

    if (!fileUrl) {
      return { error: 'URL not found' };
    }

    // on success
    return { error: '', file: { url: fileUrl, fields: fieldsParsed } };
  } catch (error) {
    // eslint-disable-next-line
    console.log('formData: ', error);

    return { error };
  }
};

export const generateHeader = (value: string): string => {
  if (!value) {
    return '';
  }

  let header = '';
  const arr = new Uint8Array(value).subarray(0, 4);

  for (let i = 0; i < arr.length; i += 1) {
    header += arr[i].toString(16);
  }

  return header;
};

export const generatePreSignedFileUpload = async (
  file: File,
  fileType: string,
  mutation: any,
): Promise<UploadResponse> => {
  return new Promise((resolve) => {
    const securedFile = secureFile(file);

    const errorPreSigned = 'Error generating pre signed file';
    const onCompleted = async ({ PreSignedFileUpload }) => {
      // something wrong can happen with the mutation
      if (!PreSignedFileUpload || PreSignedFileUpload.error) {
        resolve({
          error:
            PreSignedFileUpload && PreSignedFileUpload.error
              ? PreSignedFileUpload.error
              : errorPreSigned,
        });

        return;
      }

      if (
        PreSignedFileUpload &&
        (PreSignedFileUpload.error || !PreSignedFileUpload.payload)
      ) {
        resolve({ error: PreSignedFileUpload.error || errorPreSigned });

        return;
      }

      resolve(postFile(PreSignedFileUpload, securedFile));
    };

    const onError = (error: Error) => {
      resolve({ error: error.stack || errorPreSigned });
    };

    // TODO: add mutation inside package
    mutation.commit(
      { type: fileType, contentType: file.type },
      onCompleted,
      onError,
    );
  });
};

// png, jpg and jpeg
export const IMAGE_HEADERS = [
  '89504e47', //png
  'ffd8ffe0', //jpg
  'ffd8ffe1', //jpg
  'ffd8ffe2', //jpg
  'ffd8ffe3', //jpg
  'ffd8ffe8', //jpg
  'ffd8ffdb', //jpg
  'ffd8ffee',
  '47494638', // gif
];
export const FILE_HEADERS = [
  '504b34', // xlsx
  '25504446', // pdf
  'D0CF11E0A1B11AE1', // doc
  '504B0304', // docx
  '504B030414000600', // docx
];

export enum FILE_TYPES {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
}

export const toType = (mimeType: string) =>
  Object.keys(FILE_TYPES).find((type) =>
    mimeType ? mimeType.includes(type.toLowerCase()) : false,
  ) || FILE_TYPES.FILE;

// TODO: add other file types
export const findHeaders = (headerType: string): string[] => {
  if (typeof headerType !== 'string') {
    return [];
  }

  if (headerType.toUpperCase() === FILE_TYPES.IMAGE) {
    return IMAGE_HEADERS;
  }

  if (headerType.toUpperCase() === FILE_TYPES.FILE) {
    return FILE_HEADERS;
  }

  return [];
};

export const findAllHeaders = (headerTypes: string[]): string[] => {
  return headerTypes.reduce((acc, headerType) => {
    if (typeof headerType !== 'string') {
      return acc;
    }

    if (headerType.toUpperCase() === FILE_TYPES.IMAGE) {
      return [...acc, ...IMAGE_HEADERS];
    }

    if (headerType.toUpperCase() === FILE_TYPES.FILE) {
      return [...acc, ...FILE_HEADERS];
    }
  }, []);
};
