import type { RouteType } from '../../utils';

import adjustRoutes from './adjustRoutes';
import candidateRoutes from './candidateRoutes';
import groupInterviewRoutes from './groupInterviewRoutes';
import interviewRoutes from './interviewRoutes';
import jobExamRoutes from './jobExamRoutes';
import jobPostingRoutes from './jobPostingRoutes';
import jobRequestRoutes from './jobRequestRoutes';
import landingPageRoutes from './landingPageRoutes';
import referralRoutes from './referralRoutes';

const hiringRoutes: RouteType[] = [
  ...adjustRoutes,
  ...jobPostingRoutes,
  ...landingPageRoutes,
  ...jobExamRoutes,
  ...candidateRoutes,
  ...interviewRoutes,
  ...referralRoutes,
  ...jobRequestRoutes,
  ...groupInterviewRoutes,
];

export default hiringRoutes;
