/**
 * @generated SignedSource<<acd822dd179eead256b168eb63c12916>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type modulesNamesFragment$data = {
  readonly preferences: {
    readonly complaint: {
      readonly module: {
        readonly name: string;
      };
    };
    readonly complaintExternal: {
      readonly module: {
        readonly name: string;
      };
      readonly title: string;
    };
    readonly feedback: {
      readonly module: {
        readonly name: string;
        readonly pluralName: string;
      };
    };
    readonly feedbackOneToOne: {
      readonly module: {
        readonly name: string;
      };
    };
    readonly nineBox: {
      readonly module: {
        readonly name: string;
      };
    };
  };
  readonly " $fragmentType": "modulesNamesFragment";
};
export type modulesNamesFragment$key = {
  readonly " $data"?: modulesNamesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"modulesNamesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "modulesNamesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyPreferencesFeedback",
          "kind": "LinkedField",
          "name": "feedback",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeedbackModulePreferences",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pluralName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyPreferencesFeedbackOneToOne",
          "kind": "LinkedField",
          "name": "feedbackOneToOne",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeedbackOneToOneModulePreferences",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyComplaintPreferences",
          "kind": "LinkedField",
          "name": "complaint",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ComplaintModulePreferences",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyComplaintExternalPreferences",
          "kind": "LinkedField",
          "name": "complaintExternal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ComplaintExternalModulePreferences",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyPreferencesNineBox",
          "kind": "LinkedField",
          "name": "nineBox",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NineBoxModulePreferences",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "999f44cb4a48fcfcb09dd42bde6d5742";

export default node;
