import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';

import { routeTo } from '../../../router/utils';
import type { SideBarMenuItemProps } from '../menuItems';

export const feedback = ({ query, t }: SideBarMenuItemProps) => {
  return [
    {
      label: t('{{Feedbacks}}'),
      icon: <AutorenewOutlinedIcon />,
      items: [
        {
          label: t('Self-evaluation'),
          link: routeTo('feedbacks.give.selfreview', { id: query.me.id }),
          isDisplayed: [
            (me) => me.company.preferences.feedback.allowSelfReview,
          ],
        },
        {
          label: t('Give'),
          link: routeTo('feedbacks.give'),
        },
        {
          label: t('Ask'),
          link: routeTo('feedbacks.request'),
          isDisplayed: [
            (me) => me.company.preferences.feedback.allowFeedbackRequest,
          ],
        },
        {
          label: t('Reports'),
          link: routeTo('myFeedbacks'),
        },
        {
          label: t('External'),
          link: routeTo('externalFeedbacks'),
        },
      ],
    },
  ];
};

export const feedbackAdmin = ({ t }) => {
  return [
    {
      label: t('{{Feedbacks}}'),
      icon: <AutorenewOutlinedIcon />,
      items: [
        {
          label: t('Reports'),
          link: routeTo('feedback.dashboard'),
        },
        {
          label: t('Reports External'),
          link: routeTo('feedback.external.dashboard'),
        },
        {
          label: t('Users'),
          link: routeTo('feedback.users'),
        },
        {
          label: t('Settings'),
          link: routeTo('feedback.settings'),
        },
      ],
    },
  ];
};
