import { ActionButton } from '@feedback/ui';
import styled from 'styled-components';
import queryString from 'query-string';

import { graphql, createFragmentContainer } from 'react-relay';

import { getDomainName } from '../../../security/auth';

import type { LoginSamlField_company } from './__generated__/LoginSamlField_company.graphql';
import { AUTH_TYPE } from './AuthUtils';
import { getLogidRouteCallback } from './LoginForm';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
`;

type Props = {
  company: LoginSamlField_company;
};
const LoginSamlField = ({ company }: Props) => {
  const { core } = company.preferences;
  const { authConfigs } = core;

  const validActiveConfigs = authConfigs.filter((config) => config.active);

  if (validActiveConfigs.length === 0) {
    return null;
  }

  const handleSamlLogin = async (config) => {
    const host = `https://${getDomainName()}`;

    const callbackUrl = getLogidRouteCallback();

    const qs = queryString.stringify({
      hostname: host,
      samlID: config.id,
      callbackUrl,
    });

    if (config.type === AUTH_TYPE.MICROSOFT) {
      const samlLoginUrl = `${process.env.REDIRECT_SERVER_URI}/saml/login?${qs}`;

      window.location.href = samlLoginUrl;

      return;
    }

    if (config.type === AUTH_TYPE.SAML) {
      const samlLoginUrl = `${process.env.REDIRECT_SERVER_URI}/saml2/login?${qs}`;

      window.location.href = samlLoginUrl;

      return;
    }
  };

  return validActiveConfigs.map((config) => (
    <ButtonWrapper key={config._id}>
      <ActionButton color='secondary' onClick={() => handleSamlLogin(config)}>
        {config.buttonLabel}
      </ActionButton>
    </ButtonWrapper>
  ));
};

export default createFragmentContainer(LoginSamlField, {
  company: graphql`
    fragment LoginSamlField_company on Company {
      preferences {
        core {
          authConfigs {
            id
            _id
            type
            buttonLabel
            active
          }
          login {
            enableSaml
            enableSaml2
          }
          integration {
            microsoft {
              tenant
            }
          }
        }
      }
    }
  `,
});
