import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import { useTranslation } from 'react-i18next';

import { ResourceSelectField, RelaySelectField } from '@feedback/select';

import type { TagModuleSelectField_query } from './__generated__/TagModuleSelectField_query.graphql';

type Props = {
  query: TagModuleSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: Value;
  name: string;
  active: boolean;
  moduleOwner: string;
  resetInputOnSelection?: boolean;
};

type Value = {
  id: string;
  name: string;
};
const TagModuleSelectField = (props: Props) => {
  const { t } = useTranslation();

  const {
    query,
    relay, // eslint-disable-line no-unused-vars
    hintText = t('Search Tag'),
    active,
    moduleOwner,
    resetInputOnSelection = true,
    useFormik = true,
    ...rest
  } = props;

  const getFilterFragmentVariables = (state) => {
    const { search, filters: resetFilter } = state;

    return {
      moduleOwner,
      first: 10,
      filters: resetFilter
        ? { moduleOwner }
        : {
            search,
            moduleOwner,
          },
    };
  };

  const getRenderVariables = (value) => {
    const { search } = value;

    return {
      first: 10,
      filters: {
        search,
      },
    };
  };

  if (useFormik) {
    return (
      <ResourceSelectField
        relay={relay}
        connection={query.tagModules}
        hintText={hintText}
        getFilterFragmentVariables={getFilterFragmentVariables}
        getRenderVariables={getRenderVariables}
        resetInputOnSelection={resetInputOnSelection}
        {...rest}
      />
    );
  }

  return (
    <RelaySelectField
      relay={relay}
      connection={query.tagModules}
      hintText={hintText}
      getRenderVariables={getRenderVariables}
      getFilterFragmentVariables={getFilterFragmentVariables}
      resetInputOnSelection={resetInputOnSelection}
      {...rest}
    />
  );
};

export default createRefetchContainer(
  TagModuleSelectField,
  {
    query: graphql`
      fragment TagModuleSelectField_query on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 50 }
        after: { type: String }
        filters: { type: TagModuleFilter }
      ) {
        tagModules(first: $first, filters: $filters, after: $after)
          @connection(key: "TagModuleSelectField_tagModules", filters: []) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query TagModuleSelectFieldQuery(
      $first: Int
      $filters: TagModuleFilter
      $after: String
    ) {
      ...TagModuleSelectField_query
        @arguments(
          first: $first
          filters: $filters
          after: $after
        )
    }
  `,
);
