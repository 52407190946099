import type { StateChangeOptions } from 'downshift';
import Downshift from 'downshift';
import { useState, useRef } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import type { Disposable } from 'relay-runtime';

import SelectField from '../selectField/SelectField';
import type { Connection } from '../types';

const TOTAL_REFETCH_ITEMS = 10;
const MAX_CONNECTION_COUNT = 10000;

type Node = {
  id: string;
  name: string;
};

type Props = {
  multiple?: boolean;
  onChange?: (node: any) => void;
  onSelectValue?: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string;
  fullWidth?: boolean;
  connection: Connection<any>;
  getItemFromNode: () => Node[];
  name: string;
  value: string;
  resetInputOnSelection?: boolean;
};

type Value = {
  id: string;
  name: string;
};

const RelaySelectField = (props: Props) => {
  const timeoutId = useRef<number | null>();
  const loadMoreDisposable = useRef<Disposable | null>();
  const newSearchDisposable = useRef<Disposable | null>();

  const {
    connection,
    relay,
    fullWidth,
    hintText,
    onChange,
    resetInputOnSelection,
    ...rest
  } = props;

  const { name, value, onSelectValue, multiple } = props;

  const getItemFromNode = (node) => {
    if (props.getItemFromNode) {
      return props.getItemFromNode(node);
    }

    return {
      ...node,
      id: node.id,
      name: node.name,
    };
  };

  const formatEdges = () => {
    return (
      Array.isArray(connection?.edges) &&
      connection.edges
        .filter(({ node }) => !!node)
        .map(({ node }) => getItemFromNode(node))
        .filter((node) => !!node)
    );
  };

  const getValue = () => {
    if (value) {
      return value;
    }

    return null;
  };

  // eslint-disable-next-line
  const [input, setInput] = useState<string>('');

  const initialValue = getValue();

  const [search, setSearch] = useState(initialValue ? initialValue.name : '');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getFilterFragmentVariables = (value) => {
    if (props.getFilterFragmentVariables) {
      return props.getFilterFragmentVariables({
        search: value || search,
        input,
        isFetching,
      });
    }

    return {
      search: value || search,
    };
  };

  const getRenderVariables = (value) => {
    if (props.getRenderVariables) {
      return props.getRenderVariables({
        search: value || search,
        input,
        isFetching,
      });
    }

    return null;
  };

  const handleSearch = (value) => {
    if (isFetching) {
      return null;
    }

    setIsFetching(true);

    const refetchVariables = (fragmentVariables) => ({
      ...fragmentVariables,
      search,
      ...getFilterFragmentVariables(value),
    });

    const renderVariables = getRenderVariables(value);

    newSearchDisposable.current = relay.refetch(
      refetchVariables,
      renderVariables,
      () => {
        newSearchDisposable.current = null;

        setIsFetching(false);
      },
      { force: true },
    );
  };

  const onInputChange = (value: string) => {
    timeoutId.current && clearTimeout(timeoutId.current);

    setSearch(value);

    // cancel load more if there is a new search going on
    if (loadMoreDisposable.current) {
      loadMoreDisposable.current.dispose();
      setIsFetching(false);
    }

    // cancel old search if new search will happen
    if (newSearchDisposable.current) {
      newSearchDisposable.current.dispose();
      setIsFetching(false);
    }

    timeoutId.current = setTimeout(() => handleSearch(value), 500);
  };

  const handleSelect = (value: Value | Value[] | undefined) => {
    onSelectValue && onSelectValue(value);

    if (Array.isArray(value) && multiple) {
      return onChange && onChange(value.reverse());
    }

    onChange && onChange(value);

    if (resetInputOnSelection) {
      onInputChange('');
    }
  };

  const onStateChange = (changes: StateChangeOptions) => {
    const { type, isOpen } = changes;

    if (type === Downshift.stateChangeTypes.unknown) {
      if (isOpen) {
        onInputChange(search);
      }
    }
  };

  const handleLoadMore = () => {
    if (!connection) {
      return;
    }

    const { edges, pageInfo } = connection;

    if (!pageInfo.hasNextPage) {
      return;
    }

    const total = edges.length + TOTAL_REFETCH_ITEMS;

    const fragmentRenderVariables = getRenderVariables() || {};
    const renderVariables = { first: total, ...fragmentRenderVariables };

    if (isFetching) {
      // do not loadMore if it is still loading more or searching
      return;
    }

    setIsFetching(true);

    const refetchVariables = (fragmentVariables) => ({
      ...fragmentVariables,
      ...getFilterFragmentVariables(search),
      first: TOTAL_REFETCH_ITEMS,
      after: pageInfo.endCursor,
    });

    loadMoreDisposable.current = relay.refetch(
      refetchVariables,
      renderVariables,
      () => {
        setIsFetching(false);

        loadMoreDisposable.current = null;
      },
      {
        force: false,
      },
    );
  };

  const getInfiniteLoaderProps = ({ downshiftProps }) => {
    const { count, edges, pageInfo } = connection;

    return {
      rowCount: count ?? MAX_CONNECTION_COUNT, // handle case without totalCount
      isRowLoaded: ({ index }) => (edges ? !!edges[index] : false),
      loadMoreRows: async () => {
        if (isFetching) {
          return;
        }

        if (!pageInfo.hasNextPage) {
          return;
        }

        downshiftProps.setHighlightedIndex(null);

        return handleLoadMore();
      },
    };
  };

  // how to use select field with a initial value
  // just pass prop value with a node: { id, name }
  return (
    <SelectField
      hintText={hintText}
      options={formatEdges()}
      onChange={handleSelect}
      inputValue={search}
      onInputChange={onInputChange}
      fullWidth={fullWidth}
      getFragmentVariables={getFilterFragmentVariables}
      getFilterFragmentVariables={getFilterFragmentVariables}
      connection={connection}
      selectedItem={getValue()}
      withInfiniteScroll={true}
      onStateChange={onStateChange}
      isLoading={isFetching}
      getInfiniteLoaderProps={getInfiniteLoaderProps}
      resetInputOnSelection={resetInputOnSelection}
      {...rest}
    />
  );
};

export default RelaySelectField;
