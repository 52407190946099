/**
 * @generated SignedSource<<ff71072099fbb22dc2c9db1c69bf1b09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelectField_query$data = {
  readonly usersSelected: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly active: boolean | null | undefined;
        readonly correlations: {
          readonly employeeID: string | null | undefined;
        } | null | undefined;
        readonly email: string | null | undefined;
        readonly id: string;
        readonly manager: {
          readonly active: boolean | null | undefined;
          readonly correlations: {
            readonly employeeID: string | null | undefined;
          } | null | undefined;
          readonly email: string | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly profileImage: {
            readonly url: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly name: string | null | undefined;
        readonly profileImage: {
          readonly url: string | null | undefined;
        } | null | undefined;
      };
    } | null | undefined>;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "UserSelectField_query";
};
export type UserSelectField_query$key = {
  readonly " $data"?: UserSelectField_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSelectField_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Correlation",
  "kind": "LinkedField",
  "name": "correlations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeeID",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": 300,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupIdsNotIn"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasHeadCount"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "searchWithViewer"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userIdNotIn"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "usersSelected"
        ]
      }
    ]
  },
  "name": "UserSelectField_query",
  "selections": [
    {
      "alias": "usersSelected",
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "__UserSelectField_usersSelected_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoExtended",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "manager",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ddc9ded336091d25b280d22adf75443a";

export default node;
