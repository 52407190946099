import { ErrorBoundary } from 'react-error-boundary';
import { Text } from 'rebass';
import { Card } from '@feedback/ui';
import { useTranslation } from 'react-i18next';

import config from '../../config';

const ContentError = ({ error }) => {
  const { t } = useTranslation();

  if (config.NODE_ENV === 'production') {
    return (
      <Card>
        <Text>{t('Oops! an error has occurred')}</Text>
      </Card>
    );
  }

  return (
    <Card>
      <Text>{t('Error rendering')}</Text>
      <Text>{t('Here’s what we know..')}</Text>
      <Text>
        <strong>Error:</strong> {error.toString()}
      </Text>
    </Card>
  );
};

const ContentBoundary = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ContentError}>{children}</ErrorBoundary>
  );
};

export default ContentBoundary;
