import invariant from 'invariant';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { Modal } from '@feedback/ui';

import type {
  RouteType } from '../../../router/utils/index';
import {
  drawRoutes,
  hasMatchChildren,
} from '../../../router/utils/index';

type Props = {
  title: string;
  modalRoutes: RouteType[];
  isScrollable?: boolean;
  showClose?: boolean;
};

const RoutedModal = ({
  modalRoutes,
  showClose,
  title: propsTitle,
  isScrollable = false,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const handleCloseModal = () => {
    if (history.length > 2) {
      history.goBack();

      return;
    }

    history.push(match.path);
  };

  const routesMemo = useMemo(() => {
    return drawRoutes(modalRoutes, null, { ...rest, handleCloseModal });
  }, [modalRoutes]);

  if (!modalRoutes) {
    invariant(modalRoutes, `missing modalRoutes when using RoutedModal`);

    return null;
  }

  const hasMatchAnyChildren = () => {
    return hasMatchChildren(location, modalRoutes);
  };

  const getModalTitle = () => {
    const { pathname } = location;

    const matchedRoute = modalRoutes.filter((route) =>
      matchPath(pathname, { path: route.path, exact: true }),
    );

    if (matchedRoute.length > 0) {
      if (matchedRoute[0].modalTitle) {
        const { modalTitle } = matchedRoute[0];

        return typeof modalTitle === 'function'
          ? modalTitle({ t })
          : modalTitle;
      }

      return propsTitle;
    }

    return propsTitle;
  };

  return (
    <Modal
      {...rest}
      isOpen={!!hasMatchAnyChildren()}
      isScrollable={isScrollable}
      handleClose={handleCloseModal}
      title={getModalTitle()}
      showClose={showClose}
      fullWidth
    >
      {routesMemo}
    </Modal>
  );
};

export default RoutedModal;
