import type { RouteType } from '../../utils';

import businessDivisionRoutes from './businessDivisionRoutes';
import costRevenueCenterRoutes from './costRevenueCenterRoutes';
import headCountRoutes from './headCountRoutes';
import jobRoleRoutes from './jobRoleRoutes';

export const PATH = 'home/planning';

const planningRoutes: RouteType[] = [
  ...headCountRoutes,
  ...costRevenueCenterRoutes,
  ...jobRoleRoutes,
  ...businessDivisionRoutes,
];

export default planningRoutes;
