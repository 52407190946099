import dot from 'dot-object';

import getQuery from './getQuery';
import { stringToValue } from './stringToValue';

// TODO - enable be typed parsed - check this https://github.com/ljharb/qs
export const getFiltersFromLocation = (filterKeys: string[] = []) => {
  const queryParams: any = getQuery();

  const filter = filterKeys
    .map((key) => {
      let value = stringToValue(dot.pick(key, queryParams), key);

      if (typeof value !== 'boolean' && value !== null && !value) {
        return;
      }

      if (typeof value !== 'boolean' && Number(value) && key !== 'search') {
        value = Number(value);
      }

      if (typeof value === 'object' && value !== null) {
        Object.keys(value).map((key) => {
          if (Number(value[key]) && parseFloat(value[key])) {
            value[key] = parseFloat(value[key]);
          }
        });
      }

      return { [key]: value };
    })
    .reduce((acc, filter) => ({ ...acc, ...filter }), {});

  return filter ? dot.object(filter) : {};
};
