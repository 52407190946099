import styled from 'styled-components';

import type {
  ColorProps,
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  BorderProps,
  PositionProps,
} from 'styled-system';

import {
  color,
  space,
  flexbox,
  layout,
  border,
  display,
  position,
} from 'styled-system';

import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import type { CardProps as MUICardProps } from '@material-ui/core/Card';
import _CardMUI from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import CardTitle from './CardTitle';

type ExtendedCardProps = MUICardProps &
  FlexboxProps &
  LayoutProps &
  BorderProps &
  SpaceProps &
  ColorProps &
  PositionProps;

const CardMUI = styled(_CardMUI)<ExtendedCardProps>`
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
  border-radius: 8px;
  box-sizing: border-box;
  ${flexbox}
  ${layout}
  ${border}
  ${space}
  ${color}
  ${display}
  ${position}
  
  @media print {
    box-shadow: none;
  }
`;

export type CardProps = {
  children: ReactNode;
  className?: string;
} & ExtendedCardProps;

const Card = forwardRef(({ children, ...props }: CardProps, ref) => {
  return (
    <CardMUI {...props} ref={ref}>
      {children}
    </CardMUI>
  );
});

export { CardContent, CardHeader, CardMedia, CardActions, CardTitle };

export default Card;
