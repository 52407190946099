// @flow

type Props = {
  width: number;
  height?: number;
  color?: string;
  viewBox?: string;
};

const Permissions = ({ width, color, viewBox, height }: Props) => {
  return (
    <svg viewBox={viewBox} width={width} height={height}>
      <path
        fill={color}
        fillRule='nonzero'
        d='M18.115 1.924a6.453 6.453 0 0 0-9.115 0 6.443 6.443 0 0 0-1.57 6.541l-7.258 7.26a.585.585 0 0 0-.172.413v3.315c0 .324.262.586.586.586h3.315a.585.585 0 0 0 .414-.172l.829-.829a.586.586 0 0 0 .167-.482l-.103-.89 1.234-.116a.585.585 0 0 0 .528-.528l.116-1.234.89.104a.587.587 0 0 0 .653-.582l.001-1.091h1.071a.585.585 0 0 0 .414-.172l1.503-1.483c2.295.744 4.78.193 6.497-1.525a6.453 6.453 0 0 0 0-9.115zm-1.657 4.143a1.76 1.76 0 0 1-2.486 0 1.76 1.76 0 0 1 0-2.485 1.76 1.76 0 0 1 2.486 0 1.76 1.76 0 0 1 0 2.485z'
      />
    </svg>
  );
};

Permissions.defaultProps = {
  color: '#607389',
  viewBox: '0 0 20 22',
  height: 24,
};

export default Permissions;
