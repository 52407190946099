/**
 * @generated SignedSource<<aee7ca73ef4e7727ecc3aa5d7e9c155e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDetails_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_user">;
  readonly " $fragmentType": "UserDetails_user";
};
export type UserDetails_user$key = {
  readonly " $data"?: UserDetails_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDetails_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDetails_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAvatar_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4f1edcc6666b713dfe6e6d49a380b00c";

export default node;
