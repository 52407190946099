import { createAction, handleActions } from 'redux-actions';

const initialState = {
  user: {
    id: null,
    token: null,
  },
};

const TYPES = {
  CLEAR: 'SIGNUP:CLEAR',
  SET_USER: 'SIGNUP:SET_USER',
};

export const clearSignUp = createAction(TYPES.CLEAR);

export const setUser = createAction(TYPES.SET_USER);

export default handleActions(
  {
    [TYPES.CLEAR]: () => initialState,

    [TYPES.SET_USER]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        id: payload.id,
        token: payload.token,
        email: payload.email,
      },
    }),
  },
  initialState,
);
