import styled from 'styled-components';
import AccountCircle from '@material-ui/icons/AccountCircle';

const Img = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(90, 90, 90, 0.3);
`;

type Props = {
  size: number;
  profileImage?: string | null;
};

const ProfilePicBadge = ({ size = 30, profileImage = null }: Props) => (
  <>
    {profileImage ? (
      <Img size={size} src={profileImage} />
    ) : (
      <AccountCircle fontSize='large' />
    )}
  </>
);

export default ProfilePicBadge;
