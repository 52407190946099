/**
 * @generated SignedSource<<ee07395e5686b7421fa9b6002463e7ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PreSignedUploadFileType = "CANDIDATE_CV" | "CANDIDATE_PROFILE_IMAGE" | "DATA_IMPORT" | "GENERIC_DOCUMENT" | "HOME_BANNER" | "LOGO_APP" | "LOGO_WEB" | "OMBUDSMAN_EXTERNAL" | "RESERVATION_BLUEPRINT" | "SOCIAL_POST_MEDIA" | "USER_PROFILE_IMAGE" | "%future added value";
export type PreSignedFileUploadMutationInput = {
  clientMutationId?: string | null | undefined;
  contentType: string;
  type: PreSignedUploadFileType;
};
export type PreSignedFileUploadMutation$variables = {
  input: PreSignedFileUploadMutationInput;
};
export type PreSignedFileUploadMutation$data = {
  readonly PreSignedFileUpload: {
    readonly error: string | null | undefined;
    readonly payload: {
      readonly fields: string;
      readonly url: string;
    } | null | undefined;
  } | null | undefined;
};
export type PreSignedFileUploadMutation = {
  response: PreSignedFileUploadMutation$data;
  variables: PreSignedFileUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PreSignedFileUploadMutationPayload",
    "kind": "LinkedField",
    "name": "PreSignedFileUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "S3PostUploadSigningPayload",
        "kind": "LinkedField",
        "name": "payload",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fields",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreSignedFileUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreSignedFileUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1e107feafc1ac6693e40619eca7ddb97",
    "id": null,
    "metadata": {},
    "name": "PreSignedFileUploadMutation",
    "operationKind": "mutation",
    "text": "mutation PreSignedFileUploadMutation(\n  $input: PreSignedFileUploadMutationInput!\n) {\n  PreSignedFileUpload(input: $input) {\n    payload {\n      url\n      fields\n    }\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d87ca26dcdf185bcaf9195b56efead4";

export default node;
