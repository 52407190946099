import styled from 'styled-components';

const Message = styled.p`
  text-align: center;
  color: #bb6771;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  height: 20px;
`;

type Props = {
  children: string;
};
const ErrorMessage = ({ children }: Props) => {
  return <Message>{children}</Message>;
};

export default ErrorMessage;
