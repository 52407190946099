import { createFragmentContainer, graphql } from 'react-relay';

import { hasRole } from '../../../../security/index';

import { getRoles } from './utils';

import type { RoleFlag_user } from './__generated__/RoleFlag_user.graphql';

const DefaultFallback = () => <div />;

type Props = {
  routes?: string[];
  roles?: string[];
  children: React.ReactChildren;
  fallbackComponent?: React.ReactNode;
  user: RoleFlag_user;
};

const RoleFlag = ({
  user,
  roles,
  children,
  fallbackComponent,
  routes,
}: Props) => {
  const pathRoles = getRoles(routes, roles);

  if (hasRole(user, pathRoles)) {
    return children;
  }

  return fallbackComponent ? fallbackComponent : <DefaultFallback />;
};

export default createFragmentContainer(RoleFlag, {
  user: graphql`
    fragment RoleFlag_user on User {
      allRoles
    }
  `,
});
