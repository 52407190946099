import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { usePrevious } from './usePrevious';

export const usePageView = () => {
  const location = useLocation();

  const lastLocation = usePrevious(location);

  useEffect(() => {
    const handlePageView = () => {
      if (!lastLocation) {
        ReactGA.pageview(window.location.pathname + window.location.search);

        return;
      }

      if (
        lastLocation.pathname !== location.pathname ||
        lastLocation.search !== location.search
      ) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    };

    handlePageView();
  }, [location]);
};
