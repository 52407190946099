/**
 * @generated SignedSource<<444801ef2f39b99389d8112c88363849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CompanyProjectType = "FEEDBACK_HOUSE" | "LOGID" | "OPEN_PIX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderTrialWarning_me$data = {
  readonly company: {
    readonly projectType: CompanyProjectType | null | undefined;
    readonly trialDate: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "HeaderTrialWarning_me";
};
export type HeaderTrialWarning_me$key = {
  readonly " $data"?: HeaderTrialWarning_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderTrialWarning_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderTrialWarning_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "da5e5b4b1bf78fedfed2faa3ee5c5f2d";

export default node;
