import { RelayEnvironmentProvider } from 'react-relay';
import { isUnsupportedBrowser } from '@feedback/utils';

import { UnsupportedBrowserWarning } from '@feedback/ui';

import Providers from './Providers';
import ClientRouter from './router/ClientRouter';

import { Environment } from './relay';

const Main = () => {
  const renderClientRouter = () => {
    return (
      <RelayEnvironmentProvider environment={Environment}>
        <Providers>
          <ClientRouter />
        </Providers>
      </RelayEnvironmentProvider>
    );
  };

  return isUnsupportedBrowser() ? (
    <UnsupportedBrowserWarning />
  ) : (
    renderClientRouter()
  );
};

export default Main;
