import PropTypes from 'prop-types';

const MyTeam = ({ width, color }) => {
  const styles = {
    fill: 'none',
    stroke: color || '#757575',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
  };

  return (
    <svg viewBox='0 0 40 25' width={width}>
      <g>
        <g>
          <path
            style={styles}
            d='M18,1.2c-2.3,0-4.2,2.4-4.2,5.3c0,4.2,1.2,3,1.8,4.3c0.2,0.3,0.2,1.2,0,1.5c-1.7,2.5-4.9,0.6-4.9,5.4
            c0,1.1,0,1.9,1.5,2.2'
          />
        </g>
        <g>
          <path
            style={styles}
            d='M18,1.2c0.7,0,1.4,0.2,2,0.6c0.3,0.2,0.6,0.5,0.9,0.8'
          />
        </g>
        <g>
          <g>
            <path
              style={styles}
              d='M22,5c-2.3,0-4.2,2.4-4.2,5.3c0,4.2,1.2,3,1.8,4.3c0.2,0.3,0.2,1.2,0,1.5c-1.7,2.5-4.9,0.6-4.9,5.4
              c0,1.9,0,2.6,7.3,2.6'
            />
          </g>
          <g>
            <path
              style={styles}
              d='M22,5c2.3,0,4.2,2.4,4.2,5.3c0,4.2-1.2,3-1.8,4.3c-0.2,0.3-0.2,1.2,0,1.5c1.7,2.5,4.9,0.6,4.9,5.4
              c0,1.9,0,2.6-7.3,2.6'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

MyTeam.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default MyTeam;
