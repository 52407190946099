import { PureComponent } from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  text-align: center;
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  padding: ${(props) => props.padding};
  font-weight: 800;
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

class MaterialIcon extends PureComponent<Props> {
  static defaultProps = {
    color: 'rgb(96, 115, 137)',
    fontSize: '14px',
    padding: '5px',
  };

  render() {
    const { children } = this.props;
    const className = this.props.className
      ? `material-icons ${this.props.className}`
      : 'material-icons';

    return (
      <Icon {...this.props} className={className}>
        {children}
      </Icon>
    );
  }
}

export default MaterialIcon;
