/**
 * @generated SignedSource<<dace30a967a387dba93be4d7641d5139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CompanyProjectType = "FEEDBACK_HOUSE" | "LOGID" | "OPEN_PIX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Sidebar_query$data = {
  readonly me: {
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly businessDivisions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly company: {
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly nameFriendly: string | null | undefined;
      readonly preferences: {
        readonly complaint: {
          readonly hideMenu: boolean | null | undefined;
          readonly module: {
            readonly name: string;
          };
        };
        readonly complaintExternal: {
          readonly module: {
            readonly name: string;
          };
        };
        readonly feedback: {
          readonly allowFeedbackRequest: boolean | null | undefined;
          readonly allowSelfReview: boolean | null | undefined;
          readonly module: {
            readonly name: string;
            readonly pluralName: string;
          };
        };
      };
      readonly projectType: CompanyProjectType | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"Brand_company">;
    } | null | undefined;
    readonly hasDataExports: boolean;
    readonly hasGoalGroup: boolean;
    readonly hasNineBoxReportAvailable: boolean;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly isInNineBox: boolean;
    readonly isResponsibleByCostRevenueCenters: boolean;
    readonly isResponsibleForSomeCase: boolean | null | undefined;
    readonly myTeam: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null | undefined;
          readonly nick: string | null | undefined;
        };
      } | null | undefined>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SidebarHeader_me">;
  } | null | undefined;
  readonly version: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"menuItems_query">;
  readonly " $fragmentType": "Sidebar_query";
};
export type Sidebar_query$key = {
  readonly " $data"?: Sidebar_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"Sidebar_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allRoles",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameFriendly",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyComplaintPreferences",
      "kind": "LinkedField",
      "name": "complaint",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ComplaintModulePreferences",
          "kind": "LinkedField",
          "name": "module",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideMenu",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyPreferencesFeedback",
      "kind": "LinkedField",
      "name": "feedback",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedbackModulePreferences",
          "kind": "LinkedField",
          "name": "module",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pluralName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowFeedbackRequest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSelfReview",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyComplaintExternalPreferences",
      "kind": "LinkedField",
      "name": "complaintExternal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ComplaintExternalModulePreferences",
          "kind": "LinkedField",
          "name": "module",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasGoalGroup",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isResponsibleByCostRevenueCenters",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isResponsibleForSomeCase",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInNineBox",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNineBoxReportAvailable",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDataExports",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "BusinessDivisionConnection",
  "kind": "LinkedField",
  "name": "businessDivisions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessDivisionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BusinessDivision",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "businessDivisions(first:1)"
},
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 3
    }
  ],
  "concreteType": "UserConnection",
  "kind": "LinkedField",
  "name": "myTeam",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfoExtended",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nick",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "myTeam(first:3)"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sidebar_query",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "menuItems_query",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "me",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "usePermissionFragment",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingFeedbackRequestCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingFeedbackRequestSentCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingUserManagerInvitationsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingUserManagerTransfersCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feedbacksReceivedCount",
              "storageKey": null
            },
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Brand_company"
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SidebarHeader_me"
        },
        (v0/*: any*/),
        (v6/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ad375896eb97da1d272cd39674f9abd6";

export default node;
