import { useEffect } from 'react';

import { createFragmentContainer, graphql } from 'react-relay';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { MODULES } from '@feedback/roles';

import { routeTo } from '../utils';

import { createQueryRendererModern } from '../../relay/index';
import { hasFeature } from '../../security';

import type { UserInCompanyMiddleware_query } from './__generated__/UserInCompanyMiddleware_query.graphql';

const hasFeedbackExternalModule = (
  company: UserInCompanyMiddleware_query['company'],
) => hasFeature({ company }, [MODULES.FEEDBACK_EXTERNAL]);

const userInCompanyMiddleware = (
  Component: React.ComponentType<{}>,
): React.ComponentType<{}> => {
  type Props = {
    query: UserInCompanyMiddleware_query;
  };

  const UserInCompanyMiddleware = (props: Props) => {
    const { query, history } = props;
    const { company, node } = query;

    const redirectIfNotFound = () => {
      if (!company || !node) {
        history.push(routeTo('notFound.external'));

        return;
      }

      if (node.__typename === 'Group' && !node.visibleExternally) {
        history.push(routeTo('notFound.external'));

        return;
      }

      if (!hasFeedbackExternalModule(company)) {
        return history.push(routeTo('notFound.external'));
      }
    };

    useEffect(() => {
      redirectIfNotFound();
    });

    if (!company || !node) {
      return null;
    }

    if (!hasFeedbackExternalModule(company)) {
      return null;
    }

    return <Component companyId={company.id} {...props} />;
  };

  const UserInCompanyMiddlewareFragmentContainer = createFragmentContainer(
    UserInCompanyMiddleware,
    {
      query: graphql`
        fragment UserInCompanyMiddleware_query on Query {
          node: nodeNick(nick: $nick) {
            __typename
            ... on User {
              id
            }
            ... on Group {
              id
              visibleExternally
            }
          }
          company: companyDomainName {
            id
            _id
            name
            nameFriendly
            logo {
              url(fitIn: true)
            }
            features
          }
        }
      `,
    },
  );

  return withRouter(
    createQueryRendererModern(UserInCompanyMiddlewareFragmentContainer, {
      query: graphql`
        query UserInCompanyMiddlewareQuery($nick: String!) {
          ...UserInCompanyMiddleware_query
        }
      `,
      queriesParams: (props) => ({
        nick: props.match.params.nick,
      }),
      loadingView: null,
    }),
  );
};

export default userInCompanyMiddleware;
