/**
 * @generated SignedSource<<b63a73449c494445f245a4e60101d16f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThemeFragment$data = {
  readonly button: {
    readonly backgroundColor: string | null | undefined;
    readonly textColor: string | null | undefined;
  };
  readonly darkGray: string;
  readonly graphLighterColor: string;
  readonly highlightColor: string;
  readonly id: string;
  readonly lightGray: string;
  readonly mediumGray: string;
  readonly name: string | null | undefined;
  readonly primaryDarkerColor: string;
  readonly primaryLighterColor: string;
  readonly richHeaderColor1: string;
  readonly richHeaderColor2: string;
  readonly richHeaderTextColor: string;
  readonly " $fragmentType": "ThemeFragment";
};
export type ThemeFragment$key = {
  readonly " $data"?: ThemeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThemeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryDarkerColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryLighterColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Style",
      "kind": "LinkedField",
      "name": "button",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textColor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richHeaderColor1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richHeaderColor2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richHeaderTextColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "darkGray",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediumGray",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lightGray",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "graphLighterColor",
      "storageKey": null
    }
  ],
  "type": "Theme",
  "abstractKey": null
};

(node as any).hash = "07499aece08458ecedacd9626874b017";

export default node;
