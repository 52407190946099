import type { RouteType } from '../../utils';

// Templates
import AdminApp from '../../../components/root/AdminApp';

import careerRoutes from '../user/careerRoutes';
import settingsRoutes from '../core/settingsRoutes';
import complaintsRoutes from '../complaint/complaintsRoutes';
import externalComplaintsRoutes from '../complaint/externalComplaintsRoutes';
import oneToOneRoutes from '../oneToOneRoutes';
import feedbackRankingRoutes from '../feedback/feedbackRankingRoutes';
import feedbacksRoutes from '../feedback/feedbacksRoutes';
import goalRoutes from '../goalsRoutes';
import hiringRoutes from '../hiring/hiringRoutes';
import homeRoutes from '../homeRoutes';
import journeyRoutes from '../temp/journeyRoutes';
import managerRoutes from '../user/managerRoutes';
import { teamRoutes } from '../user/teamsRoutes';
import meRoutes from '../user/meRoutes';
import nineBoxRoutes from '../nineBoxRoutes';
import performanceRoutes from '../performanceRoutes';
import permissionsRoutes from '../core/permissionsRoutes';
import planningRoutes from '../planning/planningRoutes';
import pollRoutes from '../poll/pollRoutes';
import socialRoutes from '../social/socialRoutes';
import usersRoutes from '../user/usersRoutes';
import pdiRoutes from '../pdiRoutes';
import apiPluginsRoutes from '../core/apiPluginsRoutes';

import routeHandlers from '../routeHandlers';

const PATH = '/home';

const rootRoutes: RouteType = {
  name: 'rootRoutes',
  path: PATH,
  exact: false,
  component: AdminApp,
  routes: [
    ...settingsRoutes,
    ...careerRoutes,
    ...complaintsRoutes,
    ...externalComplaintsRoutes,
    ...feedbacksRoutes,
    ...hiringRoutes,
    ...managerRoutes,
    ...meRoutes,
    ...permissionsRoutes,
    ...planningRoutes,
    ...usersRoutes,
    ...homeRoutes,
    ...goalRoutes,
    ...oneToOneRoutes,
    ...performanceRoutes,
    ...feedbackRankingRoutes,
    ...journeyRoutes,
    ...socialRoutes,
    ...pollRoutes,
    ...nineBoxRoutes,
    ...pdiRoutes,
    ...teamRoutes,
    ...apiPluginsRoutes,

    // ...productRoutes,
    ...routeHandlers(PATH),
  ],
};

export default rootRoutes;
