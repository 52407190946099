import { commitMutation, graphql } from 'react-relay';

import { Environment } from '../../../relay/index';

import type {
  UserNotificationMarkAsReadInput,
  UserNotificationMarkAsReadResponse,
} from './__generated__/UserNotificationMarkAsReadMutation.graphql';

const mutation = graphql`
  mutation UserNotificationMarkAsReadMutation(
    $input: UserNotificationMarkAsReadInput!
  ) {
    UserNotificationMarkAsRead(input: $input) {
      error
      userNotification {
        id
        body
        creator {
          ...UserAvatar_user
        }
        urlWeb
        params {
          key
          value
        }
        sentAt
        openedAtWeb
        wasRead
      }
      me {
        unreadNotificationsCount
      }
    }
  }
`;

function commit(
  input: $Diff<UserNotificationMarkAsReadInput, { source: string }>,
  onCompleted?: (response: UserNotificationMarkAsReadResponse) => void,
  onError?: (error: Error) => void,
) {
  const variables = {
    input: {
      ...input,
      source: 'WEB',
    },
  };

  return commitMutation(Environment, {
    mutation,
    variables,
    onCompleted,
    onError,
  });
}

export default { commit };
