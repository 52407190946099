import type { RequestParameters, Variables } from 'relay-runtime';
import { Observable } from 'relay-runtime';
import { createClient } from 'graphql-ws';

import config from '../config';
import { getToken } from '../security/auth';

const subscriptionsClient = createClient({
  url: config.GRAPHQL_SUBSCRIPTION_URL,
  connectionParams: () => {
    return {
      Authorization: getToken(),
    };
  },
  // debug
  on: {
    connecting: () => {
      // eslint-disable-next-line
      console.log('graphql-ws connecting');
    },
    connected: () => {
      // eslint-disable-next-line
      console.log('graphql-ws connected');
    },
    closed: (closeEvent) => {
      // eslint-disable-next-line
      console.log('graphql-ws closed', {
        closeEvent,
        code: closeEvent.code,
        reason: closeEvent.reason,
      });
    },
  },
});

export const subscribeFn = (
  operation: RequestParameters,
  variables: Variables,
) => {
  return Observable.create((sink) => {
    if (!operation.text) {
      return sink.error(new Error('Operation text cannot be empty'));
    }

    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      {
        ...sink,
        error: (err) => {
          if (Array.isArray(err))
            // GraphQLError[]
            return sink.error(
              new Error(err.map(({ message }) => message).join(', ')),
            );

          if (err instanceof CloseEvent)
            return sink.error(
              new Error(
                `Socket closed with event ${err.code} ${err.reason || ''}`, // reason will be available on clean closes only
              ),
            );

          return sink.error(err);
        },
      },
    );
  });
};
