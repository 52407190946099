import { createFragmentContainer, graphql } from 'react-relay';

import { routeTo } from '../../../../router/utils/index';
import {
  concatPermissions,
  getPermissionsFromRoutes,
} from '../../../../security/index';

import FeatureFlag from './FeatureFlag';
import RoleFlag from './RoleFlag';

import type { PermissionFlag_user } from './__generated__/PermissionFlag_user.graphql';

type Props = {
  routes?: string[];
  features?: string[];
  roles?: string[];
  children: React.ReactNode;
  user: PermissionFlag_user;
  fallbackComponent?: React.ReactNode;
};

const PermissionFlag = ({
  children,
  routes = [],
  user,
  roles,
  features,
  fallbackComponent,
}: Props) => {
  const routesPath = routes.map((route) => routeTo(route));
  const { requiredFeatures, requiredRoles } = concatPermissions(
    getPermissionsFromRoutes(routesPath),
  );

  return (
    <FeatureFlag
      user={user}
      features={features || requiredFeatures}
      fallbackComponent={fallbackComponent}
    >
      <RoleFlag user={user} roles={roles || requiredRoles}>
        {children}
      </RoleFlag>
    </FeatureFlag>
  );
};

export default createFragmentContainer(PermissionFlag, {
  user: graphql`
    fragment PermissionFlag_user on User {
      ...FeatureFlag_user
      ...RoleFlag_user
    }
  `,
});
