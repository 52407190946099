import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Avatar from './Avatar';

import type { CandidateAvatar_candidate } from './__generated__/CandidateAvatar_candidate.graphql';

type Props = {
  candidate: CandidateAvatar_candidate;
  size: number;
};

const Wrapper = styled.div`
  margin-right: 10px;
`;

const UserAvatar = ({ candidate, size }: Props) => {
  const { name } = candidate;

  return (
    <Wrapper>
      <Avatar name={name} imageUrl={candidate?.profileImage?.url} size={size} />
    </Wrapper>
  );
};

UserAvatar.defaultProps = {
  size: 35,
};

export default createFragmentContainer(UserAvatar, {
  candidate: graphql`
    fragment CandidateAvatar_candidate on Candidate {
      id
      name
      profileImage {
        url
      }
    }
  `,
});
