import { IoMegaphoneOutline } from 'react-icons/io5';

import { routeTo } from '../../../router/utils';
import type { SideBarMenuItemProps } from '../menuItems';

export const ombudsman = ({ query }: SideBarMenuItemProps) => [
  {
    label: query?.me?.company?.preferences?.complaint?.module?.name,
    icon: <IoMegaphoneOutline />,
    link: routeTo('complaints.user.list'),
    isDisplayed: [(me) => !me.company.preferences.complaint.hideMenu],
  },
];

export const ombudsmanAdmin = ({ query, t }: SideBarMenuItemProps) => [
  {
    label: `${query?.me?.company?.preferences?.complaint?.module?.name}`,
    icon: <IoMegaphoneOutline />,
    items: [
      {
        label: t('Reports'),
        link: routeTo('complaints.view.dashboard'),
      },
      {
        label: t('List of Cases'),
        link: routeTo('complaints.view.list'),
        isDisplayed: [(me) => me.isResponsibleForSomeCase],
      },
      {
        label: t('Settings'),
        link: routeTo('complaints.settings'),
      },
    ],
  },
];

export const externalOmbudsmanAdmin = ({ query, t }) => [
  {
    label: `${query?.me?.company?.preferences?.complaintExternal?.module?.name}`,
    icon: <IoMegaphoneOutline />,
    items: [
      {
        label: t('Reports'),
        link: routeTo('complaints.external.view.dashboard'),
      },
      {
        label: t('List of Cases'),
        link: routeTo('complaints.external.view.list'),
      },
      {
        label: t('Settings'),
        link: routeTo('complaints.external.settings'),
      },
    ],
  },
];
