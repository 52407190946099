import * as Sentry from '@sentry/browser';
import { useEffect, Suspense } from 'react';

import PrintProvider, { NoPrint } from 'react-easy-print';
import { withErrorBoundary } from 'react-error-boundary';

import { Provider } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
// import { MomentUTCUtils } from '@feedback/form';
import MomentUtils from '@date-io/moment';

import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/styles'; // v1.x
import { ThemeProvider } from 'styled-components';

import SimpleReactLightbox from 'simple-react-lightbox';
import { I18nextProvider } from 'react-i18next';

import { DialogProvider } from '@feedback/dialog';
import {
  // ThemeGlobal,
  Loading,
  SnackbarProvider as SnackbarProviderUI,
} from '@feedback/ui';

import { SnackbarProvider } from 'notistack';

import { theme } from './components/Theme';
import i18n from './i18n/i18n';
import i18nInterpolation from './i18n/i18nInterpolation';

import 'moment/locale/pt-br';

import createQueryRendererModern from './relay/createQueryRendererModern';
import shouldUseSentry from './shouldUseSentry';
import Store from './store/Store';

import type { Providers_query } from './__generated__/Providers_query.graphql';

moment.locale('pt-br');

// @TODO Show error screen
const ScreenErrorFallbackComponent = (/*{ componentStack, error }*/) => <div />;

const onScreenErrorHandler = (
  error: Error,
  info: {
    componentStack: string;
  },
) => {
  if (shouldUseSentry) {
    Sentry.captureException(error);
    // TODO - capture componentStack
    // eslint-disable-next-line
    console.log('componentStack: ', info.componentStack);
  }
};

type Props = {
  query: Providers_query;
  children: React.ReactNode;
};
const ProvidersInner = ({ query, children, store = Store }: Props) => {
  const { company, me } = query;

  const lang = me?.lang || company?.lang || 'pt-BR';

  const themeQuery = company?.preferences?.theme;
  const i18nCustom = i18nInterpolation(company);

  useEffect(() => {
    const changeLanguage = async () => {
      await i18n.changeLanguage(lang);
      await i18nCustom.changeLanguage(lang);
    };

    changeLanguage();
  }, [lang]);

  const calculatedTheme = theme(themeQuery);

  return (
    <PrintProvider>
      <NoPrint>
        <Provider store={store}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={calculatedTheme}>
              <>
                {/* <ThemeGlobal /> */}
                <MuiThemeProvider theme={calculatedTheme}>
                  <Suspense fallback={<Loading fullScreen={true} />}>
                    <I18nextProvider i18n={i18nCustom}>
                      <SnackbarProviderUI>
                        <SnackbarProvider>
                          <DialogProvider>
                            <MuiPickersUtilsProvider
                              utils={MomentUtils}
                              locale={'pt-br'}
                              moment={moment}
                            >
                              <SimpleReactLightbox>
                                {children}
                              </SimpleReactLightbox>
                            </MuiPickersUtilsProvider>
                          </DialogProvider>
                        </SnackbarProvider>
                      </SnackbarProviderUI>
                    </I18nextProvider>
                  </Suspense>
                </MuiThemeProvider>
              </>
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </NoPrint>
    </PrintProvider>
  );
};

const Providers = withErrorBoundary(ProvidersInner, {
  onError: onScreenErrorHandler,
  FallbackComponent: ScreenErrorFallbackComponent,
});

export const ProvidersFragmentContainer = createFragmentContainer(Providers, {
  query: graphql`
    fragment Providers_query on Query {
      company: companyDomainName {
        lang
        ...modulesNamesFragment @relay(mask: false)
        ...ThemeCompanyFragment @relay(mask: false)
      }
      me {
        lang
      }
    }
  `,
});

const ProvidersQueryRenderer = createQueryRendererModern(
  ProvidersFragmentContainer,
  {
    query: graphql`
      query ProvidersQuery {
        ...Providers_query
      }
    `,
  },
);

export default ProvidersQueryRenderer;
