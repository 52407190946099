import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';

import * as Sentry from '@sentry/browser';

import { useScript } from '@feedback/hooks';

import type { useCrisp_user } from './__generated__/useCrisp_user.graphql';

const userCrispFragment = graphql`
  fragment useCrisp_user on User @inline {
    id
    name
    email
    company {
      nameFriendly
    }
  }
`;

type UseCrisp = {
  userRef: useCrisp_user;
  crispId: string;
};
export const useCrisp = ({ userRef }: UseCrisp) => {
  const user = readInlineData(userCrispFragment, userRef);

  //const onClose = () => window.$crisp.push(['do', 'chat:hide']);

  useEffect(() => {
    if (!user) {
      // Sentry.captureException(
      //   new Error('Crisp not loaded because user not loaded'),
      // );

      return;
    }

    // TODO - only change user if user or company changed
    // crisp was already set
    const { nameFriendly } = user.company;

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.CRISP_CHAT_ID;
    window.CRISP_COOKIE_DOMAIN = window.location.hostname;
    window.CRISP_TOKEN_ID = `${user.id}`;
    window.$crisp.push(['safe', true]);

    // Disable hide crisp
    // window.$crisp.push(['do', 'chat:hide']);
    // window.$crisp.push(['on', 'chat:closed', onClose]);

    if (user.email) {
      window.$crisp.push(['set', 'user:email', [user.email]]);
    }

    if (user.name) {
      window.$crisp.push(['set', 'user:nickname', [user.name]]);
    }

    if (nameFriendly) {
      window.$crisp.push(['set', 'user:company', [nameFriendly]]);
    }
  }, [user]);

  const scriptURL = 'https://client.crisp.chat/l.js';

  // eslint-disable-next-line
  const [loaded, error] = useScript({ src: scriptURL });

  useEffect(() => {
    if (!error) {
      return;
    }

    Sentry.captureException(new Error('Crisp not loaded'));
  }, [error]);
};
