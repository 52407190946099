/**
 * @generated SignedSource<<21e2087d4605a435777389c27dbfc8e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CompanyProjectType = "FEEDBACK_HOUSE" | "LOGID" | "OPEN_PIX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminApp_query$data = {
  readonly company: {
    readonly _id: string;
    readonly id: string;
    readonly logo: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly name: string | null | undefined;
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly me: {
    readonly _id: string;
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly company: {
      readonly _id: string;
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly name: string | null | undefined;
      readonly preferences: {
        readonly core: {
          readonly agreementTerm: {
            readonly active: boolean;
          };
        };
      };
      readonly projectType: CompanyProjectType | null | undefined;
    } | null | undefined;
    readonly hasAcceptAgreementTerm: boolean;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly name: string | null | undefined;
    readonly shouldChangePassword: boolean | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useCrisp_user" | "usePermissionFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Header_query" | "Sidebar_query">;
  readonly " $fragmentType": "AdminApp_query";
};
export type AdminApp_query$key = {
  readonly " $data"?: AdminApp_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminApp_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allRoles",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameFriendly",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminApp_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyPreferences",
              "kind": "LinkedField",
              "name": "preferences",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyCorePreferences",
                  "kind": "LinkedField",
                  "name": "core",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompanyAgreementTermPreferences",
                      "kind": "LinkedField",
                      "name": "agreementTerm",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "active",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useCrisp_user",
          "selections": [
            (v6/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "usePermissionFragment",
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v2/*: any*/),
        (v6/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shouldChangePassword",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAcceptAgreementTerm",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "company",
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companyDomainName",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "fitIn",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "url",
              "storageKey": "url(fitIn:true)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Header_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Sidebar_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "39e4495c0b00145c8f400967ddec9937";

export default node;
