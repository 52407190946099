import { isValidElement } from 'react';

import { ActionButton } from '@feedback/ui';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { TextField } from '@feedback/form';

const styles = {
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  toolbarTitle: {
    flex: 1,
    fontSize: 24,
    fontWeight: 400,
    // alignSelf: 'center',
    marginTop: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarSubtitle: {
    display: 'block',
    flex: 1,
    fontSize: 20,
    fontWeight: 400,
    alignSelf: 'center',
    marginTop: 0,
  },
  toolbarActions: {
    flex: 1,
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  searchBar: {
    flex: 1,
    marginTop: 12,
  },
  searchBarInput: {
    marginBottom: 0,
  },
};

const Toolbar = (props) => {
  const {
    title,
    subtitle,
    titleContainerStyle,
    titleStyle,
    subtitleStyle,
    onRefresh,
    onSearch,
    primaryActionLabel,
    primaryActionOnClick,
  } = props;

  return (
    <div style={{ ...styles.toolbarContainer, ...titleContainerStyle }}>
      <div style={styles.container}>
        {isValidElement(title) ? (
          title
        ) : (
          <h1 style={{ ...styles.toolbarTitle, ...titleStyle }}>{title}</h1>
        )}

        {isValidElement(subtitle)
          ? subtitle
          : subtitle && (
              <h2 style={{ ...styles.toolbarSubtitle, ...subtitleStyle }}>
                {subtitle}
              </h2>
            )}
      </div>

      {onSearch && (
        <div style={styles.searchBar}>
          <TextField
            label='Buscar'
            onChange={({ target }) => onSearch(target.value)}
          />
        </div>
      )}

      <div style={styles.toolbarActions}>
        {onRefresh ? (
          <IconButton onClick={onRefresh}>
            <Icon>refresh</Icon>
          </IconButton>
        ) : null}

        {primaryActionLabel ? (
          <ActionButton variant='text' color='primary' onClick={primaryActionOnClick || {}}>
            {primaryActionLabel}
          </ActionButton>
        ) : null}
      </div>
    </div>
  );
};

export default Toolbar;
