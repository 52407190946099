import loadable from '@loadable/component';

import { COMPANY_PROJECT_TYPES_ENUM } from '@feedback/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/me';

const meRoutes: RouteType[] = [
  {
    name: 'me.account',
    path: `${PATH}/account`,
    labelHeader: 'Minha Conta',
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
    routes: [
      {
        name: 'me.account.edit',
        path: `${PATH}/account/edit`,
        labelHeader: 'Editar Meus Dados',
        requiredRoles: [],
        requiredFeatures: [],
        exact: false,
        component: loadable(() => import('../../../components/user/me/MeEdit')),
        label: 'Dados',
        modalRoutes: [
          {
            name: 'me.account.edit.password',
            path: `${PATH}/account/edit/password`,
            labelHeader: 'Minha Conta',
            component: loadable(
              () => import('../../../components/user/me/ChangePasswordDialog'),
            ),
            exact: true,
            requiredRoles: [],
            requiredFeatures: [],
            modalTitle: 'Alterar Senha',
          },
        ],
      },
      {
        name: 'me.account.profile',
        path: `${PATH}/account/profile`,
        requiredRoles: [],
        requiredFeatures: [],
        requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
        exact: false,
        labelHeader: 'Meu Perfil',
        label: 'Perfil',
        component: loadable(
          () => import('../../../components/career/CareerProfile'),
        ),
        modalRoutes: [
          {
            name: 'me.account.profile.add.jobExperience',
            path: `${PATH}/account/profile/jobExperience/add`,
            labelHeader: 'Carreiras',
            component: loadable(
              () => import('../../../components/career/JobExperienceAdd'),
            ),
            modalTitle: 'Adicionar Experiência',
            exact: true,
            requiredRoles: [],
            requiredFeatures: [],
            requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
          },
          {
            name: 'me.account.profile.add.careerFormation',
            path: `${PATH}/account/profile/careerFormation/add`,
            labelHeader: 'Carreiras',
            component: loadable(
              () => import('../../../components/career/CareerFormationAdd'),
            ),
            modalTitle: 'Adicionar Formação',
            exact: true,
            requiredRoles: [],
            requiredFeatures: [],
            requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
          },
          {
            name: 'me.account.profile.add.careerCourse',
            path: `${PATH}/account/profile/careerCourse/add`,
            labelHeader: 'Carreiras',
            component: loadable(
              () => import('../../../components/career/CareerCourseAdd'),
            ),
            modalTitle: 'Adicionar Curso',
            exact: true,
            requiredRoles: [],
            requiredFeatures: [],
            requiredProjectsTypes: [COMPANY_PROJECT_TYPES_ENUM.FEEDBACK_HOUSE],
          },
        ],
      },
    ],
  },
];

export default meRoutes;
