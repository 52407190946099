import styled from 'styled-components';

export default styled.div`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-border-radius: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      height: 3px;
      background: ${(props) => props.theme.palette.primary.main};
    }
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
