import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const StyledLink = styled.span`
  color: #607389;
  font-size: 12px;
  cursor: pointer;
`;

const StyledBoldLink = styled.span`
  font-weight: bold;
  color: #309dd1;
  font-size: 16px;
  cursor: pointer;
`;

type Props = {
  children: string;
  action: () => void;
};
const Link = ({ children, action, bold = false }: Props) => (
  <Wrapper>
    {bold ? (
      <StyledBoldLink onClick={action}>{children}</StyledBoldLink>
    ) : (
      <StyledLink onClick={action}>{children}</StyledLink>
    )}
  </Wrapper>
);

export default Link;
