import { useMemo } from 'react';

import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { usePageView } from '@feedback/hooks';

import type { RouteType } from '@feedback/router';

import { useDomainName } from '../../../router/middlewares/useDomainName';
import { createQueryRendererModern } from '../../../relay';
import { drawRoutes } from '../../../router/utils';
import { getDomainName } from '../../../security/auth';
import DomainNotFound from '../../core/DomainNotFound';

import type { LoginApp_query$data } from './__generated__/LoginApp_query.graphql'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom left, #d9e0e9, #eee, #d9e0e9);
`;

type Props = {
  routes: RouteType[];
  query: LoginApp_query$data
};
const LoginApp = ({ routes, query, ...props }: Props) => {
  const { company } = query;

  usePageView();

  const routesMemo = useMemo(() => {
    return drawRoutes(routes, null, {
      routes, query, ...props,
    });
  }, []);

  const validDomainName = useDomainName(company);
  
  if (!validDomainName) {
    return <DomainNotFound domainName={getDomainName()} />;
  }

  return <Container>{routesMemo}</Container>;
};

const LoginAppFragmentContainer = createFragmentContainer(LoginApp, {
  query: graphql`
    fragment LoginApp_query on Query {
      company: companyDomainName {
        ...useDomainName_company @relay(mask: false)
      }
    }
  `,
});

export default createQueryRendererModern(LoginAppFragmentContainer, {
  query: graphql`
    query LoginAppQuery {
      ...LoginApp_query
    }
  `,
});
