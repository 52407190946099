import { MODULES } from '@feedback/roles';

import { routeTo } from '../../../../router/utils/index';
import { concatPermissions, getPermissionsFromRoutes } from '../../../../security/index';

const getPermission = (routes: string[], permission: string[], defaultPermission: string[]) => {
  if (permission) {
    return permission;
  }

  if (routes) {
    const routesPath = routes.map(route => routeTo(route));

    return concatPermissions(getPermissionsFromRoutes(routesPath));
  }

  return defaultPermission;
};

export const getFeatures = (routes: string[], features: string[]) => {
  const defaultFeatures = [MODULES.TEMP];
  const permissions = getPermission(routes, features, defaultFeatures);

  if (permissions.requiredFeatures) {
    return permissions.requiredFeatures;
  }

  return permissions;
};

export const getRoles = (routes: string[], roles: string[]) => {
  const defaultRoles: string[] = [];
  const permissions = getPermission(routes, roles, defaultRoles);

  if (permissions.requiredRoles) {
    return permissions.requiredRoles;
  }

  return permissions;
};
