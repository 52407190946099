import { Component } from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';

import { ResourceSelectField } from '@feedback/select';

import type { JobExamSelectField_query } from './__generated__/JobExamSelectField_query.graphql';

type JobExam = {
  id: string;
  title: string;
};

type Props = {
  query: JobExamSelectField_query;
  multiple?: boolean;
  onChange: (node: any) => void;
  relay: RelayRefetchProp;
  hintText?: string | null;
  displayUnderline?: boolean | null;
  fullWidth?: boolean;
  value?: JobExam;
};

type State = {
  input: string;
  search: string;
};

class JobExamSelectField extends Component<Props, State> {
  static defaultProps = {
    hintText: 'Buscar prova',
    name: 'jobExam.id',
  };

  getItemFromNode = ({ id, title }) => {
    return {
      id,
      name: title,
    };
  };

  getFilterFragmentVariables = (state) => {
    const { search } = state;
    const { values } = this.props;
    const jobExamId = values?.jobExam?.id;

    return {
      search,
      ...(jobExamId ? { jobExamId } : {}),
    };
  };

  getRenderVariables = (state) => {
    const { search } = state;

    return {
      search,
    };
  };

  render() {
    const { query, relay, ...props } = this.props;

    return (
      <ResourceSelectField
        relay={relay}
        connection={query.jobExams}
        getItemFromNode={this.getItemFromNode}
        getRenderVariables={this.getRenderVariables}
        getFilterFragmentVariables={this.getFilterFragmentVariables}
        {...props}
      />
    );
  }
}

const JobExamSelectFieldRefetchContainer = createRefetchContainer(
  JobExamSelectField,
  {
    query: graphql`
      fragment JobExamSelectField_query on Query
      @argumentDefinitions(
        count: { type: Int, defaultValue: 30 }
        search: { type: String, defaultValue: "" }
        after: { type: String }
        isActive: { type: Boolean, defaultValue: true }
      ) {
        jobExams(
          first: $count
          search: $search
          after: $after
          isActive: $isActive
        ) @connection(key: "JobExamSelectField_jobExams", filters: ["search"]) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `,
  },
  graphql`
    query JobExamSelectFieldRefetchQuery(
      $count: Int
      $search: String
      $after: String
      $isActive: Boolean
    ) {
      ...JobExamSelectField_query
        @arguments(
          count: $count
          search: $search
          after: $after
          isActive: $isActive
        )
    }
  `,
);

export default JobExamSelectFieldRefetchContainer;
