import type { RouteType } from '@feedback/router';

import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@feedback/roles';

const PATH = '/home/goals';

const goalsRoutes: RouteType[] = [
  {
    name: 'goals.group.admin.overview',
    path: `${PATH}/groups/admin/overview`,
    labelHeader: ({ t }) => t('Goals Groups'),
    component: loadable(
      () =>
        import(
          '../../components/goalGroup/list/detail/GoalGroupAdminListDetail'
        ),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.GOALS.ADMIN.ALL,
      ROLES.GOALS.GOAL_GROUP.VIEW,
      ROLES.GOALS.USER.ALL,
    ],
    requiredFeatures: [MODULES.GOALS],
    routes: [
      {
        name: 'goals.group.admin.overview.list',
        path: `${PATH}/groups/admin/overview/company`,
        labelHeader: ({ t }) => t('Goals Groups'),
        label: ({ t }) => t('List'),
        component: loadable(
          () => import('../../components/goalGroup/list/GoalsGroupAllList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOAL_GROUP.VIEW,
        ],
        requiredFeatures: [MODULES.GOALS],
      },
      {
        name: 'goals.group.admin.overview.users.list',
        path: `${PATH}/groups/admin/overview/users/list`,
        labelHeader: ({ t }) => t('Users'),
        label: ({ t }) => t('Users'),
        component: loadable(
          () => import('../../components/goalGroup/byUser/GoalGroupListByUser'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.USER.VIEW,
        ],
        requiredFeatures: [MODULES.GOALS],
      },
      {
        name: 'goals.group.admin.overview.area.list',
        path: `${PATH}/groups/admin/overview/area/list`,
        labelHeader: ({ t }) => t('Areas'),
        label: ({ t }) => t('Areas'),
        component: loadable(
          () => import('../../components/goalGroup/byArea/GoalGroupListByArea'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.AREA.VIEW,
        ],
        requiredFeatures: [MODULES.GOALS],
      },
      {
        name: 'goals.groups.settings.general',
        path: `${PATH}/groups/admin/overview/settings`,
        label: ({ t }) => t('Settings'),
        labelHeader: ({ t }) => t('Settings'),
        component: loadable(
          () =>
            import(
              '../../components/goalGroup/generalSettings/GoalGroupGeneralSettings'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL],
        requiredFeatures: [MODULES.GOALS],
      },
    ],
  },
  {
    name: 'goals.group.user.overview',
    path: `${PATH}/groups/mygoals/:type/:user`,
    labelHeader: ({ t }) => t('Goals'),
    component: loadable(
      () =>
        import(
          '../../components/goalGroup/list/detail/GoalGroupUserListDetail'
        ),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL, ROLES.GOALS.GOALS.ALL],
    requiredFeatures: [MODULES.GOALS],
    routes: [
      {
        name: 'goals.groups.user.overview.list',
        path: `${PATH}/groups/mygoals/:type/:user/list`,
        labelHeader: ({ t }) => t('My goal groups'),
        label: ({ t }) => t('My goal groups'),
        component: loadable(
          () => import('../../components/goalGroup/list/GoalsGroupList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
      },
      {
        name: 'goals.groups.user.overview.areas',
        path: `${PATH}/groups/mygoals/:type/:user/areas`,
        labelHeader: ({ t }) => t('Areas'),
        label: ({ t }) => t('Areas'),
        component: loadable(
          () =>
            import(
              '../../components/goalGroup/byArea/UsersGoalGroupListByArea'
            ),
        ),
        exact: true,
        requiredRoles: [ROLES.GOALS.AREA.VIEW],
        requiredFeatures: [MODULES.GOALS],
      },
    ],
  },
  {
    name: 'goals.groups.list.user',
    path: `${PATH}/groups/list/:type/:user`,
    labelHeader: ({ t }) => t('Goal Group'),
    component: loadable(
      () => import('../../components/goalGroup/list/GoalsGroupList'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL, ROLES.GOALS.GOALS.ALL],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.groups.goals',
    path: `${PATH}/groups/goals`,
    labelHeader: 'Metas',
    component: loadable(
      () => import('../../components/goalGroup/all/AllGoals'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL],
    requiredFeatures: [MODULES.GOALS, MODULES.TEMP],
  },
  {
    name: 'goals.groups.add',
    path: `${PATH}/groups/add`,
    labelHeader: ({ t }) => t('Create Goal Group'),
    component: loadable(
      () => import('../../components/goalGroup/add/new/GoalGroupNewAddForm'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.GOALS.ADMIN.ALL,
      ROLES.GOALS.GOAL_GROUP.CREATE,
    ],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.groups.detail.view',
    path: `${PATH}/groups/detail/:id/:type/:user`,
    labelHeader: 'Metas',
    component: loadable(
      () => import('../../components/goalGroup/detail/GoalGroupDetails'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.GOALS],
    routes: [
      {
        name: 'goals.group.user.goals',
        path: `${PATH}/groups/detail/:id/:type/:user/list/user/goals`,
        labelHeader: ({ t }) => t('My Goals'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/list/GoalGroupGoalList'),
        ),
        label: ({ t }) => t('My Goals'),
      },
      {
        name: 'goals.group.new.goals',
        path: `${PATH}/groups/detail/:id/:type/:user/list/goals`,
        labelHeader: ({ t }) => t('ADMIN GOALS'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/list/GoalGroupGoalList'),
        ),
        label: ({ t }) => t('ADMIN GOALS'),
      },
      {
        name: 'goals.group.info',
        path: `${PATH}/groups/detail/:id/:type/:user/info`,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        labelHeader: 'Metas',
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/GoalGroupInfo'),
        ),
        label: ({ t }) => t('Details'),
      },
      {
        name: 'goals.group.users',
        path: `${PATH}/groups/detail/:id/:type/:user/users`,
        labelHeader: 'Metas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/users/GoalGroupUserListNew'),
        ),
        label: ({ t }) => t('Users'),
      },
      {
        name: 'goals.group.rules',
        path: `${PATH}/groups/detail/:id/:type/:user/rules`,
        labelHeader: 'Metas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/rules/GoalGroupRules'),
        ),
        label: ({ t }) => t('Permissions'),
      },
      {
        name: 'goals.group.checkpoint',
        path: `${PATH}/groups/detail/:id/:type/:user/checkpoint`,
        labelHeader: 'Metas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS, MODULES.TEMP],
        exact: true,
        component: loadable(
          () =>
            import('../../components/goalGroup/checkpoint/GoalGroupCheckpoint'),
        ),
        label: ({ t }) => t('Checkpoint'),
      },
      {
        name: 'goals.group.history',
        path: `${PATH}/groups/detail/:id/:type/:user/history`,
        labelHeader: 'Metas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/history/GoalGroupHistory'),
        ),
        label: ({ t }) => t('History'),
      },
      {
        name: 'goals.group.costRevenuesCenter',
        path: `${PATH}/groups/detail/:id/:type/:user/costRevenueCenter`,
        labelHeader: 'Metas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/goalGroup/areas/GoalGroupCostRevenueCenter'
            ),
        ),
        label: ({ t }) => t('Areas'),
      },
      {
        name: 'goals.group.adjust',
        path: `${PATH}/groups/detail/:id/:type/:user/edit`,
        labelHeader: 'Metas',
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.GOALS.ADMIN.ALL,
          ROLES.GOALS.GOALS.ALL,
        ],
        requiredFeatures: [MODULES.GOALS],
        exact: true,
        component: loadable(
          () => import('../../components/goalGroup/adjust/GoalGroupEdit'),
        ),
        label: ({ t }) => t('Settings'),
      },
    ],
  },
  {
    name: 'goals.group.new.goal.add',
    labelHeader: ({ t }) => t('Goals'),
    path: `${PATH}/groups/goal/add`,
    component: loadable(
      () => import('../../components/goals/add/form/GoalAddForm'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.GOALS.ADMIN.ALL,
      ROLES.GOALS.GOALS.ALL,
      ROLES.GOALS.USER.ALL,
      ROLES.GOALS.USER.VIEW,
    ],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.group.add.goal',
    labelHeader: ({ t }) => t('Create Goal'),
    path: `${PATH}/groups/:id/new`,
    component: loadable(
      () => import('../../components/goals/add/GlobalGoalAdd'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.template.add',
    labelHeader: 'Criar Template Meta',
    path: `${PATH}/groups/:id/template/new`,
    component: loadable(
      () => import('../../components/goals/add/GoalAddTemplate'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.add',
    path: `${PATH}/groups/:id/myGoals/new`,
    labelHeader: 'Criar Meta',
    component: loadable(
      () => import('../../components/goals/add/IndividualGoalAdd'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL, ROLES.GOALS.GOALS.ALL],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goal.to.user.add',
    path: `${PATH}/groups/:id/user/:user/new`,
    labelHeader: 'Criar Meta',
    component: loadable(
      () => import('../../components/goals/add/IndividualGoalAdd'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL, ROLES.GOALS.GOALS.ALL],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.add.costRevenueCenter',
    path: `${PATH}/groups/:goalGroupId/costRevenueCenter/:id/new`,
    labelHeader: 'Criar Meta',
    component: loadable(
      () => import('../../components/goals/add/CostRevenueCenterGoalAdd'),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [MODULES.GOALS],
  },
  {
    name: 'goals.edit',
    path: `${PATH}/edit/:id/`,
    labelHeader: 'Editar Meta',
    component: loadable(() => import('../../components/goals/form/GoalEdit')),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.GOALS.ADMIN.ALL, ROLES.GOALS.GOALS.ALL],
    requiredFeatures: [MODULES.GOALS],
  },
];

export const areaRoute = 'goals.group.costRevenuesCenter';

export const rhRoutes = [
  'goals.group.user.goals',
  'goals.group.new.goals',
  'goals.group.rules',
  'goals.group.adjust',
  'goals.group.checkpoint',
  'goals.group.history',
  'goals.group.users',
  'goal.to.user.add',
  'goals.group.costRevenuesCenter',
];
export const userRoutes = [
  'goals.group.user.goals',
  'goals.group.new.goals',
  'goals.group.history',
  'goals.group.info',
];

export const GOALS_PARAMS = {
  COMPANY: 'company',
  USER: 'user',
  PROFILE: 'profile',
  COST_REVENUE_CENTER: 'costRevenueCenter',
};

export const GOAL_TYPES = {
  JOB_ROLE: 'JOB_ROLE',
};

export default goalsRoutes;
