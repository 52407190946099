/**
 * @generated SignedSource<<3b853b9059f70cdb5c4aa8bd95028ec7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginAppQuery$variables = Record<PropertyKey, never>;
export type LoginAppQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoginApp_query">;
};
export type LoginAppQuery = {
  response: LoginAppQuery$data;
  variables: LoginAppQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginAppQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoginApp_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginAppQuery",
    "selections": [
      {
        "alias": "company",
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyDomainName",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameFriendly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "logo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "fitIn",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(fitIn:true)"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd49112ead37ec685c26845acbdcaeb4",
    "id": null,
    "metadata": {},
    "name": "LoginAppQuery",
    "operationKind": "query",
    "text": "query LoginAppQuery {\n  ...LoginApp_query\n}\n\nfragment LoginApp_query on Query {\n  company: companyDomainName {\n    id\n    _id\n    name\n    nameFriendly\n    logo {\n      url(fitIn: true)\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "70a716b419b5d4a6713a956bbaed5a05";

export default node;
