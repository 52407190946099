import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const LinkWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`;

const Link = styled.span`
  color: #607389;
  font-size: 14px;
`;

const Header = ({ history }) => (
  <LinkWrapper onClick={() => history.goBack()}>
    <ChevronLeftIcon />
    <Link>Voltar</Link>
  </LinkWrapper>
);

export default Header;
